import { Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BreadCrumbsStyled = styled(Breadcrumbs)`
  && {
    .MuiBreadcrumbs-separator {
      margin: 0;
      font-size: ${({ theme }) => theme.fontSize.lg}px;
    }
  }
`;

export const LinkStyled = styled(Link)<{ active: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.lg}px;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme, active }) =>
    active ? theme.colors.primary : theme.colors.secondary};
  text-decoration: none;
`;
