import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';

export const weatherForecastParams = (
  params: CustomURLSearchParams,
  regionId: string
) => {
  regionId.length > 0 && params.set('regionId', regionId);

  return params;
};

export const diseaseForecastParams = (
  params: CustomURLSearchParams,
  regionId: string
) => {
  regionId.length > 0 && params.set('regionId', regionId);

  return params;
};

export const sprayingForecastParams = (
  params: CustomURLSearchParams,
  regionId: string
) => {
  regionId.length > 0 && params.set('regionId', regionId);

  return params;
};
