import React from 'react';
import { useTranslation } from 'react-i18next';
import Alert from 'src/components/modules/torq/alert';
import { useReportContext } from 'src/contexts/report';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { Container, EmptyMessage, Title } from './styles';

const Alerts = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { alerts } = useReportContext();
  const padding = isMobile ? '0 1rem' : '0';
  const isEmpty = alerts.length === 0;

  return (
    <Container style={{ padding }}>
      <Title>{t('report.activeAlerts')}</Title>
      {isEmpty && <EmptyMessage>{t('report.noActiveAlerts')}</EmptyMessage>}
      {alerts.map((alert) => (
        <Alert alert={alert} mode="card" details={false} key={alert.id} />
      ))}
    </Container>
  );
};

export default Alerts;
