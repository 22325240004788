import { SagaIterator } from '@redux-saga/types';
import { IOrderAggregation, IOrderSummary } from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  downloadInvoiceById,
  downloadReport,
  getCultivations,
  getOrders,
  getOrdersIds,
  getSummary,
} from '../../services/orders';
import {
  FetchStatusEnum,
  OrdersDownloadInvoiceByIdAction,
  OrdersDownloadReportAction,
  OrdersGetAction,
  OrdersGetSummaryAction,
  OrdersIdsGetAction,
} from '../../types';
import { download, downloadInvoice } from '../../utils/downloadFiles';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getOrders.type, getOrdersEffectSaga),
  takeLatest(actions.getOrdersIds.type, getOrdersIdsEffectSaga),
  takeLatest(actions.getCultivations.type, getCultivationsEffectSaga),
  takeLatest(actions.getSummary.type, getSummaryEffectSaga),
  takeLatest(actions.downloadInvoiceById.type, downloadInvoiceByIdEffectSaga),
  takeLatest(actions.downloadReport.type, downloadReportEffectSaga),
  takeLatest(actions.getUrlReport.type, getUrlReportEffectSaga),
];

export type getOrdersResponse = {
  total: number;
  results: IOrderAggregation[];
};

export function* getOrdersEffectSaga(
  action: OrdersGetAction
): SagaIterator<void> {
  try {
    const { results: ordersAggregation, total }: getOrdersResponse = yield call(
      getOrders,
      action.payload
    );
    yield put(
      actions.orders({ ordersAggregation, total, page: action.payload.page })
    );
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export function* getSummaryEffectSaga(
  action: OrdersGetSummaryAction
): SagaIterator<void> {
  try {
    const summary: IOrderSummary[] = yield call(getSummary, action.payload);
    yield put(actions.summary({ summary }));
    yield put(
      actions.updateStatusSummary({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusSummary({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}

export function* downloadReportEffectSaga(
  action: OrdersDownloadReportAction
): SagaIterator<void> {
  try {
    const data = yield call(downloadReport, action.payload);
    const url = data.url;
    yield call(download, url, 'pedidos.xlsx');

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export function* getUrlReportEffectSaga(
  action: OrdersDownloadReportAction
): SagaIterator<void> {
  try {
    const data = yield call(downloadReport, action.payload);
    const url = data.url;

    yield put(actions.reportUrl({ url }));

    yield put(
      actions.updateStatusReport({
        fetchStatusDownload: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });

    yield put(
      actions.updateStatusReport({
        fetchStatusDownload: FetchStatusEnum.Failure,
      })
    );
  }
}

type DownloadInvoiceResponse = {
  pdfUrl?: string;
  xmlUrl?: string;
};

export function* downloadInvoiceByIdEffectSaga(
  action: OrdersDownloadInvoiceByIdAction
): SagaIterator<void> {
  try {
    const { pdfUrl, xmlUrl }: DownloadInvoiceResponse = yield call(
      downloadInvoiceById,
      action.payload
    );
    yield call(downloadInvoice, action.payload.invoiceId, pdfUrl, xmlUrl);
    yield put(
      actions.updateStatusDownload({
        fetchStatusDownload: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });

    yield put(
      actions.updateStatusDownload({
        fetchStatusDownload: FetchStatusEnum.Failure,
      })
    );
  }
}

export type getOrdersIdsResponse = {
  total: number;
  results: string[];
};

export function* getOrdersIdsEffectSaga(
  action: OrdersIdsGetAction
): SagaIterator<void> {
  try {
    const { results: ordersIds, total }: getOrdersIdsResponse = yield call(
      getOrdersIds,
      action.payload
    );
    yield put(actions.ordersIds({ ordersIds, total }));
    yield put(
      actions.updateStatusIds({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusIds({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}

export type getCultivationsResponse = {
  total: number;
  results: string[];
};

export function* getCultivationsEffectSaga(): SagaIterator<void> {
  try {
    const { results: cultivations, total }: getCultivationsResponse =
      yield call(getCultivations);
    yield put(actions.cultivations({ cultivations, total }));
    yield put(
      actions.updateStatusCultivations({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusCultivations({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}
