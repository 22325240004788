import { Grid, IconButton, SvgIcon } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../../../../assets/logo.svg';
import { MenuProvider } from '../../../../providers/menu';
import Content from './content';
import {
  MainContainer,
  MenuContentContainer,
  MenuIcon,
  ClearIcon,
} from './styles';

const headerHeight = 48;

const MobileMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenu = useCallback(() => {
    setShowMenu((prevState) => !prevState);
  }, []);

  return (
    <MenuProvider menuState={{ showMenu, setShowMenu }}>
      <MainContainer
        $headerHeight={headerHeight}
        container
        item
        xs={12}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Link to={'/dashboard'}>
          <Grid container item xs={6} alignItems="center">
            <SvgIcon
              component={Logo}
              viewBox="0 0 500 153"
              style={{
                width: 75,
                height: 60,
              }}
            />
          </Grid>
        </Link>
        <Grid
          container
          item
          xs={6}
          alignItems="center"
          justifyContent="flex-end"
        >
          <IconButton onClick={handleMenu}>
            {showMenu ? <ClearIcon /> : <MenuIcon />}
          </IconButton>
        </Grid>
        <MenuContentContainer showMenu={showMenu} headerHeight={headerHeight}>
          <Content headerHeight={headerHeight} />
        </MenuContentContainer>
      </MainContainer>
    </MenuProvider>
  );
};

export default MobileMenu;
