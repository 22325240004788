import { IconButton } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import Modal from 'src/components/elements/modal';

import Caption from './caption';

const GrowthCurveCaption: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <IconButton
        size="small"
        onClick={() => setOpenModal(true)}
        style={{ marginLeft: 5 }}
      >
        <InfoOutlined />
      </IconButton>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        content={<Caption />}
        width={'auto'}
      />
    </>
  );
};

export default GrowthCurveCaption;
