import React from 'react';

import Loader from '../../elements/loader';
import { Container } from './styles';

const GlobalLoader: React.FC = () => {
  return (
    <Container>
      <Loader size={30} />
    </Container>
  );
};

export default GlobalLoader;
