import React from 'react';
import { Link } from 'react-router-dom';

import { ErrorTypography, ErrorWrapper, ErrorIcon } from './styles';

interface Props {
  message?: string;
  icon?: boolean;
  link?: {
    route: string;
    text: string;
  };
}

const ErrorContainer = ({ message, icon = true, link }: Props): JSX.Element => {
  return (
    <ErrorWrapper>
      {icon && <ErrorIcon />}
      <ErrorTypography variant="body2" color="error">
        {message} {link && <Link to={link.route}>{link.text}</Link>}
      </ErrorTypography>
    </ErrorWrapper>
  );
};

export default ErrorContainer;
