import { Grid, Typography } from '@material-ui/core';
import { DefaultThemeColorsType } from '@sinagro/frontend-shared/themes';
import { MdDelete, MdDeleteForever } from 'react-icons/md';
import styled from 'styled-components';

export const Container = styled(Grid)`
  padding: 0.5rem;
  text-align: center;
`;

export const Title = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.xl}px;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    margin-bottom: 2rem;
  }
`;

export const Text = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    margin-bottom: 1rem;
  }
`;

export const ButtonContainer = styled(Grid)`
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

export const DeleteIcon = styled(MdDelete)<{
  $color: DefaultThemeColorsType;
}>`
  color: ${({ theme, $color }) => theme.colors[$color]};
  font-size: ${({ theme }) => theme.fontSize.xl}px !important;
  margin-right: 0.5rem;
`;

export const DeleteAccountIcon = styled(MdDeleteForever)`
  color: ${({ theme }) => theme.colors.danger};
  font-size: 44px;
  margin-bottom: 1.5rem;
`;
