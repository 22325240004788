import { ReportGet, ReportShareLinkGet } from '../../types';
import api, { Api } from '../api';

export function getReport(payload: ReportGet) {
  Api.setToken(payload.token);
  return api.get(`/report`);
}

export function getShareLink(payload: ReportShareLinkGet) {
  return api.post(`/report/${payload.regionId}`, payload);
}
