import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { emailRegex } from '../../utils/regexes';

export const useLoginSchema = () => {
  const { t } = useTranslation();

  const emailOrPhoneMessage = t('form.invalidField', {
    field: t('login.emailOrPhone'),
  });

  return yup.object().shape(
    {
      emailOrPhone: yup.string().when('emailOrPhone', {
        is: (value?: string) => {
          // It is phone then
          if (value && value.includes('(')) {
            return true;
          }
          // It is email then
          return false;
        },
        then: yup
          .string()
          .min(14, emailOrPhoneMessage)
          .max(15, emailOrPhoneMessage),
        otherwise: yup.string().matches(emailRegex, emailOrPhoneMessage),
      }),
      password: yup.string().required(t('form.required')),
      stayConnected: yup.bool(),
    },
    [['emailOrPhone', 'emailOrPhone']]
  );
};
