import type {
  OrdersDownloadInvoiceById,
  OrdersDownloadReport,
  OrdersGet,
  OrdersGetSummary,
  OrdersIdsGet,
} from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';
import api from '../api';
import {
  ordersFiltersParams,
  ordersIdsParams,
  ordersPageParams,
} from './queryParams';

export function getOrders(payload: OrdersGet) {
  let params = new CustomURLSearchParams({});

  params = ordersFiltersParams(params, payload.filters);
  params = ordersPageParams(params, payload);
  return api.get(`/order/aggregated?${params}`);
}

export function getOrdersIds(payload: OrdersIdsGet) {
  let params = new CustomURLSearchParams({});
  params = ordersIdsParams(params, payload);
  return api.get(`filters/order/available?${params}`);
}

export function getSummary(payload: OrdersGetSummary) {
  let params = new CustomURLSearchParams({});
  params = ordersFiltersParams(params, payload.filters);

  return api.get(`/order/aggregated/summary?${params}`);
}

export function downloadInvoiceById(payload: OrdersDownloadInvoiceById) {
  const url = `/order/invoice/${payload.invoiceId}/download`;
  return api.get(url);
}

export function downloadReport(payload: OrdersDownloadReport) {
  let params = new CustomURLSearchParams({});
  params = ordersFiltersParams(params, payload.filters);

  return api.get(`/order/download?${params}`);
}

export function getCultivations() {
  return api.get(`filters/cultivation/available`);
}

export default { downloadInvoiceById };
