import React from 'react';
import { useTheme } from 'styled-components';

import { LoaderContainer } from './styles';

type LoaderProps = {
  color?: string;
  size?: number;
};

const Loader = ({ color, size = 16 }: LoaderProps) => {
  const theme = useTheme();
  return <LoaderContainer color={color ?? theme.colors.primary} size={size} />;
};

export default Loader;
