import { takeLatest } from 'redux-saga/effects';

import { actions } from '../slice';
import {
  getRemittancesDefensivesAndSpecialtiesDeliveredEffectSaga,
  getRemittancesDefensivesAndSpecialtiesHandlingEffectSaga,
  getRemittancesDefensivesAndSpecialtiesInTransitEffectSaga,
  getRemittancesDefensivesAndSpecialtiesScheduledEffectSaga,
} from './defensivesAndSpecialties';
import {
  getRemittancesFertilizersDeliveredEffectSaga,
  getRemittancesFertilizersHandlingEffectSaga,
  getRemittancesFertilizersInTransitEffectSaga,
  getRemittancesFertilizersScheduledEffectSaga,
} from './fertilizers';
import {
  getRemittancesOthersDeliveredEffectSaga,
  getRemittancesOthersHandlingEffectSaga,
  getRemittancesOthersInTransitEffectSaga,
  getRemittancesOthersScheduledEffectSaga,
} from './others';
import {
  getRemittancesSeedsDeliveredEffectSaga,
  getRemittancesSeedsHandlingEffectSaga,
  getRemittancesSeedsInTransitEffectSaga,
  getRemittancesSeedsScheduledEffectSaga,
} from './seeds';

export default [
  takeLatest(
    actions.getRemittancesDefensivesAndSpecialtiesScheduled.type,
    getRemittancesDefensivesAndSpecialtiesScheduledEffectSaga
  ),
  takeLatest(
    actions.getRemittancesDefensivesAndSpecialtiesHandling.type,
    getRemittancesDefensivesAndSpecialtiesHandlingEffectSaga
  ),
  takeLatest(
    actions.getRemittancesDefensivesAndSpecialtiesInTransit.type,
    getRemittancesDefensivesAndSpecialtiesInTransitEffectSaga
  ),
  takeLatest(
    actions.getRemittancesDefensivesAndSpecialtiesDelivered.type,
    getRemittancesDefensivesAndSpecialtiesDeliveredEffectSaga
  ),
  takeLatest(
    actions.getRemittancesSeedsScheduled.type,
    getRemittancesSeedsScheduledEffectSaga
  ),
  takeLatest(
    actions.getRemittancesSeedsHandling.type,
    getRemittancesSeedsHandlingEffectSaga
  ),
  takeLatest(
    actions.getRemittancesSeedsInTransit.type,
    getRemittancesSeedsInTransitEffectSaga
  ),
  takeLatest(
    actions.getRemittancesSeedsDelivered.type,
    getRemittancesSeedsDeliveredEffectSaga
  ),
  takeLatest(
    actions.getRemittancesFertilizersScheduled.type,
    getRemittancesFertilizersScheduledEffectSaga
  ),
  takeLatest(
    actions.getRemittancesFertilizersHandling.type,
    getRemittancesFertilizersHandlingEffectSaga
  ),
  takeLatest(
    actions.getRemittancesFertilizersInTransit.type,
    getRemittancesFertilizersInTransitEffectSaga
  ),
  takeLatest(
    actions.getRemittancesFertilizersDelivered.type,
    getRemittancesFertilizersDeliveredEffectSaga
  ),
  takeLatest(
    actions.getRemittancesOthersScheduled.type,
    getRemittancesOthersScheduledEffectSaga
  ),
  takeLatest(
    actions.getRemittancesOthersHandling.type,
    getRemittancesOthersHandlingEffectSaga
  ),
  takeLatest(
    actions.getRemittancesOthersInTransit.type,
    getRemittancesOthersInTransitEffectSaga
  ),
  takeLatest(
    actions.getRemittancesOthersDelivered.type,
    getRemittancesOthersDeliveredEffectSaga
  ),
];
