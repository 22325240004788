import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: fixed;
  z-index: 1;
  bottom: 2rem;
  right: 2rem;
`;

export const FloatingMessage = styled(Grid)`
  width: '100%';
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => `0px 8px 16px ${theme.colors.shadow}`};
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;

  p:first-child {
    padding-bottom: 0.5rem;
  }

  div {
    width: 0px;
    height: 0px;

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${({ theme }) => theme.colors.white};

    position: absolute;
    right: 0.75rem;
    bottom: 3rem;
  }
`;

export const Text = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.lg}px;
  }
`;
