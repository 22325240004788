import { Grid, Link, Typography } from '@material-ui/core';
import { RecoverMethodEnum } from '@sinagro/shared';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Logo from '../../components/elements/logo';
import Button from '../../components/elements/materialUI/button';
import WelcomeLayout from '../../components/layout/welcomeLayout';
import {
  ButtonContainer,
  LinkContainer,
  LogoContainer,
  TitleContainer,
  TitleLabel,
} from './styles';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigation = useCallback(
    (recoverMethod: RecoverMethodEnum) => {
      navigate('/forgot-password-form', {
        state: { recoverMethod: recoverMethod },
      });
    },
    [navigate]
  );

  const handleReturnToLogin = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  return (
    <WelcomeLayout>
      <Grid
        container
        item
        xs={12}
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid
          container
          item
          xs={12}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ flex: 1 }}
        >
          <LogoContainer item xs={12}>
            <Logo />
          </LogoContainer>
          <TitleContainer item xs={12}>
            <TitleLabel variant="h1" color="textPrimary">
              {t('forgotPassword.optionText')}
            </TitleLabel>
          </TitleContainer>
          <ButtonContainer item xs={12} lg={8}>
            <Button
              onClick={() => handleNavigation(RecoverMethodEnum.Email)}
              variant="contained"
              color="primary"
              fullWidth
            >
              <Typography variant="h6">{t('forgotPassword.email')}</Typography>
            </Button>
          </ButtonContainer>
          <ButtonContainer item xs={12} lg={8}>
            <Button
              onClick={() => handleNavigation(RecoverMethodEnum.Whatsapp)}
              variant="contained"
              color="primary"
              fullWidth
            >
              <Typography variant="h6">
                {t('forgotPassword.whatsapp')}
              </Typography>
            </Button>
          </ButtonContainer>
          <LinkContainer item xs={12}>
            <Link
              component="button"
              onClick={handleReturnToLogin}
              variant="body2"
              underline="always"
              style={{ fontSize: 14 }}
            >
              <>{t('forgotPassword.returnToLogin')}</>
            </Link>
          </LinkContainer>
        </Grid>
      </Grid>
    </WelcomeLayout>
  );
};

export default ForgotPassword;
