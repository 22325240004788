import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const SubItem = styled(Grid)`
  button {
    padding: 0px !important;
    min-width: auto;
    margin-bottom: 0.5rem;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  p {
    line-height: normal;
    padding-left: 0.5rem;
  }
`;
