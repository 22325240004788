import { NotificationGet } from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';

export const notificationPageParams = (
  params: CustomURLSearchParams,
  payload: NotificationGet
) => {
  payload.limit && params.set('limit', payload.limit.toString());

  payload.page && params.set('page', payload.page.toString());

  return params;
};
