import { Grid } from '@material-ui/core';
import { Email, Facebook, Instagram, LinkedIn } from '@material-ui/icons';
import React from 'react';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { SocialMedia, StyledLink } from './styles';

type Props = {
  openMenu: boolean;
};

const Footer = ({ openMenu }: Props) => {
  const isMobile = useIsMobile();
  const align = 'flex-start';
  const direction = 'row';

  return (
    <Grid
      container
      style={{
        paddingTop: '3rem',
        flexGrow: 4,
      }}
    >
      <SocialMedia
        item
        container
        xs={12}
        direction={direction}
        alignItems={align}
        $isMobile={isMobile}
        $openMenu={openMenu}
      >
        <StyledLink
          href="mailto:portaldocliente@gruposinova.com.br"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Email color="action" />
        </StyledLink>

        <StyledLink
          href="https://www.facebook.com/GRUPOSINOVAAGRO"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Facebook color="action" />
        </StyledLink>

        <StyledLink
          href="https://www.instagram.com/grupo.sinova="
          rel="noopener noreferrer"
          target="_blank"
        >
          <Instagram color="action" />
        </StyledLink>

        <StyledLink
          href="https://www.linkedin.com/company/gruposinova"
          rel="noopener noreferrer"
          target="_blank"
        >
          <LinkedIn color="action" />
        </StyledLink>
      </SocialMedia>
    </Grid>
  );
};

export default Footer;
