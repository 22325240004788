import { SprayingForecastDTO } from '@sinagro/shared';
import React from 'react';
import { formatDateHour, formattedCurrentHour } from 'src/utils/format';

import { sprayingRecommendation } from '../../../../../pages/torq/utils/sprayingRecommendation';
import HeaderItem from './headerItem';
import {
  Container,
  Content,
  Bar,
  Spraying,
  Risk,
  ItemContainer,
  Tab,
} from './styles';

type ItemProps = {
  item: SprayingForecastDTO;
  showHeader: boolean;
};

const Item: React.FC<ItemProps> = ({ item, showHeader }) => {
  const sprayingColor = item.sprayingRecommendation
    ? sprayingRecommendation[item.sprayingRecommendation].color
    : sprayingRecommendation['default'].color;

  const currentHour = formattedCurrentHour();

  const isActive = currentHour === formatDateHour(item.date, true);

  return (
    <ItemContainer className="hourColumn">
      {showHeader && <Tab $isActive={isActive} />}
      <Container $isActive={isActive}>
        {showHeader && <HeaderItem item={item} isActive={isActive} />}
        <Content>
          <Bar>
            <Spraying $color={sprayingColor}>
              <Risk>{item.risk?.code}</Risk>
            </Spraying>
          </Bar>
        </Content>
      </Container>
    </ItemContainer>
  );
};

export default React.memo(Item);
