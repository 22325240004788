import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import LogoSVG from '../../../assets/logo_horizontal.svg';
import { Container, Content, Divider, Link, Title } from './styles';

const ReportHeader: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const padding = isMobile ? '1rem' : '1rem 2rem';

  const handleLogin = useCallback(() => {
    navigate('/');
  }, [navigate]);

  if (isMobile) {
    return (
      <Container style={{ padding }}>
        <img src={LogoSVG} />
        <Title>{t('report.title')}</Title>
      </Container>
    );
  }

  return (
    <Container style={{ padding }}>
      <Title>{t('report.title')}</Title>
      <Content>
        <Link onClick={handleLogin} underline="always">
          {t('report.moreDetailsLink')}
        </Link>
        <Divider />
        <img src={LogoSVG} />
      </Content>
    </Container>
  );
};

export default ReportHeader;
