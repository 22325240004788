import { SagaIterator } from '@redux-saga/types';
import {
  IFinancialSummaryItem,
  PayableBondDTO,
  PaymentConditionEnum,
} from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getInvoicesNumbers,
  getNextBills,
  getNextBillsSummary,
} from '../../../services/financialPayable/v2';
import { FetchStatusEnum } from '../../../types';
import {
  InvoicesNumbersGetAction,
  NextBillsGetAction,
  NextBillsSummaryGetAction,
} from '../../../types/v2';
import { handleError } from '../../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getNextBillsInTerm.type, getNextBillsInTermEffectSaga),
  takeLatest(actions.getNextBillsBarter.type, getNextBillsBarterEffectSaga),
  takeLatest(actions.getNextBillsInCash.type, getNextBillsInCashEffectSaga),
  takeLatest(actions.getNextBillsPartial.type, getNextBillsPartialEffectSaga),
  takeLatest(actions.getNextBillsPrus.type, getNextBillsPrusEffectSaga),
  takeLatest(actions.getNextBillsOther.type, getNextBillsOtherEffectSaga),
  takeLatest(
    actions.getNextBillsInTermSummary.type,
    getNextBillsInTermSummaryEffectSaga
  ),
  takeLatest(
    actions.getNextBillsBarterSummary.type,
    getNextBillsBarterSummaryEffectSaga
  ),
  takeLatest(
    actions.getNextBillsInCashSummary.type,
    getNextBillsInCashSummaryEffectSaga
  ),
  takeLatest(
    actions.getNextBillsPartialSummary.type,
    getNextBillsPartialSummaryEffectSaga
  ),
  takeLatest(
    actions.getNextBillsOtherSummary.type,
    getNextBillsOtherSummaryEffectSaga
  ),
  takeLatest(actions.getInvoicesNumbers.type, getInvoicesNumbersEffectSaga),
];

export type getNextBillsResponse = {
  total: number;
  results: PayableBondDTO[];
  page: number;
};

export function* getNextBillsInTermEffectSaga(
  action: NextBillsGetAction
): SagaIterator<void> {
  try {
    const filtersInTerm = {
      ...action.payload.filters,
      paymentCondition: PaymentConditionEnum.InTerm,
    };
    const { results: nextBills, total: nextBillsTotal }: getNextBillsResponse =
      yield call(getNextBills, { ...action.payload, filters: filtersInTerm });
    yield put(
      actions.nextBillsInTerm({
        nextBills,
        nextBillsTotal,
      })
    );
    yield put(
      actions.updateStatusNextBillsInTerm({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusNextBillsInTerm({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getNextBillsBarterEffectSaga(
  action: NextBillsGetAction
): SagaIterator<void> {
  try {
    const filtersBarter = {
      ...action.payload.filters,
      paymentCondition: PaymentConditionEnum.Barter,
    };
    const { results: nextBills, total: nextBillsTotal }: getNextBillsResponse =
      yield call(getNextBills, { ...action.payload, filters: filtersBarter });
    yield put(
      actions.nextBillsBarter({
        nextBills,
        nextBillsTotal,
      })
    );
    yield put(
      actions.updateStatusNextBillsBarter({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusNextBillsBarter({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getNextBillsInCashEffectSaga(
  action: NextBillsGetAction
): SagaIterator<void> {
  try {
    const filtersInCash = {
      ...action.payload.filters,
      paymentCondition: PaymentConditionEnum.InCash,
    };
    const { results: nextBills, total: nextBillsTotal }: getNextBillsResponse =
      yield call(getNextBills, { ...action.payload, filters: filtersInCash });
    yield put(
      actions.nextBillsInCash({
        nextBills,
        nextBillsTotal,
      })
    );
    yield put(
      actions.updateStatusNextBillsInCash({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusNextBillsInCash({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getNextBillsPartialEffectSaga(
  action: NextBillsGetAction
): SagaIterator<void> {
  try {
    const filtersPartial = {
      ...action.payload.filters,
      paymentCondition: PaymentConditionEnum.Partial,
    };
    const { results: nextBills, total: nextBillsTotal }: getNextBillsResponse =
      yield call(getNextBills, { ...action.payload, filters: filtersPartial });
    yield put(
      actions.nextBillsPartial({
        nextBills,
        nextBillsTotal,
      })
    );
    yield put(
      actions.updateStatusNextBillsPartial({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusNextBillsPartial({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getNextBillsPrusEffectSaga(
  action: NextBillsGetAction
): SagaIterator<void> {
  try {
    const filtersPrus = {
      ...action.payload.filters,
      prus: true,
    };
    const { results: nextBills, total: nextBillsTotal }: getNextBillsResponse =
      yield call(getNextBills, { ...action.payload, filters: filtersPrus });
    yield put(
      actions.nextBillsPrus({
        nextBills,
        nextBillsTotal,
      })
    );
    yield put(
      actions.updateStatusNextBillsPrus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusNextBillsPrus({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getNextBillsOtherEffectSaga(
  action: NextBillsGetAction
): SagaIterator<void> {
  try {
    const filtersOther = {
      ...action.payload.filters,
      paymentCondition: PaymentConditionEnum.Other,
    };
    const { results: nextBills, total: nextBillsTotal }: getNextBillsResponse =
      yield call(getNextBills, { ...action.payload, filters: filtersOther });
    yield put(
      actions.nextBillsOther({
        nextBills,
        nextBillsTotal,
      })
    );
    yield put(
      actions.updateStatusNextBillsOther({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusNextBillsOther({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export type getNextBillsSummaryResponse = {
  totals: IFinancialSummaryItem[];
};

export function* getNextBillsInTermSummaryEffectSaga(
  action: NextBillsSummaryGetAction
): SagaIterator<void> {
  try {
    const filtersInTerm = {
      ...action.payload.filters,
      paymentCondition: PaymentConditionEnum.InTerm,
    };
    const { totals }: getNextBillsSummaryResponse = yield call(
      getNextBillsSummary,
      {
        ...action.payload,
        filters: filtersInTerm,
      }
    );
    yield put(
      actions.nextBillsInTermSummaryTotals({
        summaryTotals: totals,
      })
    );
    yield put(
      actions.updateStatusNextBillsInTermSummary({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusNextBillsInTermSummary({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getNextBillsBarterSummaryEffectSaga(
  action: NextBillsSummaryGetAction
): SagaIterator<void> {
  try {
    const filtersBarter = {
      ...action.payload.filters,
      paymentCondition: PaymentConditionEnum.Barter,
    };
    const { totals }: getNextBillsSummaryResponse = yield call(
      getNextBillsSummary,
      {
        ...action.payload,
        filters: filtersBarter,
      }
    );
    yield put(
      actions.nextBillsBarterSummaryTotals({
        summaryTotals: totals,
      })
    );
    yield put(
      actions.updateStatusNextBillsBarterSummary({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusNextBillsBarterSummary({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getNextBillsInCashSummaryEffectSaga(
  action: NextBillsSummaryGetAction
): SagaIterator<void> {
  try {
    const filtersInCash = {
      ...action.payload.filters,
      paymentCondition: PaymentConditionEnum.InCash,
    };
    const { totals }: getNextBillsSummaryResponse = yield call(
      getNextBillsSummary,
      {
        ...action.payload,
        filters: filtersInCash,
      }
    );
    yield put(
      actions.nextBillsInCashSummaryTotals({
        summaryTotals: totals,
      })
    );
    yield put(
      actions.updateStatusNextBillsInCashSummary({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusNextBillsInCashSummary({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getNextBillsPartialSummaryEffectSaga(
  action: NextBillsSummaryGetAction
): SagaIterator<void> {
  try {
    const filtersPartial = {
      ...action.payload.filters,
      paymentCondition: PaymentConditionEnum.Partial,
    };
    const { totals }: getNextBillsSummaryResponse = yield call(
      getNextBillsSummary,
      {
        ...action.payload,
        filters: filtersPartial,
      }
    );
    yield put(
      actions.nextBillsPartialSummaryTotals({
        summaryTotals: totals,
      })
    );
    yield put(
      actions.updateStatusNextBillsPartialSummary({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusNextBillsPartialSummary({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getNextBillsOtherSummaryEffectSaga(
  action: NextBillsSummaryGetAction
): SagaIterator<void> {
  try {
    const filtersOther = {
      ...action.payload.filters,
      paymentCondition: PaymentConditionEnum.Other,
    };
    const { totals }: getNextBillsSummaryResponse = yield call(
      getNextBillsSummary,
      {
        ...action.payload,
        filters: filtersOther,
      }
    );
    yield put(
      actions.nextBillsOtherSummaryTotals({
        summaryTotals: totals,
      })
    );
    yield put(
      actions.updateStatusNextBillsOtherSummary({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusNextBillsOtherSummary({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export type getInvoicesNumbersResponse = {
  results: string[];
  total: number;
};

export function* getInvoicesNumbersEffectSaga(
  action: InvoicesNumbersGetAction
): SagaIterator<void> {
  try {
    const { results: invoices, total }: getInvoicesNumbersResponse = yield call(
      getInvoicesNumbers,
      action.payload
    );
    yield put(actions.invoicesNumbers({ invoices, total }));
    yield put(
      actions.updateStatusInvoicesNumbers({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusInvoicesNumbers({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
