import {
  internetConnectionTimeout,
  internetConnectionTimeoutErrorMessage,
} from '@sinagro/frontend-shared/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useSelector, useDispatch, actions } from '../../../state/store';
import { Icon } from './styles';

const InternetConnection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isTestingConnection, setIsTestingConnection] = useState(true);
  const connection = useSelector(
    (state) => state.shared.internetConnection.connection
  );

  useEffect(() => {
    dispatch(actions.shared.internetConnection.testConnection());
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setIsTestingConnection(true);
      dispatch(actions.shared.internetConnection.execTestConnection());
    }, internetConnectionTimeout);

    setIsTestingConnection(false);
  }, [dispatch, isTestingConnection]);

  useEffect(() => {
    if (connection === internetConnectionTimeoutErrorMessage) {
      toast(t('commons.error.timeoutInternertConnection'), {
        type: 'error',
        icon: <Icon />,
      });
    }
  }, [connection, t]);

  return null;
};

export default InternetConnection;
