/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit';

import {
  ConnectionAction,
  ConnectionUpdateErrorAction,
  ConnectionUpdateStatusAction,
  FetchStatusEnum,
  InternetConnectionSlice,
} from '../../types';

export const initialState: InternetConnectionSlice = {
  connection: '',
  fetchStatus: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'internetConnection',
  initialState,
  reducers: {
    clear: () => initialState,
    connection: (state, action: ConnectionAction) => {
      state.connection = action.payload.connection;
    },
    testConnection: (state) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    execTestConnection: () => {},
    updateStatus: (state, action: ConnectionUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: ConnectionUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
