import { InteractionTypeEnum } from '@sinagro/shared';

import { SendCampaignInteraction } from '../../types';
import api from '../api';

export function sendCampaignInteraction(
  payload: SendCampaignInteraction
): Promise<SendCampaignInteraction> {
  return api.post(`/interaction/${InteractionTypeEnum.Campaign}`, payload);
}
