import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const HeaderContainer = styled(Grid)<{ $openMenu: boolean }>`
  button {
    padding: 1rem;
    min-width: auto;
    align-self: ${({ $openMenu }) => ($openMenu ? 'flex-end' : 'center')};
    border-radius: 50%;
    margin-bottom: 0.5rem;
  }

  svg {
    height: 24px;
    width: 24px;
  }
`;
