import { DiseaseForecastDTO, HourlyWeatherForecastDTO } from '@sinagro/shared';
import {
  compareDate,
  formatDateAndMonth,
  formattedCurrentDate,
  formatTimestampDate,
  isToday,
} from 'src/utils/format';

import i18n from '../../../i18n';

export const getMaxValue = (
  hourly: HourlyWeatherForecastDTO[],
  type: 'precipitation' | 'temperature' | 'humidity'
) => {
  const maxValue = hourly.reduce((accumulator, currentItem) => {
    return accumulator[type] > currentItem[type] ? accumulator : currentItem;
  });

  return maxValue[type];
};

export const getDifferenceBetweenDates = (
  lastDate: Date | string | undefined,
  currentDate: Date | string | undefined
) => {
  if (!lastDate || !currentDate) return '-';
  const differenceInDays = Math.round(
    (formatTimestampDate(currentDate) - formatTimestampDate(lastDate)) /
      (1000 * 60 * 60 * 24)
  );

  const differenceInHours = differenceInDays * 24;
  const formattedDifferenceInHours = differenceInHours <= 24 ? 24 : 48;
  const isHour = differenceInDays < 3;

  const difference = isHour ? formattedDifferenceInHours : differenceInDays;
  const type = isHour
    ? i18n.t('torq.spraying.hours')
    : i18n.t('torq.spraying.days');

  return `${difference} ${type}`;
};

export const formattedDisease = (disease: DiseaseForecastDTO) => {
  const currentDate = formattedCurrentDate();
  return disease.daily.filter((item) => {
    if (compareDate(item.date, currentDate, true)) {
      return item;
    }
  });
};

export const formatItemDate = (itemDate: string) => {
  if (isToday(itemDate, true)) return i18n.t('torq.disease.today');
  else return formatDateAndMonth(itemDate, true);
};
