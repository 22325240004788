import { createSlice } from '@reduxjs/toolkit';

import {
  FetchStatusEnum,
  NewsFetchedAction,
  NewsSlice,
  NewsUpdateStatusAction,
} from '../../types';

const initialState: NewsSlice = {
  news: [],
  fetchStatus: FetchStatusEnum.None,
};

export const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    clear: () => initialState,
    getNews: (state) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    news: (state, action: NewsFetchedAction) => {
      state.news = action.payload.news;
    },
    updateStatus: (state, action: NewsUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
