import { analytics } from '@sinagro/frontend-shared/firebase';
import { AnalyticsEventsEnum } from '@sinagro/frontend-shared/sharedTypes';

export const logAnalyticsEvent = (
  eventName: string,
  data?: Record<string, string | number | boolean>
) => {
  analytics().logEvent(eventName, {
    ...data,
    platform: 'web',
  });
};

export const analyticsLogger = {
  downloadInvoice(context: 'order' | 'payable') {
    logAnalyticsEvent(AnalyticsEventsEnum.DownloadInvoice, {
      context,
    });
  },
  scheduleAppointment(context: 'tech' | 'store') {
    if (context === 'tech') {
      logAnalyticsEvent(AnalyticsEventsEnum.ScheduleAppointmentTech);
    }
    if (context === 'store') {
      logAnalyticsEvent(AnalyticsEventsEnum.ScheduleAppointmentStore);
    }
  },
  changeOrderColumns(data: Record<string, boolean>) {
    logAnalyticsEvent(AnalyticsEventsEnum.OrderTableColumnChange, data);
  },
  orderGroupBy(context: 'farm' | 'deal') {
    logAnalyticsEvent(AnalyticsEventsEnum.OrderGroupyBy, {
      context,
    });
  },
  whatsapp(
    context: 'login' | 'signup' | 'general',
    data?: Record<string, string | number | boolean>
  ) {
    if (context === 'login') {
      logAnalyticsEvent(AnalyticsEventsEnum.WhatsappLinkLogin, data);
    }
    if (context === 'signup') {
      logAnalyticsEvent(AnalyticsEventsEnum.WhatsappLinkSignUp, data);
    }
    if (context === 'general') {
      logAnalyticsEvent(AnalyticsEventsEnum.WhatsappLinkGeneral, data);
    }
  },
  campaign(context: 'open' | 'click') {
    if (context === 'open') {
      logAnalyticsEvent(AnalyticsEventsEnum.CampaignOpen);
    }
    if (context === 'click') {
      logAnalyticsEvent(AnalyticsEventsEnum.CampaignClick);
    }
  },
};
