import { createSlice } from '@reduxjs/toolkit';

import {
  WeatherForecastFetchAction,
  WeatherForecastGetAction,
  WeatherForecastUpdateErrorAction,
  WeatherForecastUpdateStatusAction,
  DiseaseForecastFetchAction,
  DiseaseForecastGetAction,
  DiseaseForecastUpdateStatusAction,
  SprayingForecastGetAction,
  SprayingForecastFetchAction,
  SprayingForecastUpdateStatusAction,
  FetchStatusEnum,
  TorqSlice,
  FarmDetailsUpdateStatusAction,
  FarmDetailsGetAction,
  FarmDetailsFetchAction,
  CountActiveAlertsGetAction,
  CountActiveAlertsFetchAction,
  CountActiveAlertsUpdateStatusAction,
  ActiveAlertsGetAction,
  ActiveAlertsFetchAction,
  ActiveAlertsUpdateStatusAction,
  RemoteSensingImagesGetAction,
  RemoteSensingImagesFetchAction,
  RemoteSensingImagesUpdateStatusAction,
  GrowthCurveChartUpdateStatusAction,
  GrowthCurveChartGetAction,
  GrowthCurveChartFetchAction,
} from '../../types';

export const initialState: TorqSlice = {
  weatherForecast: { daily: [], hourly: [] },
  diseaseForecast: [],
  sprayingForecast: [],
  farmDetails: null,
  alerts: [],
  activeAlerts: 0,
  remoteSensingImages: null,
  growthCurveChart: null,
  fetchStatus: FetchStatusEnum.None,
  fetchStatusDisease: FetchStatusEnum.None,
  fetchStatusSpraying: FetchStatusEnum.None,
  fetchStatusFarmDetails: FetchStatusEnum.None,
  fetchStatusCountActiveAlerts: FetchStatusEnum.None,
  fetchStatusActiveAlerts: FetchStatusEnum.None,
  fetchStatusRemoteSensingImages: FetchStatusEnum.None,
  fetchStatusGrowthCurveChart: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'torq',
  initialState,
  reducers: {
    clear: () => initialState,
    getWeatherForecast: (state, _: WeatherForecastGetAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    weatherForecast: (state, action: WeatherForecastFetchAction) => {
      const { weatherForecast } = action.payload;
      state.weatherForecast = weatherForecast;
    },
    getDiseaseForecast: (state, _: DiseaseForecastGetAction) => {
      state.fetchStatusDisease = FetchStatusEnum.Fetching;
    },
    diseaseForecast: (state, action: DiseaseForecastFetchAction) => {
      const { diseaseForecast } = action.payload;
      state.diseaseForecast = diseaseForecast;
    },
    getSprayingForecast: (state, _: SprayingForecastGetAction) => {
      state.fetchStatusSpraying = FetchStatusEnum.Fetching;
    },
    sprayingForecast: (state, action: SprayingForecastFetchAction) => {
      const { sprayingForecast } = action.payload;
      state.sprayingForecast = sprayingForecast;
    },
    getFarmDetails: (state, _: FarmDetailsGetAction) => {
      state.fetchStatusFarmDetails = FetchStatusEnum.Fetching;
    },
    farmDetails: (state, action: FarmDetailsFetchAction) => {
      const { farmDetails } = action.payload;
      state.farmDetails = farmDetails;
    },
    getCountActiveAlerts: (state, _: CountActiveAlertsGetAction) => {
      state.fetchStatusCountActiveAlerts = FetchStatusEnum.Fetching;
    },
    countActiveAlerts: (state, action: CountActiveAlertsFetchAction) => {
      const { activeAlerts } = action.payload;
      state.activeAlerts = activeAlerts;
    },
    getActiveAlerts: (state, _: ActiveAlertsGetAction) => {
      state.fetchStatusActiveAlerts = FetchStatusEnum.Fetching;
    },
    activeAlerts: (state, action: ActiveAlertsFetchAction) => {
      const { alerts } = action.payload;
      state.alerts = alerts;
    },
    getRemoteSensingImages: (state, _: RemoteSensingImagesGetAction) => {
      state.fetchStatusRemoteSensingImages = FetchStatusEnum.Fetching;
    },
    remoteSensingImages: (state, action: RemoteSensingImagesFetchAction) => {
      const { remoteSensingImages } = action.payload;
      state.remoteSensingImages = remoteSensingImages;
    },
    getGrowthCurveChart: (state, _: GrowthCurveChartGetAction) => {
      state.fetchStatusGrowthCurveChart = FetchStatusEnum.Fetching;
    },
    growthCurveChart: (state, action: GrowthCurveChartFetchAction) => {
      const { growthCurveChart } = action.payload;
      state.growthCurveChart = growthCurveChart;
    },
    updateStatusSpraying: (
      state,
      action: SprayingForecastUpdateStatusAction
    ) => {
      state.fetchStatusSpraying = action.payload.fetchStatus;
    },
    updateStatusDisease: (state, action: DiseaseForecastUpdateStatusAction) => {
      state.fetchStatusDisease = action.payload.fetchStatus;
    },
    updateStatusFarmDetails: (state, action: FarmDetailsUpdateStatusAction) => {
      state.fetchStatusFarmDetails = action.payload.fetchStatus;
    },
    updateStatusCountActiveAlerts: (
      state,
      action: CountActiveAlertsUpdateStatusAction
    ) => {
      state.fetchStatusCountActiveAlerts = action.payload.fetchStatus;
    },
    updateStatusActiveAlerts: (
      state,
      action: ActiveAlertsUpdateStatusAction
    ) => {
      state.fetchStatusActiveAlerts = action.payload.fetchStatus;
    },
    updateStatusRemoteSensingImages: (
      state,
      action: RemoteSensingImagesUpdateStatusAction
    ) => {
      state.fetchStatusRemoteSensingImages = action.payload.fetchStatus;
    },
    updateStatusGrowthCurveChart: (
      state,
      action: GrowthCurveChartUpdateStatusAction
    ) => {
      state.fetchStatusGrowthCurveChart = action.payload.fetchStatus;
    },
    updateStatus: (state, action: WeatherForecastUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: WeatherForecastUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
