import { createSlice } from '@reduxjs/toolkit';

import {
  FetchStatusEnum,
  FullHarvestReferencesFetchedAction,
  HarvestReferencesFetchedAction,
  HarvestReferenceSlice,
  HarvestReferenceUpdateErrorAction,
  HarvestReferenceUpdateStatusAction,
} from '../../types';

const initialState: HarvestReferenceSlice = {
  harvestReferences: [],
  fullHarvestReferences: [],
  fetchStatus: FetchStatusEnum.None,
  fetchStatusFull: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'harvestReference',
  initialState,
  reducers: {
    clear: () => initialState,
    getHarvestReferences: (state) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    harvestReferences: (state, action: HarvestReferencesFetchedAction) => {
      state.harvestReferences = action.payload.harvestReferences;
    },
    getFullHarvestReferences: (state) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    fullHarvestReferences: (
      state,
      action: FullHarvestReferencesFetchedAction
    ) => {
      state.fullHarvestReferences = action.payload.fullHarvestReferences;
    },
    updateStatus: (state, action: HarvestReferenceUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateStatusFull: (state, action: HarvestReferenceUpdateStatusAction) => {
      state.fetchStatusFull = action.payload.fetchStatus;
    },
    updateError: (state, action: HarvestReferenceUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
