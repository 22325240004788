import { SagaIterator } from '@redux-saga/types';
import {
  GroupedRemittanceSegmentEnum,
  RemittanceStatusEnum,
} from '@sinagro/shared';
import { SharedError, unknownError } from '@sinagro/shared/errors';
import { call, put } from 'redux-saga/effects';

import { getRemittance } from '../../../../services/tracking/v2';
import { FetchStatusEnum } from '../../../../types';
import { RemittanceGetAction, RemittanceResponse } from '../../../../types/v2';
import { handleError } from '../../../errors/sagas';
import { actions } from '../slice';

export function* getRemittancesDefensivesAndSpecialtiesScheduledEffectSaga(
  action: RemittanceGetAction
): SagaIterator<void> {
  try {
    const filters = {
      ...action.payload.filters,
      segment: GroupedRemittanceSegmentEnum.DefensivesAndSpecialties,
      remittanceStatus: RemittanceStatusEnum.Scheduled,
    };

    const payload = { ...action.payload, filters };
    const { results: remittances, total }: RemittanceResponse = yield call(
      getRemittance,
      payload
    );

    yield put(
      actions.remittancesDefensivesAndSpecialtiesScheduled({
        remittances,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusDefensivesAndSpecialtiesScheduled({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError | undefined = yield call(handleError, {
      error,
    });

    yield put(
      actions.updateError({
        error: knownError || unknownError,
      })
    );

    yield put(
      actions.updateStatusDefensivesAndSpecialtiesScheduled({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getRemittancesDefensivesAndSpecialtiesHandlingEffectSaga(
  action: RemittanceGetAction
): SagaIterator<void> {
  try {
    const filters = {
      ...action.payload.filters,
      segment: GroupedRemittanceSegmentEnum.DefensivesAndSpecialties,
      remittanceStatus: RemittanceStatusEnum.Handling,
    };

    const payload = { ...action.payload, filters };
    const { results: remittances, total }: RemittanceResponse = yield call(
      getRemittance,
      payload
    );

    yield put(
      actions.remittancesDefensivesAndSpecialtiesHandling({
        remittances,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusDefensivesAndSpecialtiesHandling({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError | undefined = yield call(handleError, {
      error,
    });

    yield put(
      actions.updateError({
        error: knownError || unknownError,
      })
    );

    yield put(
      actions.updateStatusDefensivesAndSpecialtiesHandling({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getRemittancesDefensivesAndSpecialtiesInTransitEffectSaga(
  action: RemittanceGetAction
): SagaIterator<void> {
  try {
    const filters = {
      ...action.payload.filters,
      segment: GroupedRemittanceSegmentEnum.DefensivesAndSpecialties,
      remittanceStatus: RemittanceStatusEnum.InTransit,
    };

    const payload = { ...action.payload, filters };
    const { results: remittances, total }: RemittanceResponse = yield call(
      getRemittance,
      payload
    );

    yield put(
      actions.remittancesDefensivesAndSpecialtiesInTransit({
        remittances,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusDefensivesAndSpecialtiesInTransit({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError | undefined = yield call(handleError, {
      error,
    });

    yield put(
      actions.updateError({
        error: knownError || unknownError,
      })
    );

    yield put(
      actions.updateStatusDefensivesAndSpecialtiesInTransit({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getRemittancesDefensivesAndSpecialtiesDeliveredEffectSaga(
  action: RemittanceGetAction
): SagaIterator<void> {
  try {
    const filters = {
      ...action.payload.filters,
      segment: GroupedRemittanceSegmentEnum.DefensivesAndSpecialties,
      remittanceStatus: RemittanceStatusEnum.Delivered,
    };

    const payload = { ...action.payload, filters };
    const { results: remittances, total }: RemittanceResponse = yield call(
      getRemittance,
      payload
    );

    yield put(
      actions.remittancesDefensivesAndSpecialtiesDelivered({
        remittances,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusDefensivesAndSpecialtiesDelivered({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError | undefined = yield call(handleError, {
      error,
    });

    yield put(
      actions.updateError({
        error: knownError || unknownError,
      })
    );

    yield put(
      actions.updateStatusDefensivesAndSpecialtiesDelivered({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
