import type { FiltersType } from '../../../types';
import { ReceivablesGet } from '../../../types/v2';
import { CustomURLSearchParams } from '../../../utils/customUrlSearchParams';

export const receivablesFiltersParams = (
  params: CustomURLSearchParams,
  filters: FiltersType
) => {
  filters.harvestReferences.length > 0 &&
    params.set('harvestReference', filters.harvestReferences.join(','));

  filters.contract.length > 0 &&
    params.set('contractId', `%${filters.contract}%`);

  filters.searchTerm.length > 0 &&
    params.set('search', `%${filters.searchTerm}%`);

  filters.dueDate.startDate &&
    params.set(
      'dueDate(min)',
      filters.dueDate.startDate.toDateString() + ' 00:00:00'
    );

  filters.dueDate.endDate &&
    params.set(
      'dueDate(max)',
      filters.dueDate.endDate.toDateString() + ' 23:59:59'
    );

  filters.documentDate.startDate &&
    params.set(
      'documentDate(min)',
      filters.documentDate.startDate.toDateString() + ' 00:00:00'
    );

  filters.documentDate.endDate &&
    params.set(
      'documentDate(max)',
      filters.documentDate.endDate.toDateString() + ' 23:59:59'
    );

  filters.compensationDate.startDate &&
    params.set(
      'compensationDate(min)',
      filters.compensationDate.startDate.toDateString() + ' 00:00:00'
    );

  filters.compensationDate.endDate &&
    params.set(
      'compensationDate(max)',
      filters.compensationDate.endDate.toDateString() + ' 23:59:59'
    );

  filters.operationReceivableType &&
    params.set('operationType', filters.operationReceivableType);

  filters.operationType && params.set('operationType', filters.operationType);
  filters.invoiceNumber && params.set('invoiceNumber', filters.invoiceNumber);
  filters.clientId && params.set('clientId', filters.clientId);

  return params;
};

export const receivablesPageParams = (
  params: CustomURLSearchParams,
  payload: ReceivablesGet
) => {
  payload.limit && params.set('limit', payload.limit.toString());

  payload.page && params.set('page', payload.page.toString());

  return params;
};
