import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../materialUI/button';
import { Svg, SvgConteiner } from './styles';

const ErrorElement = () => {
  const { t } = useTranslation();

  const handleGoBack = () => {
    window.location.replace('/');
  };

  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      justifyContent="center"
      alignItems="center"
      style={{ flex: 1 }}
    >
      <Grid item>
        <SvgConteiner>
          <Svg />
        </SvgConteiner>
        <Button
          onClick={handleGoBack}
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: '1rem' }}
        >
          <Typography variant="h6">{t('commons.goBack')}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ErrorElement;
