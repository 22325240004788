import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import { replaceMask } from '@sinagro/frontend-shared/validations';
import { ValidationCodeTypeEnum } from '@sinagro/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormPhoneField, FormTextField } from 'src/components/modules/form';

import Loader from '../../../components/elements/loader';
import Button from '../../../components/elements/materialUI/button';
import { actions, useSelector, useDispatch } from '../../../state/store';
import { FieldContainer } from '../styles';
import ValidationCode from '../validationCode';
import { useEmailSchema, usePhoneSchema } from './schema';
import { Container, Footer, Text, Title } from './styles';

type EditFormType = { data: string };

type Props = {
  validationCodeType: ValidationCodeTypeEnum;
};

const EditField = ({ validationCodeType }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schemaEmail = useEmailSchema();
  const schemaPhone = usePhoneSchema();

  const [emailOrPhone, setEmailOrPhone] = useState('');

  const isEmail = validationCodeType === ValidationCodeTypeEnum.Email;

  const title = isEmail
    ? t('profile.editField.newEmail')
    : t('profile.editField.newPhoneNumber');

  const error = useSelector(
    (state) => state.shared.userProfile.errorUpdateUserEmailOrPhone
  );

  const fetchStatus = useSelector(
    (state) => state.shared.userProfile.fetchStatusUpdateUserEmailOrPhone
  );

  const schema = isEmail ? schemaEmail : schemaPhone;

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<EditFormType>({
    resolver: yupResolver(schema),
  });

  const value = watch('data');
  useEffect(() => {
    if (value) {
      setValue('data', value.trim(), {
        shouldValidate: true,
      });
    }
  }, [value, setValue]);

  const onSubmit: SubmitHandler<EditFormType> = useCallback(
    (form: EditFormType) => {
      const sanitizedForm = {
        data: isEmail ? form.data : `55${replaceMask(form.data)}`,
        type: validationCodeType,
      };
      setEmailOrPhone(sanitizedForm.data);
      dispatch(
        actions.shared.userProfile.updateUserEmailOrPhone(sanitizedForm)
      );
    },
    [dispatch, isEmail, validationCodeType]
  );

  useEffect(() => {
    if (errors)
      dispatch(
        actions.shared.userProfile.updateErrorUpdateUserEmailOrPhone({
          errorUpdateUserEmailOrPhone: { errorRef: '', message: '' },
        })
      );
  }, [dispatch, errors.data?.message, errors]);

  if (fetchStatus === FetchStatusEnum.Fetching) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (fetchStatus === FetchStatusEnum.Success) {
    return (
      <ValidationCode
        validationCodeType={validationCodeType}
        emailOrPhone={emailOrPhone}
        requestByEditField
      />
    );
  }

  return (
    <Container>
      <Title color="primary">{title}</Title>

      <FieldContainer item xs={12}>
        {isEmail ? (
          <FormTextField
            name="data"
            label={t('profile.editField.email')}
            defaultValue=""
            control={control}
            errorMessage={errors.data?.message}
            testID="inputData"
          />
        ) : (
          <FormPhoneField
            name="data"
            label={t('profile.editField.phoneNumber')}
            defaultValue=""
            control={control}
            errorMessage={errors.data?.message}
          />
        )}
      </FieldContainer>

      <Footer>
        <Button
          variant="contained"
          color="primary"
          style={{ width: 96 }}
          onClick={handleSubmit(onSubmit)}
        >
          <Typography variant="h6">
            {t('profile.editField.saveButton')}
          </Typography>
        </Button>
      </Footer>

      {error && error?.message !== '' && (
        <Text
          variant="body2"
          color="error"
          style={{ marginTop: '1rem', textAlign: 'center' }}
        >
          {error.message}
        </Text>
      )}
    </Container>
  );
};

export default EditField;
