import { Select } from '@material-ui/core';
import styled from 'styled-components';

export const SelectStyled = styled(Select)<{ border?: string }>`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0;

  fieldset {
    border: ${({ border }) => border ?? 'none'};
  }

  .MuiOutlinedInput-input {
    padding: 1rem 0.5rem;
  }
`;
