import { Grid, Typography } from '@material-ui/core';
import {
  DefaultThemeColorsType,
  DefaultThemeTorqGrowthCurveColorsType,
} from '@sinagro/frontend-shared/themes';
import styled from 'styled-components';

export const PlotHeader = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const PlotCropContainer = styled(Grid)`
  margin-left: 1rem;
  display: flex;
  align-items: center;
`;

export const PlotCrop = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    margin-left: 0.5rem;
  }
`;

export const PlotTitle = styled(Typography)`
  flex: 1;
  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
  }
`;

export const PlotContent = styled(Grid)`
  display: flex;
  gap: 0.5rem;
`;

export const PlotItem = styled(Grid)<{
  $color?: DefaultThemeColorsType;
  $growthColor?: DefaultThemeTorqGrowthCurveColorsType;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.5rem;
  gap: 0.5rem;
  background-color: ${({ theme, $color, $growthColor }) =>
    $color
      ? theme.colors[$color]
      : $growthColor
      ? theme.colors.torqGrowthCurve[$growthColor]
      : theme.colors.textSecondary}10;
  border-radius: 8px;

  svg {
    fill: ${({ theme, $color }) =>
      $color ? theme.colors[$color] : theme.colors.textSecondary};
  }
`;

export const PlotText = styled(Typography)`
  display: flex;
  align-items: center;

  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.md}px;
  }

  svg {
    width: ${({ theme }) => theme.fontSize.xl}px;
    height: ${({ theme }) => theme.fontSize.xl}px;
  }
`;
