import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import { useEffect } from 'react';
import { useSelector } from 'src/state/store';

import packageJSON from '../../../../package.json';
import { clearCache } from './utils';

const SystemConfig = () => {
  const { version } = packageJSON;

  const systemConfig = useSelector(
    (state) => state.shared.systemConfig.systemConfig
  );
  const fetchStatus = useSelector(
    (state) => state.shared.systemConfig.fetchStatus
  );

  const success = fetchStatus === FetchStatusEnum.Success;
  const updateVersion =
    systemConfig !== null && systemConfig.webVersion !== version;

  useEffect(() => {
    if (success && updateVersion) {
      const reloadApplication = async () => {
        await clearCache();
        window.location.reload();
      };

      reloadApplication();
    }
  }, [success, updateVersion]);

  return null;
};

export default SystemConfig;
