import type { RemittanceGet } from '../../../types/v2';
import { RemittanceFilters } from '../../../types/v2';
import { CustomURLSearchParams } from '../../../utils/customUrlSearchParams';

export const trackingFiltersParams = (
  params: CustomURLSearchParams,
  filters: RemittanceFilters
) => {
  filters.orderId && params.set('orderId', filters.orderId);
  filters.segment && params.set('segment', filters.segment);
  filters.remittanceStatus &&
    params.set('remittanceStatus', filters.remittanceStatus);
  params.set('harvestReferences', filters.harvestReferences);

  return params;
};

export const trackingPageParams = (
  params: CustomURLSearchParams,
  payload: RemittanceGet
) => {
  payload.limit && params.set('limit', payload.limit.toString());

  payload.page && params.set('page', payload.page.toString());

  return params;
};
