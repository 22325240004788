import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useSelector, useDispatch, actions } from '../../../state/store';

const Errors = () => {
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.shared.errors.errors);

  useEffect(() => {
    errors.forEach((error) => {
      toast(error.message, { type: 'error' });
      dispatch(actions.shared.errors.remove({ error }));
    });
  }, [dispatch, errors]);

  return null;
};

export default Errors;
