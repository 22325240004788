import React from 'react';

import { AvatarContainer, Avatar, Initials } from './styles';

type AvatarProps = {
  name: string;
};

const MenuAvatar = ({ name }: AvatarProps) => {
  return (
    <AvatarContainer container item justifyContent="center" alignItems="center">
      <Avatar>
        <Initials>
          {name[0]}
          {name.split(' ')[1] ? name.split(' ')[1][0] : ''}
        </Initials>
      </Avatar>
    </AvatarContainer>
  );
};

export default MenuAvatar;
