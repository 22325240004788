import { Typography } from '@material-ui/core';
import { IAlert } from '@sinagro/shared';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/elements/materialUI/button';
import Modal from 'src/components/elements/modal';

import { alertColor, alertIcon } from '../../../../pages/torq/utils/alert';
import Details from './details';
import { AlertContainer, Icon, Text } from './styles';

type AlertProps = {
  alert: Partial<IAlert>;
  mode?: 'default' | 'card';
  details?: boolean;
};

const Alert = ({ alert, mode = 'default', details = true }: AlertProps) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const isCard = mode === 'card';

  const color = alertColor[alert.level!];
  const icon = alertIcon[alert.category!];

  return (
    <>
      <AlertContainer $isCard={isCard}>
        <Icon $color={color}>{icon}</Icon>
        <Text>{alert.title}</Text>

        {details && (
          <Button
            onClick={() => setOpenModal(true)}
            variant="outlined"
            color="secondary"
            style={{
              padding: '0.5rem 1rem',
            }}
          >
            <Typography
              variant="h6"
              color="primary"
              style={{
                fontWeight: 600,
              }}
            >
              {t('torq.alert.details')}
            </Typography>
          </Button>
        )}
      </AlertContainer>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        content={
          <Details
            title={alert.title || ''}
            description={alert.description || ''}
          />
        }
        width={'280px'}
      />
    </>
  );
};

export default Alert;
