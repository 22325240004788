import { SharedError } from '.'

export const documentAlreadyInUseError: SharedError = {
  errorRef: 'user/document-already-in-use',
  message: 'Já possuímos um usuário com esse CPF ou CNPJ',
}
export const emailAlreadyInUseError: SharedError = {
  errorRef: 'user/email-already-in-use',
  message: 'Já possuímos um usuário com esse e-mail',
}
export const phoneNumberAlreadyInUseError: SharedError = {
  errorRef: 'user/phone-number-already-in-use',
  message: 'Já possuímos um usuário com esse número de telefone',
}
export const emailIsGrupoSinagroBlockUserError: SharedError = {
  errorRef: 'user/is-email-grupo-sinagro-blocked',
  message: 'Não é permitido o cadastro no Portal com e-mails da empresa',
}
export const userNotFoundError: SharedError = {
  errorRef: 'user/not-found',
  message: 'Usuário não encontrado',
}
export const userNotFromBackofficeError: SharedError = {
  errorRef: 'user/not-from-backoffice',
  message: 'Usuário não é do backoffice',
}
export const userIsAlreadyValidatedError: SharedError = {
  errorRef: 'user/already-validated',
  message: 'Usuário já validado',
}
export const changeDemoUserNameError: SharedError = {
  errorRef: 'user/demo-user-change-name',
  message: 'Não é possível alterar o nome do usuário demonstração.',
}
export const changeDemoUserEmailError: SharedError = {
  errorRef: 'user/demo-user-change-email',
  message: 'Não é possível alterar o e-mail do usuário demonstração.',
}
export const patchThirdPartyUserEmailError: SharedError = {
  errorRef: 'user/third-party-user-patch',
  message: 'Não é possível alterar o usuário AD.',
}
export const changeDemoUserPhoneNumberError: SharedError = {
  errorRef: 'user/demo-user-change-phone',
  message:
    'Não é possível alterar o número de telefone do usuário demonstração.',
}
export const changeNotDigitalUserDigitalRelatedClientError: SharedError = {
  errorRef: 'user/not-digital-user-change-digital-related-clients',
  message:
    'Não é possível alterar os clientes digitais relacionados de usuário que não é consultor digital.',
}
export const requestDemoUserValidationCode: SharedError = {
  errorRef: 'user/demo-user-request-validation-code',
  message:
    'Não é possível solicitar um código de validação para o usuário demonstração.',
}
export const deleteDemoUser: SharedError = {
  errorRef: 'user/demo-user-delete',
  message: 'Não é possível remover o usuário demonstração.',
}
export const wrongCredentialsError: SharedError = {
  errorRef: 'login/wrong-credentials',
  message: 'Credenciais Inválidas',
}
export const invalidTokenError: SharedError = {
  errorRef: 'token/invalid-token',
  message: 'Autenticação inválida, refaça seu login',
}
export const invalidPermissionError: SharedError = {
  errorRef: 'permission/invalid-permission',
  message:
    'Você não tem permissões para acessar esse recurso, entre em contato com o suporte',
}
export const invalidAppKeyError: SharedError = {
  errorRef: 'appkey/invalid-appkey',
  message: 'Autenticação inválida',
}
export const invalidOldPasswordError: SharedError = {
  errorRef: 'password/invalid-old-password',
  message: 'Senha antiga não confere, tente novamente',
}
export const changeDemoUserPasswordError: SharedError = {
  errorRef: 'password/demo-user-change-password',
  message: 'Não é possível alterar a senha do usuário demonstração.',
}
export const invalidPasswordConfirmationError: SharedError = {
  errorRef: 'password/invalid-password-confirmation',
  message: 'Nova senha e confirmação não conferem, tente novamente',
}
export const sameUserInviteEmailError: SharedError = {
  errorRef: 'user-invite/same-user-invite-email',
  message: 'Não é possível criar convites para o próprio usuário',
}
export const domainBlocklistError: SharedError = {
  errorRef: 'user-invite/domain-blocklist',
  message: 'Não é possível convidar usuários com esse domínio de e-mail',
}
export const userInviteAlreadyExistsError: SharedError = {
  errorRef: 'user-invite/user-invite-already-exists',
  message: 'Já existe um convite com o mesmo e-mail',
}
export const noRelatedClientsError: SharedError = {
  errorRef: 'user-invite/no-related-clients',
  message: 'Não existem clientes relacionados para incluir no convite',
}
export const invitedUserCannotInvite: SharedError = {
  errorRef: 'user-invite/invited-user-cannot-invite',
  message:
    'Por motivos de segurança, não é possível convidar outros usuários para ver os dados que foram compartilhados com você.',
}
export const userInviteNotFoundError: SharedError = {
  errorRef: 'user-invite/not-found',
  message: 'Convite não encontrado',
}
export const patchAlreadyValidatedUser: SharedError = {
  errorRef: 'user/patch-already-validated',
  message: 'Usuário já validado',
}

export default [
  documentAlreadyInUseError,
  emailAlreadyInUseError,
  phoneNumberAlreadyInUseError,
  wrongCredentialsError,
  invalidTokenError,
  userNotFoundError,
  userIsAlreadyValidatedError,
  invalidAppKeyError,
  invalidOldPasswordError,
  invalidPasswordConfirmationError,
  sameUserInviteEmailError,
  userInviteAlreadyExistsError,
  noRelatedClientsError,
  invitedUserCannotInvite,
  userInviteNotFoundError,
  changeDemoUserPasswordError,
  domainBlocklistError,
  changeDemoUserNameError,
  changeDemoUserEmailError,
  changeDemoUserPhoneNumberError,
  deleteDemoUser,
  requestDemoUserValidationCode,
  patchAlreadyValidatedUser,
  invalidPermissionError,
  userNotFromBackofficeError,
  emailIsGrupoSinagroBlockUserError,
]
