import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { emailRegex, phoneNumberRegex } from '../../../utils/regexes';

export const useEmailSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    data: yup
      .string()
      .required(t('form.required'))
      .matches(emailRegex, t('form.invalidFormat')),
  });
};

export const usePhoneSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    data: yup
      .string()
      .required(t('form.required'))
      .matches(phoneNumberRegex, t('form.invalidFormat')),
  });
};
