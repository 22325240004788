import { Grid, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Container = styled(Grid)`
  padding: 0.5rem;
`;

export const Header = styled(Grid)`
  flex: 1;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primaryLight};
`;

export const Title = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.xxl}px;
    margin-bottom: 1rem;
  }
`;

export const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PlotContainer = styled(Grid)<{ $border: boolean }>`
  padding-top: 1rem;

  ${({ $border }) =>
    $border &&
    css`
      border-top: 1px solid ${({ theme }) => theme.colors.primaryLight};
    `}

  :hover {
    cursor: pointer;
  }
`;

export const Text = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: ${({ theme }) => theme.fontSize.md}px;
    text-align: center;
  }
`;

export const FilterContent = styled(Grid)`
  display: flex;
  gap: 0.5rem;
`;
