import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Select from '../../../../../components/elements/select';
import { getInputProps } from '../utils';
import { Container } from './styles';

export type OptionsProps = {
  value: string;
  label: string;
};

type CropSelectProps = {
  options: OptionsProps[];
  value: string;
  setValue: (newValue: string) => void;
  parentElementIsPopper?: boolean;
};

const CropSelect: React.FC<CropSelectProps> = ({
  options,
  value,
  setValue,
  parentElementIsPopper,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const inputProps = getInputProps(parentElementIsPopper, -648);

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<{
        value: unknown;
      }>
    ) => {
      setValue(event.target.value as string);
    },
    [setValue]
  );

  return (
    <Container>
      <Select
        label={t('torq.plot.filter.crop')}
        options={[...options, { value: '', label: 'Todos' }]}
        handleChange={handleChange}
        value={value?.toString() ?? ''}
        MenuProps={{ style: { marginTop: 36 } }}
        labelStyle={{ marginTop: '-0.9rem', paddingLeft: '0.6rem' }}
        border={`1px solid ${theme.colors.border}`}
        inputProps={inputProps}
      />
    </Container>
  );
};

export default CropSelect;
