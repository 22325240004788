import {
  internetConnectionTimeout,
  internetConnectionTimeoutErrorMessage,
} from '../../../utils';
import api from '../api';

export async function testConnection(): Promise<void> {
  return api
    .get(`/`, {
      timeout: internetConnectionTimeout,
      timeoutErrorMessage: internetConnectionTimeoutErrorMessage,
    })
    .catch((error) => {
      if (
        error.message === internetConnectionTimeoutErrorMessage ||
        error.message === 'Network Error' ||
        error === '[Error: timeout-internet-connection]'
      ) {
        return internetConnectionTimeoutErrorMessage as any;
      }
      throw new Error(error);
    });
}
