import { createSlice } from '@reduxjs/toolkit';
import { GroupByEnum } from '@sinagro/shared';

import {
  FiltersSlice,
  FiltersType,
  FiltersUpdateFiltersAction,
  FilterUpdateFilterAction,
  FinancialSelectorFilterEnum,
} from '../../types';

const filterData: FiltersType = {
  ordersGroupBy: GroupByEnum.PropertyName,
  order: '',
  cultivation: '',
  productName: '',
  propertyName: '',
  segment: [],
  orderItemStatus: [],
  harvestReferences: [],
  searchTerm: '',
  paymentDate: { startDate: null, endDate: null },
  deliveryDate: { startDate: null, endDate: null },
  contractDeliveryStatus: null,
  financialSelector: FinancialSelectorFilterEnum.Receivable,
  paymentStatus: null,
  dueDate: { startDate: null, endDate: null },
  contract: '',
  modality: null,
  currency: null,
  invoice: '',
  paymentCondition: null,
  paymentMethod: null,
  receivableStatus: null,
  payableStatus: null,
  documentDate: { startDate: null, endDate: null },
  compensationDate: { startDate: null, endDate: null },
  operationReceivableType: null,
  operationPayableType: null,
  operationType: null,
  invoiceNumber: '',
  prus: null,
  clientId: '',
};

export const initialState: FiltersSlice = {
  data: filterData,
  appliedFilters: filterData,
};

export const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    clear: () => initialState,
    updateFilter: (state, action: FilterUpdateFilterAction) => {
      const { filter, value } = action.payload;
      state.data = { ...state.data, [filter]: value };
    },
    updateFilters: (state, action: FiltersUpdateFiltersAction) => {
      state.data = action.payload.data;
    },
    applyFilters: (state) => {
      state.appliedFilters = state.data;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
