import { Grid } from '@material-ui/core';
import { CreditPackageStatusEnum } from '@sinagro/shared';
import React from 'react';

import { useSelector } from '../../../state/store';
import {
  items,
  menuItemsFeatureFlag,
} from '../menu/components/menuItems/items';
import Cards from './components/card';
import TorqCard from './components/torqCard';

const ServicesCards = () => {
  const featureFlag = useSelector(
    (state) => state.shared.featureFlag.featureFlag
  );
  const regions =
    useSelector((state) => state.shared.userProfile.userProfile.regions) || [];
  const isClientTorq = !!regions.length;

  const servicesItems = items(isClientTorq).filter(
    (item) => item.id === 'services'
  )[0].subMenu!;
  const menuItems = menuItemsFeatureFlag(servicesItems, featureFlag);

  const creditPackage = useSelector(
    (state) => state.shared.creditPackage.creditPackage
  );
  const creditPackageItem = menuItems.filter(
    (item) => item.id === 'creditPackage'
  );
  const showCreditPackageBadge = creditPackage.some(
    (item) =>
      item.status === CreditPackageStatusEnum.Pending ||
      item.status === CreditPackageStatusEnum.Rejected
  );

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {menuItems.map((item) => {
        if (!item.hiddenCard) {
          return (
            <Grid item xs={6} md={3} zeroMinWidth>
              <Cards
                id={item.id}
                Icon={item.Icon}
                label={item.label}
                url={item.url}
                key={item.url}
              />
            </Grid>
          );
        }
      })}

      {creditPackageItem.length > 0 && (
        <Grid item xs={6} md={3} zeroMinWidth>
          <Cards
            id={creditPackageItem[0].id}
            Icon={creditPackageItem[0].Icon}
            label={creditPackageItem[0].label}
            url={creditPackageItem[0].url}
            badge={showCreditPackageBadge}
          />
        </Grid>
      )}

      <Grid item xs={12} md={6} zeroMinWidth>
        <TorqCard />
      </Grid>
    </Grid>
  );
};

export default ServicesCards;
