import { Typography } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import { IUserInvite } from '@sinagro/shared';
import React, { useCallback, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useTheme as UseTheme } from 'styled-components';

import Modal from '../../../components/elements/modal';
import { useIsMobile } from '../../../hooks/responsiveLayout';
import { actions, useDispatch, useSelector } from '../../../state/store';
import DeleteInvite from './deleteInvite';
import { EmailContainer, ListItem, TrashContainer } from './styles';
import { StatusCell } from './utils';

type Item = {
  userInvite: IUserInvite;
};

const InviteItem = ({ userInvite }: Item) => {
  const isMobile = useIsMobile();
  const theme = UseTheme();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const fetchStatus = useSelector(
    (state) => state.shared.userInvite.fetchStatusUpdateUserInvite
  );

  const deleteInviteSuccess = fetchStatus === FetchStatusEnum.Success;

  const handleClose = useCallback(() => {
    setOpen(false);
    if (deleteInviteSuccess) {
      dispatch(actions.shared.userInvite.clear());
      dispatch(actions.shared.userInvite.getUserInvite());
    }
  }, [deleteInviteSuccess, dispatch]);

  return (
    <>
      <ListItem container item isMobile={isMobile}>
        <EmailContainer isMobile={isMobile}>
          <Typography color="secondary" variant="h4">
            {userInvite.emailToInvite}
          </Typography>
        </EmailContainer>
        {isMobile ? (
          <>
            <TrashContainer onClick={() => setOpen(true)}>
              <FaTrash color={theme.colors.primary} />
            </TrashContainer>
            <StatusCell status={userInvite.status} />
          </>
        ) : (
          <>
            <StatusCell status={userInvite.status} />
            <TrashContainer onClick={() => setOpen(true)}>
              <FaTrash color={theme.colors.primary} />
            </TrashContainer>
          </>
        )}
      </ListItem>
      <Modal
        open={open}
        onClose={handleClose}
        content={<DeleteInvite userInvite={userInvite} onClose={handleClose} />}
      />
    </>
  );
};

export default InviteItem;
