import { Typography } from '@material-ui/core';
import { ReportProblemOutlined, CheckCircleOutline } from '@material-ui/icons';
import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
`;
export const ErrorTypography = styled(Typography)`
  text-align: start;
  padding-top: 0.3rem;
  padding-left: 0.3rem;
`;
export const ErrorIcon = styled(ReportProblemOutlined).attrs((_) => ({
  fontSize: 'small',
}))`
  color: ${({ theme }) => theme.colors.danger};
`;

export const SuccessWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
`;
export const SuccessTypography = styled(Typography)`
  text-align: start;
  padding-top: 0.3rem;
  padding-left: 0.3rem;
  color: ${({ theme }) => theme.colors.green};
`;
export const SuccessIcon = styled(CheckCircleOutline).attrs((_) => ({
  fontSize: 'small',
}))`
  color: ${({ theme }) => theme.colors.green};
`;

export const HelperTypography = styled(Typography).attrs({
  variant: 'body1',
})`
  text-align: start;
  padding-top: 0.3rem;
  padding-left: 0.3rem;
  color: ${({ theme }) => theme.colors.textSecondary};
`;
