import { createSlice } from '@reduxjs/toolkit';

import {
  CreateUserInviteAction,
  CreateUserInviteUpdateErrorAction,
  CreateUserInviteUpdateStatusAction,
  DeleteUserInviteAction,
  FetchStatusEnum,
  UpdateUserInviteDeleteErrorAction,
  UpdateUserInviteDeleteStatusAction,
  UserInvitesFetchedAction,
  UserInviteSlice,
  UserInvitesUpdateErrorAction,
  UserInvitesUpdateStatusAction,
} from '../../types';

const initialState: UserInviteSlice = {
  userInvites: [],
  fetchStatus: FetchStatusEnum.None,
  fetchStatusUpdateUserInvite: FetchStatusEnum.None,
  fetchStatusCreateUserInvite: FetchStatusEnum.None,
  error: null,
  errorUpdateUserInvite: null,
  errorCreateUserInvite: null,
};

export const slice = createSlice({
  name: 'userInvite',
  initialState,
  reducers: {
    clear: () => initialState,
    getUserInvite: (state) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    userInvites: (state, action: UserInvitesFetchedAction) => {
      state.userInvites = action.payload.userInvite;
    },
    updateStatus: (state, action: UserInvitesUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: UserInvitesUpdateErrorAction) => {
      state.error = action.payload.error;
    },
    createUserInvite: (state, _: CreateUserInviteAction) => {
      state.fetchStatusCreateUserInvite = FetchStatusEnum.Fetching;
    },
    updateStatusCreateUserInvite: (
      state,
      action: CreateUserInviteUpdateStatusAction
    ) => {
      state.fetchStatusCreateUserInvite =
        action.payload.fetchStatusCreateUserInvite;
    },
    updateErrorCreateUserInvite: (
      state,
      action: CreateUserInviteUpdateErrorAction
    ) => {
      state.errorCreateUserInvite = action.payload.errorCreateUserInvite;
    },
    deleteUserInvite: (state, _: DeleteUserInviteAction) => {
      state.fetchStatusUpdateUserInvite = FetchStatusEnum.Fetching;
    },
    updateStatusDeleteUserInvite: (
      state,
      action: UpdateUserInviteDeleteStatusAction
    ) => {
      state.fetchStatusUpdateUserInvite =
        action.payload.fetchStatusDeleteUserInvite;
    },
    updateErrorDeleteUserInvite: (
      state,
      action: UpdateUserInviteDeleteErrorAction
    ) => {
      state.errorUpdateUserInvite = action.payload.errorDeleteUserInvite;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
