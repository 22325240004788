import { SagaIterator } from '@redux-saga/types';
import {
  GroupedRemittanceSegmentEnum,
  RemittanceStatusEnum,
} from '@sinagro/shared';
import { SharedError, unknownError } from '@sinagro/shared/errors';
import { call, put } from 'redux-saga/effects';

import { getRemittance } from '../../../../services/tracking/v2';
import { FetchStatusEnum } from '../../../../types';
import { RemittanceGetAction, RemittanceResponse } from '../../../../types/v2';
import { handleError } from '../../../errors/sagas';
import { actions } from '../slice';

export function* getRemittancesSeedsScheduledEffectSaga(
  action: RemittanceGetAction
): SagaIterator<void> {
  try {
    const filters = {
      ...action.payload.filters,
      segment: GroupedRemittanceSegmentEnum.Seeds,
      remittanceStatus: RemittanceStatusEnum.Scheduled,
    };

    const payload = { ...action.payload, filters };
    const { results: remittances, total }: RemittanceResponse = yield call(
      getRemittance,
      payload
    );

    yield put(
      actions.remittancesSeedsScheduled({
        remittances,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusSeedsScheduled({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError | undefined = yield call(handleError, {
      error,
    });

    yield put(
      actions.updateError({
        error: knownError || unknownError,
      })
    );

    yield put(
      actions.updateStatusSeedsScheduled({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getRemittancesSeedsHandlingEffectSaga(
  action: RemittanceGetAction
): SagaIterator<void> {
  try {
    const filters = {
      ...action.payload.filters,
      segment: GroupedRemittanceSegmentEnum.Seeds,
      remittanceStatus: RemittanceStatusEnum.Handling,
    };

    const payload = { ...action.payload, filters };
    const { results: remittances, total }: RemittanceResponse = yield call(
      getRemittance,
      payload
    );

    yield put(
      actions.remittancesSeedsHandling({
        remittances,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusSeedsHandling({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError | undefined = yield call(handleError, {
      error,
    });

    yield put(
      actions.updateError({
        error: knownError || unknownError,
      })
    );

    yield put(
      actions.updateStatusSeedsHandling({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getRemittancesSeedsInTransitEffectSaga(
  action: RemittanceGetAction
): SagaIterator<void> {
  try {
    const filters = {
      ...action.payload.filters,
      segment: GroupedRemittanceSegmentEnum.Seeds,
      remittanceStatus: RemittanceStatusEnum.InTransit,
    };

    const payload = { ...action.payload, filters };
    const { results: remittances, total }: RemittanceResponse = yield call(
      getRemittance,
      payload
    );

    yield put(
      actions.remittancesSeedsInTransit({
        remittances,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusSeedsInTransit({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError | undefined = yield call(handleError, {
      error,
    });

    yield put(
      actions.updateError({
        error: knownError || unknownError,
      })
    );

    yield put(
      actions.updateStatusSeedsInTransit({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getRemittancesSeedsDeliveredEffectSaga(
  action: RemittanceGetAction
): SagaIterator<void> {
  try {
    const filters = {
      ...action.payload.filters,
      segment: GroupedRemittanceSegmentEnum.Seeds,
      remittanceStatus: RemittanceStatusEnum.Delivered,
    };

    const payload = { ...action.payload, filters };
    const { results: remittances, total }: RemittanceResponse = yield call(
      getRemittance,
      payload
    );

    yield put(
      actions.remittancesSeedsDelivered({
        remittances,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusSeedsDelivered({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError | undefined = yield call(handleError, {
      error,
    });

    yield put(
      actions.updateError({
        error: knownError || unknownError,
      })
    );

    yield put(
      actions.updateStatusSeedsDelivered({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
