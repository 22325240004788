import { Box, IconButton } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Box)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: ${({ theme }) => `0px 3px 6px ${theme.colors.shadow}`};
  width: 500px;

  max-height: 90%;
  overflow-y: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  padding: 1rem;

  transform: translate(-50%, -50%);

  ::-webkit-scrollbar {
    width: 6px;
    background-color: ${({ theme }) => theme.colors.border};
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: ${({ theme }) => theme.colors.borderSecondary};
    border-radius: 8px;
  }

  @media (max-width: 700px) {
    width: 90% !important;
  }
`;

export const CloseButton = styled(IconButton)`
  && {
    padding: 0;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
`;
