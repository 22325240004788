import { Grid, Link, Typography } from '@material-ui/core';
import { WhatsApp } from '@material-ui/icons';
import { auth } from '@sinagro/frontend-shared/firebase';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAccountCircle, MdLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { analyticsLogger } from 'src/utils/analytics';

import Button from '../../../../../components/elements/materialUI/button';
import { useMenuContext } from '../../../../../contexts/menu';
import { useNotificationContext } from '../../../../../contexts/notification';
import { actions, useDispatch } from '../../../../../state/store';
import { SubItem } from './styles';

type Props = {
  openMenu?: boolean;
};

const Footer = ({ openMenu = true }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setShowMenu } = useMenuContext();
  const { deleteUserWebToken } = useNotificationContext();

  const handleNavigation = useCallback(
    (url: string) => {
      if (setShowMenu) setShowMenu(false);
      navigate(url);
    },
    [navigate, setShowMenu]
  );

  const handleLogOut = useCallback(() => {
    deleteUserWebToken && deleteUserWebToken();
    auth().signOut();
    dispatch(actions.shared.trade.clear());
    dispatch(actions.shared.user.clear());
    dispatch(actions.shared.userProfile.clear());
    dispatch(actions.shared.orders.clear());
    navigate('/', { replace: true });
  }, [deleteUserWebToken, dispatch, navigate]);

  const padding = openMenu ? '0 1rem' : 'auto';
  const align = openMenu ? 'flex-start' : 'center';

  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  return (
    <Grid
      container
      style={{
        padding,
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'space-between',
      }}
    >
      <SubItem
        item
        xs={12}
        direction="row"
        alignItems="center"
        onClick={() => handleNavigation('/profile')}
        justifyContent={align}
      >
        <Button>
          <MdAccountCircle color="white" />
          {openMenu && (
            <Typography color="textSecondary" variant="body1">
              {t('menu.profile')}
            </Typography>
          )}
        </Button>
      </SubItem>

      <SubItem
        item
        xs={12}
        direction="row"
        alignItems="center"
        justifyContent={align}
      >
        <Link
          href={`https://api.whatsapp.com/send?1=pt_BR&phone=${whatsappNumber}`}
          rel="noopener noreferrer"
          target="_blank"
          onClick={() =>
            analyticsLogger.whatsapp('general', {
              menu: true,
            })
          }
        >
          <WhatsApp color="secondary" />
        </Link>
      </SubItem>

      <SubItem
        item
        xs={12}
        direction="row"
        alignItems="center"
        onClick={handleLogOut}
        justifyContent={align}
      >
        <Button>
          <MdLogout color="white" />
          {openMenu && (
            <Typography color="textSecondary" variant="body1">
              {t('commons.logOut')}
            </Typography>
          )}
        </Button>
      </SubItem>
    </Grid>
  );
};

export default Footer;
