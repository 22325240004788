import { Grid, Link as MUILink } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  padding: 1rem;
`;

export const Content = styled(Grid)`
  width: 100%;
  background: ${({ theme }) => theme.colors.blue}10;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
`;

export const Link = styled(MUILink)`
  && {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    color: ${({ theme }) => theme.colors.blue};
  }
  cursor: pointer;
`;
