import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  width: 100%;
`;

export const Title = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.xxl}px;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 1rem;
  }
`;

export const EmptyMessage = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.md}px;
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`;
