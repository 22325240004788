import React, { useCallback } from 'react';

import { Code, CodeContent, Text } from './styles';

type Props = {
  code: string[];
  setCode: (code: string[]) => void;
};

const inputSize = 6;

const CodeInput = ({ code, setCode }: Props) => {
  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
      event.preventDefault();
      const value = event.target.value;
      if (value.match(/[0-9^$]+/g) || value === '') {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);
        if (value !== '') document.getElementById(`code-${index + 1}`)?.focus();
      }
    },
    [code, setCode]
  );

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
      if (event.key === 'Backspace' && index > 0) {
        if (code[5] === '' || index !== 5) {
          document.getElementById(`code-${index - 1}`)?.focus();
        }
        const newCode = [...code];
        newCode[index] = '';
        setCode(newCode);
      }
    },
    [code, setCode]
  );

  const handleOnPaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const data = event.clipboardData.getData('text');
      if (data.length === 6 && data.match(/[0-9^$]+/g)) {
        const newCode = data.split('');
        setCode(newCode);
        document.getElementById(`code-${inputSize - 1}`)?.focus();
      }
    },
    [setCode]
  );

  const codeComponent = React.useMemo(
    () =>
      [...Array(inputSize)].map((_, index) => {
        return (
          <>
            <Code
              id={`code-${index}`}
              key={`code-${index}`}
              maxLength={1}
              autoFocus={index === 0}
              value={code[index] ?? ''}
              onChange={(e) => handleOnChange(e, index)}
              onKeyDown={(e) => handleOnKeyDown(e, index)}
              onPaste={(e) => handleOnPaste(e)}
            />
            {index === inputSize / 2 - 1 && (
              <Text color="secondary" style={{ margin: '0 0.5rem' }}>
                -
              </Text>
            )}
          </>
        );
      }),
    [code, handleOnChange, handleOnKeyDown, handleOnPaste]
  );

  return (
    <CodeContent container direction="row" style={{ gap: 8 }}>
      {codeComponent}
    </CodeContent>
  );
};

export default CodeInput;
