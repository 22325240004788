import { SagaIterator } from '@redux-saga/types';
import { ListCampaignsDTO } from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getCampaigns } from '../../services/campaigns';
import { FetchStatusEnum } from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [takeLatest(actions.getCampaigns.type, getCampaignsEffectSaga)];

export type getCampaignsResponse = ListCampaignsDTO[];

export function* getCampaignsEffectSaga(): SagaIterator<void> {
  try {
    const campaigns: getCampaignsResponse = yield call(getCampaigns);
    yield put(actions.campaigns({ campaigns }));
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
