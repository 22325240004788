import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import { auth } from '@sinagro/frontend-shared/firebase';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNotificationContext } from 'src/contexts/notification';
import { useTheme } from 'styled-components';

import Button from '../../../components/elements/materialUI/button';
import { FormPasswordField } from '../../../components/modules/form';
import { actions, useDispatch, useSelector } from '../../../state/store';
import { usePasswordSchema } from './schema';
import {
  Container,
  ButtonContainer,
  DeleteAccountIcon,
  Title,
  DeleteIcon,
} from './styles';

export type PasswordFormType = {
  password: string;
};

type Props = {
  onClose: () => void;
};

const DeleteAccountForm: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { deleteUserWebToken } = useNotificationContext();

  const schema = usePasswordSchema();

  const error = useSelector(
    (state) => state.shared.userProfile.errorDeleteAccount
  );

  const fetchStatus = useSelector(
    (state) => state.shared.userProfile.fetchStatusDeleteAccount
  );

  const loading = fetchStatus === FetchStatusEnum.Fetching;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PasswordFormType>({
    resolver: yupResolver(schema),
  });

  const onSubmitDeleteAccount: SubmitHandler<PasswordFormType> = useCallback(
    (form: PasswordFormType) => {
      dispatch(
        actions.shared.userProfile.deleteAccount({
          password: form.password,
        })
      );
    },
    [dispatch]
  );

  const signOut = useCallback(async () => {
    deleteUserWebToken && deleteUserWebToken();
    auth().signOut();
    dispatch(actions.auth.clear());
    dispatch(actions.shared.user.clear());
    dispatch(actions.shared.userProfile.clear());
  }, [deleteUserWebToken, dispatch]);

  useEffect(() => {
    if (fetchStatus === FetchStatusEnum.Success) {
      toast(t('profile.deleteAccount.success'), { type: 'success' });

      reset({
        password: '',
      });

      setTimeout(signOut, 2000);
    }
  }, [fetchStatus, t, reset, signOut]);

  return (
    <Container>
      <DeleteAccountIcon name="delete-forever" />
      <Title color="primary">{t('profile.deleteAccount.confirmTitle')}</Title>

      <FormPasswordField
        testID="password"
        name="password"
        label={t('profile.deleteAccount.password')}
        control={control}
        defaultValue=""
        errorMessage={errors.password?.message}
      />

      {error && (
        <Typography
          style={{ textAlign: 'center', marginTop: '1rem' }}
          variant="body2"
          color="error"
        >
          {error.message}
        </Typography>
      )}

      <ButtonContainer container>
        <Button
          variant="contained"
          color="primary"
          style={{
            background: theme.colors.danger,
            borderColor: theme.colors.danger,
            height: '3rem',
          }}
          onClick={handleSubmit(onSubmitDeleteAccount)}
          loading={loading}
        >
          <DeleteIcon name="delete-forever" $color="white" />
          <Typography variant="h6">
            {t('profile.deleteAccount.confirm')}
          </Typography>
        </Button>

        <Button variant="text" color="primary" onClick={onClose}>
          <Typography variant="h6" style={{ textDecoration: 'underline' }}>
            {t('profile.deleteAccount.cancel')}
          </Typography>
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default DeleteAccountForm;
