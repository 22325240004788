import { FarmDetailsDTO, RegionType } from '@sinagro/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FarmDetails from 'src/components/modules/torq/farmDetails';
import { useReportContext } from 'src/contexts/report';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { Container, Content, Title } from './styles';

const FarmSummary = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { region } = useReportContext();

  if (region === null || region?.type !== RegionType.Farm) {
    return <></>;
  }

  return (
    <Container container $isMobile={isMobile}>
      {isMobile && <Title>{t('report.farmSummary')}</Title>}
      <Content container $isMobile={isMobile}>
        <FarmDetails farmDetails={region as FarmDetailsDTO} />
      </Content>
    </Container>
  );
};

export default FarmSummary;
