import { Grid, Typography, Link as MUILink } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.xl}px;
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const Content = styled(Grid)`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const Link = styled(MUILink)`
  && {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    color: ${({ theme }) => theme.colors.blue};
  }
  cursor: pointer;
`;

export const Divider = styled.div`
  background: ${({ theme }) => theme.colors.border};
  height: 2rem;
  width: 1px;
`;
