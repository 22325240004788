import { Grid, Link } from '@material-ui/core';
import styled from 'styled-components';

export const SocialMedia = styled(Grid)<{
  $isMobile: boolean;
  $openMenu: boolean;
}>`
  padding: ${({ $isMobile, $openMenu }) =>
    $isMobile
      ? '1rem 0'
      : $openMenu
      ? '1rem 0 1rem 13rem'
      : '1rem 0 1rem 5rem'};

  border-top: 1px ${({ theme }) => theme.colors.borderSecondary} solid;
  justify-content: center;
  align-self: flex-end !important;
  bottom: 0 !important;
  /* position: ${({ $isMobile }) => ($isMobile ? 'initial' : 'absolute')}; */
  position: initial;
`;

export const SubItem = styled(Grid)`
  svg {
    width: 24px;
    height: 24px;
  }

  p {
    line-height: normal;
    padding-left: 0.5rem;
  }
`;

export const StyledLink = styled(Link)`
  margin-right: 1rem !important;
`;
