import type {
  FinancialReceivableGetReceivables,
  FinancialReceivableGetReceivablesPaged,
} from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';
import api from '../api';
import { receivablesFiltersParams, receivablesPageParams } from './queryParams';

export function getSummary() {
  return api.get(`/financial/receivable/aggregated/summary`);
}

export function getReceivables(payload: FinancialReceivableGetReceivables) {
  let params = new CustomURLSearchParams({});
  params = receivablesFiltersParams(params, payload.filters);

  return api.get(`/financial/receivable/aggregated?${params}`);
}

export function getReceivablesPaged(
  payload: FinancialReceivableGetReceivablesPaged
) {
  let params = new CustomURLSearchParams({});

  params = receivablesFiltersParams(params, payload.filters);
  params = receivablesPageParams(params, payload);
  return api.get(`/financial/receivable?${params}`);
}
