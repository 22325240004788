import { SharedError } from '.'

export const regionDataNotFound: SharedError = {
  errorRef: 'hub/region-data-not-available',
  message: 'Não foi possível encontrar os dados da região',
}

export const regionNotActive: SharedError = {
  errorRef: 'hub/region-data-not-available',
  message: 'Região não está ativa',
}

export const regionNotFound: SharedError = {
  errorRef: 'hub/region-not-found',
  message: 'Região não encontrada',
}

export const errorFetchingRegionData: SharedError = {
  errorRef: 'hub/error-fetching-region-data',
  message: 'Não foi possível buscar os dados da região',
}

export const userCannotFetchRegionData: SharedError = {
  errorRef: 'hub/error-cannot-fetch-region-data',
  message: 'O usuário não tem permissão para buscar os dados da região',
}

export const reportNotFound: SharedError = {
  errorRef: 'hub/report-not-found',
  message: 'Relatório não encontrado',
}

export const noPlantingDateFound: SharedError = {
  errorRef: 'hub/error-no-planting-date',
  message:
    'Não foi possível buscar os dados da região. Data de plantio não encontrada.',
}

export const plotMapImageRemoteSensingNotFound: SharedError = {
  errorRef: 'hub/plot-map-image-not-found',
  message: 'Imagem do mapa do talhão não encontrada',
}

export const plotNdviImageRemoteSensingNotFound: SharedError = {
  errorRef: 'hub/plot-ndvi-image-not-found',
  message: 'Imagem ndvi do talhão não encontrada',
}

export const plotBothImagesRemoteSensingNotFound: SharedError = {
  errorRef: 'hub/plot-map-and-ndvi-image-not-found',
  message: 'Imagens do mapa e ndvi do talhão não encontradas',
}

export const getWeatherByLocationBadRequestError: SharedError = {
  errorRef: 'hub/get-weather-location-missing-params',
  message: 'Parâmetros lat, long e date são obrigatórios',
}

export const getWeatherByLocationBadRequestWrongTypeError: SharedError = {
  errorRef: 'hub/get-weather-location-lat-or-long-wrong-type',
  message:
    'Parâmetros lat e long devem ser números válidos (decimais, positivos ou negativos)',
}

export default [
  regionDataNotFound,
  regionNotFound,
  errorFetchingRegionData,
  userCannotFetchRegionData,
  reportNotFound,
  regionNotActive,
  noPlantingDateFound,
]
