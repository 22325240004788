import {
  DefaultThemeColorsType,
  DefaultThemeTorqGrowthCurveColorsType,
} from '@sinagro/frontend-shared/themes';
import { MdPlace, MdStore } from 'react-icons/md';
import styled from 'styled-components';

import { ReactComponent as DiseaseAlertSVG } from '../../../assets/icons/torq/alertCategory/disease.svg';
import { ReactComponent as FarmHealthBadAlertSVG } from '../../../assets/icons/torq/alertCategory/farmHealthBad.svg';
import { ReactComponent as FarmHealthGoodAlertSVG } from '../../../assets/icons/torq/alertCategory/farmHealthGood.svg';
import { ReactComponent as PrecipitationAlertSVG } from '../../../assets/icons/torq/alertCategory/precipitation.svg';
import { ReactComponent as SprayingAlertSVG } from '../../../assets/icons/torq/alertCategory/spraying.svg';
import { ReactComponent as TemperatureAlertSVG } from '../../../assets/icons/torq/alertCategory/temperature.svg';
import { ReactComponent as CropSVG } from '../../../assets/icons/torq/crop.svg';
import { ReactComponent as CornSVG } from '../../../assets/icons/torq/crop/corn.svg';
import { ReactComponent as CottonSVG } from '../../../assets/icons/torq/crop/cotton.svg';
import { ReactComponent as SoySVG } from '../../../assets/icons/torq/crop/soy.svg';
import { ReactComponent as GrowthCurveSVG } from '../../../assets/icons/torq/growth_curve.svg';
import { ReactComponent as HarvestEstimateSVG } from '../../../assets/icons/torq/harvest_estimate.svg';
import { ReactComponent as HumiditySVG } from '../../../assets/icons/torq/humidity.svg';
import { ReactComponent as MaxTemperatureSVG } from '../../../assets/icons/torq/maxTemperature.svg';
import { ReactComponent as MinTemperatureSVG } from '../../../assets/icons/torq/minTemperature.svg';
import { ReactComponent as NDVISVG } from '../../../assets/icons/torq/ndvi.svg';
import { ReactComponent as PlotSVG } from '../../../assets/icons/torq/plot.svg';
import { ReactComponent as PrecipitationSVG } from '../../../assets/icons/torq/precipitation.svg';
import { ReactComponent as SprayingSVG } from '../../../assets/icons/torq/spraying.svg';
import { ReactComponent as TemperatureSVG } from '../../../assets/icons/torq/temperature.svg';
import { ReactComponent as TrendDownSVG } from '../../../assets/icons/torq/trend_down.svg';
import { ReactComponent as TrendUpSVG } from '../../../assets/icons/torq/trend_up.svg';
import { ReactComponent as BlizzardSVG } from '../../../assets/icons/torq/weatherCondition/blizzard.svg';
import { ReactComponent as BreezySVG } from '../../../assets/icons/torq/weatherCondition/breezy.svg';
import { ReactComponent as ClearSVG } from '../../../assets/icons/torq/weatherCondition/clear.svg';
import { ReactComponent as CloudySVG } from '../../../assets/icons/torq/weatherCondition/cloudy.svg';
import { ReactComponent as DriftingSnowSVG } from '../../../assets/icons/torq/weatherCondition/driftingSnow.svg';
import { ReactComponent as DrizzleSVG } from '../../../assets/icons/torq/weatherCondition/drizzle.svg';
import { ReactComponent as FlurriesSVG } from '../../../assets/icons/torq/weatherCondition/flurries.svg';
import { ReactComponent as FoggySVG } from '../../../assets/icons/torq/weatherCondition/foggy.svg';
import { ReactComponent as FreezingDrizzleSVG } from '../../../assets/icons/torq/weatherCondition/freezingDrizzle.svg';
import { ReactComponent as FreezingRainSVG } from '../../../assets/icons/torq/weatherCondition/freezingRain.svg';
import { ReactComponent as HailSVG } from '../../../assets/icons/torq/weatherCondition/hail.svg';
import { ReactComponent as HazeSVG } from '../../../assets/icons/torq/weatherCondition/haze.svg';
import { ReactComponent as HeavyRainSVG } from '../../../assets/icons/torq/weatherCondition/heavyRain.svg';
import { ReactComponent as HeavySnowSVG } from '../../../assets/icons/torq/weatherCondition/heavySnow.svg';
import { ReactComponent as HotSVG } from '../../../assets/icons/torq/weatherCondition/hot.svg';
import { ReactComponent as HurricaneSVG } from '../../../assets/icons/torq/weatherCondition/hurricane.svg';
import { ReactComponent as IceCrystalsSVG } from '../../../assets/icons/torq/weatherCondition/iceCrystals.svg';
import { ReactComponent as IsolatedThunderstormsSVG } from '../../../assets/icons/torq/weatherCondition/isolatedThunderstorms.svg';
import { ReactComponent as MixedRainAndHailSVG } from '../../../assets/icons/torq/weatherCondition/mixedRainAndHail.svg';
import { ReactComponent as MostlyClearSVG } from '../../../assets/icons/torq/weatherCondition/mostlyClear.svg';
import { ReactComponent as MostlyCloudySVG } from '../../../assets/icons/torq/weatherCondition/mostlyCloudy.svg';
import { ReactComponent as MostlySunnySVG } from '../../../assets/icons/torq/weatherCondition/mostlySunny.svg';
import { ReactComponent as PartlyCloudySVG } from '../../../assets/icons/torq/weatherCondition/partlyCloudy.svg';
import { ReactComponent as RainSVG } from '../../../assets/icons/torq/weatherCondition/rain.svg';
import { ReactComponent as RainSleetSVG } from '../../../assets/icons/torq/weatherCondition/rainSleet.svg';
import { ReactComponent as RainSnowSVG } from '../../../assets/icons/torq/weatherCondition/rainSnow.svg';
import { ReactComponent as SandstormSVG } from '../../../assets/icons/torq/weatherCondition/sandstorm.svg';
import { ReactComponent as ScatteredShowersSVG } from '../../../assets/icons/torq/weatherCondition/scatteredShowers.svg';
import { ReactComponent as ScatteredSnowShowersSVG } from '../../../assets/icons/torq/weatherCondition/scatteredSnowShowers.svg';
import { ReactComponent as ScatteredThunderstormsSVG } from '../../../assets/icons/torq/weatherCondition/scatteredThunderstorms.svg';
import { ReactComponent as ShowersSVG } from '../../../assets/icons/torq/weatherCondition/showers.svg';
import { ReactComponent as SleetSVG } from '../../../assets/icons/torq/weatherCondition/sleet.svg';
import { ReactComponent as SmokeSVG } from '../../../assets/icons/torq/weatherCondition/smoke.svg';
import { ReactComponent as SnowSVG } from '../../../assets/icons/torq/weatherCondition/snow.svg';
import { ReactComponent as SnowShowersSVG } from '../../../assets/icons/torq/weatherCondition/snowShowers.svg';
import { ReactComponent as StrongStormsSVG } from '../../../assets/icons/torq/weatherCondition/strongStorms.svg';
import { ReactComponent as SunnySVG } from '../../../assets/icons/torq/weatherCondition/sunny.svg';
import { ReactComponent as ThunderstormsSVG } from '../../../assets/icons/torq/weatherCondition/thunderstorms.svg';
import { ReactComponent as TornadoSVG } from '../../../assets/icons/torq/weatherCondition/tornado.svg';
import { ReactComponent as TropicalStormSVG } from '../../../assets/icons/torq/weatherCondition/tropicalStorm.svg';
import { ReactComponent as WindySVG } from '../../../assets/icons/torq/weatherCondition/windy.svg';
import { ReactComponent as WintryMixSVG } from '../../../assets/icons/torq/weatherCondition/wintryMix.svg';
import { ReactComponent as WindSVG } from '../../../assets/icons/torq/wind.svg';
import { ReactComponent as WindESVG } from '../../../assets/icons/torq/windDirections/wind_e.svg';
import { ReactComponent as WindNSVG } from '../../../assets/icons/torq/windDirections/wind_n.svg';
import { ReactComponent as WindNESVG } from '../../../assets/icons/torq/windDirections/wind_ne.svg';
import { ReactComponent as WindNWSVG } from '../../../assets/icons/torq/windDirections/wind_nw.svg';
import { ReactComponent as WindSSVG } from '../../../assets/icons/torq/windDirections/wind_s.svg';
import { ReactComponent as WindSESVG } from '../../../assets/icons/torq/windDirections/wind_se.svg';
import { ReactComponent as WindSWSVG } from '../../../assets/icons/torq/windDirections/wind_sw.svg';
import { ReactComponent as WindWSVG } from '../../../assets/icons/torq/windDirections/wind_w.svg';

export const PrecipitationIcon = styled(PrecipitationSVG)<{
  size?: number;
  fill?: DefaultThemeColorsType;
}>`
  fill: ${({ fill, theme }) => (fill ? theme.colors[fill] : theme.colors.text)};
  width: ${({ size }) => (size ? size : 24)}px;
  height: ${({ size }) => (size ? size : 24)}px;
`;

export const SprayingIcon = styled(SprayingSVG)<{
  size?: number;
  fill?: DefaultThemeColorsType;
}>`
  fill: ${({ fill, theme }) =>
    fill ? theme.colors[fill] : theme.colors.textSecondary};
  width: ${({ size }) => (size ? size : 24)}px;
  height: ${({ size }) => (size ? size : 24)}px;
`;

export const HumidityIcon = styled(HumiditySVG)``;

export const TemperatureIcon = styled(TemperatureSVG)``;

export const WindIcon = styled(WindSVG)``;

export const BlizzardIcon = styled(BlizzardSVG)``;

export const BreezyIcon = styled(BreezySVG)``;

export const ClearIcon = styled(ClearSVG)``;

export const CloudyIcon = styled(CloudySVG)``;

export const DriftingSnowIcon = styled(DriftingSnowSVG)``;

export const FlurriesIcon = styled(FlurriesSVG)``;

export const HeavyRainIcon = styled(HeavyRainSVG)``;

export const HotIcon = styled(HotSVG)``;

export const MostlyClearIcon = styled(MostlyClearSVG)``;

export const DrizzleIcon = styled(DrizzleSVG)``;

export const FoggyIcon = styled(FoggySVG)``;

export const FreezingDrizzleIcon = styled(FreezingDrizzleSVG)``;

export const FreezingRainIcon = styled(FreezingRainSVG)``;

export const HailIcon = styled(HailSVG)``;

export const HazeIcon = styled(HazeSVG)``;

export const HeavySnowIcon = styled(HeavySnowSVG)``;

export const HurricaneIcon = styled(HurricaneSVG)``;

export const IceCrystalsIcon = styled(IceCrystalsSVG)``;

export const IsolatedThunderstormsIcon = styled(IsolatedThunderstormsSVG)``;

export const MixedRainAndHailIcon = styled(MixedRainAndHailSVG)``;

export const MostlyCloudyIcon = styled(MostlyCloudySVG)``;

export const MostlySunnyIcon = styled(MostlySunnySVG)``;

export const PartlyCloudyIcon = styled(PartlyCloudySVG)``;

export const RainIcon = styled(RainSVG)``;

export const RainSleetIcon = styled(RainSleetSVG)``;

export const RainSnowIcon = styled(RainSnowSVG)``;

export const SandstormIcon = styled(SandstormSVG)``;

export const ScatteredShowersIcon = styled(ScatteredShowersSVG)``;

export const ScatteredSnowShowersIcon = styled(ScatteredSnowShowersSVG)``;

export const ScatteredThunderstormsIcon = styled(ScatteredThunderstormsSVG)``;

export const ShowersIcon = styled(ShowersSVG)``;

export const SleetIcon = styled(SleetSVG)``;

export const SmokeIcon = styled(SmokeSVG)``;

export const SnowIcon = styled(SnowSVG)``;

export const SnowShowersIcon = styled(SnowShowersSVG)``;

export const StrongStormsIcon = styled(StrongStormsSVG)``;

export const SunnyIcon = styled(SunnySVG)``;

export const ThunderstormsIcon = styled(ThunderstormsSVG)``;

export const TornadoIcon = styled(TornadoSVG)``;

export const TropicalStormIcon = styled(TropicalStormSVG)``;

export const WindyIcon = styled(WindySVG)``;

export const WintryMixIcon = styled(WintryMixSVG)``;

export const WindE = styled(WindESVG)``;

export const WindN = styled(WindNSVG)``;

export const WindNE = styled(WindNESVG)``;

export const WindNW = styled(WindNWSVG)``;

export const WindS = styled(WindSSVG)``;

export const WindSE = styled(WindSESVG)``;

export const WindSW = styled(WindSWSVG)``;

export const WindW = styled(WindWSVG)``;

export const HarvestEstimateIcon = styled(HarvestEstimateSVG)<{
  size?: number;
  fill?: DefaultThemeColorsType;
}>`
  fill: ${({ fill, theme }) =>
    fill ? theme.colors[fill] : theme.colors.textSecondary};
  width: ${({ size }) => (size ? size : 24)}px;
  height: ${({ size }) => (size ? size : 24)}px;
`;

export const GrowthCurveIcon = styled(GrowthCurveSVG)<{
  size?: number;
  fill?: DefaultThemeTorqGrowthCurveColorsType;
}>`
  && {
    fill: ${({ fill, theme }) =>
      fill ? theme.colors.torqGrowthCurve[fill] : theme.colors.textSecondary};
    width: ${({ size }) => (size ? size : 24)}px;
    height: ${({ size }) => (size ? size : 24)}px;
  }
`;

export const NDVIIcon = styled(NDVISVG)<{
  size?: number;
  fill?: DefaultThemeColorsType;
}>`
  fill: ${({ fill, theme }) =>
    fill ? theme.colors[fill] : theme.colors.textSecondary};
  width: ${({ size }) => (size ? size : 24)}px;
  height: ${({ size }) => (size ? size : 24)}px;
`;

export const TrendUpIcon = styled(TrendUpSVG)``;

export const TrendDownIcon = styled(TrendDownSVG)``;

export const TemperatureAlertIcon = styled(TemperatureAlertSVG)<{
  size?: number;
  fill?: DefaultThemeColorsType;
}>`
  fill: ${({ fill, theme }) =>
    fill ? theme.colors[fill] : theme.colors.textSecondary};
  width: ${({ size }) => (size ? size : 24)}px;
  height: ${({ size }) => (size ? size : 24)}px;
`;

export const PrecipitationAlertIcon = styled(PrecipitationAlertSVG)<{
  size?: number;
  fill?: DefaultThemeColorsType;
}>`
  fill: ${({ fill, theme }) =>
    fill ? theme.colors[fill] : theme.colors.textSecondary};
  width: ${({ size }) => (size ? size : 24)}px;
  height: ${({ size }) => (size ? size : 24)}px;
`;

export const FarmHealthGoodAlertIcon = styled(FarmHealthGoodAlertSVG)<{
  size?: number;
  fill?: DefaultThemeColorsType;
}>`
  fill: ${({ fill, theme }) =>
    fill ? theme.colors[fill] : theme.colors.textSecondary};
  width: ${({ size }) => (size ? size : 24)}px;
  height: ${({ size }) => (size ? size : 24)}px;
`;

export const FarmHealthBadAlertIcon = styled(FarmHealthBadAlertSVG)<{
  size?: number;
  fill?: DefaultThemeColorsType;
}>`
  fill: ${({ fill, theme }) =>
    fill ? theme.colors[fill] : theme.colors.textSecondary};
  width: ${({ size }) => (size ? size : 24)}px;
  height: ${({ size }) => (size ? size : 24)}px;
`;

export const DiseaseAlertIcon = styled(DiseaseAlertSVG)<{
  size?: number;
  fill?: DefaultThemeColorsType;
}>`
  fill: ${({ fill, theme }) =>
    fill ? theme.colors[fill] : theme.colors.textSecondary};
  width: ${({ size }) => (size ? size : 24)}px;
  height: ${({ size }) => (size ? size : 24)}px;
`;

export const SprayingAlertIcon = styled(SprayingAlertSVG)<{
  size?: number;
  fill?: DefaultThemeColorsType;
}>`
  fill: ${({ fill, theme }) =>
    fill ? theme.colors[fill] : theme.colors.textSecondary};
  width: ${({ size }) => (size ? size : 24)}px;
  height: ${({ size }) => (size ? size : 24)}px;
`;

export const FarmIcon = styled(MdStore)`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSize.xxl}px;
`;

export const RegionIcon = styled(MdPlace)`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSize.xxl}px;
`;

export const PlotIcon = styled(PlotSVG)``;

export const CropIcon = styled(CropSVG)``;

export const CornIcon = styled(CornSVG)``;

export const SoyIcon = styled(SoySVG)``;

export const CottonIcon = styled(CottonSVG)``;

export const MaxTemperature = styled(MaxTemperatureSVG)``;

export const MinTemperature = styled(MinTemperatureSVG)``;
