import { DefaultThemeColorsType } from '@sinagro/frontend-shared/themes';
import { AlertCategory, AlertLevel } from '@sinagro/shared';
import React, { ReactElement } from 'react';

import {
  DiseaseAlertIcon,
  FarmHealthBadAlertIcon,
  FarmHealthGoodAlertIcon,
  PrecipitationAlertIcon,
  SprayingAlertIcon,
  TemperatureAlertIcon,
} from './styles';

export const alertColor: Record<AlertLevel, DefaultThemeColorsType> = {
  [AlertLevel.High]: 'danger',
  [AlertLevel.Normal]: 'orange',
  [AlertLevel.Low]: 'yellow',
  [AlertLevel.Positive]: 'primary',
};

export const alertIcon: Record<AlertCategory, ReactElement> = {
  [AlertCategory.Temperature]: <TemperatureAlertIcon />,
  [AlertCategory.Precipitation]: <PrecipitationAlertIcon />,
  [AlertCategory.Disease]: <DiseaseAlertIcon />,
  [AlertCategory.Spraying]: <SprayingAlertIcon />,
  [AlertCategory.FarmHealthGood]: <FarmHealthGoodAlertIcon />,
  [AlertCategory.FarmHealthBad]: <FarmHealthBadAlertIcon />,
};
