import { ThemeOptions } from '@material-ui/core';
import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';

export const materialUITheme: ThemeOptions = {
  palette: {
    primary: { main: theme.colors.primary },
    secondary: { main: theme.colors.secondary },
    background: { default: theme.colors.background },
    text: { primary: theme.colors.secondary, secondary: theme.colors.white },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1360,
      xl: 1920,
    },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        padding: 0,
        color: theme.colors.secondary,
      },
    },
    MuiFormLabel: { root: { color: theme.colors.secondary } },
    MuiInputLabel: { root: { fontSize: theme.fontSize.lg } },
    MuiInputBase: { root: { fontSize: theme.fontSize.lg } },
    MuiSvgIcon: {
      colorPrimary: { color: theme.colors.primary },
      colorSecondary: { color: theme.colors.white },
    },
  },
  typography: {
    fontFamily: theme.fontFamily,
    h6: {
      fontSize: theme.fontSize.lg,
      fontWeight: theme.fontWeight.regular,
      lineHeight: 1,
    },
    h5: {
      fontSize: theme.fontSize.xl,
      fontWeight: theme.fontWeight.regular,
      lineHeight: 1,
    },
    h4: {
      fontSize: theme.fontSize.lg,
      fontWeight: theme.fontWeight.semibold,
      lineHeight: 1,
    },
    h3: {
      fontSize: theme.fontSize.xl,
      fontWeight: theme.fontWeight.semibold,
      lineHeight: 1,
    },
    h2: {
      fontSize: theme.fontSize.xxl,
      fontWeight: theme.fontWeight.semibold,
      lineHeight: 1,
    },
    h1: {
      fontSize: theme.fontSize.xxxl,
      fontWeight: theme.fontWeight.semibold,
      lineHeight: 1,
    },
    body1: {
      fontSize: theme.fontSize.md,
      fontWeight: theme.fontWeight.regular,
      lineHeight: 1,
    },
    body2: {
      fontSize: theme.fontSize.sm,
      fontWeight: theme.fontWeight.regular,
      lineHeight: 1,
    },
  },
};
