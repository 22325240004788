import { SagaIterator } from '@redux-saga/types';
import {
  IFinancialReceivableAggregation,
  IFinancialReceivableAggregationItem,
  IFinancialReceivableSummary,
} from '@sinagro/shared';
import { call, takeLatest, put } from 'redux-saga/effects';

import {
  getReceivables,
  getReceivablesPaged,
  getSummary,
} from '../../services/financialReceivable';
import {
  FetchStatusEnum,
  FinancialReceivableGetReceivablesAction,
  ReceivableGetAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getSummary.type, getSummaryEffectSaga),
  takeLatest(actions.getReceivables.type, getReceivablesEffectSaga),
  takeLatest(actions.getReceivablesPaged.type, getReceivablesPagedEffectSaga),
];

export function* getSummaryEffectSaga(): SagaIterator<void> {
  try {
    const summary: IFinancialReceivableSummary = yield call(getSummary);
    yield put(actions.summary({ summary }));
    yield put(
      actions.updateStatusSummary({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusSummary({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}

export function* getReceivablesEffectSaga(
  action: FinancialReceivableGetReceivablesAction
): SagaIterator<void> {
  try {
    const receivables: IFinancialReceivableAggregation[] = yield call(
      getReceivables,
      action.payload
    );
    yield put(actions.receivables({ receivables }));
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export type getReceivableResponse = {
  total: number;
  results: IFinancialReceivableAggregationItem[];
};

export function* getReceivablesPagedEffectSaga(
  action: ReceivableGetAction
): SagaIterator<void> {
  try {
    const { results: receivablesPaged, total }: getReceivableResponse =
      yield call(getReceivablesPaged, action.payload);
    yield put(
      actions.receivablesPaged({
        receivablesPaged,
        total,
        page: action.payload.page,
      })
    );
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
