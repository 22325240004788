import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { ReactComponent as ScheduleSVG } from '../../../assets/icons/schedule.svg';

export const BaseGrid = styled(Grid)`
  cursor: pointer;
  padding: 12px;

  :hover {
    background-color: #f2f2f2;
    transition: 0.5s;
  }
`;

export const Link = styled.a`
  all: unset;
`;

export const Button = styled(Grid)<{ $isActive: boolean }>`
  width: 100%;
  height: 80px;
  padding: 1rem;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.primaryLight : theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primaryLight};
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
    cursor: pointer;
  }
`;

export const ButtonText = styled(Typography)<{ $small?: boolean }>`
  && {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme, $small }) =>
      $small ? theme.fontSize.md : theme.fontSize.lg}px;
    margin-top: ${({ $small }) => ($small ? 4 : 0)}px;
  }
`;

export const ScheduleIcon = styled(ScheduleSVG)`
  width: 56px;
`;
