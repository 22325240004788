import type {
  InvoicesNumbersGet,
  NextBillsGet,
  NextBillsSummaryGet,
} from '../../../types/v2';
import { CustomURLSearchParams } from '../../../utils/customUrlSearchParams';
import api from '../../api';
import {
  invoicesNumbersParams,
  payablesFiltersParams,
  payablesPageParams,
} from './queryParams';

export function getNextBills(payload: NextBillsGet) {
  let params = new CustomURLSearchParams({});

  params = payablesFiltersParams(params, payload.filters);
  params = payablesPageParams(params, payload);
  return api.get(`/financial/payable/next-bills?${params}`);
}

export function getNextBillsSummary(payload: NextBillsSummaryGet) {
  let params = new CustomURLSearchParams({});

  params = payablesFiltersParams(params, payload.filters);
  return api.get(`/financial/payable/next-bills/summary?${params}`);
}

export function getInvoicesNumbers(payload: InvoicesNumbersGet) {
  let params = new CustomURLSearchParams({});
  params = invoicesNumbersParams(params, payload);
  return api.get(`/filters/financial/invoice/available?${params}`);
}
