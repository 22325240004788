import { createSlice } from '@reduxjs/toolkit';

import { FetchStatusEnum } from '../../../../types';
import {
  TrackingSlice,
  RemittanceUpdateErrorAction,
} from '../../../../types/v2';
import { reducersDefensivesAndSpecialties } from './defensivesAndSpecialties';
import { reducersFertilizers } from './fertilizers';
import { reducersOthers } from './others';
import { reducersSeeds } from './seeds';

export const initialState: TrackingSlice = {
  remittancesDefensivesAndSpecialtiesScheduled: [],
  remittancesDefensivesAndSpecialtiesHandling: [],
  remittancesDefensivesAndSpecialtiesInTransit: [],
  remittancesDefensivesAndSpecialtiesDelivered: [],
  remittancesSeedsScheduled: [],
  remittancesSeedsHandling: [],
  remittancesSeedsInTransit: [],
  remittancesSeedsDelivered: [],
  remittancesFertilizersScheduled: [],
  remittancesFertilizersHandling: [],
  remittancesFertilizersInTransit: [],
  remittancesFertilizersDelivered: [],
  remittancesOthersScheduled: [],
  remittancesOthersHandling: [],
  remittancesOthersInTransit: [],
  remittancesOthersDelivered: [],
  fetchStatusDefensivesAndSpecialtiesScheduled: FetchStatusEnum.None,
  fetchStatusDefensivesAndSpecialtiesHandling: FetchStatusEnum.None,
  fetchStatusDefensivesAndSpecialtiesInTransit: FetchStatusEnum.None,
  fetchStatusDefensivesAndSpecialtiesDelivered: FetchStatusEnum.None,
  fetchStatusSeedsScheduled: FetchStatusEnum.None,
  fetchStatusSeedsHandling: FetchStatusEnum.None,
  fetchStatusSeedsInTransit: FetchStatusEnum.None,
  fetchStatusSeedsDelivered: FetchStatusEnum.None,
  fetchStatusFertilizersScheduled: FetchStatusEnum.None,
  fetchStatusFertilizersHandling: FetchStatusEnum.None,
  fetchStatusFertilizersInTransit: FetchStatusEnum.None,
  fetchStatusFertilizersDelivered: FetchStatusEnum.None,
  fetchStatusOthersScheduled: FetchStatusEnum.None,
  fetchStatusOthersHandling: FetchStatusEnum.None,
  fetchStatusOthersInTransit: FetchStatusEnum.None,
  fetchStatusOthersDelivered: FetchStatusEnum.None,
  pageDefensivesAndSpecialtiesScheduled: 0,
  pageDefensivesAndSpecialtiesHandling: 0,
  pageDefensivesAndSpecialtiesInTransit: 0,
  pageDefensivesAndSpecialtiesDelivered: 0,
  pageSeedsScheduled: 0,
  pageSeedsHandling: 0,
  pageSeedsInTransit: 0,
  pageSeedsDelivered: 0,
  pageFertilizersScheduled: 0,
  pageFertilizersHandling: 0,
  pageFertilizersInTransit: 0,
  pageFertilizersDelivered: 0,
  pageOthersScheduled: 0,
  pageOthersHandling: 0,
  pageOthersInTransit: 0,
  pageOthersDelivered: 0,
  totalDefensivesAndSpecialtiesScheduled: 0,
  totalDefensivesAndSpecialtiesHandling: 0,
  totalDefensivesAndSpecialtiesInTransit: 0,
  totalDefensivesAndSpecialtiesDelivered: 0,
  totalSeedsScheduled: 0,
  totalSeedsHandling: 0,
  totalSeedsInTransit: 0,
  totalSeedsDelivered: 0,
  totalFertilizersScheduled: 0,
  totalFertilizersHandling: 0,
  totalFertilizersInTransit: 0,
  totalFertilizersDelivered: 0,
  totalOthersScheduled: 0,
  totalOthersHandling: 0,
  totalOthersInTransit: 0,
  totalOthersDelivered: 0,
  error: null,
};

export const slice = createSlice({
  name: 'remittances',
  initialState,
  reducers: {
    clear: () => initialState,
    ...reducersDefensivesAndSpecialties,
    ...reducersSeeds,
    ...reducersFertilizers,
    ...reducersOthers,
    updateError: (state, action: RemittanceUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
