import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const ProfileIcon = styled(FontAwesomeIcon)`
  font-size: 26px;
  color: ${({ theme }) => theme.colors.darkCyan};
  z-index: 3;
`;

export const HeaderContainer = styled(Grid)`
  width: 320px !important;
  margin-left: calc(100% - 600px);
  display: flex;
  justify-content: space-around;
  position: absolute;
  z-index: 4;
`;

export const MainContainer = styled(Grid)`
  height: 7vh;
  width: 250px !important;
  margin-top: 32px !important ;
  cursor: pointer;
  user-select: none;
  margin-left: 2rem;
`;

export const OptionsContainer = styled(Grid)`
  width: 300px;
  margin-top: 0.5rem !important;
  height: auto;
  box-shadow: 1px 1px 10px 0px lightgrey;
`;
