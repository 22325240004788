export const defaultTheme = {
  colors: {
    background: '#F9FAFF',
    backgroundSecondary: '#EEF0FA',
    primary: '#20286D',
    primaryMedium: '#1C235F',
    primaryDark: '#0E1448',
    primaryLight: '#E7EAF6',
    secondary: '#707070',
    tertiary: '#808495',
    quaternary: '#29a537',
    quaternary2: '#8EB44D',
    quaternaryLight: '#B1CD80',
    white: '#FFFFFF',
    shadow: '#00000029',
    disabled: '#0000001F',
    black: '#000000',
    table: {
      primary: '#FFFFFF',
      secondary: '#9D9D9D',
      tertiary: '#20286D',
      primaryLight: '#E7EAF6',
      background: '#F9FAFF',
    },
    danger: '#DB4437',
    dangerLight: '#fdeded',
    dangerDisabled: '#f2a9a3',
    dangerOnHover: '#ad1003',
    yellow: '#F4B400',
    darkGreen: '#678C3A',
    green: '#30C836',
    purple: '#63206D',
    lightBlue: '#2196F3',
    cyan: '#1FA28D',
    darkCyan: '#293643',
    blue: '#094D92',
    orange: '#FB8319',
    red: '#FF2B19',
    segment: {
      defensive: '#8EB44D',
      fertilizer: '#20286D',
      seed: '#16697A',
      specialty: '#00A5CF',
      animal_nutrition: '#094D92',
      grains: '#0EAD69',
      unknow: '#808495',
    },
    financialOperation: {
      payment: '#BBD688',
      advance: '#BBD688',
      purchase: '#64B6F7',
      residual: '#90CAF9',
      unknown: '#9D9D9D',
      receivable: '#64B6F7',
      devolution: '#FFE03F',
      received: '#BBD688',
      barter: '#BBD688',
    },
    notifications: {
      default: '#20286D',
      defaultLight: '#FEF9FF',
      payable: '#1888E0',
      payableLight: '#F9F9FF',
      order: '#F4B400',
      orderLight: '#FFFDF9',
      tracking: '#1FA28D',
      trackingLight: '#F6FAF9',
      contract: '#A947B8',
      contractLight: '#FEF9FF',
      hub: '#FB8319',
      hubLight: '#FFFAF6',
      hubMedium: '#FFE8D3',
      hubAlert: '#20286D',
      hubAlertLight: '#FEF9FF',
      hubAlertMedium: '#DBDFFD',
      hubFarmHealth: '#20286D',
      hubFarmHealthLight: '#FFFFFF',
      hubFarmHealthMedium: '#DBDFFD',
    },
    remoteSensingScale: {
      bad90: '#C20E09',
      bad50: '#DB4C42',
      bad10: '#F59023',
      regular90: '#FFC12A',
      regular50: '#F5ED57',
      regular10: '#EFFF5B',
      good10: '#DBF17B',
      good50: '#4AD85C',
      good90: '#2FC44E',
    },
    border: '#E6E6E6',
    borderSecondary: '#C0C0C0',
    text: '#30313C',
    title: '#191919',
    textSecondary: '#7D7F8E',
    backgroundDisabled: '#F8F8F8',
    textDisabled: '#BBBBBB',
    torqBlue: '#1F5C94',
    torqMediumBlue: '#C8E5FF',
    torqLightBlue: '#0D73D0',
    torqMediumLightBlue: '#C6E3FF',
    torqMediumOrange: '#FB831925',
    torqDisease: {
      veryLow: '#6CBA30',
      low: '#90DB56',
      medium: '#F7A511',
      high: '#FB8579',
      veryHigh: '#E0584A',
    },
    torqGrowthCurve: {
      within: '#8EB44D',
      above: '#20286D',
      below: '#DB4437',
      beyond: '#FB8319',
      newPlot: '#808495',
      noBandForCrop: '#707070',
      waitingHarvestClosure: '#808495',
      waitingNewHarvest: '#707070',
    },
    creditPackageStatus: {
      pending: '#FFDADA',
      review: '#FFEAAE',
      approved: '#E5F8C1',
      rejected: '#FFDADA',
    },
    backgroundBlack: '#242424',
    backoffice: {
      blue: '#1976D2',
      purple: '#9C27B0',
      green: '#30C836',
      darkGreen: '#2E7D32',
      sinagroGreen: '#8EB44D',
      orange: '#ED6C02',
      lightGray: '#F8F8F8',
      red: '#DB4437',
      yellow: '#F4B400',
      lightYellow: '#FFFDC8',
      gray: '#808495',
    },
  },
  fontFamily:
    "'-apple-system', 'BlinkMacSystemFont', 'Josefin Sans', sans-serif",
  fontFamilyMobile: {
    regular: 'JosefinSans-Regular',
    medium: 'JosefinSans-Medium',
    light: 'JosefinSans-Light',
    thin: 'JosefinSans-Thin',
  },
  fontSize: {
    xs: 9,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 18,
    xxl: 20,
    xxxl: 22,
    big: 24,
    xbig: 30,
    xxbig: 40,
  },
  fontWeight: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
};

export type DefaultThemeColorsType = keyof Omit<
  typeof defaultTheme['colors'],
  'table' | 'segment' | 'notifications'
>;
export type DefaultThemeTableColorsType =
  keyof typeof defaultTheme['colors']['table'];
export type DefaultThemeSegmentColorsType =
  keyof typeof defaultTheme['colors']['segment'];
export type DefaultThemeFinancialOperationColorsType =
  keyof typeof defaultTheme['colors']['financialOperation'];
export type DefaultThemeCreditPackageStatusColorsType =
  keyof typeof defaultTheme['colors']['creditPackageStatus'];
export type DefaultThemeFontSizeType = keyof typeof defaultTheme['fontSize'];
export type DefaultThemeTorqGrowthCurveColorsType =
  keyof typeof defaultTheme['colors']['torqGrowthCurve'];
export type DefaultThemeRemoteSensingColorsType =
  keyof typeof defaultTheme['colors']['remoteSensingScale'];
