import { UserInviteStatusEnum } from '@sinagro/shared';
import React from 'react';
import {
  RiMailCheckFill,
  RiMailCloseFill,
  RiMailDownloadFill,
} from 'react-icons/ri';

import Badge from '../../../components/elements/badge';
import { UserInviteStatusEnumTranslated } from '../../../utils/enumsTranslate';

type StatusCellProps = {
  status: UserInviteStatusEnum;
};

export const StatusCell = ({ status }: StatusCellProps) => {
  const backgroundColor =
    status === UserInviteStatusEnum.Accepted
      ? 'green'
      : status === UserInviteStatusEnum.Rejected
      ? 'danger'
      : 'yellow';
  const icon =
    status === UserInviteStatusEnum.Accepted
      ? RiMailCheckFill
      : status === UserInviteStatusEnum.Rejected
      ? RiMailCloseFill
      : RiMailDownloadFill;
  const statusTranslated = UserInviteStatusEnumTranslated[status];

  return (
    <Badge
      label={statusTranslated}
      color={backgroundColor}
      labelColor="textSecondary"
      fontSize="xl"
      type="default"
      Icon={icon}
    />
  );
};
