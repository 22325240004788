import {
  WeatherForecastGet,
  DiseaseForecastGet,
  SprayingForecastGet,
  FarmDetailsGet,
  CountActiveAlertsGet,
  ActiveAlertsGet,
  RemoteSensingImagesGet,
  GrowthCurveChartGet,
} from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';
import api from '../api';
import {
  weatherForecastParams,
  diseaseForecastParams,
  sprayingForecastParams,
} from './queryParams';

export function getWeatherForecast(payload: WeatherForecastGet) {
  let params = new CustomURLSearchParams({});

  params = weatherForecastParams(params, payload.regionId);
  return api.get(`/weather/forecast?${params}`);
}

export function getDiseaseForecast(payload: DiseaseForecastGet) {
  let params = new CustomURLSearchParams({});

  params = diseaseForecastParams(params, payload.regionId);
  return api.get(`/disease/forecast?${params}`);
}

export function getSprayingForecast(payload: SprayingForecastGet) {
  let params = new CustomURLSearchParams({});

  params = sprayingForecastParams(params, payload.regionId);
  return api.get(`/spraying/forecast?${params}`);
}

export function getFarmDetails(payload: FarmDetailsGet) {
  return api.get(`/region/farm/${payload.farmId}`);
}

export function getCountActiveAlerts(payload: CountActiveAlertsGet) {
  return api.get(`/region/${payload.regionId}/alerts/count`);
}

export function getActiveAlerts(payload: ActiveAlertsGet) {
  return api.get(`/region/${payload.regionId}/alerts`);
}

export function getGrowthCurveChart(payload: GrowthCurveChartGet) {
  return api.get(`/region/${payload.regionId}/widget/growth-curve`);
}

export function getRemoteSensingImages(payload: RemoteSensingImagesGet) {
  return api.get(`/region/${payload.regionId}/plotImages`);
}
