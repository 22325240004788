/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit';
import { DocumentTypeEnum, INotificationConfig } from '@sinagro/shared';

import {
  DeleteAccountAction,
  DeleteAccountUpdateErrorAction,
  DeleteAccountUpdateStatusAction,
  FetchStatusEnum,
  UpdateUserDeviceTokenAction,
  UpdateUserEmailOrPhoneAction,
  UpdateUserEmailOrPhoneUpdateErrorAction,
  UpdateUserEmailOrPhoneUpdateStatusAction,
  UpdateUserProfileAction,
  UpdateUserProfileUpdateErrorAction,
  UpdateUserProfileUpdateStatusAction,
  UpdateUserWebTokenAction,
  UserProfileFetchedAction,
  UserProfileSlice,
  UserProfileUpdateErrorAction,
  UserProfileUpdateStatusAction,
} from '../../types';

const initialState: UserProfileSlice = {
  userProfile: {
    id: '',
    name: '',
    email: '',
    phoneNumber: '',
    document: '',
    documentType: DocumentTypeEnum.CPF,
    notificationConfig: { web: {}, mobile: {} } as INotificationConfig,
  },
  fetchStatus: FetchStatusEnum.None,
  fetchStatusUpdateUserProfile: FetchStatusEnum.None,
  fetchStatusUpdateUserEmailOrPhone: FetchStatusEnum.None,
  fetchStatusDeleteAccount: FetchStatusEnum.None,
  error: null,
  errorUpdateUserProfile: null,
  errorUpdateUserEmailOrPhone: null,
  errorDeleteAccount: null,
};

export const slice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    clear: () => initialState,
    getUserProfile: (state) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    userProfile: (state, action: UserProfileFetchedAction) => {
      state.userProfile = action.payload.userProfile;
    },
    updateStatus: (state, action: UserProfileUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: UserProfileUpdateErrorAction) => {
      state.error = action.payload.error;
    },
    updateUserProfile: (state, _: UpdateUserProfileAction) => {
      state.fetchStatusUpdateUserProfile = FetchStatusEnum.Fetching;
    },
    updateStatusUpdateUserProfile: (
      state,
      action: UpdateUserProfileUpdateStatusAction
    ) => {
      state.fetchStatusUpdateUserProfile =
        action.payload.fetchStatusUpdateUserProfile;
    },
    updateErrorUpdateUserProfile: (
      state,
      action: UpdateUserProfileUpdateErrorAction
    ) => {
      state.errorUpdateUserProfile = action.payload.errorUpdateUserProfile;
    },
    updateUserEmailOrPhone: (state, _: UpdateUserEmailOrPhoneAction) => {
      state.fetchStatusUpdateUserEmailOrPhone = FetchStatusEnum.Fetching;
    },
    updateStatusUpdateUserEmailOrPhone: (
      state,
      action: UpdateUserEmailOrPhoneUpdateStatusAction
    ) => {
      state.fetchStatusUpdateUserEmailOrPhone =
        action.payload.fetchStatusUpdateUserEmailOrPhone;
    },
    updateErrorUpdateUserEmailOrPhone: (
      state,
      action: UpdateUserEmailOrPhoneUpdateErrorAction
    ) => {
      state.errorUpdateUserEmailOrPhone =
        action.payload.errorUpdateUserEmailOrPhone;
    },
    createUserDeviceToken: (_, __: UpdateUserDeviceTokenAction) => {},
    deleteUserDeviceToken: (_, __: UpdateUserDeviceTokenAction) => {},
    createUserWebToken: (_, __: UpdateUserWebTokenAction) => {},
    deleteUserWebToken: (_, __: UpdateUserWebTokenAction) => {},
    deleteAccount: (state, _: DeleteAccountAction) => {
      state.fetchStatusDeleteAccount = FetchStatusEnum.Fetching;
    },
    updateStatusDeleteAccount: (
      state,
      action: DeleteAccountUpdateStatusAction
    ) => {
      state.fetchStatusDeleteAccount = action.payload.fetchStatusDeleteAccount;
    },
    updateErrorDeleteAccount: (
      state,
      action: DeleteAccountUpdateErrorAction
    ) => {
      state.errorDeleteAccount = action.payload.errorDeleteAccount;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
