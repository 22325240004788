export interface SharedError {
  errorRef: string
  message: string
}

import defaultErrors from './defaultErrors'
import userErrors from './userErrors'
import validationCodeErrors from './validationCodeErrors'
import contractErrors from './contractErrors'
import orderErrors from './orderErrors'
import sapErrors from './sapErrors'
import notificationErrors from './notificationErrors'
import hubErrors from './hubErrors'
import systemConfigErrors from './systemConfigErrors'
import remittanceErrors from './remittanceError'
import creditPackageErrors from './creditPackageErrors'
import duplicateErrors from './duplicateErrors'

export const errorList: SharedError[] = [
  ...userErrors,
  ...defaultErrors,
  ...validationCodeErrors,
  ...contractErrors,
  ...orderErrors,
  ...sapErrors,
  ...notificationErrors,
  ...hubErrors,
  ...systemConfigErrors,
  ...remittanceErrors,
  ...creditPackageErrors,
  ...duplicateErrors,
]
