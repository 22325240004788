import { SagaIterator } from '@redux-saga/types';
import { IContract } from '@sinagro/shared';
import { call, takeLatest, put } from 'redux-saga/effects';

import {
  downloadCargoStatement,
  getContracts,
  downloadReport,
} from '../../services/trade';
import {
  FetchStatusEnum,
  TradeGetContractsAction,
  TradeDownloadCargoStatementAction,
  TradeDownloadReportAction,
} from '../../types';
import { download } from '../../utils/downloadFiles';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getContracts.type, getContractsEffectSaga),
  takeLatest(
    actions.downloadCargoStatement.type,
    downloadCargoStatementEffectSaga
  ),
  takeLatest(actions.downloadReport.type, downloadReportEffectSaga),
];

export type GetContractsResponse = {
  total: number;
  results: IContract[];
};

export function* getContractsEffectSaga(
  action: TradeGetContractsAction
): SagaIterator<void> {
  try {
    const { results: contracts, total }: GetContractsResponse = yield call(
      getContracts,
      action.payload
    );
    yield put(
      actions.contracts({ contracts, total, page: action.payload.page })
    );
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
type DownloadCargoStatementResponse = {
  url: string;
};

export function* downloadCargoStatementEffectSaga(
  action: TradeDownloadCargoStatementAction
): SagaIterator<void> {
  try {
    const { url }: DownloadCargoStatementResponse = yield call(
      downloadCargoStatement,
      action.payload
    );
    yield call(download, url, action.payload.cargoStatementId);
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export function* downloadReportEffectSaga(
  action: TradeDownloadReportAction
): SagaIterator<void> {
  try {
    const data = yield call(downloadReport, action.payload);
    const url = data.url;
    yield call(download, url, action.payload.contractId);
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
