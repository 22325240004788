import { SagaIterator } from '@redux-saga/types';
import {
  IFinancialOpenCreditsAggregation,
  IFinancialOpenCreditsAggregationItem,
  IFinancialOpenCreditsSummary,
} from '@sinagro/shared';
import { call, takeLatest, put } from 'redux-saga/effects';

import {
  getOpenCredits,
  getOpenCreditsPaged,
  getSummary,
} from '../../services/financialOpenCredits';
import {
  FetchStatusEnum,
  FinancialOpenCreditsGetOpenCreditsAction,
  FinancialOpenCreditsGetSummaryAction,
  OpenCreditsGetAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getSummary.type, getSummaryEffectSaga),
  takeLatest(actions.getOpenCredits.type, getOpenCreditsEffectSaga),
  takeLatest(actions.getOpenCreditsPaged.type, getOpenCreditsPagedEffectSaga),
];

export function* getSummaryEffectSaga(
  action: FinancialOpenCreditsGetSummaryAction
): SagaIterator<void> {
  try {
    const summary: IFinancialOpenCreditsSummary = yield call(
      getSummary,
      action.payload
    );
    yield put(actions.summary({ summary }));
    yield put(
      actions.updateStatusSummary({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusSummary({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}

export function* getOpenCreditsEffectSaga(
  action: FinancialOpenCreditsGetOpenCreditsAction
): SagaIterator<void> {
  try {
    const openCredits: IFinancialOpenCreditsAggregation[] = yield call(
      getOpenCredits,
      action.payload
    );
    yield put(actions.openCredits({ openCredits }));
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export type getOpenCreditsResponse = {
  total: number;
  results: IFinancialOpenCreditsAggregationItem[];
};

export function* getOpenCreditsPagedEffectSaga(
  action: OpenCreditsGetAction
): SagaIterator<void> {
  try {
    const { results: openCreditsPaged, total }: getOpenCreditsResponse =
      yield call(getOpenCreditsPaged, action.payload);
    yield put(
      actions.openCreditsPaged({
        openCreditsPaged,
        total,
        page: action.payload.page,
      })
    );
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
