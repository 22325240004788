import { SharedError } from '.'

export const contractNotFoundError: SharedError = {
  errorRef: 'contract/not-found',
  message: 'Contrato não encontrado',
}
export const cargoItemNotFoundError: SharedError = {
  errorRef: 'contract:cargo-item/not-found',
  message: 'Romaneio não encontrado',
}
export const cargoItemFileNotFoundError: SharedError = {
  errorRef: 'contract:cargo-item:file/not-found',
  message: 'Arquivo de romaneio não encontrado',
}

export default [
  contractNotFoundError,
  cargoItemNotFoundError,
  cargoItemFileNotFoundError,
]
