import { SagaIterator } from '@redux-saga/types';
import { NewsDTO } from '@sinagro/shared';
import { call, takeLatest, put } from 'redux-saga/effects';

import { getNews } from '../../services/news';
import { FetchStatusEnum } from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [takeLatest(actions.getNews.type, getNewsEffectSaga)];

export type getNewsResponse = NewsDTO[];

export function* getNewsEffectSaga(): SagaIterator<void> {
  try {
    const news: getNewsResponse = yield call(getNews);
    yield put(actions.news({ news }));
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
