import { SharedError } from '.'

export const invalidCreditPackageClientError: SharedError = {
  errorRef: 'credit-package/forbidden',
  message: 'Cliente inválido',
}

export const invalidCreditPackageIdError: SharedError = {
  errorRef: 'credit-package/not-found',
  message: 'Pacote de documentos não encontrado (Id inválido)',
}

export const invalidCreditPackageDocumentIdError: SharedError = {
  errorRef: 'credit-package:document/not-found',
  message: 'Documento não encontrado (Id inválido)',
}

export const badRequestMissingRouteParamDocumentId: SharedError = {
  errorRef: 'credit-package/badRequest/missing-documentId',
  message: 'Route param documentId é obrigatório',
}

export const badRequestMissingRouteParamDocumentPackageId: SharedError = {
  errorRef: 'credit-package/badRequest/missing-documentPackageId',
  message: 'Route param documentPackageId é obrigatório',
}

export const badRequestMissingFileName: SharedError = {
  errorRef: 'credit-package/badRequest/missing-fileName',
  message: 'Body params fileName é obrigatório',
}

export const badRequestMissingFileType: SharedError = {
  errorRef: 'credit-package/badRequest/missing-fileType',
  message: 'Body params fileType é obrigatório',
}

export const badRequestFileTypeIsNotValid: SharedError = {
  errorRef: 'credit-package/badRequest/invalid-type',
  message: 'Tipo do arquivo inválido. Tipos válidos: JPEG PNG PDF XLSX XML',
}

export const invalidCreditPackageDocumentStatusError: SharedError = {
  errorRef: 'credit-package:document/invalid-status',
  message: 'O status informado não é válido para esse documento (sem upload)',
}

export const noFilesAttachedForCreditPackageDocumentsError: SharedError = {
  errorRef: 'credit-package/not-files-attached',
  message:
    'Não existem arquivos anexados para os documentos desse pacote de crédito',
}

export const downloadAllDocumentsError: SharedError = {
  errorRef: 'credit-package/download-all-documents',
  message: 'Não foi possível baixar todos os arquivos',
}

export const missingDaysToExpireQueryParams: SharedError = {
  errorRef: 'credit-package:pending-email/missing-daysToExpire',
  message: 'Query params daysToExpire is required',
}

export const daysToExpireIsNotANumber: SharedError = {
  errorRef: 'credit-package:pending-email/daysToExpire-is-not-a-number',
  message: 'Query params daysToExpire must be a number',
}

export const newCreditRequestCreditPackageNotFound: SharedError = {
  errorRef: 'credit-package-new-credit-request/notfound',
  message: 'Pacote de crédito não encontrado',
}
export default [
  invalidCreditPackageIdError,
  invalidCreditPackageClientError,
  badRequestMissingFileName,
  badRequestMissingFileType,
  badRequestMissingRouteParamDocumentId,
  badRequestMissingRouteParamDocumentPackageId,
  badRequestFileTypeIsNotValid,
  noFilesAttachedForCreditPackageDocumentsError,
  newCreditRequestCreditPackageNotFound,
]
