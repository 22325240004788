import type {
  GroupedRemittanceGet,
  RemittanceHistoryGet,
  DownloadReceipts,
} from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';
import api from '../api';
import { trackingFiltersParams, trackingPageParams } from './queryParams';

const config = { timeout: 60000 };

export function getGroupedRemittance(payload: GroupedRemittanceGet) {
  let params = new CustomURLSearchParams({});

  params = trackingFiltersParams(params, payload.filters);
  return api.get(`/remittance?${params}`, config);
}

export function getRemittanceHistory(payload: RemittanceHistoryGet) {
  let params = new CustomURLSearchParams({});

  params = trackingPageParams(params, payload);
  return api.get(`/remittance/history?${params}`, config);
}

export function downloadReceipts(payload: DownloadReceipts) {
  return api.get(`/remittance/${payload.remittanceId}/deliver-receipt`, config);
}

export function downloadInvoices(payload: DownloadReceipts) {
  return api.get(`/remittance/${payload.remittanceId}/invoice`, config);
}

export default { downloadReceipts, downloadInvoices };
