import type { FiltersType, ReceivableGet } from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';

export const receivablesFiltersParams = (
  params: CustomURLSearchParams,
  filters: FiltersType
) => {
  filters.harvestReferences.length > 0 &&
    params.set('harvestReference', filters.harvestReferences.join(','));

  filters.searchTerm.length > 0 &&
    params.set('search', `%${filters.searchTerm}%`);

  filters.dueDate.startDate &&
    params.set(
      'dueDate(min)',
      filters.dueDate.startDate.toDateString() + ' 00:00:00'
    );

  filters.dueDate.endDate &&
    params.set(
      'dueDate(max)',
      filters.dueDate.endDate.toDateString() + ' 23:59:59'
    );

  filters.receivableStatus &&
    params.set('receivableStatus', filters.receivableStatus);

  filters.contract.length > 0 &&
    params.set('contract', `%${filters.contract}}%`);

  filters.productName.length > 0 &&
    params.set('productName', `%${filters.productName}%`);

  filters.propertyName.length > 0 &&
    params.set('propertyName', `%${filters.propertyName}%`);

  filters.modality && params.set('type', filters.modality);

  filters.currency && params.set('currency', filters.currency);

  filters.invoice.length > 0 && params.set('invoice', `%${filters.invoice}%`);

  filters.paymentDate.startDate &&
    params.set(
      'paymentDate(min)',
      filters.paymentDate.startDate.toDateString() + ' 00:00:00'
    );

  filters.paymentDate.endDate &&
    params.set(
      'paymentDate(max)',
      filters.paymentDate.endDate.toDateString() + ' 23:59:59'
    );

  return params;
};

export const receivablesPageParams = (
  params: CustomURLSearchParams,
  payload: ReceivableGet
) => {
  payload.limit && params.set('limit', payload.limit.toString());

  payload.page && params.set('page', payload.page.toString());

  return params;
};
