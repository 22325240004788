import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import Accordion from 'src/components/elements/accordion';
import {
  regionProperties,
  RegionPropertiesEnum,
} from 'src/pages/torq/utils/regionProperties';
import { useTheme } from 'styled-components';

import { Container, Title, Item, Text, Header } from './styles';

const PlotCaption = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const properties = Object.values(RegionPropertiesEnum);

  const style = {
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: `${theme.colors.primary}07`,
    boxShadow: 'none',
    marginBottom: 16,
  };

  const summary = useMemo(() => {
    return (
      <Header>
        <Title>{t('torq.plot.summary.caption')}</Title>
        {expanded ? <MdExpandLess /> : <MdExpandMore />}
      </Header>
    );
  }, [t, expanded]);

  const details = useMemo(() => {
    return (
      <Container>
        {properties.map((item) => (
          <Item key={item}>
            {regionProperties[item].Icon}
            <Text>{regionProperties[item].label}</Text>
          </Item>
        ))}
      </Container>
    );
  }, [properties]);

  const handleExpand = React.useCallback(() => {
    setExpanded((prevState) => !prevState);
  }, [setExpanded]);

  return (
    <Accordion
      summary={summary}
      details={details}
      expanded={expanded}
      onChange={handleExpand}
      style={style}
    />
  );
};

export default PlotCaption;
