import { Backdrop, ClickAwayListener } from '@material-ui/core';
import React, { useCallback } from 'react';

import { useOverlayContext } from '../../../contexts/overlay';

const Overlay: React.FC = () => {
  const overlay = useOverlayContext();

  const handleClose = useCallback(() => {
    if (overlay.setComponent) overlay.setComponent(null);
  }, [overlay]);

  return (
    <Backdrop
      invisible
      open={!!overlay.component}
      style={{
        zIndex: 10,
        height: document.getElementById('root')?.scrollHeight ?? '100vh',
        position: 'absolute',
      }}
    >
      {!!overlay.component && (
        <ClickAwayListener onClickAway={handleClose}>
          <div>{overlay.component}</div>
        </ClickAwayListener>
      )}
    </Backdrop>
  );
};

export default Overlay;
