import { Grid } from '@material-ui/core';
import { DailyWeatherForecastDTO, WeatherConditionEnum } from '@sinagro/shared';
import React from 'react';
import { useIsMobile } from 'src/hooks/responsiveLayout';
import {
  MaxTemperature,
  MinTemperature,
  PrecipitationIcon,
} from 'src/pages/torq/utils/styles';
import { weatherCondition } from 'src/pages/torq/utils/weatherCondition';
import {
  formatDateAndMonth,
  formatPrecipitation,
  formatTemperature,
  formatWeekday,
} from 'src/utils/format';

import {
  DateContent,
  Summary,
  TemperatureContent,
  Text,
  WeatherConditionContent,
  PrecipitationContent,
  TextTemperature,
} from './styles';

type Props = {
  day: DailyWeatherForecastDTO;
  type?: 'portal' | 'report';
  isActive?: boolean;
};

const DayWeather = ({ day, type = 'portal', isActive = false }: Props) => {
  const isMobile = useIsMobile();
  const cardIsActive = type === 'report' ? true : isActive;

  const condition =
    day.condition !== undefined
      ? day.condition
      : WeatherConditionEnum.NotAvailable;
  const currentWeatherCondition = weatherCondition[condition];

  return (
    <>
      <Summary>
        <Grid>
          <DateContent>
            <Text $isMobile={isMobile}>{formatWeekday(day.date, true)}</Text>
            <Text $isMobile={isMobile}>
              {formatDateAndMonth(day.date, true)}
            </Text>
          </DateContent>

          <TemperatureContent>
            <Grid>
              <MinTemperature />
              <TextTemperature $isActive={cardIsActive} $isMobile={isMobile}>
                {formatTemperature(day.minTemperature)}
              </TextTemperature>
            </Grid>
            <Grid>
              <MaxTemperature />
              <TextTemperature $isActive={cardIsActive} $isMobile={isMobile}>
                {formatTemperature(day.maxTemperature)}
              </TextTemperature>
            </Grid>
          </TemperatureContent>
        </Grid>

        <WeatherConditionContent>
          {currentWeatherCondition.Icon}
          <Text $isMobile={isMobile}>{currentWeatherCondition.label}</Text>
        </WeatherConditionContent>
      </Summary>

      <PrecipitationContent>
        <PrecipitationIcon fill="torqBlue" />
        <Text $isMobile={isMobile}>
          {formatPrecipitation(day.precipitation)}
        </Text>
      </PrecipitationContent>
    </>
  );
};

export default DayWeather;
