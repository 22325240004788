import {
  DailyWeatherForecastDTO,
  HourlyWeatherForecastDTO,
  SprayingForecastDTO,
} from '@sinagro/shared';
import i18n from 'i18next';
import { DailyWeatherForecast, SprayingForecast } from 'src/contexts/torq';
import { getDifferenceBetweenDates } from 'src/pages/torq/utils/utils';
import {
  compareDate,
  formatDate,
  formatDateAndMonth,
  formattedCurrentDate,
} from 'src/utils/format';

export const getFormattedDaily = (
  daily: DailyWeatherForecastDTO[],
  hourly: HourlyWeatherForecastDTO[]
) => {
  const formattedDaily: Array<DailyWeatherForecast> = [];
  let lastDate = '';

  daily.map((item) => {
    const formattedDay = formatDate(item.date, true);
    const hourlyFiltered = hourly.filter(
      (item) => formatDate(item.date, true) === formattedDay
    );

    if (hourlyFiltered.length === 24) {
      const isRecommended =
        hourlyFiltered.filter(
          (item) => item.sprayingRecommendation !== undefined
        ).length === 24;

      if (isRecommended) {
        lastDate = item.date;
      }

      const difference = getDifferenceBetweenDates(lastDate, item.date);
      const message = isRecommended
        ? ''
        : `${i18n.t('torq.spraying.availableAt')} ${difference}`;

      formattedDaily.push({
        ...item,
        hourly: hourlyFiltered,
        sprayingRecommendation: {
          isRecommended,
          message,
        },
      });
    }
  });

  return formattedDaily;
};

export const getFormattedSprayingForecast = (
  spraying: SprayingForecastDTO[]
) => {
  const formattedSpraying: Array<SprayingForecast> = [];
  const currentDate = formattedCurrentDate();
  let lastDate = '';
  let currentSprayingDate = 0;

  spraying.map((item) => {
    const date = formatDateAndMonth(item.date, true);

    if (lastDate !== date) {
      const newItem = {
        date: item.date,
        hourly: [],
      };

      formattedSpraying.push({ ...newItem });

      lastDate = date;
      currentSprayingDate = formattedSpraying.findIndex(
        (currentItem) => currentItem.date === item.date
      );
    }

    formattedSpraying[currentSprayingDate].hourly?.push({ ...item });
  });

  const sprayingFiltered = formattedSpraying.filter((item) => {
    return compareDate(item.date, currentDate) && item.hourly?.length === 24;
  });

  return sprayingFiltered;
};

export const getFilteredDailyWeather = (daily: DailyWeatherForecastDTO[]) => {
  const currentDate = formattedCurrentDate();

  const filtered = daily.filter((item) => {
    if (compareDate(item.date, currentDate, true)) {
      return item;
    }
  });

  return filtered;
};
