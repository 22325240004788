import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const MainContainer = styled(Grid)`
  && {
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.white};
    padding: 0.1rem 0.5rem 1rem 0.5rem;
    wrap: nowrap;
    max-width: 40rem;
    border: 1px solid ${({ theme }) => theme.colors.border};
    margin: 1rem 0;
  }
`;

export const Header = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.2rem;
  margin: 1rem 0.25rem 0 0;
`;

export const Title = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.xxxl}px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Article = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.md}px;
    color: ${({ theme }) => theme.colors.primary};
    margin: 8px 0.25rem 0.25rem 0.25rem;
  }
`;

export const Subtext = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.sm}px;
    color: ${({ theme }) => theme.colors.secondary};
    display: flex;
    justify-content: flex-start;
    margin-left: 0.25rem;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primary};
`;
