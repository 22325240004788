import { createSlice } from '@reduxjs/toolkit';

import { FetchStatusEnum } from '../../../types';
import {
  FinancialReceivableSlice,
  ReceivablesFetchAction,
  ReceivablesGetAction,
  ReceivablesSummaryGetAction,
  ReceivablesSummaryFetchAction,
  ReceivablesSummaryUpdateStatusAction,
  ReceivablesUpdateErrorAction,
  ReceivablesUpdateStatusAction,
} from '../../../types/v2';

const initialState: FinancialReceivableSlice = {
  summaryTotals: [],
  fetchStatus: FetchStatusEnum.None,
  fetchStatusSummary: FetchStatusEnum.None,
  receivables: [],
  receivablesInfiniteScroll: [],
  error: null,
  page: 0,
  total: 0,
};

export const slice = createSlice({
  name: 'financialReceivables',
  initialState,
  reducers: {
    clear: () => initialState,
    getReceivables: (state, action: ReceivablesGetAction) => {
      const { page, limit } = action.payload;
      state.page = page;
      state.limit = limit;
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    receivables: (state, action: ReceivablesFetchAction) => {
      const { receivables, total, page } = action.payload;
      state.receivables = receivables;
      state.receivablesInfiniteScroll =
        page === 0
          ? receivables
          : [...state.receivablesInfiniteScroll, ...receivables];
      state.total = total;
    },
    updateStatus: (state, action: ReceivablesUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: ReceivablesUpdateErrorAction) => {
      state.error = action.payload.error;
    },
    getSummary: (state, _: ReceivablesSummaryGetAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    summaryTotals: (state, action: ReceivablesSummaryFetchAction) => {
      const { summaryTotals } = action.payload;
      state.summaryTotals = summaryTotals;
    },
    updateStatusSummary: (
      state,
      action: ReceivablesSummaryUpdateStatusAction
    ) => {
      state.fetchStatusSummary = action.payload.fetchStatusSummary;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
