import { OptimalGrowthCurveStatus, PlotDetails } from '@sinagro/shared';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Plot from 'src/components/modules/torq/plot';
import { useTorqContext } from 'src/contexts/torq';

import PlotCaption from '../../../components/modules/torq/plotCaption';
import CropSelect from './filters/cropSelect';
import GrowthCurveStatusSelect from './filters/growthCurveStatusSelect';
import {
  Container,
  Title,
  Header,
  Content,
  PlotContainer,
  Text,
  FilterContent,
} from './styles';

type PlotListProps = {
  onClose: () => void;
  parentElementIsPopper?: boolean;
  selectedGrowthCurveStatus?: OptimalGrowthCurveStatus | string;
};

const PlotList = ({
  onClose,
  parentElementIsPopper,
  selectedGrowthCurveStatus = '',
}: PlotListProps) => {
  const { t } = useTranslation();
  const { farmDetails, onChangeRegionId } = useTorqContext();
  const [growthCurveStatus, setGrowthCurveStatus] = useState(
    selectedGrowthCurveStatus
  );
  const [crop, setCrop] = useState('');

  const crops: string[] = [];
  const plots: PlotDetails[] =
    farmDetails?.children.map((plot) => {
      crops.push(plot.crop ?? t('torq.plot.emptyCrop'));
      return { ...plot, crop: plot.crop ?? t('torq.plot.emptyCrop') };
    }) || [];

  const cropOptions = [...new Set(crops)].map((item) => {
    return { value: item, label: item };
  });

  const filteredPlots = plots
    .filter(
      (plot) =>
        growthCurveStatus === '' || plot.growthCurve === growthCurveStatus
    )
    .filter((plot) => crop === '' || plot.crop === crop);

  const filteredPlotsEmpty =
    growthCurveStatus !== '' && filteredPlots.length === 0;

  const changePlot = (plotId: string) => {
    onClose();
    onChangeRegionId && onChangeRegionId(plotId);
  };

  if (farmDetails === null) {
    return <></>;
  }

  return (
    <Container data-testid="plot-list">
      <Header>
        <Title>{t('torq.plot.plotList')}</Title>
      </Header>

      <PlotCaption />

      <FilterContent>
        <GrowthCurveStatusSelect
          value={growthCurveStatus}
          setValue={setGrowthCurveStatus}
          parentElementIsPopper={parentElementIsPopper}
        />

        <CropSelect
          options={cropOptions}
          value={crop}
          setValue={setCrop}
          parentElementIsPopper={parentElementIsPopper}
        />
      </FilterContent>

      <Content>
        {filteredPlots.map((item, index) => (
          <PlotContainer
            onClick={() => changePlot(item.id)}
            key={item.id}
            $border={index !== 0}
            data-testid={`plot-${item.id}`}
          >
            <Plot item={item} />
          </PlotContainer>
        ))}
      </Content>

      {filteredPlotsEmpty && <Text>{`${t('torq.plot.filter.empty')}`}</Text>}
    </Container>
  );
};

export default PlotList;
