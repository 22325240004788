import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import React, { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormTextField } from 'src/components/modules/form';

import Loader from '../../../components/elements/loader';
import Button from '../../../components/elements/materialUI/button';
import { actions, useDispatch, useSelector } from '../../../state/store';
import { FieldContainer } from '../styles';
import { useNameSchema } from './schema';
import { Container, Footer, Text, Title } from './styles';

type EditFormType = { data: string };

const EditField = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schema = useNameSchema();

  const error = useSelector(
    (state) => state.shared.userProfile.errorUpdateUserProfile
  );

  const fetchStatus = useSelector(
    (state) => state.shared.userProfile.fetchStatusUpdateUserProfile
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<EditFormType>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<EditFormType> = useCallback(
    (form: EditFormType) => {
      const sanitizedForm = {
        name: form.data,
      };
      dispatch(actions.shared.userProfile.updateUserProfile(sanitizedForm));
    },
    [dispatch]
  );

  if (fetchStatus === FetchStatusEnum.Fetching) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <Title color="primary">{t('profile.editField.newName')}</Title>

      <FieldContainer item xs={12}>
        <FormTextField
          name="data"
          label={t('profile.editField.name')}
          defaultValue=""
          control={control}
          errorMessage={errors.data?.message}
          testID="inputData"
        />
      </FieldContainer>

      <Footer>
        <Button
          variant="contained"
          color="primary"
          style={{ width: 96 }}
          onClick={handleSubmit(onSubmit)}
        >
          <Typography variant="h6">
            {t('profile.editField.saveButton')}
          </Typography>
        </Button>
      </Footer>

      {error && (
        <Text
          variant="body2"
          color="error"
          style={{ marginTop: '1rem', textAlign: 'center' }}
        >
          {error.message}
        </Text>
      )}
    </Container>
  );
};

export default EditField;
