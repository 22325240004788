import { SagaIterator } from '@redux-saga/types';
import { SharedError } from '@sinagro/shared/errors';
import { call, takeLatest, put } from 'redux-saga/effects';

import { reduxSagaFirebase } from '../../../firebase';
import { requestCode, checkCode } from '../../services/validationCode';
import { FetchStatusEnum, RequestCodeAction } from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.request.type, requestEffectSaga),
  takeLatest(actions.check.type, checkEffectSaga),
  takeLatest(actions.profileDataCheck.type, profileDataCheckEffectSaga),
];

export function* requestEffectSaga(
  action: RequestCodeAction
): SagaIterator<void> {
  try {
    yield call(requestCode, action.payload);
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });
    if (knownError) {
      yield put(actions.updateError({ error: knownError }));
    }

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export function* checkEffectSaga(
  action: RequestCodeAction
): SagaIterator<void> {
  try {
    const response: { token: string } = yield call(checkCode, action.payload);
    yield call(reduxSagaFirebase.auth.signInWithCustomToken, response.token);
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
    yield put(
      actions.updateValidationCodeStatus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });
    if (knownError) {
      yield put(actions.updateError({ error: knownError }));
    }

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export function* profileDataCheckEffectSaga(
  action: RequestCodeAction
): SagaIterator<void> {
  try {
    const response: { token: string } = yield call(checkCode, action.payload);
    yield call(reduxSagaFirebase.auth.signInWithCustomToken, response.token);
    yield put(
      actions.updateCheckStatus({ fetchCheckStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });
    if (knownError) {
      yield put(actions.updateError({ error: knownError }));
    }

    yield put(
      actions.updateCheckStatus({ fetchCheckStatus: FetchStatusEnum.Failure })
    );
  }
}
