/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit';

import {
  TradeUpdateStatusAction,
  TradeSlice,
  TradeUpdateErrorAction,
  FetchStatusEnum,
  TradeGetContractsAction,
  TradeContractsAction,
  TradeDownloadCargoStatementAction,
  TradeDownloadReportAction,
} from '../../types';

const initialState: TradeSlice = {
  page: 0,
  total: 0,
  contracts: [],
  fetchStatus: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    clear: () => initialState,
    getContracts: (state, action: TradeGetContractsAction) => {
      const { page, limit } = action.payload;
      state.page = page;
      state.limit = limit;
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    contracts: (state, action: TradeContractsAction) => {
      const { page, contracts, total } = action.payload;
      state.contracts =
        page === 0 ? contracts : [...state.contracts, ...contracts];
      state.total = total;
    },
    downloadCargoStatement: (_, __: TradeDownloadCargoStatementAction) => {},
    downloadReport: (_, __: TradeDownloadReportAction) => {},
    updateStatus: (state, action: TradeUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: TradeUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
