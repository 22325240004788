import { Grid } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/elements/loader';

import { useSelector } from '../../state/store';
import { SubtitleCategory, Subtitle, Text } from './styles';

const IdentificationNumbers: React.FC = () => {
  const { t } = useTranslation();

  const userProfile = useSelector(
    (state) => state.shared.userProfile.userProfile
  );

  const fetchStatus = useSelector(
    (state) => state.shared.userProfile.fetchStatus
  );

  const relatedClients = userProfile.relatedClients
    ? userProfile.relatedClients.filter((client) => !client.inviteId)
    : [];
  const invitedClients = userProfile.relatedClients
    ? userProfile.relatedClients.filter((client) => client.inviteId)
    : [];

  const empty = invitedClients.length === 0 && relatedClients.length === 0;

  if (fetchStatus === FetchStatusEnum.Fetching) {
    return (
      <Grid container style={{ paddingTop: '1rem', justifyContent: 'center' }}>
        <Loader />
      </Grid>
    );
  }

  return (
    <Grid container item style={{ paddingTop: '1rem' }}>
      <SubtitleCategory color="primary">
        {t('profile.identificationNumbers.title')}
      </SubtitleCategory>

      {empty ? (
        <>
          <Subtitle color="secondary">
            {t('profile.identificationNumbers.empty.part1')}
          </Subtitle>
          <Subtitle color="secondary">
            {t('profile.identificationNumbers.empty.part2')}
          </Subtitle>
          <Text color="secondary">
            {t('profile.identificationNumbers.empty.part3')}
          </Text>
        </>
      ) : (
        <>
          {relatedClients.length > 0 && (
            <Grid style={{ width: '100%' }}>
              <Subtitle color="primary">
                {t('profile.identificationNumbers.myIds')}
              </Subtitle>
              {relatedClients.map((client) => (
                <Text color="secondary" key={client.id}>
                  {client.id} - {client.name}
                </Text>
              ))}
            </Grid>
          )}
          {invitedClients.length > 0 && (
            <Grid style={{ width: '100%' }}>
              <Subtitle color="primary">
                {t('profile.identificationNumbers.guestIds')}
              </Subtitle>
              {invitedClients.map((client) => (
                <Text color="secondary" key={client.id}>
                  {client.id} - {client.name}
                </Text>
              ))}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default IdentificationNumbers;
