import type { CreditGetSegmentDetail, CreditGetSummary } from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';
import api from '../api';
import { creditFiltersParams } from './queryParams';

export function getSummary(payload: CreditGetSummary) {
  let params = new CustomURLSearchParams({});
  params = creditFiltersParams(params, payload.filters);

  return api.get(`/credit/summary?${params}`);
}

export function getSegmentDetail(payload: CreditGetSegmentDetail) {
  let params = new CustomURLSearchParams({});

  params = creditFiltersParams(params, payload.filters);

  return api.get(`/credit/segment-detail?${params}`);
}
