import * as shared from '@sinagro/frontend-shared/state';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { all } from 'redux-saga/effects';

import * as alert from './alert';
import * as auth from './auth';
import * as columns from './columns';
import * as installAppInvite from './installAppInvite';
import * as menu from './menu';

export const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

export const reducer = combineReducers({
  router: routerReducer,
  shared: shared.reducer,
  auth: auth.reducer,
  columns: columns.reducer,
  installAppInvite: installAppInvite.reducer,
  menu: menu.reducer,
  alert: alert.reducer,
});

export const actions = Object.freeze({
  shared: shared.actions,
  auth: auth.actions,
  columns: columns.actions,
  installAppInvite: installAppInvite.actions,
  menu: menu.actions,
  alert: alert.actions,
});

export const sagas = function* AllSagas() {
  yield all([...auth.sagas, ...shared.sagas()]);
};
