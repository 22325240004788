import { Grid, Typography, Link } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonContainer = styled(Grid)`
  width: 100%;
  padding: 2rem 0 1rem 0;
`;

export const LogoContainer = styled(Grid)`
  padding-top: 1rem;
`;

export const ValueContainer = styled(Grid)`
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const TitleContainer = styled(Grid)`
  padding: 2rem;
  text-align: center;
`;

export const SubtitleContainer = styled(Grid)`
  text-align: center;
`;

export const CodeContainer = styled(Grid)`
  padding: 2rem;
  text-align: center;
`;

export const ErrorLabel = styled(Typography)`
  padding: 1rem 0;
  text-align: center;
`;

export const Code = styled.input`
  background: ${({ theme }) =>
    `${theme.colors.white} 0% 0% no-repeat padding-box`};
  box-shadow: ${({ theme }) => `0px 3px 6px ${theme.colors.shadow}`};
  border-radius: 2px;
  opacity: 1;
  height: 3.4rem;
  width: 2.2rem;
  outline: 0;
  border: 0;
  font-size: ${({ theme }) => theme.fontSize.big}px;
  text-align: center;
`;

export const TitleLabel = styled(Typography)`
  && {
    font-size: clamp(14px, 3vw, 16px);
    line-height: 1.4;
  }
`;

export const ChangeLink = styled(Link)`
  cursor: pointer;
  margin-top: 3rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;
