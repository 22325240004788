import { Typography, Grid } from '@material-ui/core';
import {
  AnalyticsEventsEnum,
  FetchStatusEnum,
} from '@sinagro/frontend-shared/sharedTypes';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { logAnalyticsEvent } from 'src/utils/analytics';

import Logo from '../../components/elements/logo';
import Button from '../../components/elements/materialUI/button';
import WelcomeLayout from '../../components/layout/welcomeLayout';
import { actions, useDispatch, useSelector } from '../../state/store';
import {
  ButtonContainer,
  LogoContainer,
  TitleContainer,
  SubTitleContainer,
} from './styles';

const ValidationCodeSuccess: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = useCallback(() => {
    navigate('/dashboard');
  }, [navigate]);

  const fetchCheckStatus = useSelector(
    (state) => state.shared.validationCode.fetchCheckStatus
  );
  React.useEffect(() => {
    if (fetchCheckStatus === FetchStatusEnum.Success) {
      logAnalyticsEvent(AnalyticsEventsEnum.AccountValidation, {
        platform: 'web',
      });
    }
  }, [fetchCheckStatus]);

  useEffect(() => {
    return () => {
      dispatch(actions.shared.validationCode.clear());
    };
  });

  return (
    <WelcomeLayout>
      <Grid
        container
        item
        xs={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid
          container
          item
          xs={12}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ flex: 1 }}
        >
          <LogoContainer item xs={12}>
            <Logo />
          </LogoContainer>
          <TitleContainer item xs={12}>
            <Typography variant="h1" color="textPrimary">
              {t('validationCodeSuccess.title')}
            </Typography>
          </TitleContainer>
          <SubTitleContainer item xs={12}>
            <Typography variant="h6" color="textPrimary">
              {t('validationCodeSuccess.subtitle')}
            </Typography>
          </SubTitleContainer>
          <ButtonContainer item xs={12} lg={6}>
            <Button
              onClick={handleNavigation}
              variant="contained"
              color="primary"
              fullWidth
              data-testid="buttonValidationCodeSuccess"
            >
              <Typography variant="h6">
                {t('validationCodeSuccess.submit')}
              </Typography>
            </Button>
          </ButtonContainer>
        </Grid>
      </Grid>
    </WelcomeLayout>
  );
};

export default ValidationCodeSuccess;
