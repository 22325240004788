import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItemProps } from 'src/components/modules/menu/components/menuItems/items';
import { useTheme } from 'styled-components';

import { Badge, Container, Title } from './styles';

const Cards = ({ Icon, label, url, id, badge }: MenuItemProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Container to={url} data-testid={`card-${id}`}>
      {badge && <Badge />}
      {Icon && (
        <Icon
          color={theme.colors.quaternary}
          style={{ height: 24, width: 24 }}
        />
      )}
      <Title>{t(`${label}`)}</Title>
    </Container>
  );
};

export default Cards;
