import { Grid, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Container = styled(Grid)<{ $isMobile: boolean }>`
  margin-bottom: 3rem;
  padding-left: 1rem;
  border-left: 1px solid ${({ theme }) => theme.colors.border};

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 100%;
      padding: 0 1rem;
      margin-left: 0rem;
      border-left: 0px;
    `}
`;

export const Content = styled(Grid)<{ $isMobile: boolean }>`
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 100%;
      padding: 1rem;
      background: ${({ theme }) => theme.colors.white};
      box-shadow: 0px 8px 8px rgba(32, 40, 109, 0.1);
      border-radius: 8px;
    `}
`;

export const Title = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.xxl}px;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 1rem;
  }
`;
