import { createSlice } from '@reduxjs/toolkit';

import {
  FinancialReceivableUpdateStatusAction,
  FinancialReceivableSlice,
  FinancialReceivableUpdateErrorAction,
  FetchStatusEnum,
  FinancialReceivableGetSummaryAction,
  FinancialReceivableGetReceivablesAction,
  FinancialReceivableSummaryAction,
  FinancialReceivableReceivablesAction,
  ReceivableGetAction,
  ReceivableFetchAction,
} from '../../types';

const initialState: FinancialReceivableSlice = {
  fetchStatusSummary: FetchStatusEnum.None,
  fetchStatus: FetchStatusEnum.None,
  receivables: [],
  receivablesPaged: [],
  error: null,
  summary: {
    items: [],
    totals: [],
  },
  page: 0,
  total: 0,
};

export const slice = createSlice({
  name: 'financialReceivable',
  initialState,
  reducers: {
    clear: () => initialState,
    updateStatus: (state, action: FinancialReceivableUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: FinancialReceivableUpdateErrorAction) => {
      state.error = action.payload.error;
    },
    getSummary: (state, _: FinancialReceivableGetSummaryAction) => {
      state.fetchStatusSummary = FetchStatusEnum.Fetching;
    },
    getReceivables: (state, _: FinancialReceivableGetReceivablesAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    getReceivablesPaged: (state, action: ReceivableGetAction) => {
      const { page, limit } = action.payload;
      state.page = page;
      state.limit = limit;
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    summary: (state, action: FinancialReceivableSummaryAction) => {
      state.summary = action.payload.summary;
    },
    receivables: (state, action: FinancialReceivableReceivablesAction) => {
      state.receivables = action.payload.receivables;
    },
    receivablesPaged: (state, action: ReceivableFetchAction) => {
      const { receivablesPaged, total } = action.payload;
      state.receivablesPaged = receivablesPaged;
      state.total = total;
    },
    updateStatusSummary: (
      state,
      action: FinancialReceivableUpdateStatusAction
    ) => {
      state.fetchStatusSummary = action.payload.fetchStatus;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
