import { createSlice } from '@reduxjs/toolkit';

import {
  FetchStatusEnum,
  WeatherForecastGeolocationFetchAction,
  WeatherForecastGeolocationGetAction,
  WeatherForecastGeolocationUpdateErrorAction,
  WeatherForecastGeolocationUpdateStatusAction,
  WeatherSlice,
} from '../../types';

export const initialState: WeatherSlice = {
  weatherForecast: { daily: [], hourly: [] },
  fetchStatus: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    clear: () => initialState,
    getWeatherForecast: (state, _: WeatherForecastGeolocationGetAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    weatherForecast: (state, action: WeatherForecastGeolocationFetchAction) => {
      const { weatherForecast } = action.payload;
      state.weatherForecast = weatherForecast;
    },
    updateStatus: (
      state,
      action: WeatherForecastGeolocationUpdateStatusAction
    ) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (
      state,
      action: WeatherForecastGeolocationUpdateErrorAction
    ) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
