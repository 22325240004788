import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled(Typography)<{ $isMobile: boolean }>`
  && {
    width: 100%;
    color: ${({ theme }) => theme.colors.darkCyan};
    font-size: ${({ theme, $isMobile }) =>
      $isMobile ? theme.fontSize.xxxl : theme.fontSize.xbig}px;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    text-align: left;
  }
`;

export const Subtitle = styled(Typography)`
  && {
    width: 50%;
    margin-top: 1rem;
    font-size: ${({ theme }) => theme.fontSize.xl}px;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
`;
