import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonContainer = styled(Grid)`
  width: 100%;
  padding: 1rem 0;
`;

export const LogoContainer = styled(Grid)`
  padding-top: 1rem;
`;

export const TitleContainer = styled(Grid)`
  padding: 2rem 2rem 3rem 2rem;
  text-align: center;
`;

export const TitleLabel = styled(Typography)`
  && {
    font-size: clamp(16px, 3vw, 22px);
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 26px;
  color: ${({ theme }) => theme.colors.darkCyan};
  z-index: 3;
`;
