import { FetchStatusEnum } from '../../../../types';
import {
  RemittanceFetchAction,
  RemittanceGetAction,
  RemittanceUpdateStatusAction,
  TrackingSlice,
} from '../../../../types/v2';

export const reducersFertilizers = {
  getRemittancesFertilizersScheduled: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusFertilizersScheduled = FetchStatusEnum.Fetching;
  },
  remittancesFertilizersScheduled: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesFertilizersScheduled =
      page === 0
        ? remittances
        : [...state.remittancesFertilizersScheduled, ...remittances];
    state.totalFertilizersScheduled = total;
  },
  updateStatusFertilizersScheduled: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusFertilizersScheduled = action.payload.fetchStatus;
  },
  getRemittancesFertilizersHandling: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusFertilizersHandling = FetchStatusEnum.Fetching;
  },
  remittancesFertilizersHandling: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesFertilizersHandling =
      page === 0
        ? remittances
        : [...state.remittancesFertilizersHandling, ...remittances];
    state.totalFertilizersHandling = total;
  },
  updateStatusFertilizersHandling: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusFertilizersHandling = action.payload.fetchStatus;
  },
  getRemittancesFertilizersInTransit: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusFertilizersInTransit = FetchStatusEnum.Fetching;
  },
  remittancesFertilizersInTransit: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesFertilizersInTransit =
      page === 0
        ? remittances
        : [...state.remittancesFertilizersInTransit, ...remittances];
    state.totalFertilizersInTransit = total;
  },
  updateStatusFertilizersInTransit: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusFertilizersInTransit = action.payload.fetchStatus;
  },
  getRemittancesFertilizersDelivered: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusFertilizersDelivered = FetchStatusEnum.Fetching;
  },
  remittancesFertilizersDelivered: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesFertilizersDelivered =
      page === 0
        ? remittances
        : [...state.remittancesFertilizersDelivered, ...remittances];
    state.totalFertilizersDelivered = total;
  },
  updateStatusFertilizersDelivered: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusFertilizersDelivered = action.payload.fetchStatus;
  },
};
