import { ButtonBase, Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Arrow = styled(Grid)`
  height: 100%;
  display: flex;
  padding-top: 0.5rem;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  align-items: center;
  justify-content: center;
  filter: brightness(0.95);

  :hover {
    cursor: pointer;
    filter: brightness(0.9);
    transition: 0.3s;
  }

  svg {
    font-size: 24px;
    fill: ${({ theme }) => theme.colors.textSecondary};
  }
`;

export const ArrowButton = styled(ButtonBase)`
  width: 100%;
  height: 100%;
`;
