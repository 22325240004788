import { createSlice } from '@reduxjs/toolkit';

import { FetchStatusEnum } from '../../../types';
import {
  FinancialPayableSlice,
  NextBillsUpdateErrorAction,
  NextBillsUpdateStatusAction,
  NextBillsGetAction,
  NextBillsFetchAction,
  NextBillsSummaryFetchAction,
  NextBillsSummaryGetAction,
  InvoicesNumbersFetchAction,
  InvoicesNumbersGetAction,
} from '../../../types/v2';

const initialState: FinancialPayableSlice = {
  fetchStatusNextBillsInTermSummary: FetchStatusEnum.None,
  fetchStatusNextBillsBarterSummary: FetchStatusEnum.None,
  fetchStatusNextBillsInCashSummary: FetchStatusEnum.None,
  fetchStatusNextBillsPartialSummary: FetchStatusEnum.None,
  fetchStatusNextBillsOtherSummary: FetchStatusEnum.None,
  fetchStatusNextBillsInTerm: FetchStatusEnum.None,
  fetchStatusNextBillsBarter: FetchStatusEnum.None,
  fetchStatusNextBillsInCash: FetchStatusEnum.None,
  fetchStatusNextBillsPartial: FetchStatusEnum.None,
  fetchStatusNextBillsPrus: FetchStatusEnum.None,
  fetchStatusNextBillsOther: FetchStatusEnum.None,
  fetchStatusInvoicesNumbers: FetchStatusEnum.None,
  nextBillsInTermSummaryTotals: [],
  nextBillsBarterSummaryTotals: [],
  nextBillsInCashSummaryTotals: [],
  nextBillsPartialSummaryTotals: [],
  nextBillsOtherSummaryTotals: [],
  nextBillsInTerm: [],
  nextBillsBarter: [],
  nextBillsInCash: [],
  nextBillsPartial: [],
  nextBillsPrus: [],
  nextBillsOther: [],
  invoices: [],
  error: null,
  page: 0,
  nextBillsInTermTotal: 0,
  nextBillsBarterTotal: 0,
  nextBillsInCashTotal: 0,
  nextBillsPartialTotal: 0,
  nextBillsPrusTotal: 0,
  nextBillsOtherTotal: 0,
  invoicesTotals: 0,
};

export const slice = createSlice({
  name: 'financialPayables',
  initialState,
  reducers: {
    clear: () => initialState,
    updateError: (state, action: NextBillsUpdateErrorAction) => {
      state.error = action.payload.error;
    },
    getNextBillsInTerm: (state, _: NextBillsGetAction) => {
      state.fetchStatusNextBillsInTerm = FetchStatusEnum.Fetching;
    },
    nextBillsInTerm: (state, action: NextBillsFetchAction) => {
      const { nextBills, nextBillsTotal } = action.payload;
      state.nextBillsInTerm = nextBills;
      state.nextBillsInTermTotal = nextBillsTotal;
    },
    updateStatusNextBillsInTerm: (
      state,
      action: NextBillsUpdateStatusAction
    ) => {
      state.fetchStatusNextBillsInTerm = action.payload.fetchStatus;
    },
    getNextBillsBarter: (state, _: NextBillsGetAction) => {
      state.fetchStatusNextBillsBarter = FetchStatusEnum.Fetching;
    },
    nextBillsBarter: (state, action: NextBillsFetchAction) => {
      const { nextBills, nextBillsTotal } = action.payload;
      state.nextBillsBarter = nextBills;
      state.nextBillsBarterTotal = nextBillsTotal;
    },
    updateStatusNextBillsBarter: (
      state,
      action: NextBillsUpdateStatusAction
    ) => {
      state.fetchStatusNextBillsBarter = action.payload.fetchStatus;
    },
    getNextBillsInCash: (state, _: NextBillsGetAction) => {
      state.fetchStatusNextBillsInCash = FetchStatusEnum.Fetching;
    },
    nextBillsInCash: (state, action: NextBillsFetchAction) => {
      const { nextBills, nextBillsTotal } = action.payload;
      state.nextBillsInCash = nextBills;
      state.nextBillsInCashTotal = nextBillsTotal;
    },
    updateStatusNextBillsInCash: (
      state,
      action: NextBillsUpdateStatusAction
    ) => {
      state.fetchStatusNextBillsInCash = action.payload.fetchStatus;
    },
    getNextBillsPartial: (state, _: NextBillsGetAction) => {
      state.fetchStatusNextBillsPartial = FetchStatusEnum.Fetching;
    },
    nextBillsPartial: (state, action: NextBillsFetchAction) => {
      const { nextBills, nextBillsTotal } = action.payload;
      state.nextBillsPartial = nextBills;
      state.nextBillsPartialTotal = nextBillsTotal;
    },
    updateStatusNextBillsPartial: (
      state,
      action: NextBillsUpdateStatusAction
    ) => {
      state.fetchStatusNextBillsPartial = action.payload.fetchStatus;
    },
    getNextBillsPrus: (state, _: NextBillsGetAction) => {
      state.fetchStatusNextBillsPrus = FetchStatusEnum.Fetching;
    },
    nextBillsPrus: (state, action: NextBillsFetchAction) => {
      const { nextBills, nextBillsTotal } = action.payload;
      state.nextBillsPrus = nextBills;
      state.nextBillsPrusTotal = nextBillsTotal;
    },
    updateStatusNextBillsPrus: (state, action: NextBillsUpdateStatusAction) => {
      state.fetchStatusNextBillsPrus = action.payload.fetchStatus;
    },
    getNextBillsOther: (state, _: NextBillsGetAction) => {
      state.fetchStatusNextBillsOther = FetchStatusEnum.Fetching;
    },
    nextBillsOther: (state, action: NextBillsFetchAction) => {
      const { nextBills, nextBillsTotal } = action.payload;
      state.nextBillsOther = nextBills;
      state.nextBillsOtherTotal = nextBillsTotal;
    },
    updateStatusNextBillsOther: (
      state,
      action: NextBillsUpdateStatusAction
    ) => {
      state.fetchStatusNextBillsOther = action.payload.fetchStatus;
    },
    getNextBillsInTermSummary: (state, _: NextBillsSummaryGetAction) => {
      state.fetchStatusNextBillsInTermSummary = FetchStatusEnum.Fetching;
    },
    nextBillsInTermSummaryTotals: (
      state,
      action: NextBillsSummaryFetchAction
    ) => {
      const { summaryTotals } = action.payload;
      state.nextBillsInTermSummaryTotals = summaryTotals;
    },
    updateStatusNextBillsInTermSummary: (
      state,
      action: NextBillsUpdateStatusAction
    ) => {
      state.fetchStatusNextBillsInTermSummary = action.payload.fetchStatus;
    },
    getNextBillsBarterSummary: (state, _: NextBillsSummaryGetAction) => {
      state.fetchStatusNextBillsBarterSummary = FetchStatusEnum.Fetching;
    },
    nextBillsBarterSummaryTotals: (
      state,
      action: NextBillsSummaryFetchAction
    ) => {
      const { summaryTotals } = action.payload;
      state.nextBillsBarterSummaryTotals = summaryTotals;
    },
    updateStatusNextBillsBarterSummary: (
      state,
      action: NextBillsUpdateStatusAction
    ) => {
      state.fetchStatusNextBillsBarterSummary = action.payload.fetchStatus;
    },
    getNextBillsInCashSummary: (state, _: NextBillsSummaryGetAction) => {
      state.fetchStatusNextBillsInCashSummary = FetchStatusEnum.Fetching;
    },
    nextBillsInCashSummaryTotals: (
      state,
      action: NextBillsSummaryFetchAction
    ) => {
      const { summaryTotals } = action.payload;
      state.nextBillsInCashSummaryTotals = summaryTotals;
    },
    updateStatusNextBillsInCashSummary: (
      state,
      action: NextBillsUpdateStatusAction
    ) => {
      state.fetchStatusNextBillsInCashSummary = action.payload.fetchStatus;
    },
    getNextBillsPartialSummary: (state, _: NextBillsSummaryGetAction) => {
      state.fetchStatusNextBillsPartialSummary = FetchStatusEnum.Fetching;
    },
    nextBillsPartialSummaryTotals: (
      state,
      action: NextBillsSummaryFetchAction
    ) => {
      const { summaryTotals } = action.payload;
      state.nextBillsPartialSummaryTotals = summaryTotals;
    },
    updateStatusNextBillsPartialSummary: (
      state,
      action: NextBillsUpdateStatusAction
    ) => {
      state.fetchStatusNextBillsPartialSummary = action.payload.fetchStatus;
    },
    getNextBillsOtherSummary: (state, _: NextBillsSummaryGetAction) => {
      state.fetchStatusNextBillsOtherSummary = FetchStatusEnum.Fetching;
    },
    nextBillsOtherSummaryTotals: (
      state,
      action: NextBillsSummaryFetchAction
    ) => {
      const { summaryTotals } = action.payload;
      state.nextBillsOtherSummaryTotals = summaryTotals;
    },
    updateStatusNextBillsOtherSummary: (
      state,
      action: NextBillsUpdateStatusAction
    ) => {
      state.fetchStatusNextBillsOtherSummary = action.payload.fetchStatus;
    },
    invoicesNumbers: (state, action: InvoicesNumbersFetchAction) => {
      const { invoices, total } = action.payload;
      state.invoices = invoices;
      state.invoicesTotals = total;
    },
    getInvoicesNumbers: (state, _: InvoicesNumbersGetAction) => {
      state.fetchStatusInvoicesNumbers = FetchStatusEnum.Fetching;
    },
    updateStatusInvoicesNumbers: (
      state,
      action: NextBillsUpdateStatusAction
    ) => {
      state.fetchStatusInvoicesNumbers = action.payload.fetchStatus;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
