import {
  MenuList,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { DefaultThemeColorsType } from '@sinagro/frontend-shared/themes';
import styled from 'styled-components';

import { ReactComponent as TorqSVG } from '../../../../../assets/icons/torq.svg';

export const MenuListItems = styled(MenuList)`
  @media (max-height: 740px) {
    height: 65vh;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 0px;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      width: 0px;
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      width: 4px;
    }
  }
`;

export const TorqIcon = styled(TorqSVG)``;

export const Wrapper = styled(Grid)``;

export const Container = styled(MenuItem)<{
  $isActive: boolean;
  $color?: DefaultThemeColorsType;
  $openMenu: boolean;
}>`
  && {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: ${({ $openMenu }) =>
      $openMenu ? 'flex-start' : 'center'};
    align-items: center;
    padding: 0;
    margin: 0.5rem 5%;
    width: 90%;
    height: 48px;
    border-radius: 8px;

    background-color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.quaternary : theme.colors.primary} !important;
  }

  svg {
    fill: ${({ theme, $isActive, $color }) =>
      $isActive
        ? theme.colors.white
        : $color
        ? theme.colors[$color]
        : theme.colors.white};
  }

  p {
    color: ${({ theme, $isActive, $color }) =>
      $isActive
        ? theme.colors.white
        : $color
        ? theme.colors[$color]
        : theme.colors.white};
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.quaternary} !important;

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }

    p {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Content = styled(Grid)<{ $openMenu: boolean }>`
  && {
    display: flex;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: ${({ $openMenu }) => ($openMenu ? '1rem' : 'auto')};
    align-items: center;
    justify-content: ${({ $openMenu }) =>
      $openMenu ? 'flex-start' : 'center'};
  }
`;

export const Label = styled(Typography)<{
  $isActive: boolean;
}>`
  padding-left: 0.5rem;
  text-decoration-color: ${({ theme }) => theme.colors.white};
  text-decoration-thickness: 0.063rem;
  text-decoration-line: ${({ $isActive }) =>
    $isActive ? 'underline' : 'none'};
`;

export const ExpandedButton = styled(IconButton)`
  && {
    position: absolute;
    right: 0 !important;
    z-index: 2;
  }
`;

export const SubMenuList = styled(Grid)<{ $openMenu: boolean }>`
  margin-left: ${({ $openMenu }) => ($openMenu ? 1.75 : 0)}rem;
`;
