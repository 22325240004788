import { Grid, Typography } from '@material-ui/core';
import { DefaultThemeTorqGrowthCurveColorsType } from '@sinagro/frontend-shared/themes';
import styled from 'styled-components';

export const Content = styled(Grid)<{ $isMobile: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : 'row')};
  justify-content: space-between;
  gap: 1.5rem;
`;

export const Item = styled(Grid)`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Icon = styled(Grid)`
  background: ${({ theme }) => theme.colors.textSecondary}10;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: ${({ theme }) => theme.fontSize.md}px;
  }
`;

export const Value = styled(Typography)`
  display: flex;
  align-items: center;
  height: auto;

  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    margin-top: 4px;
  }

  svg {
    margin-left: 0.5rem;
  }
`;

export const GrowthContainer = styled(Grid)<{ $isMobile: boolean }>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const GrowthItem = styled(Typography)<{
  $color: DefaultThemeTorqGrowthCurveColorsType;
}>`
  display: flex;
  height: 24px;
  width: auto;
  white-space: nowrap;
  border-radius: 16px;
  background-color: pink;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  background-color: ${({ theme, $color }) =>
    theme.colors.torqGrowthCurve[$color]};

  && {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.md}px;
    margin: 0.1rem;
  }
`;
