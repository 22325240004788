import { SagaIterator } from '@redux-saga/types';
import {
  BaseReportData,
  FarmReportData,
  ReportDTO,
  ReportUrlDTO,
} from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getReport, getShareLink } from '../../services/report';
import {
  AuthenticatedEnum,
  FetchStatusEnum,
  ReportGetAction,
  ReportShareLinkGetAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getReport.type, getReportEffectSaga),
  takeLatest(actions.getShareLink.type, getShareLinkEffectSaga),
];

export type getReportResponse = ReportDTO<BaseReportData | FarmReportData>;

export function* getReportEffectSaga(
  action: ReportGetAction
): SagaIterator<void> {
  try {
    const report: getReportResponse = yield call(getReport, action.payload);
    yield put(actions.report({ report }));
    yield put(
      actions.reportUserAuthenticated({
        reportUserAuthenticated: AuthenticatedEnum.Authenticated,
      })
    );
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      doSignOutWhenInvalidToken: false,
    });
    yield put(
      actions.reportUserAuthenticated({
        reportUserAuthenticated: AuthenticatedEnum.NotAuthenticated,
      })
    );
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export type getShareLinkResponse = ReportUrlDTO;

export function* getShareLinkEffectSaga(
  action: ReportShareLinkGetAction
): SagaIterator<void> {
  try {
    const shareLink: getShareLinkResponse = yield call(
      getShareLink,
      action.payload
    );
    yield put(actions.shareLink({ shareLink }));
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
