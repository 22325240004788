import i18n from 'i18next';

export const formatDate = (
  date: Date | string | undefined,
  withLocaleTimezone?: boolean
) => {
  if (!date) return '-';
  if (typeof date === 'string') date = new Date(date);
  const timeZone = withLocaleTimezone ? undefined : 'UTC';

  const formattedDate = date.toLocaleString('pt-BR', {
    timeZone,
    dateStyle: 'short',
  });
  return formattedDate;
};

export const isInvalidDate = (date: Date) => {
  return date.toDateString() === 'Invalid Date';
};

export const formatDateTime = (
  date: Date | string | undefined | null,
  withLocaleTimezone?: boolean
) => {
  if (!date) {
    return '-';
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const timeZone = withLocaleTimezone ? undefined : 'UTC';
  const formattedDateTime = date.toLocaleString('pt-BR', {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });

  return formattedDateTime;
};

export const formatTimestampDate = (date: Date | string | undefined) => {
  if (!date) return 0;
  if (typeof date === 'string') date = new Date(date);
  const formattedDate = date.getTime();
  return formattedDate;
};

export const formatDateYearAndMonth = (
  date: Date | string | undefined,
  month: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' = 'short'
) => {
  if (!date) return '-';
  if (typeof date === 'string') date = new Date(date);
  const formattedDate = date.toLocaleString('pt-BR', {
    timeZone: 'UTC',
    month: month,
    year: 'numeric',
  });
  return formattedDate;
};

export const formatDateAndMonth = (
  date: Date | string | undefined,
  withLocaleTimezone?: boolean
) => {
  if (!date) {
    return '-';
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const timeZone = withLocaleTimezone ? undefined : 'UTC';

  const formattedDateAndMonth = date.toLocaleString('pt-BR', {
    timeZone,
    month: '2-digit',
    day: '2-digit',
  });

  return formattedDateAndMonth;
};

export const formatWeekday = (
  date: Date | string | undefined,
  withLocaleTimezone?: boolean
) => {
  if (!date) {
    return '-';
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const timeZone = withLocaleTimezone ? undefined : 'UTC';

  const weekday = date
    .toLocaleString('pt-BR', {
      timeZone,
      weekday: 'long',
    })
    .split('-feira')[0];

  if (isToday(date, withLocaleTimezone)) {
    return `${i18n.t('commons.today')}, ${weekday}`;
  }

  const formattedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);

  return formattedWeekday;
};

export const isToday = (
  date: Date | string | undefined,
  withLocaleTimezone?: boolean
) => {
  if (!date) {
    return false;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const today = formatDate(new Date(), true);
  const currentDate = formatDate(date, withLocaleTimezone);

  return today === currentDate;
};

export const formatDateHour = (
  date: Date | string | undefined | null,
  withLocaleTimezone?: boolean
) => {
  if (!date) {
    return '-';
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const timeZone = withLocaleTimezone ? undefined : 'UTC';

  const formattedHour = date.toLocaleString('pt-BR', {
    timeZone,
    hour: '2-digit',
  });

  return formattedHour;
};

export const formatDateHourMinute = (
  date: Date | string | undefined | null,
  withLocaleTimezone?: boolean
) => {
  if (!date) {
    return '-';
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const timeZone = withLocaleTimezone ? undefined : 'UTC';

  const formattedHourMinute = date.toLocaleString('pt-BR', {
    timeZone,
    hour: '2-digit',
    minute: '2-digit',
  });

  return formattedHourMinute;
};

export const formattedCurrentHour = () => {
  const currentHour = new Date().toLocaleString('pt-BR', {
    hour: '2-digit',
  });

  return currentHour;
};

export const formattedCurrentDate = () => {
  return new Date();
};

export const compareDate = (
  date: Date | string,
  currentDate: Date,
  notConsiderEqual?: boolean
) => {
  const formattedDate = new Date(date).toISOString().split('T')[0];
  const formattedCurrentDate = new Date(currentDate)
    .toISOString()
    .split('T')[0];

  if (notConsiderEqual) {
    return formattedDate > formattedCurrentDate;
  }

  return formattedDate >= formattedCurrentDate;
};

export const formatDateLong = (date: Date | string | undefined) => {
  if (!date) return '-';
  if (typeof date === 'string') date = new Date(date);

  const formattedDate = date
    .toLocaleString('pt-BR', {
      timeZone: 'UTC',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })
    .split('de');

  return `${formattedDate[0]}de${formattedDate[1]}/${formattedDate[2]}`;
};

export const formatDateYearMonthDay = (
  date: Date | string | undefined,
  withLocaleTimezone?: boolean
) => {
  if (!date) return '-';
  if (typeof date === 'string') date = new Date(date);
  const timeZone = withLocaleTimezone ? undefined : 'UTC';

  const dateParts = date
    .toLocaleString('pt-BR', {
      timeZone,
      dateStyle: 'short',
    })
    .split('/');

  const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

  return formattedDate;
};

export const formatDateToISOString = (date: Date | string | undefined) => {
  if (!date) return '-';
  if (typeof date === 'string') date = new Date(date);

  const dateParts = date
    .toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
    })
    .split(',');

  const currentDate = dateParts[0].split('/');
  const currentHour = dateParts[1].trim();

  const formattedDate = `${currentDate[2]}-${currentDate[1]}-${currentDate[0]}T${currentHour}:00:00`;

  return formattedDate;
};
