import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';

export const weatherForecastParams = (
  params: CustomURLSearchParams,
  lat: number,
  long: number,
  date: string
) => {
  params.set('lat', lat.toString());
  params.set('long', long.toString());
  params.set('date', date);

  return params;
};
