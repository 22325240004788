import { SagaIterator } from '@redux-saga/types';
import { SharedError } from '@sinagro/shared/errors';
import { call, put, takeLatest } from 'redux-saga/effects';

import { setNewPassword } from '../../services/user';
import { FetchStatusEnum, SetNewPasswordAction } from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.changePassword.type, setNewPasswordEffectSaga),
];

export function* setNewPasswordEffectSaga(
  action: SetNewPasswordAction
): SagaIterator<void> {
  try {
    yield call(setNewPassword, action.payload);
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(actions.updateError({ error: knownError }));
    }

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
