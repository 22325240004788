import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { Control, FieldValues, UseControllerProps } from 'react-hook-form';

import FormTextField from './formTextField';

interface FormPasswordFieldProps<T> extends UseControllerProps<T> {
  control: Control<T>;
  label: string;
  errorMessage?: string;
  testID?: string;
  helper?: string;
  success?: string;
  handleKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const FormPasswordField = <T extends FieldValues>(
  props: FormPasswordFieldProps<T>
) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, []);

  const endAdornment = React.useMemo(
    () => (
      <InputAdornment position="start">
        <IconButton tabIndex="-1" onClick={handleClick} edge="end">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
    [showPassword, handleClick]
  );

  return (
    <FormTextField
      {...props}
      handleKeyPress={props.handleKeyPress}
      type={showPassword ? 'text' : 'password'}
      inputProps={{ endAdornment }}
      testID={props.testID}
      helper={props.helper}
      success={props.success}
    />
  );
};

export default FormPasswordField;
