import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSize.xxxl}px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

export const Description = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: ${({ theme }) => theme.fontSize.lg}px;

    margin: 2rem 0;
  }
`;
