import { createSlice } from '@reduxjs/toolkit';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';

import {
  ChangePasswordUpdateErrorAction,
  ChangePasswordUpdateStatusAction,
  SetNewPasswordAction,
  SetNewPasswordSlice,
} from '../../types';

const initialState: SetNewPasswordSlice = {
  fetchStatus: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    clear: () => initialState,
    changePassword: (state, _: SetNewPasswordAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    updateStatus: (state, action: ChangePasswordUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: ChangePasswordUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
