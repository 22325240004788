/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit';

import {
  CreditGetSegmentDetailAction,
  CreditGetSummaryAction,
  CreditSegmentDetailAction,
  CreditSlice,
  CreditSummaryAction,
  CreditUpdateErrorAction,
  CreditUpdateStatusAction,
  FetchStatusEnum,
} from '../../types';

export const initialState: CreditSlice = {
  summary: [],
  segmentDetail: [],
  page: 0,
  total: 0,
  fetchStatusSummary: FetchStatusEnum.None,
  fetchStatus: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'credit',
  initialState,
  reducers: {
    clear: () => initialState,
    getSummary: (state, _: CreditGetSummaryAction) => {
      state.fetchStatusSummary = FetchStatusEnum.Fetching;
    },
    summary: (state, action: CreditSummaryAction) => {
      state.summary = action.payload.summary;
    },
    getSegmentDetail: (state, _: CreditGetSegmentDetailAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    segmentDetail: (state, action: CreditSegmentDetailAction) => {
      state.segmentDetail = action.payload.segmentDetail;
    },
    updateStatus: (state, action: CreditUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateStatusSummary: (state, action: CreditUpdateStatusAction) => {
      state.fetchStatusSummary = action.payload.fetchStatus;
    },
    updateError: (state, action: CreditUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
