import { SagaIterator } from '@redux-saga/types';
import { IFinancialSummaryItem, ReceivableBondDTO } from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getReceivables,
  getSummary,
} from '../../../services/financialReceivable/v2';
import { FetchStatusEnum } from '../../../types';
import {
  ReceivablesGetAction,
  ReceivablesSummaryGetAction,
} from '../../../types/v2';
import { handleError } from '../../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getReceivables.type, getReceivablesEffectSaga),
  takeLatest(actions.getSummary.type, getSummaryEffectSaga),
];

export type getReceivableResponse = {
  total: number;
  results: ReceivableBondDTO[];
  page: number;
};

export function* getReceivablesEffectSaga(
  action: ReceivablesGetAction
): SagaIterator<void> {
  try {
    const { results: receivables, total }: getReceivableResponse = yield call(
      getReceivables,
      action.payload
    );
    yield put(
      actions.receivables({
        receivables,
        total,
        page: action.payload.page,
      })
    );
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export type getSummaryResponse = {
  totals: IFinancialSummaryItem[];
};

export function* getSummaryEffectSaga(
  action: ReceivablesSummaryGetAction
): SagaIterator<void> {
  try {
    const { totals }: getSummaryResponse = yield call(
      getSummary,
      action.payload
    );
    yield put(
      actions.summaryTotals({
        summaryTotals: totals,
      })
    );
    yield put(
      actions.updateStatusSummary({
        fetchStatusSummary: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusSummary({
        fetchStatusSummary: FetchStatusEnum.Failure,
      })
    );
  }
}
