import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const FieldContainer = styled(Grid)`
  width: 100%;
  text-align: center;
`;

export const ErrorLabel = styled(Typography)`
  padding: 1rem 0;
  text-align: center;
`;

export const ButtonContainer = styled(Grid)`
  width: 50%;
  padding-left: 10rem;
`;
