import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@material-ui/core';
import {
  AuthenticatedEnum,
  FetchStatusEnum,
} from '@sinagro/frontend-shared/sharedTypes';
import { replaceMask } from '@sinagro/frontend-shared/validations';
import { ValidationCodeTypeEnum } from '@sinagro/shared';
import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/elements/materialUI/button';
import {
  FormTextField,
  FormPhoneField,
} from '../../../components/modules/form';
import { actions, useDispatch, useSelector } from '../../../state/store';
import { useEmailSchema, usePhoneSchema } from './schema';
import { ErrorLabel, FieldContainer, ButtonContainer } from './styles';

type Props = {
  validationCode?: ValidationCodeTypeEnum;
  onClose: () => void;
  setActualData: (data: string) => void;
};

type EditFormType = { data: string };

const EditField = ({ validationCode, onClose, setActualData }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schemaEmail = useEmailSchema();
  const schemaPhone = usePhoneSchema();

  const isEmail = validationCode === ValidationCodeTypeEnum.Email;

  const schema = isEmail ? schemaEmail : schemaPhone;

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<EditFormType>({
    resolver: yupResolver(schema),
  });

  const fetchStatus = useSelector(
    (state) => state.shared.userProfile.fetchStatusUpdateUserEmailOrPhone
  );

  const error = useSelector(
    (state) => state.shared.userProfile.errorUpdateUserEmailOrPhone
  );

  const canClose = fetchStatus === FetchStatusEnum.Success && !error;

  const onSubmit: SubmitHandler<EditFormType> = useCallback(
    (form: EditFormType) => {
      dispatch(actions.shared.userProfile.clear());

      const sanitizedForm = {
        data: isEmail ? form.data : `55${replaceMask(form.data)}`,
        type: validationCode ? validationCode : ValidationCodeTypeEnum.Email,
      };

      dispatch(
        actions.shared.userProfile.updateUserEmailOrPhone(sanitizedForm)
      );
      dispatch(
        actions.shared.user.userAuthenticated({
          userAuthenticated: AuthenticatedEnum.Authenticated,
        })
      );
    },
    [dispatch, isEmail, validationCode]
  );

  useEffect(() => {
    if (canClose) {
      setActualData(isEmail ? getValues('data') : `55${getValues('data')}`);
      onClose();
    }
  }, [canClose, onClose, setActualData, getValues, isEmail]);

  useEffect(() => {
    return () => {
      dispatch(actions.shared.userProfile.clear());
    };
  }, [dispatch]);

  return (
    <>
      <Grid style={{ textAlign: 'center' }}>
        <Typography variant="h6">
          {isEmail
            ? t('profile.editField.newEmail')
            : t('profile.editField.newPhoneNumber')}
        </Typography>

        {validationCode === ValidationCodeTypeEnum.Email ? (
          <FieldContainer item xs={12}>
            <FormTextField
              testID="inputEmail"
              name="data"
              label={t('signUpForm.email')}
              control={control}
              defaultValue=""
              errorMessage={errors.data?.message}
              placeholder="nome@email.com"
            />
          </FieldContainer>
        ) : (
          <FieldContainer item xs={12}>
            <FormPhoneField
              name="data"
              label={t('signUpForm.phoneNumber')}
              control={control}
              defaultValue=""
              errorMessage={errors.data?.message}
              placeholder="(00) 00000-0000"
            />
          </FieldContainer>
        )}
      </Grid>
      <Grid item xs={12}>
        <ErrorLabel variant="body2" color="error">
          {error?.message}
        </ErrorLabel>
      </Grid>
      <ButtonContainer container item>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
          fullWidth
          loading={fetchStatus === FetchStatusEnum.Fetching}
        >
          <Typography variant="h6">
            {t('validationCode.changeButton')}
          </Typography>
        </Button>
      </ButtonContainer>
    </>
  );
};

export default EditField;
