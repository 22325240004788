import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export class Api {
  client: AxiosInstance;
  static token: string;

  constructor() {
    const client = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.client = client;
  }

  static setToken(token: string) {
    this.token = `Bearer ${token}`;
  }

  async uploadFile(
    writeUrl: string,
    name: string,
    type: string,
    fileToUpload: any
  ) {
    await this.client.put(writeUrl, fileToUpload, {
      headers: {
        'Content-type': `${type}`,
        'Content-Disposition': `inline; filename="${name}"`,
      },
    });
  }

  async get<T extends Record<string, unknown>>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<T | T[]> {
    const response = await this.client.get(path, {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: Api.token,
      },
    });
    return response.data;
  }

  async post<T extends Record<string, unknown>>(
    path: string,
    payload: T
  ): Promise<T> {
    const response = await this.client.post(path, payload, {
      headers: { Authorization: Api.token },
    });
    return response.data;
  }

  async patch<T extends Record<string, unknown> | unknown[]>(
    path: string,
    payload: T
  ): Promise<T> {
    const response = await this.client.patch(path, payload, {
      headers: { Authorization: Api.token },
    });
    return response.data;
  }

  async put<T extends Record<string, unknown>>(
    path: string,
    payload: T
  ): Promise<T> {
    const response = await this.client.put(path, payload, {
      headers: { Authorization: Api.token },
    });
    return response.data;
  }

  async delete(path: string): Promise<number> {
    const response = await this.client.delete(path, {
      headers: { Authorization: Api.token },
    });
    return response.data;
  }
}

export default new Api();
