import { createSlice } from '@reduxjs/toolkit';
import { isToday } from 'src/utils/format';

import {
  AlertSlice,
  StoragePopperAlert,
  UpdatePopperAlertAction,
} from './types';

const initialState: AlertSlice = {
  showPopperAlert: true,
};

export const slice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    clear: () => initialState,
    getPopperAlert: (state) => {
      const storage = localStorage.getItem('storagePopperAlert');
      const { showPopperAlert, lastDate }: StoragePopperAlert = storage
        ? JSON.parse(storage)
        : { showPopperAlert: true, lastDate: null };

      if (lastDate !== null && !isToday(lastDate)) {
        state.showPopperAlert = true;
        return;
      }
      state.showPopperAlert = showPopperAlert;
    },
    setPopperAlert: (state, action: UpdatePopperAlertAction) => {
      const showPopperAlert = action.payload.showPopperAlert;
      const storagePopperAlert: StoragePopperAlert = {
        showPopperAlert,
        lastDate: new Date(),
      };
      localStorage.setItem(
        'storagePopperAlert',
        JSON.stringify(storagePopperAlert)
      );
      state.showPopperAlert = action.payload.showPopperAlert;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
