import { Grid, Link } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  FormPasswordField,
  FormCheckbox,
  FormEmailOrPhone,
} from '../../components/modules/form';
import { LoginType } from './';
import { FieldContainer } from './styles';

type ContentProps = {
  control: Control<LoginType>;
  errors: DeepMap<Partial<LoginType>, FieldError>;
  handleKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => void;
};

const Content: React.FC<ContentProps> = ({
  control,
  errors,
  handleKeyPress,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleForgotPassword = useCallback(() => {
    navigate('/forgot-password');
  }, [navigate]);

  return (
    <Grid container item xs={12} spacing={2}>
      <FieldContainer item xs={12}>
        <FormEmailOrPhone
          name="emailOrPhone"
          label={t('login.emailOrPhone')}
          control={control}
          handleKeyPress={handleKeyPress}
          defaultValue=""
          errorMessage={errors.emailOrPhone?.message}
        />
      </FieldContainer>
      <FieldContainer item xs={12}>
        <FormPasswordField
          testID="inputPassword"
          label={t('login.password')}
          name="password"
          control={control}
          handleKeyPress={handleKeyPress}
          defaultValue=""
          errorMessage={errors.password?.message}
        />
      </FieldContainer>
      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item>
          <FormCheckbox
            label={t('login.keepSession')}
            name="stayConnected"
            control={control}
            defaultValue={false}
          />
        </Grid>
        <Grid item>
          <Link
            component="button"
            onClick={handleForgotPassword}
            underline="always"
            style={{ fontSize: 14 }}
          >
            <>{t('login.recoveryPassword')}</>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Content;
