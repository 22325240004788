import { createSlice } from '@reduxjs/toolkit';

import {
  FinancialOpenCreditsUpdateStatusAction,
  FinancialOpenCreditsSlice,
  FinancialOpenCreditsUpdateErrorAction,
  FetchStatusEnum,
  FinancialOpenCreditsGetSummaryAction,
  FinancialOpenCreditsGetOpenCreditsAction,
  FinancialOpenCreditsSummaryAction,
  FinancialOpenCreditsOpenCreditsAction,
  OpenCreditsFetchAction,
  OpenCreditsGetAction,
} from '../../types';

const initialState: FinancialOpenCreditsSlice = {
  fetchStatusSummary: FetchStatusEnum.None,
  fetchStatus: FetchStatusEnum.None,
  openCredits: [],
  openCreditsPaged: [],
  error: null,
  summary: {
    items: [],
    totals: [],
  },
  page: 0,
  total: 0,
};

export const slice = createSlice({
  name: 'financialOpenCredits',
  initialState,
  reducers: {
    clear: () => initialState,
    updateStatus: (state, action: FinancialOpenCreditsUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: FinancialOpenCreditsUpdateErrorAction) => {
      state.error = action.payload.error;
    },
    getSummary: (state, _: FinancialOpenCreditsGetSummaryAction) => {
      state.fetchStatusSummary = FetchStatusEnum.Fetching;
    },
    getOpenCredits: (state, _: FinancialOpenCreditsGetOpenCreditsAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    getOpenCreditsPaged: (state, action: OpenCreditsGetAction) => {
      const { page, limit } = action.payload;
      state.page = page;
      state.limit = limit;
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    summary: (state, action: FinancialOpenCreditsSummaryAction) => {
      state.summary = action.payload.summary;
    },
    openCredits: (state, action: FinancialOpenCreditsOpenCreditsAction) => {
      state.openCredits = action.payload.openCredits;
    },
    openCreditsPaged: (state, action: OpenCreditsFetchAction) => {
      const { openCreditsPaged, total } = action.payload;
      state.openCreditsPaged = openCreditsPaged;
      state.total = total;
    },
    updateStatusSummary: (
      state,
      action: FinancialOpenCreditsUpdateStatusAction
    ) => {
      state.fetchStatusSummary = action.payload.fetchStatus;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
