import { SagaIterator } from '@redux-saga/types';
import { DuplicateStatusEnum } from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  downloadInvoiceById,
  getDuplicates,
  updateDuplicatesStatus,
} from '../../services/duplicate';
import {
  DuplicatesDownloadInvoiceByIdAction,
  FetchStatusEnum,
  GetDuplicatesAction,
  GetDuplicatesResponse,
  SendOneDuplicateToRevisionAction,
  SendOneDuplicateToSignatureAction,
  SendToRevisionAction,
  SendToSignatureAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';
import {
  handleMultipleDuplicateResponse,
  handleOneDuplicateResponse,
} from './utils';

export default [
  takeLatest(actions.getDuplicates.type, getDuplicatesEffectSaga),
  takeLatest(actions.sendToSignature.type, sendToSignatureEffectSaga),
  takeLatest(actions.sendToRevision.type, sendToRevisionEffectSaga),
  takeLatest(
    actions.sendOneDuplicateToRevision.type,
    sendOneDuplicateToRevisionEffectSaga
  ),
  takeLatest(
    actions.sendOneDuplicateToSignature.type,
    sendOneDuplicateToSignatureEffectSaga
  ),
  takeLatest(actions.downloadInvoiceById.type, downloadInvoiceByIdEffectSaga),
];

export function* getDuplicatesEffectSaga(
  action: GetDuplicatesAction
): SagaIterator<void> {
  try {
    const { results: duplicates, total }: GetDuplicatesResponse = yield call(
      getDuplicates,
      action.payload
    );

    const totalPending = duplicates.reduce<number>((acc, item) => {
      if (item.status === DuplicateStatusEnum.PendingSignature) {
        acc += 1;
      }
      return acc;
    }, 0);

    const { page, resetItems } = action.payload;

    yield put(
      actions.persistDuplicates({
        duplicates,
        total,
        totalPending,
        page,
        resetItems,
      })
    );
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export function* sendToSignatureEffectSaga(
  params: SendToSignatureAction
): SagaIterator<void> {
  try {
    const updateResponse: { success: boolean }[] = yield call(
      updateDuplicatesStatus,
      params.payload
    );

    yield call(
      handleMultipleDuplicateResponse,
      updateResponse,
      params.payload,
      'sendToSignatureStatus'
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateKey({
        key: 'sendToSignatureStatus',
        value: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* sendToRevisionEffectSaga(
  params: SendToRevisionAction
): SagaIterator<void> {
  try {
    const updateResponse: { success: boolean }[] = yield call(
      updateDuplicatesStatus,
      params.payload
    );

    yield call(
      handleMultipleDuplicateResponse,
      updateResponse,
      params.payload,
      'sendToRevisionStatus'
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateKey({
        key: 'sendToRevisionStatus',
        value: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* sendOneDuplicateToSignatureEffectSaga(
  params: SendOneDuplicateToSignatureAction
): SagaIterator<void> {
  try {
    const updateResponse: { success: boolean }[] = yield call(
      updateDuplicatesStatus,
      [params.payload]
    );

    yield call(
      handleOneDuplicateResponse,
      updateResponse,
      params.payload.duplicateId,
      'sendToSignatureStatus'
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateKey({
        key: 'sendToSignatureStatus',
        value: FetchStatusEnum.Failure,
      })
    );
  }
}
export function* sendOneDuplicateToRevisionEffectSaga(
  params: SendOneDuplicateToRevisionAction
): SagaIterator<void> {
  try {
    const updateResponse: { success: boolean }[] = yield call(
      updateDuplicatesStatus,
      [params.payload]
    );

    yield call(
      handleOneDuplicateResponse,
      updateResponse,
      params.payload.duplicateId,
      'sendToRevisionStatus'
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateKey({
        key: 'sendToRevisionStatus',
        value: FetchStatusEnum.Failure,
      })
    );
  }
}
type DownloadInvoiceResponse = {
  pdfUrl?: string;
  xmlUrl?: string;
};

export function* downloadInvoiceByIdEffectSaga(
  action: DuplicatesDownloadInvoiceByIdAction
): SagaIterator<void> {
  try {
    const { pdfUrl, xmlUrl }: DownloadInvoiceResponse = yield call(
      downloadInvoiceById,
      action.payload
    );
    yield put(
      actions.updateKey({
        key: 'lastInvoiceDownloadedByDuplicateId',
        value: { [action.payload.duplicateId]: pdfUrl || xmlUrl || '' },
      })
    );
    yield put(
      actions.updateKey({
        key: 'fetchStatusDownload',
        value: FetchStatusEnum.Success,
      })
    );
  } catch (_) {
    yield put(
      actions.updateKey({
        key: 'fetchStatusDownload',
        value: FetchStatusEnum.Failure,
      })
    );
  }
}
