import { createSlice } from '@reduxjs/toolkit';

import {
  FinancialPayableUpdateStatusAction,
  FinancialPayableSlice,
  FinancialPayableUpdateErrorAction,
  FetchStatusEnum,
  FinancialPayableGetSummaryAction,
  FinancialPayableGetPayablesAction,
  FinancialPayableSummaryAction,
  FinancialPayablePayablesAction,
  PayableGetAction,
  PayableFetchAction,
} from '../../types';

const initialState: FinancialPayableSlice = {
  fetchStatusSummary: FetchStatusEnum.None,
  fetchStatus: FetchStatusEnum.None,
  payables: [],
  payablesPaged: [],
  error: null,
  summary: {
    items: [],
    totals: [],
  },
  page: 0,
  total: 0,
};

export const slice = createSlice({
  name: 'financialPayable',
  initialState,
  reducers: {
    clear: () => initialState,
    updateStatus: (state, action: FinancialPayableUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: FinancialPayableUpdateErrorAction) => {
      state.error = action.payload.error;
    },
    getSummary: (state, _: FinancialPayableGetSummaryAction) => {
      state.fetchStatusSummary = FetchStatusEnum.Fetching;
    },
    getPayables: (state, _: FinancialPayableGetPayablesAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    getPayablesPaged: (state, action: PayableGetAction) => {
      const { page, limit } = action.payload;
      state.page = page;
      state.limit = limit;
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    summary: (state, action: FinancialPayableSummaryAction) => {
      state.summary = action.payload.summary;
    },
    payables: (state, action: FinancialPayablePayablesAction) => {
      state.payables = action.payload.payables;
    },
    payablesPaged: (state, action: PayableFetchAction) => {
      const { payablesPaged, total } = action.payload;
      state.payablesPaged = payablesPaged;
      state.total = total;
    },
    updateStatusSummary: (
      state,
      action: FinancialPayableUpdateStatusAction
    ) => {
      state.fetchStatusSummary = action.payload.fetchStatus;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
