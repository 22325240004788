import { Grid, Typography } from '@material-ui/core';
import { DefaultThemeColorsType } from '@sinagro/frontend-shared/themes';
import styled, { css } from 'styled-components';

export const Container = styled(Grid)`
  padding: 0.5rem;
`;

export const Title = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: ${({ theme }) => theme.fontSize.xxl}px !important;
    padding-bottom: 1.5rem;
  }
`;

export const AlertContainer = styled(Grid)<{ $isCard: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.colors.primaryLight};
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  ${({ $isCard }) =>
    $isCard &&
    css`
      border-top: 0px;
      padding: 1rem;
      background: ${({ theme }) => theme.colors.white};
      box-shadow: 0px 8px 8px rgba(32, 40, 109, 0.1);
      border-radius: 8px;
      margin-bottom: 1rem;
    `}
`;

export const Icon = styled(Grid)<{ $color: DefaultThemeColorsType }>`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme, $color }) => theme.colors[$color]}10;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${({ theme, $color }) => theme.colors[$color]};
  }
`;

export const Text = styled(Typography)`
  display: flex;
  flex: 1;
  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
  }
`;

export const Description = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    margin-bottom: 1.5rem;
  }
`;
