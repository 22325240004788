import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useProfileSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    name: yup.string().required(t('form.required')),
  });
};
