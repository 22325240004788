import { SprayingForecastDTO } from '@sinagro/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateHourMinute } from 'src/utils/format';

import { Header, Hour } from './styles';

type ItemProps = {
  item: SprayingForecastDTO;
  isActive: boolean;
};

const HeaderItem: React.FC<ItemProps> = ({ item, isActive }) => {
  const { t } = useTranslation();

  return (
    <Header>
      <Hour $isActive={isActive}>
        {isActive
          ? t('torq.details.now')
          : formatDateHourMinute(item.date, true)}
      </Hour>
    </Header>
  );
};

export default HeaderItem;
