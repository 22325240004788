import { IRelatedClient } from '@sinagro/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertPanel from 'src/components/elements/alertPanel';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { AlertContainer } from './styles';

type SeeAsProps = {
  seeAsClient: IRelatedClient;
};

const SeeAs = ({ seeAsClient }: SeeAsProps) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const alertContainerMarginTop = isMobile ? '2rem' : '0';
  const divMarginTop = isMobile ? '4.5rem' : '2rem';

  return (
    <>
      <AlertContainer style={{ marginTop: alertContainerMarginTop }}>
        <AlertPanel
          alertContent={t('header.seeAsAlert', {
            name: seeAsClient.name,
            id: seeAsClient.id,
          })}
        />
      </AlertContainer>
      <div style={{ marginTop: divMarginTop }}></div>
    </>
  );
};

export default SeeAs;
