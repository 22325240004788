import { CreateUserInvite, DeleteUserInvite } from '../../types';
import api from '../api';

export function getUserInvites() {
  return api.get(`/invites`);
}

export function createUserInvite(
  payload: CreateUserInvite
): Promise<CreateUserInvite> {
  return api.post(`/user-invite`, payload);
}

export function deleteUserInvite(payload: DeleteUserInvite) {
  return api.delete(`/user-invite/${payload.inviteId}`);
}

export default {
  getUserInvites,
  createUserInvite,
  deleteUserInvite,
};
