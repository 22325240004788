import { SagaIterator } from '@redux-saga/types';
import { SharedError } from '@sinagro/shared/errors';
import { call, put, takeLatest } from 'redux-saga/effects';

import { sendCampaignInteraction } from '../../services/interaction';
import { FetchStatusEnum, SendCampaignInteractionAction } from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(
    actions.sendCampaignInteraction.type,
    sendCampaignInteractionEffectSaga
  ),
];

export function* sendCampaignInteractionEffectSaga(
  action: SendCampaignInteractionAction
): SagaIterator<void> {
  try {
    yield call(sendCampaignInteraction, action.payload);
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(actions.updateError({ error: knownError }));
    }

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
