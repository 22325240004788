import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : 'row')};
  align-items: ${({ $isMobile }) => ($isMobile ? 'flex-start' : 'center')};
  margin-bottom: 1rem;
  gap: 1rem;
`;

export const Text = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.xxl}px;
    color: ${({ theme }) => theme.colors.text};
  }
`;
