import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)<{ $isMobile: boolean }>`
  margin-bottom: 3rem;
  padding-left: ${({ $isMobile }) => ($isMobile ? 1 : 0)}rem;
`;

export const Content = styled(Grid)<{ $isMobile: boolean }>`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 8px 8px rgba(32, 40, 109, 0.1);
  border-radius: ${({ $isMobile }) => ($isMobile ? '8px 0 0 8px' : '8px')};
`;

export const Caption = styled(Grid)`
  padding: 0.5rem 0 1rem 1.5rem;
  width: 100%;
`;
