import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Button from '../../../components/elements/materialUI/button';
import { FormTextField } from '../../../components/modules/form';
import { actions, useDispatch, useSelector } from '../../../state/store';
import { useInviteSchema } from './schema';
import { Title, Description } from './styles';

export type InviteFormType = {
  email: string;
};

const InviteUser = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const schema = useInviteSchema();
  const dispatch = useDispatch();

  const fetchStatus = useSelector(
    (state) => state.shared.userInvite.fetchStatusCreateUserInvite
  );

  const profileError = useSelector(
    (state) => state.shared.userInvite.errorCreateUserInvite
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm<InviteFormType>({
    resolver: yupResolver(schema),
  });

  const value = watch('email');
  useEffect(() => {
    if (value) {
      setValue('email', value.trim(), {
        shouldValidate: true,
      });
    }
  }, [value, setValue]);

  const onSubmit: SubmitHandler<InviteFormType> = useCallback(
    (form: InviteFormType) => {
      const sanitizedForm = {
        email: form.email.trim(),
      };
      dispatch(actions.shared.userInvite.createUserInvite(sanitizedForm));
    },
    [dispatch]
  );

  useEffect(() => {
    if (fetchStatus === FetchStatusEnum.Success) {
      reset({
        email: '',
      });
    }
  }, [fetchStatus, t, reset]);

  useEffect(() => {
    if (errors)
      dispatch(
        actions.shared.userInvite.updateErrorCreateUserInvite({
          errorCreateUserInvite: { errorRef: '', message: '' },
        })
      );
  }, [dispatch, errors.email?.message, errors]);

  return (
    <Grid container item justifyContent="center" style={{ padding: '1rem' }}>
      <Title>{t('userManagement.inviteUser.title')}</Title>
      <Description>{t('userManagement.inviteUser.description')}</Description>
      <FormTextField
        testID="inputEmail"
        name="email"
        label={t('userManagement.inviteUser.emailInput')}
        control={control}
        errorMessage={errors.email?.message}
        variant="outlined"
      />
      <Grid
        container
        item
        justifyContent="center"
        style={{ marginTop: '0.5rem' }}
      >
        <Typography
          style={{ textAlign: 'start' }}
          variant="body2"
          color="error"
        >
          {profileError?.message}
        </Typography>
      </Grid>

      <Button
        variant="contained"
        loading={fetchStatus === FetchStatusEnum.Fetching}
        color="primary"
        style={{
          width: '10rem',
          marginTop: '2rem',
          background: theme.colors.quaternary,
        }}
        onClick={handleSubmit(onSubmit)}
      >
        <Typography variant="h6">
          {t('userManagement.inviteUser.submitButton')}
        </Typography>
      </Button>
    </Grid>
  );
};

export default InviteUser;
