import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import firebase from '@sinagro/frontend-shared/firebase';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'firebase/performance';
import 'react-toastify/dist/ReactToastify.css';
import { version } from '../package.json';
import ErrorBoundary from './components/elements/errorBoundary';
import Errors from './components/modules/errors';
import InternetConnection from './components/modules/internetConnection';
import Overlay from './components/modules/overlay';
import SEO from './components/modules/seo';
import SSO from './components/modules/sso';
import SystemConfig from './components/modules/systemConfig';
import GlobalStyle from './globalStyles';
import './i18n';
import { AuthProvider } from './providers/auth';
import NotificationProvider from './providers/notification';
import { OverlayProvider } from './providers/overlay';
import ThemeProvider from './providers/theme';
import Routes from './routes';
import ScrollToTop from './routes/scrollToTop';
import store from './state/store';

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV != 'development')
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    release: version,
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
window.addEventListener('beforeinstallprompt', (e: any) => {
  /**
   * Avoid open infobar to ask for install PWA
   */
  e.preventDefault();
});

export const perf = firebase.performance();

function App(): JSX.Element {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <ThemeProvider>
        <GlobalStyle />
        <ReduxProvider store={store}>
          <BrowserRouter>
            <HelmetProvider>
              <AuthProvider>
                <NotificationProvider>
                  <OverlayProvider>
                    <SEO />
                    <ScrollToTop />
                    <Errors />
                    <InternetConnection />
                    <SystemConfig />
                    <SSO />
                    <Routes />
                    <ToastContainer autoClose={3000} />
                    <Overlay />
                  </OverlayProvider>
                </NotificationProvider>
              </AuthProvider>
            </HelmetProvider>
          </BrowserRouter>
        </ReduxProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
