import type {
  UpdateCreditPackageDocumentFile,
  UploadFileToStorageParams,
} from '../../types';
import api from '../api';

export function getCreditPackage() {
  return api.get(`/credit-document-package`);
}

export function getDocumentFileWriteUrl(
  documentPackageId: string,
  documentId: string,
  fileType: string
) {
  return api.post(
    `/credit-document-package/${documentPackageId}/credit-document/${documentId}/write`,
    { fileType }
  );
}

export function getDocumentFileReadUrl(
  documentPackageId: string,
  documentId: string
) {
  return api.get(
    `/credit-document-package/${documentPackageId}/credit-document/${documentId}/read`
  );
}

export function updateCreditPackageDocumentFile({
  documentId,
  file,
}: UpdateCreditPackageDocumentFile) {
  return api.patch(`/credit-document/${documentId}/file`, { file });
}

export function uploadFileToStorage({
  writeUrl,
  file,
}: UploadFileToStorageParams) {
  if (file.buffer) {
    return api.uploadFile(writeUrl, file.name, file.type, file.buffer);
  }
  return api.uploadFile(writeUrl, file.name, file.type, file);
}
