import type { RequestCode } from '../../types';
import api from '../api';

export function requestCode(payload: RequestCode): Promise<RequestCode> {
  return api.post('/validation-code/request', payload);
}

export function checkCode(payload: RequestCode): Promise<RequestCode> {
  return api.post('/validation-code/check', payload);
}

export default { requestCode, checkCode };
