import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import ContactFloatingButton from 'src/components/modules/contactFloatingButton';
import { actions } from 'src/state';
import { useDispatch, useSelector } from 'src/state/store';

import { useIsMobile } from '../../../hooks/responsiveLayout';
import Footer from '../../modules/footer';
import Header from '../../modules/header';
import Menu from '../../modules/menu';
import SeeAs from './seeAs';

const DashboardLayout: React.FC = ({ children }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const [openMenu, setOpenMenu] = useState(true);

  const SIDEBAR_WIDTH = openMenu ? '215px' : '80px';
  const MENU_WIDTH = isMobile ? '100%' : SIDEBAR_WIDTH;
  const CONTENT_WIDTH = isMobile ? '100%' : `calc(100% - ${MENU_WIDTH}`;
  const direction = isMobile ? 'column' : 'row';
  const paddingContent = isMobile ? '1rem 0 0 0' : '0';

  const onOpenMenu = () => {
    setOpenMenu(!openMenu);
    dispatch(actions.menu.updateMenu({ open: !openMenu }));
  };

  const userProfile = useSelector(
    (state) => state.shared.userProfile.userProfile
  );
  const seeAsClient = userProfile.relatedClients?.find(
    (client) => client.seeAsId
  );

  return (
    <>
      <Grid container direction={direction} style={{ minHeight: '100vh' }}>
        <Grid container item style={{ width: MENU_WIDTH }}>
          <Menu onClick={onOpenMenu} openMenu={openMenu} />
        </Grid>
        <Grid
          item
          style={{
            width: CONTENT_WIDTH,
            padding: paddingContent,
          }}
        >
          {seeAsClient && <SeeAs seeAsClient={seeAsClient} />}

          <Header />
          {!isMobile && <ContactFloatingButton />}
          {children}
        </Grid>
        <Footer openMenu={openMenu} />
      </Grid>
    </>
  );
};

export default DashboardLayout;
