import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Container, Content, Link } from './styles';

const ReportFooter: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogin = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Container>
      <Content>
        <Link onClick={handleLogin} underline="always">
          {t('report.moreDetailsLink')}
        </Link>
      </Content>
    </Container>
  );
};

export default ReportFooter;
