import { SagaIterator } from '@redux-saga/types';
import { CreditPackage } from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getCreditPackage,
  getDocumentFileReadUrl,
  getDocumentFileWriteUrl,
  updateCreditPackageDocumentFile,
  uploadFileToStorage,
} from '../../services/creditPackage';
import {
  FetchStatusEnum,
  UpdateCreditPackageDocumentFile,
  UploadCreditPackageDocumentFileAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getCreditPackage.type, getCreditPackageEffectSaga),
  takeLatest(
    actions.updateCreditPackageDocumentFile.type,
    updateCreditPackageDocumentFileSaga
  ),
];

export function* updateCreditPackageDocumentFileSaga(
  action: UploadCreditPackageDocumentFileAction
): SagaIterator<void> {
  try {
    if (!action.payload.file) {
      yield call(updateCreditPackageDocumentFile, {
        documentId: action.payload.documentId,
        file: null,
      });
    } else {
      const { writeUrl, key, bucketName } = yield call(
        getDocumentFileWriteUrl,
        action.payload.documentPackageId,
        action.payload.documentId,
        action.payload.file.type
      );

      yield call(uploadFileToStorage, { writeUrl, file: action.payload.file });

      const { readUrl } = yield call(
        getDocumentFileReadUrl,
        action.payload.documentPackageId,
        action.payload.documentId
      );

      const fileToUpdate: UpdateCreditPackageDocumentFile['file'] = {
        url: readUrl,
        bucketName,
        key,
        filename: action.payload.file.name,
      };

      yield call(updateCreditPackageDocumentFile, {
        documentId: action.payload.documentId,
        file: fileToUpdate,
      });
    }

    const creditPackage: CreditPackage[] = yield call(getCreditPackage);
    yield put(actions.creditPackage({ creditPackage }));
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getCreditPackageEffectSaga(): SagaIterator<void> {
  try {
    const creditPackage: CreditPackage[] = yield call(getCreditPackage);
    yield put(actions.creditPackage({ creditPackage }));
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
