import { SagaIterator } from '@redux-saga/types';
import { call, put, takeLatest, take } from 'redux-saga/effects';

import { testConnection } from '../../services/internetConnection';
import { FetchStatusEnum } from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.testConnection.type, testConnectionEffectSaga),
];

export function* testConnectionEffectSaga(): SagaIterator<void> {
  try {
    while (true) {
      yield take(actions.execTestConnection);
      const response = yield call(testConnection);
      yield put(actions.connection({ connection: response }));
      yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
    }
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
