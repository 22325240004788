import { Grid } from '@material-ui/core';
import React from 'react';

import Header from '../components/header';
import MenuItems from '../components/menuItems';
import { MainContainer } from './styles';

type Props = {
  openMenu: boolean;
  onClick: () => void;
  isMobile: boolean;
};

const DesktopMenu = ({ openMenu, onClick }: Props) => {
  return (
    <MainContainer container item direction="row" $openMenu={openMenu}>
      <Header onClick={onClick} openMenu={openMenu} />
      <MenuItems openMenu={openMenu} />
      <Grid />
    </MainContainer>
  );
};

export default DesktopMenu;
