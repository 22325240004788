import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)<{ $isMobile: boolean }>`
  width: 100%;
  margin-bottom: 3rem;
  padding: ${({ $isMobile }) => ($isMobile ? '0 1rem' : 0)};
`;

export const SubtitleContainer = styled(Grid)`
  align-items: center;
  margin-bottom: 1rem;

  svg {
    font-size: ${({ theme }) => theme.fontSize.big}px;
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-right: 0.5rem;
  }
`;

export const Subtitle = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`;
