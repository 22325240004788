type pagesProps = {
  path: string;
  title: string;
};

export const pages: pagesProps[] = [
  {
    path: '/',
    title: 'pagesTitle.login',
  },
  {
    path: '/__/invite-sign-up',
    title: 'pagesTitle.inviteSignUp',
  },
  {
    path: '/sign-up',
    title: 'pagesTitle.signUp',
  },
  {
    path: '/forgot-password',
    title: 'pagesTitle.forgotPassword',
  },
  {
    path: '/forgot-password-form',
    title: 'pagesTitle.forgotPasswordForm',
  },
  {
    path: '/__/password-recovery',
    title: 'pagesTitle.passwordRecovery',
  },
  {
    path: '/validation',
    title: 'pagesTitle.validation',
  },
  {
    path: '/validation-code',
    title: 'pagesTitle.validationCode',
  },
  {
    path: '/validation-code-success',
    title: 'pagesTitle.validationCodeSuccess',
  },
  {
    path: '/report',
    title: 'pagesTitle.report',
  },
  {
    path: '/dashboard',
    title: 'pagesTitle.dashboard',
  },
  {
    path: '/dashboard/services',
    title: 'pagesTitle.services',
  },
  {
    path: '/dashboard/orders',
    title: 'pagesTitle.orders',
  },
  {
    path: '/dashboard/trade',
    title: 'pagesTitle.trade',
  },
  {
    path: '/dashboard/credit',
    title: 'pagesTitle.credit',
  },
  {
    path: '/__/credit-package',
    title: 'pagesTitle.creditPackage',
  },
  {
    path: '/profile',
    title: 'pagesTitle.profile',
  },
  {
    path: '/profile/user-profile',
    title: 'pagesTitle.userProfile',
  },
  {
    path: '/profile/user-management',
    title: 'pagesTitle.userManagement',
  },
  {
    path: '/profile/change-password',
    title: 'pagesTitle.changePassword',
  },
  {
    path: '/notifications',
    title: 'pagesTitle.notifications',
  },
  {
    path: '/dashboard/tracking',
    title: 'pagesTitle.tracking',
  },
  {
    path: '/dashboard/torq',
    title: 'pagesTitle.torq',
  },
  {
    path: '/dashboard/financial',
    title: 'pagesTitle.financial',
  },
  {
    path: '/dashboard/quotation',
    title: 'pagesTitle.quotation',
  },
  {
    path: '/dashboard/weather-select-city',
    title: 'pagesTitle.weatherSelectCity',
  },
  {
    path: '/dashboard/weather',
    title: 'pagesTitle.weather',
  },
];
