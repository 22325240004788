import { SagaIterator } from '@redux-saga/types';
import { SharedError } from '@sinagro/shared/errors';
import { call, takeLatest, put } from 'redux-saga/effects';

import { reduxSagaFirebase } from '../../../firebase';
import { doLogin } from '../../services/auth';
import { createtUser, getAccountExistence } from '../../services/user';
import {
  FetchExistenceAction,
  FetchStatusEnum,
  SignUpPostAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

type DoLoginResponse = {
  token: string;
};

export default [
  takeLatest(actions.post.type, signUpEffectSaga),
  takeLatest(actions.postMobile.type, signUpMobileEffectSaga),
  takeLatest(actions.fetchExistence.type, fetchExistenceSaga),
];

export function* signUpEffectSaga(
  action: SignUpPostAction
): SagaIterator<void> {
  const { email, password } = action.payload;
  try {
    yield call(createtUser, action.payload);
    const loginResponse: DoLoginResponse = yield call(doLogin, {
      emailOrPhone: email,
      password: password,
    });

    yield call(
      reduxSagaFirebase.auth.signInWithCustomToken,
      loginResponse.token
    );

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(actions.updateError({ error: knownError }));
    }

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export function* signUpMobileEffectSaga(
  action: SignUpPostAction
): SagaIterator<void> {
  try {
    yield call(createtUser, action.payload);

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(actions.updateError({ error: knownError }));
    }

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export function* fetchExistenceSaga(
  action: FetchExistenceAction
): SagaIterator<void> {
  try {
    const { exists } = yield call(
      getAccountExistence,
      action.payload.field,
      action.payload.value
    );

    yield put(actions.updateExistence({ exists, field: action.payload.field }));
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(actions.updateError({ error: knownError }));
    }

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
