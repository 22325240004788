import { createSlice } from '@reduxjs/toolkit';

import { InstallAppInviteSlice, SetInstallAppInviteAction } from './types';

const initialState: InstallAppInviteSlice = {
  installAppInvite: null,
};

export const slice = createSlice({
  name: 'installAppInvite',
  initialState,
  reducers: {
    clear: () => initialState,
    getInstallAppInvite: (state) => {
      const storage = localStorage.getItem('installAppInvite');
      const installAppInvite = storage ? JSON.parse(storage) : null;
      state.installAppInvite = installAppInvite;
    },
    setInstallAppInvite: (state, action: SetInstallAppInviteAction) => {
      const installAppInvite = action.payload.installAppInvite;
      localStorage.setItem(
        'installAppInvite',
        JSON.stringify(installAppInvite)
      );
      state.installAppInvite = action.payload.installAppInvite;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
