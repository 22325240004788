import { useMediaQuery, useTheme } from '@material-ui/core';

export const useIsMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile;
};

export const useIsSmallDevice = () => {
  const theme = useTheme();
  const IsSmallDevice = useMediaQuery(theme.breakpoints.down('xs'));

  return IsSmallDevice;
};
