import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonContainer = styled(Grid)`
  width: 100%;
  padding: 2rem 0 1rem 0;
`;

export const LogoContainer = styled(Grid)`
  padding-top: 1rem;
`;

export const TitleContainer = styled(Grid)`
  padding-top: 2rem;
  text-align: center;
`;

export const SubTitleContainer = styled(Grid)`
  padding: 1rem 0 2rem 0;
  text-align: center;
`;
