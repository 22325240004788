import { FetchStatusEnum } from '../../../../types';
import {
  RemittanceFetchAction,
  RemittanceGetAction,
  RemittanceUpdateStatusAction,
  TrackingSlice,
} from '../../../../types/v2';

export const reducersOthers = {
  getRemittancesOthersScheduled: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusOthersScheduled = FetchStatusEnum.Fetching;
  },
  remittancesOthersScheduled: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesOthersScheduled =
      page === 0
        ? remittances
        : [...state.remittancesOthersScheduled, ...remittances];
    state.totalOthersScheduled = total;
  },
  updateStatusOthersScheduled: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusOthersScheduled = action.payload.fetchStatus;
  },
  getRemittancesOthersHandling: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusOthersHandling = FetchStatusEnum.Fetching;
  },
  remittancesOthersHandling: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesOthersHandling =
      page === 0
        ? remittances
        : [...state.remittancesOthersHandling, ...remittances];
    state.totalOthersHandling = total;
  },
  updateStatusOthersHandling: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusOthersHandling = action.payload.fetchStatus;
  },
  getRemittancesOthersInTransit: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusOthersInTransit = FetchStatusEnum.Fetching;
  },
  remittancesOthersInTransit: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesOthersInTransit =
      page === 0
        ? remittances
        : [...state.remittancesOthersInTransit, ...remittances];
    state.totalOthersInTransit = total;
  },
  updateStatusOthersInTransit: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusOthersInTransit = action.payload.fetchStatus;
  },
  getRemittancesOthersDelivered: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusOthersDelivered = FetchStatusEnum.Fetching;
  },
  remittancesOthersDelivered: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesOthersDelivered =
      page === 0
        ? remittances
        : [...state.remittancesOthersDelivered, ...remittances];
    state.totalOthersDelivered = total;
  },
  updateStatusOthersDelivered: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusOthersDelivered = action.payload.fetchStatus;
  },
};
