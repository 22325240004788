import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { pages } from './utils';

const SEO = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const path = location.pathname;
  const currentPage = pages.filter((page) => page.path === path);
  const currentPageTitle = currentPage.length
    ? t(currentPage[0].title)
    : t('pagesTitle.default');

  return (
    <Helmet>
      <title>{currentPageTitle}</title>
    </Helmet>
  );
};

export default SEO;
