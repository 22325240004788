import { createSlice } from '@reduxjs/toolkit';

import {
  FetchStatusEnum,
  InteractionSlice,
  InteractionUpdateErrorAction,
  InteractionUpdateStatusAction,
  SendCampaignInteractionAction,
} from '../../types';

const initialState: InteractionSlice = {
  fetchStatus: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'interaction',
  initialState,
  reducers: {
    clear: () => initialState,
    sendCampaignInteraction: (state, _: SendCampaignInteractionAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    updateStatus: (state, action: InteractionUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: InteractionUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
