import type { FiltersType, OrdersGet, OrdersIdsGet } from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';

export const ordersFiltersParams = (
  params: CustomURLSearchParams,
  filters: FiltersType
) => {
  filters.order.length > 0 && params.set('orderId', filters.order);

  filters.searchTerm.length > 0 && params.set('search', filters.searchTerm);

  filters.propertyName.length > 0 &&
    params.set('propertyName', `%${filters.propertyName}%`);

  filters.segment.length > 0 &&
    params.set('segment', filters.segment.join(','));

  filters.productName && params.set('productName', `%${filters.productName}%`);

  filters.orderItemStatus &&
    params.set('status', filters.orderItemStatus.join(','));

  filters.cultivation && params.set('cultivation', `%${filters.cultivation}%`);

  filters.ordersGroupBy && params.set('groupBy', filters.ordersGroupBy);

  filters.harvestReferences.length > 0 &&
    params.set('harvestReference', filters.harvestReferences.join(','));

  filters.currency && params.set('currency', filters.currency);

  filters.invoice && params.set('invoiceNumber', `%${filters.invoice}%`);

  return params;
};

export const ordersPageParams = (
  params: CustomURLSearchParams,
  payload: OrdersGet
) => {
  payload.limit && params.set('limit', payload.limit.toString());

  payload.page && params.set('page', payload.page.toString());

  return params;
};

export const ordersIdsParams = (
  params: CustomURLSearchParams,
  payload: OrdersIdsGet
) => {
  payload.limit && params.set('limit', payload.limit.toString());

  payload.orderId && params.set('orderId', payload.orderId.toString());

  return params;
};
