import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)<{ $isMobile: boolean }>`
  margin-bottom: 2rem;
  padding-left: ${({ $isMobile }) => ($isMobile ? 1 : 0)}rem;
`;

export const DailyContainer = styled(Grid)``;

export const List = styled(Grid)`
  width: 100%;
  display: flex;
  overflow-x: auto !important;
  padding-bottom: 1rem;

  ::-webkit-scrollbar {
    height: 0px;
  }
`;

export const DayWeatherContainer = styled(Grid)`
  width: 280px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 8px 8px rgba(32, 40, 109, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  gap: 1rem;
  margin-right: 1rem;
`;
