import styled, { keyframes } from 'styled-components';

export const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderContainer = styled.div<{ color: string; size?: number }>`
  border: 2px solid #f3f3f3;
  border-top: ${({ color }) => `2px solid ${color}`};
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  animation: ${rotation} 1s linear infinite;
`;
