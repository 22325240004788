import { SagaIterator } from '@redux-saga/types';
import { IBankAccount } from '@sinagro/shared';
import { call, takeLatest, put } from 'redux-saga/effects';

import { getBankAccounts } from '../../services/financial';
import { FetchStatusEnum } from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getBankAccounts.type, getBankAccountsEffectSaga),
];

export function* getBankAccountsEffectSaga(): SagaIterator<void> {
  try {
    const bankAccounts: IBankAccount[] = yield call(getBankAccounts);
    yield put(actions.bankAccounts({ bankAccounts }));
    yield put(
      actions.updateBankAccountsStatus({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateBankAccountsStatus({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}
