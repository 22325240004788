import {
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
} from '@material-ui/core';
import { CalendarToday, StoreSharp } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsLogger } from 'src/utils/analytics';

import ScheduleAppointmentCard from './appointmentCard';
import { Button, ButtonText, ScheduleIcon } from './styles';

const ScheduleAppointment = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popper' : undefined;

  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  const techTitle = t('scheduleAppointment.tech.title');
  const techSubtitle = t('scheduleAppointment.tech.subtitle');
  const techText = t('scheduleAppointment.tech.message');
  const techUrl = `https://api.whatsapp.com/send?1=pt_BR&phone=${whatsappNumber}&text=${techText}`;

  const storeTitle = t('scheduleAppointment.store.title');
  const storeSubtitle = t('scheduleAppointment.store.subtitle');
  const storeText = t('scheduleAppointment.store.message');
  const storeUrl = `https://api.whatsapp.com/send?1=pt_BR&phone=${whatsappNumber}&text=${storeText}`;

  const handleItemClick = (type: 'tech' | 'store') => {
    analyticsLogger.scheduleAppointment(type);
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Button
          onClick={(e) => {
            setAnchorEl(anchorEl ? null : e.currentTarget);
          }}
          color="primary"
          $isActive={open}
        >
          <ScheduleIcon />
          <Grid>
            <ButtonText>{t('scheduleAppointment.buttonText.part1')}</ButtonText>
            <ButtonText $small>
              {t('scheduleAppointment.buttonText.part2')}
            </ButtonText>
          </Grid>
        </Button>
        <Popper id={id} anchorEl={anchorEl} open={open} placement="bottom-end">
          <Paper style={{ width: '250px' }}>
            <ScheduleAppointmentCard
              Icon={CalendarToday}
              title={techTitle}
              subtitle={techSubtitle}
              onClick={() => handleItemClick('tech')}
              url={techUrl}
            />
            <Divider />
            <ScheduleAppointmentCard
              Icon={StoreSharp}
              title={storeTitle}
              subtitle={storeSubtitle}
              onClick={() => handleItemClick('store')}
              url={storeUrl}
            />
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default ScheduleAppointment;
