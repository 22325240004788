import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ForgotPassword from '../pages/forgotPassword';
import ForgotPasswordForm from '../pages/forgotPassword/form';
import Login from '../pages/login';
import PasswordRecovery from '../pages/passwordRecovery';
import Report from '../pages/report';
import SignUp from '../pages/signUpForm';
import SignUpInvite from '../pages/signUpInvite';
import AuthRoute from './authRoute';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthRoute type="public">
            <Login />
          </AuthRoute>
        }
      />
      <Route
        path="/__/invite-sign-up"
        element={
          <AuthRoute type="public">
            <SignUpInvite />
          </AuthRoute>
        }
      />
      <Route
        path="/sign-up"
        element={
          <AuthRoute type="public">
            <SignUp />
          </AuthRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <AuthRoute type="public">
            <ForgotPassword />
          </AuthRoute>
        }
      />
      <Route
        path="/forgot-password-form"
        element={
          <AuthRoute type="public">
            <ForgotPasswordForm />
          </AuthRoute>
        }
      />
      <Route
        path="/__/password-recovery"
        element={
          <AuthRoute type="public">
            <PasswordRecovery />
          </AuthRoute>
        }
      />
      <Route
        path="/report"
        element={
          <AuthRoute type="public">
            <Report />
          </AuthRoute>
        }
      />
    </Routes>
  );
};

export default PublicRoutes;
