import { createSlice } from '@reduxjs/toolkit';

import {
  FetchStatusEnum,
  SystemConfigGetAction,
  SystemConfigSlice,
  SystemConfigUpdateStatusAction,
} from '../../types';

const initialState: SystemConfigSlice = {
  systemConfig: null,
  fetchStatus: FetchStatusEnum.None,
};

export const slice = createSlice({
  name: 'SystemConfig',
  initialState,
  reducers: {
    clear: () => initialState,
    getSystemConfig: (state) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    systemConfig: (state, action: SystemConfigGetAction) => {
      state.systemConfig = action.payload.systemConfig;
    },
    updateStatus: (state, action: SystemConfigUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
