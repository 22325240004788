import { Channel, SagaIterator } from '@redux-saga/types';
import { handleError } from '@sinagro/frontend-shared/errors';
import { reduxSagaFirebase } from '@sinagro/frontend-shared/firebase';
import { authServices } from '@sinagro/frontend-shared/services';
import {
  FetchStatusEnum,
  DoLoginAction,
  AuthenticatedEnum,
  SingleSignOnAction,
} from '@sinagro/frontend-shared/sharedTypes';
import { actions as sharedActions } from '@sinagro/frontend-shared/state';
import { SharedError } from '@sinagro/shared/errors';
import { call, takeLatest, put, take } from 'redux-saga/effects';

import { actions } from './slice';
import { onIdTokenChangedChannel, syncUser } from './utils';

type DoLoginResponse = {
  token: string;
};

export default [
  takeLatest(actions.doLogin.type, doLoginEffectSaga),
  takeLatest(actions.onAuthStateChanged.type, onAuthStateChangedSaga),
  takeLatest(actions.onIdTokenChanged.type, onIdTokenChangedSaga),
  takeLatest(actions.singleSignOn.type, singleSignOnEffectSaga),
];

export function* onAuthStateChangedSaga(): SagaIterator<void> {
  const channel: Channel<unknown> = yield call(reduxSagaFirebase.auth.channel);
  while (true) {
    const { user } = yield take(channel);

    if (user) {
      yield put(
        sharedActions.user.userAuthenticated({
          userAuthenticated: AuthenticatedEnum.Authenticated,
        })
      );
    } else {
      yield put(
        sharedActions.user.userAuthenticated({
          userAuthenticated: AuthenticatedEnum.NotAuthenticated,
        })
      );
    }
  }
}

export function* onIdTokenChangedSaga(): SagaIterator<void> {
  const channel: Channel<unknown> = yield call(onIdTokenChangedChannel);
  while (true) {
    const { user } = yield take(channel);
    yield call(syncUser, user);

    if (user) {
      yield put(
        sharedActions.user.userAuthenticated({
          userAuthenticated: AuthenticatedEnum.Authenticated,
        })
      );
    } else {
      yield put(
        sharedActions.user.userAuthenticated({
          userAuthenticated: AuthenticatedEnum.NotAuthenticated,
        })
      );
    }
  }
}

export function* doLoginEffectSaga(action: DoLoginAction): SagaIterator<void> {
  try {
    const response: DoLoginResponse = yield call(
      authServices.doLogin,
      action.payload
    );
    yield call(reduxSagaFirebase.auth.signInWithCustomToken, response.token);
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });
    if (knownError) {
      yield put(actions.updateError({ error: knownError }));
    }

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export function* singleSignOnEffectSaga(
  action: SingleSignOnAction
): SagaIterator<void> {
  try {
    yield call(
      reduxSagaFirebase.auth.signInWithCustomToken,
      action.payload.token
    );
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });
    if (knownError) {
      yield put(actions.updateError({ error: knownError }));
    }

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
