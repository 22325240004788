import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography, Link } from '@material-ui/core';
import { analytics } from '@sinagro/frontend-shared/firebase';
import {
  SignUpPost,
  FetchStatusEnum,
} from '@sinagro/frontend-shared/sharedTypes';
import { replaceMask } from '@sinagro/frontend-shared/validations';
import { DocumentTypeEnum, IUser } from '@sinagro/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { analyticsLogger } from 'src/utils/analytics';

import Logo from '../../components/elements/logo';
import Button from '../../components/elements/materialUI/button';
import WelcomeLayout from '../../components/layout/welcomeLayout';
import { useDispatch, actions, useSelector } from '../../state/store';
import Form from './form';
import { useSignUpSchema } from './schema';
import { SignUpContainer, MainContainer, LinkContainer } from './styles';

export type SignUpFormType = Pick<
  IUser,
  'name' | 'email' | 'phoneNumber' | 'document' | 'documentType'
> & {
  password: string;
  confirmPassword: string;
  termsOfService: boolean;
};
export type FormFields = keyof SignUpFormType;

const SignUpForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signUpSchema = useSignUpSchema();
  const [documentType, setDocumentType] = useState(DocumentTypeEnum.CPF);

  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  const fetchStatus = useSelector((state) => state.shared.signUp.fetchStatus);

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setError,
    setValue,
    clearErrors,
  } = useForm<SignUpFormType>({
    defaultValues: { documentType },
    resolver: yupResolver(signUpSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('documentType', documentType);
    clearErrors('document');
    setValue('document', '');
  }, [clearErrors, documentType, setValue]);

  const clearFieldExistance = React.useCallback(
    (field: 'document' | 'email' | 'phone') => {
      dispatch(
        actions.shared.signUp.updateExistence({
          exists: false,
          field,
        })
      );
    },
    [dispatch]
  );

  const emailValue = watch('email');
  useEffect(() => {
    clearFieldExistance('email');
    if (emailValue) {
      setValue('email', emailValue.trim(), {
        shouldValidate: true,
      });
    }
  }, [emailValue, clearFieldExistance, setValue]);

  const phoneValue = watch('phoneNumber');
  useEffect(() => {
    clearFieldExistance('phone');
  }, [phoneValue, clearFieldExistance]);

  useEffect(() => {
    if (fetchStatus === FetchStatusEnum.Success) {
      analytics().logEvent('Cadastro', {
        platform: 'web',
        method: 'custom',
      });
    }
  }, [fetchStatus]);

  const handleAlreadyRegister = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  useEffect(() => {
    return () => {
      dispatch(actions.shared.signUp.clear());
    };
  }, [dispatch]);

  const setFieldError = useCallback(
    (field: FormFields, message: string) => {
      setError(field, {
        message,
      });
    },
    [setError]
  );

  const emailExists = useSelector((state) => state.shared.signUp.emailExistent);
  useEffect(() => {
    if (emailExists) {
      setFieldError('email', t('signUpForm.alreadyRegistered.email'));
    } else clearErrors('email');
  }, [emailExists, setFieldError, clearErrors, t]);

  const phoneExists = useSelector((state) => state.shared.signUp.phoneExistent);
  useEffect(() => {
    if (phoneExists) {
      setFieldError(
        'phoneNumber',
        t('signUpForm.alreadyRegistered.phoneNumber')
      );
    } else clearErrors('phoneNumber');
  }, [phoneExists, setFieldError, clearErrors, t]);

  const documentExists = useSelector(
    (state) => state.shared.signUp.documentExistent
  );
  useEffect(() => {
    if (documentExists) {
      setFieldError('document', t('signUpForm.alreadyRegistered.document'));
    } else clearErrors('document');
  }, [documentExists, setFieldError, clearErrors, t]);

  const checkExistence = useCallback(
    (field: FormFields) => {
      const value = String(getValues(field));
      if (!value) return;
      const sanitizedPhone = `55${replaceMask(value)}`;
      const sanitizedDocument = replaceMask(value);
      switch (field) {
        case 'email':
          dispatch(
            actions.shared.signUp.fetchExistence({
              field: 'email',
              value,
            })
          );
          break;
        case 'phoneNumber':
          dispatch(
            actions.shared.signUp.fetchExistence({
              field: 'phone',
              value: sanitizedPhone,
            })
          );
          break;
        case 'document':
          dispatch(
            actions.shared.signUp.fetchExistence({
              field: 'document',
              value: sanitizedDocument,
            })
          );
          break;
      }
    },
    [dispatch, getValues]
  );

  const onSubmit: SubmitHandler<SignUpFormType> = useCallback(
    (form: SignUpFormType) => {
      const sanitizedForm: SignUpPost = {
        documentType: form.documentType,
        name: form.name,
        email: form.email,
        password: form.password,
        phoneNumber: `55${replaceMask(String(form.phoneNumber))}`,
        document: replaceMask(String(form.document)),
      };

      dispatch(actions.shared.signUp.post(sanitizedForm));
    },
    [dispatch]
  );

  return (
    <WelcomeLayout>
      <MainContainer
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Logo />
        </Grid>
        <Form
          onBlur={checkExistence}
          control={control}
          errors={errors}
          documentType={documentType}
          setDocumentType={setDocumentType}
        />
        <SignUpContainer item xs={12}>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
            loading={fetchStatus === FetchStatusEnum.Fetching}
            fullWidth
            data-testid="buttonRegister"
          >
            <Typography variant="h6">{t('signUpForm.signUp')}</Typography>
          </Button>
        </SignUpContainer>
        <LinkContainer item xs={12}>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{ fontSize: 16 }}
          >
            {t('signUpForm.alreadyRegister')}
            <Link
              href="#"
              underline="always"
              component="button"
              variant="body2"
              onClick={handleAlreadyRegister}
              style={{ fontSize: 16, paddingLeft: '0.25rem', lineHeight: 1.7 }}
            >
              <>{t('signUpForm.doLogin')}</>
            </Link>
          </Typography>
        </LinkContainer>
        <LinkContainer item xs={12} style={{ paddingTop: 0 }}>
          <Typography
            variant="body2"
            color="textPrimary"
            style={{ fontSize: 16 }}
          >
            {t('signUpForm.needHelp')}
            <Link
              href={`https://api.whatsapp.com/send?1=pt_BR&phone=${whatsappNumber}`}
              underline="always"
              variant="body2"
              target="_blank"
              style={{
                fontSize: 16,
                paddingLeft: '0.25rem',
                lineHeight: 1.7,
              }}
              rel="noopener"
              onClick={() => analyticsLogger.whatsapp('signup')}
            >
              <>{t('signUpForm.talkToUs')}</>
            </Link>
          </Typography>
        </LinkContainer>
      </MainContainer>
    </WelcomeLayout>
  );
};

export default SignUpForm;
