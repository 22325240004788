import React from 'react';

import { useSelector } from '../../../../../state/store';
import { items, menuItemsFeatureFlag } from './items';
import MenuItem from './menuItem';
import { MenuListItems } from './styles';

type Props = {
  openMenu?: boolean;
};

const MenuItems = ({ openMenu = true }: Props) => {
  const featureFlag = useSelector(
    (state) => state.shared.featureFlag.featureFlag
  );
  const regions =
    useSelector((state) => state.shared.userProfile.userProfile.regions) || [];
  const isClientTorq = !!regions.length;

  const menuItems = menuItemsFeatureFlag(items(isClientTorq), featureFlag);

  return (
    <MenuListItems style={{ padding: '0', width: '100%' }}>
      {menuItems.map((item, index) => (
        <MenuItem key={`menu-item-${index}`} {...item} openMenu={openMenu} />
      ))}
    </MenuListItems>
  );
};

export default MenuItems;
