import { Grid, Typography } from '@material-ui/core';
import { DefaultThemeTorqGrowthCurveColorsType } from '@sinagro/frontend-shared/themes';
import styled from 'styled-components';

export const Container = styled(Grid)`
  padding: 0.5rem;
  max-width: 350px;
`;

export const Title = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: ${({ theme }) => theme.fontSize.xxl}px !important;
    margin-bottom: 1.5rem;
  }
`;

export const Description = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: ${({ theme }) => theme.fontSize.md}px;
  }
`;

export const GrowthItem = styled(Typography)<{
  $color: DefaultThemeTorqGrowthCurveColorsType;
}>`
  display: flex;
  height: 24px;
  width: fit-content;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  background-color: ${({ theme, $color }) =>
    theme.colors.torqGrowthCurve[$color]};

  && {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.md}px;
    margin: 0.5rem 0;
  }
`;

export const DetailContainer = styled(Grid)`
  padding: 0.5rem;
  max-width: 350px;
`;
