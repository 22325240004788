import i18n from 'i18next';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { BreadCrumbsStyled, LinkStyled } from './styles';

type LabelsType = string[];

const redirects: Map<string, string> = new Map([
  [i18n.t('routes.home'), '/dashboard'],
  [i18n.t('routes.trade'), '/dashboard/trade'],
  [i18n.t('routes.orders'), '/dashboard/orders'],
  [i18n.t('routes.receivable'), '/dashboard/receivable'],
  [i18n.t('routes.payable'), '/dashboard/payable'],
  [i18n.t('routes.credit'), '/dashboard/credit'],
  [i18n.t('routes.creditPackage'), '/__/credit-package'],
  [i18n.t('routes.tracking'), '/dashboard/tracking'],
  [i18n.t('routes.torq'), '/dashboard/torq'],
  [i18n.t('routes.quotation'), '/dashboard/quotation'],
  [i18n.t('routes.weatherSelectCity'), '/dashboard/weather-select-city'],
  [i18n.t('routes.weather'), '/dashboard/weather'],
  [i18n.t('routes.profile'), '/profile'],
  [i18n.t('routes.userProfile'), '/profile/user-profile'],
  [i18n.t('routes.userManagement'), '/profile/user-management'],
  [i18n.t('routes.changePassword'), '/profile/change-password'],
  [i18n.t('routes.notification'), '/notifications'],
  [i18n.t('routes.services'), '/services'],
  [i18n.t('routes.duplicates'), '/dashboard/duplicates'],
]);

const breadCrumbItems: Map<string, LabelsType> = new Map([
  ['/dashboard', [i18n.t('routes.home')]],
  ['/dashboard/trade', [i18n.t('routes.services'), i18n.t('routes.trade')]],
  ['/dashboard/orders', [i18n.t('routes.services'), i18n.t('routes.orders')]],
  [
    '/dashboard/receivable',
    [i18n.t('routes.services'), i18n.t('routes.receivable')],
  ],
  ['/dashboard/payable', [i18n.t('routes.services'), i18n.t('routes.payable')]],
  [
    '/dashboard/tracking',
    [i18n.t('routes.services'), i18n.t('routes.tracking')],
  ],
  ['/dashboard/torq', [i18n.t('routes.home'), i18n.t('routes.torq')]],
  ['/dashboard/credit', [i18n.t('routes.services'), i18n.t('routes.credit')]],
  [
    '/__/credit-package',
    [i18n.t('routes.services'), i18n.t('routes.creditPackage')],
  ],
  [
    '/dashboard/quotation',
    [i18n.t('routes.services'), i18n.t('routes.quotation')],
  ],
  [
    '/dashboard/weather-select-city',
    [i18n.t('routes.home'), i18n.t('routes.weatherSelectCity')],
  ],
  ['/dashboard/weather', [i18n.t('routes.home'), i18n.t('routes.weather')]],
  ['/profile', [i18n.t('routes.home'), i18n.t('routes.profile')]],
  [
    '/profile/user-profile',
    [
      i18n.t('routes.home'),
      i18n.t('routes.profile'),
      i18n.t('routes.userProfile'),
    ],
  ],
  [
    '/profile/user-management',
    [
      i18n.t('routes.home'),
      i18n.t('routes.profile'),
      i18n.t('routes.userManagement'),
    ],
  ],
  [
    '/profile/change-password',
    [
      i18n.t('routes.home'),
      i18n.t('routes.profile'),
      i18n.t('routes.changePassword'),
    ],
  ],
  [
    '/profile/notification-preferences',
    [
      i18n.t('routes.home'),
      i18n.t('routes.profile'),
      i18n.t('routes.notificationPreferences'),
    ],
  ],
  [i18n.t('routes.home'), i18n.t('routes.notification')],
  [
    '/dashboard/duplicates',
    [i18n.t('routes.services'), i18n.t('routes.duplicates')],
  ],
]);

const BreadCrumbs: React.FC = () => {
  const { pathname } = useLocation();

  const breadCrumbItem = breadCrumbItems.get(pathname);

  const lastItem = breadCrumbItem ? breadCrumbItem.length - 1 : 0;

  return (
    <BreadCrumbsStyled
      separator="&nbsp;&nbsp;>&nbsp;&nbsp;"
      color="secondary"
      data-testid="breadcrumbs"
    >
      {breadCrumbItem &&
        breadCrumbItem.map((label, index) => (
          <LinkStyled
            active={index !== 0 && index === lastItem}
            key={`bread-crumb-label-${index}`}
            to={redirects.get(label) ?? ''}
          >
            {label}
          </LinkStyled>
        ))}
    </BreadCrumbsStyled>
  );
};

export default BreadCrumbs;
