import { SagaIterator } from '@redux-saga/types';
import { INotification } from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getNotifications,
  getNotSeenCount,
  markAllNotificationsAsSeen,
  markNotificationAsSeen,
} from '../../services/notification';
import {
  FetchStatusEnum,
  NotificationGetAction,
  NotificationMarkAllAsSeenAction,
  NotificationMarkAsSeenAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getNotifications.type, getNotificationEffectSaga),
  takeLatest(actions.getNotSeenCount.type, getNotSeenCountEffectSaga),
  takeLatest(
    actions.markNotificationAsSeen.type,
    markNotificationAsSeenEffectSaga
  ),
  takeLatest(
    actions.markAllNotificationsAsSeen.type,
    markAllNotificationsAsSeenEffectSaga
  ),
];

export type getNotificationResponse = {
  results: INotification[];
  total: number;
  page: number;
};

export function* getNotificationEffectSaga(
  action: NotificationGetAction
): SagaIterator<void> {
  try {
    const { results: notifications, total }: getNotificationResponse =
      yield call(getNotifications, action.payload);
    yield put(
      actions.notifications({
        notifications,
        notificationsTotal: total,
        page: action.payload.page,
      })
    );
    yield put(actions.getFirstRead());
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export type getNotSeenCountResponse = {
  notSeenCount: number;
};

export function* getNotSeenCountEffectSaga(): SagaIterator<void> {
  try {
    const response: getNotSeenCountResponse = yield call(getNotSeenCount);
    yield put(actions.notSeenCount(response));
    yield put(
      actions.updateStatusNotSeenCount({
        fetchStatusNotSeenCount: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusNotSeenCount({
        fetchStatusNotSeenCount: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* markNotificationAsSeenEffectSaga(
  action: NotificationMarkAsSeenAction
): SagaIterator<void> {
  try {
    yield call(markNotificationAsSeen, action.payload);
    yield put(
      actions.updateNotificationReadAsSeen({
        notificationId: action.payload.notificationId,
      })
    );
    yield put(actions.getNotSeenCount());
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
  }
}

export function* markAllNotificationsAsSeenEffectSaga(
  action: NotificationMarkAllAsSeenAction
): SagaIterator<void> {
  try {
    yield call(markAllNotificationsAsSeen);
    yield put(actions.getNotSeenCount());
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
  }
}
