import React from 'react';
import ReportFooter from 'src/components/modules/reportFooter';
import ReportHeader from 'src/components/modules/reportHeader';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { Container } from './styles';

const ReportLayout: React.FC = ({ children }) => {
  const isMobile = useIsMobile();

  return (
    <Container container direction="column">
      <ReportHeader />
      {children}
      {isMobile && <ReportFooter />}
    </Container>
  );
};

export default ReportLayout;
