import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const ListItem = styled(Grid)<{ isMobile: boolean }>`
  padding: 0.6rem 1rem;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? '5rem' : '4rem')};
  align-items: center;
  flex-direction: row;
  justify-content: space-around;

  :nth-child(even) {
    background-color: ${({ theme }) => theme.colors.table.primary};
  }
`;

export const EmailContainer = styled(Grid)<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '35%')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '1rem' : '0')};
  text-overflow: ellipsis;
  display: block;
  overflow: ${({ isMobile }) => (isMobile ? 'scroll' : 'hidden')};
`;

export const TrashContainer = styled(Grid)`
  cursor: pointer;
`;
