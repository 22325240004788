import { messaging } from '@sinagro/frontend-shared/firebase';
import { AuthenticatedEnum } from '@sinagro/frontend-shared/sharedTypes';
import React, { useCallback, useEffect } from 'react';

import GlobalLoader from '../components/modules/globalLoader';
import { Notification } from '../contexts/notification';
import { actions, useDispatch, useSelector } from '../state/store';
import { requestNotificationPermission } from '../utils/permissions';

export const NotificationProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const firebaseWebPushKey = process.env.REACT_APP_FIREBASE_WEB_PUSH_KEY;

  const userAuthenticated = useSelector(
    (state) => state.shared.user.userAuthenticated
  );
  const userId = useSelector((state) => state.shared.user.claims.id);
  const isNotificationSupported = messaging.isSupported();

  const createUserWebToken = useCallback(async () => {
    const notificationPermission = await requestNotificationPermission;
    if (userId && notificationPermission) {
      messaging()
        .getToken({ vapidKey: firebaseWebPushKey })
        .then((token) => {
          if (token) {
            dispatch(
              actions.shared.userProfile.createUserWebToken({
                token,
              })
            );
          }
        });
    }
  }, [dispatch, firebaseWebPushKey, userId]);

  const deleteUserWebToken = useCallback(async () => {
    const notificationPermission = await requestNotificationPermission;
    if (notificationPermission) {
      messaging()
        .getToken({ vapidKey: firebaseWebPushKey })
        .then((token) => {
          if (token) {
            dispatch(
              actions.shared.userProfile.deleteUserWebToken({
                token,
              })
            );
          }
        });
    }
  }, [dispatch, firebaseWebPushKey]);

  useEffect(() => {
    createUserWebToken();
  }, [createUserWebToken]);

  useEffect(() => {
    if (!isNotificationSupported) return;
    messaging().onMessage((_) => {
      /**
       * Do not fetch it right away to avoid break operations
       * that may need API token refresh, for example.
       */
      setTimeout(() => {
        dispatch(actions.shared.notification.getNotSeenCount());
      }, 5000);
    });
  }, [dispatch, isNotificationSupported]);

  return userAuthenticated !== AuthenticatedEnum.None ? (
    <Notification.Provider value={{ deleteUserWebToken, createUserWebToken }}>
      {children}
    </Notification.Provider>
  ) : (
    <GlobalLoader />
  );
};

export default NotificationProvider;
