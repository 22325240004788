import { createSlice } from '@reduxjs/toolkit';

import {
  FetchStatusEnum,
  QuotationSlice,
  QuotationUpdateErrorAction,
  QuotationUpdateStatusAction,
  SquareQuotationFetchAction,
  SquareQuotationGetAction,
  SquaresFetchAction,
} from '../../types';

export const initialState: QuotationSlice = {
  quotation: [],
  squares: [],
  fetchStatus: FetchStatusEnum.None,
  fetchStatusSquares: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    clear: () => initialState,
    getSquares: (state) => {
      state.fetchStatusSquares = FetchStatusEnum.Fetching;
    },
    squares: (state, action: SquaresFetchAction) => {
      const { squares } = action.payload;
      state.squares = squares;
    },
    getSquareQuotation: (state, _: SquareQuotationGetAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    squareQuotation: (state, action: SquareQuotationFetchAction) => {
      const { quotation } = action.payload;
      state.quotation = quotation;
    },
    updateStatusSquare: (state, action: QuotationUpdateStatusAction) => {
      state.fetchStatusSquares = action.payload.fetchStatus;
    },
    updateStatus: (state, action: QuotationUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: QuotationUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
