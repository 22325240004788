import { Switch } from '@material-ui/core';
import React from 'react';

import {
  OptionContainer,
  OptionDescription,
  OptionTitle,
  SwitchContainer,
} from './styles';

type DefaultSwitchProps = {
  title: string;
  description?: string;
  checked: boolean;
  context: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    context: string
  ) => void;
  marginBottom?: number;
};

const DefaultSwitch = ({
  title,
  description,
  checked,
  context,
  onChange,
  marginBottom = 16,
}: DefaultSwitchProps) => {
  return (
    <SwitchContainer container item marginBottom={marginBottom}>
      <Switch
        color="primary"
        checked={checked}
        onChange={(e) => onChange(e, context)}
      />
      <OptionContainer>
        <OptionTitle>{title}</OptionTitle>
        {description && <OptionDescription>{description}</OptionDescription>}
      </OptionContainer>
    </SwitchContainer>
  );
};

export default DefaultSwitch;
