import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const CodeContent = styled(Grid)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-items: center;
  justify-content: center;
`;

export const Code = styled.input`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderSecondary};
  font-size: ${({ theme }) => theme.fontSize.xxl}px;
  outline: 0;
  text-align: center;

  :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 700px) {
    width: 36px !important;
  }
`;

export const Text = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.lg}px;
  }
`;
