import { Grid } from '@material-ui/core';
import { FarmDetailsDTO, OptimalGrowthCurveStatus } from '@sinagro/shared';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'src/components/elements/modal';
import { useIsMobile } from 'src/hooks/responsiveLayout';
import GrowthCurveCaption from 'src/pages/torq/farm/farmSummary/growthCurveCaption';
import PlotList from 'src/pages/torq/plotList';
import { growthColor } from 'src/pages/torq/utils/growth';
import {
  GrowthCurveIcon,
  HarvestEstimateIcon,
  NDVIIcon,
} from 'src/pages/torq/utils/styles';
import { trendIcon } from 'src/pages/torq/utils/trend';
import { OptimalGrowthCurveStatusEnumTranslated } from 'src/utils/enumsTranslate';
import { formatMeasurementUnit } from 'src/utils/format';

import {
  Content,
  GrowthContainer,
  GrowthItem,
  Icon,
  Item,
  Value,
  Label,
} from './styles';

type FarmDetailsProps = {
  farmDetails: FarmDetailsDTO;
  filterPlotsByGrowthCurveStatus?: boolean;
};

const FarmDetails = ({
  farmDetails,
  filterPlotsByGrowthCurveStatus,
}: FarmDetailsProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [openPlotList, setOpenPlotList] = useState(false);
  const [selectedGrowthCurveStatus, setSelectedGrowthCurveStatus] =
    useState('');

  const optimals = Object.values(OptimalGrowthCurveStatus);

  const handleOpenPlotList = (growthCurveStatus: OptimalGrowthCurveStatus) => {
    if (!filterPlotsByGrowthCurveStatus) {
      return;
    }
    setSelectedGrowthCurveStatus(growthCurveStatus);
    setOpenPlotList(true);
  };

  return (
    <>
      <Content $isMobile={isMobile}>
        <Item>
          <Icon>
            <HarvestEstimateIcon />
          </Icon>
          <Grid>
            <Label>{t('torq.farm.summary.harvestEstimate')}</Label>
            <Value>
              {t('torq.farm.summary.total')}
              {formatMeasurementUnit(
                farmDetails.summary.harvestEstimate.total.value,
                farmDetails.summary.harvestEstimate.total.measurementUnit,
                1
              )}
            </Value>
            <Value>
              {t('torq.farm.summary.average')}
              {formatMeasurementUnit(
                farmDetails.summary.harvestEstimate.average.value,
                farmDetails.summary.harvestEstimate.average.measurementUnit,
                1
              )}
            </Value>
          </Grid>
        </Item>

        <Item>
          <Icon>
            <GrowthCurveIcon />
          </Icon>
          <Grid>
            <Label>
              {t('torq.farm.summary.growthCurve')}
              <GrowthCurveCaption />
            </Label>
            <Value>
              <GrowthContainer $isMobile={isMobile}>
                {optimals.map((optimal) => (
                  <React.Fragment key={optimal}>
                    {farmDetails.summary.growthCurve[optimal]?.length > 0 && (
                      <GrowthItem
                        $color={growthColor[optimal]}
                        onClick={() => handleOpenPlotList(optimal)}
                        style={{
                          cursor: filterPlotsByGrowthCurveStatus
                            ? 'pointer'
                            : 'text',
                        }}
                        data-testid={optimal}
                      >
                        {farmDetails.summary.growthCurve[optimal].length}{' '}
                        {OptimalGrowthCurveStatusEnumTranslated[optimal]}
                      </GrowthItem>
                    )}
                  </React.Fragment>
                ))}
              </GrowthContainer>
            </Value>
          </Grid>
        </Item>

        <Item>
          <Icon>
            <NDVIIcon />
          </Icon>
          <Grid>
            <Label>{t('torq.farm.summary.ndvi')}</Label>
            <Value>
              {formatMeasurementUnit(farmDetails.summary.ndvi.value, null, 2)}
              {trendIcon[farmDetails.summary.ndvi.trend]}
            </Value>
          </Grid>
        </Item>
      </Content>

      <Modal
        open={openPlotList}
        onClose={() => setOpenPlotList(false)}
        width={'380px'}
        content={
          <PlotList
            onClose={() => setOpenPlotList(false)}
            selectedGrowthCurveStatus={selectedGrowthCurveStatus}
          />
        }
      />
    </>
  );
};

export default FarmDetails;
