import React from 'react';

import PrivateRoutes from './privateRoutes';
import PublicRoutes from './publicRoutes';
import ValidationRoutes from './validationRoutes';

const Routes = () => {
  return (
    <>
      <PublicRoutes />
      <ValidationRoutes />
      <PrivateRoutes />
    </>
  );
};

export default Routes;
