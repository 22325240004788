import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const AvatarContainer = styled(Grid)`
  padding: 3rem 0 1rem 0;

  @media (max-height: 680px) {
    padding: 1.5rem 0 1rem 0;
  }
`;

export const Avatar = styled.div`
  width: 64px;
  height: 64px;
  padding-top: 8px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  outline: 2px solid white;
  background-color: ${({ theme }) => theme.colors.quaternary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Initials = styled(Typography)`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize.big}px !important;
  font-weight: ${({ theme }) => theme.fontWeight.semibold} !important;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
`;
