import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 8px 8px rgba(32, 40, 109, 0.1);
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const Date = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`;

export const Risk = styled(Typography)`
  && {
    margin-top: 0.5rem;
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`;
