import { createSlice } from '@reduxjs/toolkit';

import { MenuSlice, UpdateMenuAction } from './types';

const initialState: MenuSlice = {
  open: true,
};

export const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    clear: () => initialState,
    updateMenu: (state, action: UpdateMenuAction) => {
      state.open = action.payload.open;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
