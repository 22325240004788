import React from 'react';

import { MenuType, Menu } from '../contexts/menu';

type MenuProviderProps = {
  menuState: MenuType;
  children: React.ReactNode;
};

export const MenuProvider = ({
  menuState,
  children,
}: MenuProviderProps): JSX.Element => {
  return <Menu.Provider value={menuState}>{children}</Menu.Provider>;
};
