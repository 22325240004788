import { Grid, Link, Typography } from '@material-ui/core';
import { WhatsApp, EmailOutlined } from '@material-ui/icons';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import { ValidationCodeTypeEnum } from '@sinagro/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Loader from '../../components/elements/loader';
import { actions, useDispatch, useSelector } from '../../state/store';
import { Code, CodeContainer, ErrorLabel, ChangeLink } from './styles';

type ContentProps = {
  validationCode?: ValidationCodeTypeEnum;
  setActualData: (actualData: string) => void;
};

const Content = ({ validationCode, setActualData }: ContentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [code, setCode] = useState<string[]>([]);
  const [countdown, setCountdown] = useState(60);
  const [resend, setResend] = useState(false);

  const claims = useSelector((state) => state.shared.user.claims);

  useEffect(() => {
    countdown > 0
      ? setTimeout(() => setCountdown(countdown - 1), 1000)
      : setResend(true);
  }, [countdown]);

  const error = useSelector((state) => state.shared.validationCode.error);

  const fetchStatusCheckCode = useSelector(
    (state) => state.shared.validationCode.fetchStatus
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
      event.preventDefault();
      const value = event.target.value;
      if (value.match(/[0-9^$]+/g) || value === '') {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);
        if (value !== '') document.getElementById(`code-${index + 1}`)?.focus();

        if (newCode.join('').trim().length === 6) {
          dispatch(
            actions.shared.validationCode.check({
              code: newCode.join(''),
            })
          );
        }
      }
    },
    [code, dispatch]
  );

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
      if (event.key === 'Backspace' && index > 0) {
        if (code[5] === '' || index !== 5) {
          document.getElementById(`code-${index - 1}`)?.focus();
        }
        const newCode = [...code];
        newCode[index] = '';
        setCode(newCode);
      }
    },
    [code, setCode]
  );

  const handleOnPaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const data = event.clipboardData.getData('text');
      if (data.length === 6 && data.match(/[0-9^$]+/g)) {
        const newCode = data.split('');
        setCode(newCode);
        document.getElementById(`code-${6 - 1}`)?.focus();
        dispatch(
          actions.shared.validationCode.check({
            code: newCode.join(''),
          })
        );
      }
    },
    [dispatch, setCode]
  );

  const handleSendAgain = useCallback(() => {
    if (validationCode) {
      setCountdown(60);
      setResend(false);
      setCode([]);
      dispatch(actions.shared.validationCode.clear());
      dispatch(
        actions.shared.validationCode.request({
          type: validationCode,
        })
      );
    }
  }, [dispatch, validationCode]);

  const codeComponent = React.useMemo(
    () =>
      [...Array(6)].map((_, index) => {
        return (
          <Grid item key={`code-${index}`}>
            <Code
              id={`code-${index}`}
              maxLength={1}
              autoFocus={code.length === 6 ? index === 5 : index === 0}
              value={code[index] ?? ''}
              onChange={(e) => handleOnChange(e, index)}
              onKeyDown={(e) => handleOnKeyDown(e, index)}
              onPaste={(e) => handleOnPaste(e)}
            />
          </Grid>
        );
      }),
    [code, handleOnChange, handleOnKeyDown, handleOnPaste]
  );

  const handleNavigation = useCallback(
    (validationCode: ValidationCodeTypeEnum) => {
      setActualData(
        validationCode === ValidationCodeTypeEnum.Email
          ? claims.email
          : String(claims.phoneNumber)
      );

      setCode([]);
      dispatch(actions.shared.validationCode.clear());
      dispatch(
        actions.shared.validationCode.request({
          type: validationCode,
        })
      );

      navigate('/validation-code', {
        state: { validationCode: validationCode },
      });
    },
    [dispatch, navigate, setActualData, claims]
  );

  return (
    <>
      <CodeContainer
        container
        item
        direction="row"
        justifyContent="center"
        spacing={2}
        xs={12}
        style={{ flexWrap: 'nowrap' }}
      >
        {fetchStatusCheckCode === FetchStatusEnum.Fetching ? (
          <Loader size={32} />
        ) : (
          codeComponent
        )}
      </CodeContainer>

      <Grid item xs={12}>
        <ErrorLabel variant="body2" color="error">
          {error?.message}
        </ErrorLabel>
      </Grid>

      <Grid item xs={12}>
        {resend ? (
          <Link
            component="button"
            underline="always"
            onClick={handleSendAgain}
            style={{ fontSize: 14 }}
          >
            <>{t('validationCode.sendAgain')}</>
          </Link>
        ) : (
          <Typography variant="subtitle1" color="textPrimary">
            {t('validationCode.sendAgainIn')} {countdown}
            {t('validationCode.secondsAbbrev')}
          </Typography>
        )}
      </Grid>

      <Grid>
        {validationCode === ValidationCodeTypeEnum.Email ? (
          <ChangeLink
            onClick={() => handleNavigation(ValidationCodeTypeEnum.Phone)}
          >
            <WhatsApp style={{ marginRight: '0.3rem' }} />
            {t('validationCode.changeToWhatsapp')}
          </ChangeLink>
        ) : (
          <ChangeLink
            onClick={() => handleNavigation(ValidationCodeTypeEnum.Email)}
          >
            <EmailOutlined style={{ marginRight: '0.3rem' }} />
            {t('validationCode.changeToEmail')}
          </ChangeLink>
        )}
      </Grid>
    </>
  );
};

export default Content;
