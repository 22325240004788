import { Grid, Typography, Link } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import { ValidationCodeTypeEnum } from '@sinagro/shared';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import Logo from '../../components/elements/logo';
import Modal from '../../components/elements/modal';
import WelcomeLayout from '../../components/layout/welcomeLayout';
import { useDispatch, useSelector } from '../../state/store';
import { formatPhone } from '../../utils/format';
import Content from './content';
import Change from './editField';
import {
  LogoContainer,
  SubtitleContainer,
  TitleContainer,
  TitleLabel,
  ValueContainer,
} from './styles';

type LocationType = {
  validationCode?: ValidationCodeTypeEnum;
};

const Validation: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state as LocationType;
  const validationCode = state?.validationCode;

  const claims = useSelector((state) => state.shared.user.claims);
  const fetchCheckStatus = useSelector(
    (state) => state.shared.validationCode.fetchValidationCodeStatus
  );

  const [openChange, setOpenChange] = useState(false);
  const [actualData, setActualData] = useState(
    validationCode === ValidationCodeTypeEnum.Email
      ? claims.email
      : claims.phoneNumber
  );

  const onClose = () => {
    setOpenChange(false);
  };

  useEffect(() => {
    if (!validationCode) {
      navigate('/validation', { replace: true });
    }
  }, [dispatch, navigate, validationCode]);

  useEffect(() => {
    if (fetchCheckStatus === FetchStatusEnum.Success) {
      navigate('/validation-code-success');
    }
  }, [fetchCheckStatus, navigate]);

  return (
    <WelcomeLayout>
      <Grid
        container
        item
        xs={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid
          container
          item
          xs={12}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ flex: 1 }}
        >
          <LogoContainer item xs={12}>
            <Logo />
          </LogoContainer>
          <TitleContainer item xs={12}>
            <Typography variant="h1" color="textPrimary">
              {t('validationCode.title')}
            </Typography>
          </TitleContainer>
          <SubtitleContainer item xs={12}>
            <TitleLabel variant="h1" color="textPrimary">
              {validationCode === ValidationCodeTypeEnum.Email ? (
                <>
                  {t('validationCode.email')}
                  <ValueContainer>
                    <Link onClick={() => setOpenChange(true)}>
                      {actualData}
                      <Edit
                        style={{
                          marginLeft: 5,
                          paddingTop: 3,
                          fontSize: 20,
                        }}
                      />
                    </Link>
                  </ValueContainer>
                </>
              ) : (
                <>
                  {t('validationCode.phone')}
                  <ValueContainer>
                    <Link onClick={() => setOpenChange(true)}>
                      {formatPhone(String(actualData))}
                      <Edit style={{ marginLeft: 5, fontSize: 20 }} />
                    </Link>
                  </ValueContainer>
                </>
              )}
            </TitleLabel>
          </SubtitleContainer>
          <Content
            validationCode={validationCode}
            setActualData={setActualData}
          />
          <Modal
            open={openChange}
            onClose={onClose}
            content={
              <Change
                validationCode={validationCode}
                onClose={onClose}
                setActualData={setActualData}
              />
            }
            width="350px"
          />
        </Grid>
      </Grid>
    </WelcomeLayout>
  );
};

export default Validation;
