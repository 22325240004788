import { SagaIterator } from '@redux-saga/types';
import { IIntegrationConfig } from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getIntegrationConfig } from '../../services/integrationConfig';
import { FetchStatusEnum } from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getIntegrationConfig.type, getIntegrationConfigEffectSaga),
];

export function* getIntegrationConfigEffectSaga(): SagaIterator<void> {
  try {
    const integrationConfig: IIntegrationConfig[] = yield call(
      getIntegrationConfig
    );
    yield put(actions.integrationConfig({ integrations: integrationConfig }));
    yield put(
      actions.updateStatusIntegrationConfig({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusIntegrationConfig({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
