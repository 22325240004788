import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonContainer = styled(Grid)`
  width: 100%;
  padding-top: 2rem;
`;

export const LogoContainer = styled(Grid)`
  padding-top: 1rem;
`;

export const TitleContainer = styled(Grid)`
  padding: 2rem 0rem;
  text-align: center;
`;

export const FieldContainer = styled(Grid)`
  width: 100%;
  text-align: center;
  padding: 0.2rem 0;
`;

export const ErrorLabel = styled(Typography)`
  padding: 1rem 0;
  text-align: center;
`;
