import { FormControlLabel as FormControlLabelMUI } from '@material-ui/core';
import styled from 'styled-components';

export const FormControlLabel = styled(FormControlLabelMUI)`
  .MuiRadio-root {
    color: ${({ theme }) => theme.colors.textSecondary} !important;
  }

  .Mui-checked {
    color: ${({ theme }) => theme.colors.primary} !important;
  }

  .MuiTypography-body1 {
    color: ${({ theme }) => theme.colors.text} !important;
  }
`;

export const FormControlLabelBox = styled(FormControlLabelMUI)<{
  isSelected: boolean;
}>`
  padding: 0 1.5rem;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primaryLight : theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 0.5rem;
  display: flex;

  .MuiRadio-root {
    color: ${({ theme }) => theme.colors.textSecondary} !important;
    margin-left: -1.4rem !important;
  }

  .Mui-checked {
    color: ${({ theme }) => theme.colors.primary} !important;
  }

  .MuiTypography-body1 {
    color: ${({ theme }) => theme.colors.text} !important;
  }
`;
