import { Grid, Typography } from '@material-ui/core';
import {
  DefaultThemeColorsType,
  DefaultThemeFontSizeType,
  DefaultThemeFinancialOperationColorsType,
  DefaultThemeCreditPackageStatusColorsType,
} from '@sinagro/frontend-shared/themes';
import styled from 'styled-components';

type ColorType = DefaultThemeColorsType;
type FinancialColorType = DefaultThemeFinancialOperationColorsType;
type CreditPackageColor = DefaultThemeCreditPackageStatusColorsType;
type FontSizeType = DefaultThemeFontSizeType;

export const BadgeContainer = styled(Grid)<{
  color: ColorType;
  borderColor?: ColorType;
}>`
  background-color: ${({ theme, color }) => theme.colors[color]};
  height: 24px;
  padding: 0rem 0.5rem;
  border-radius: 12px !important;
  align-items: center;
  display: flex;
  border: ${({ theme, borderColor }) =>
    borderColor ? `1px solid ${theme.colors[borderColor]}` : 'none'};

  svg {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const BadgeContainerCreditPackage = styled(Grid)<{
  color: CreditPackageColor;
}>`
  display: flex;
  background-color: ${({ theme, color }) =>
    theme.colors.creditPackageStatus[color]};
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  min-width: 84px;
  font-size: 10px !important;
  svg {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const BadgeContainerTable = styled(Grid)<{ color: ColorType }>`
  background-color: ${({ theme, color }) => theme.colors[color]};
  height: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  min-width: 84px;
  display: flex;

  svg {
    margin-right: 3px !important;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const BadgeContainerFinancial = styled(Grid)<{
  color: FinancialColorType;
}>`
  background-color: ${({ theme, color }) =>
    theme.colors.financialOperation[color]};
  height: 24px;
  padding: 0rem 0.5rem;
  border-radius: 12px !important;
  align-items: center;
  display: flex;

  svg {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const BadgeLabel = styled(Typography)<{
  fontSize: FontSizeType;
}>`
  font-size: ${({ theme, fontSize }) => theme.fontSize[fontSize]}px !important;
  text-align: center;
`;
