import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(Link)`
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  text-decoration: none;
  margin-bottom: 1rem;

  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(1.3);
  }
`;

export const Content = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Text = styled(Typography)<{ $small?: boolean }>`
  && {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme, $small }) =>
      $small ? theme.fontSize.md : theme.fontSize.lg}px;
    margin-top: ${({ $small }) => ($small ? 4 : 0)}px;
  }
`;
