import { SagaIterator } from '@redux-saga/types';
import { IUserInvite } from '@sinagro/shared';
import { SharedError } from '@sinagro/shared/errors';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  createUserInvite,
  deleteUserInvite,
  getUserInvites,
} from '../../services/userInvite';
import {
  CreateUserInviteAction,
  DeleteUserInviteAction,
  FetchStatusEnum,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getUserInvite.type, getUserInvitesEffectSaga),
  takeLatest(actions.createUserInvite.type, createUserInviteEffectSaga),
  takeLatest(actions.deleteUserInvite.type, deleteUserInviteEffectSaga),
];

export type getUserInvitesResponse = IUserInvite[];

export function* getUserInvitesEffectSaga(): SagaIterator<void> {
  try {
    const userInvites: getUserInvitesResponse = yield call(getUserInvites);
    yield put(actions.userInvites({ userInvite: userInvites }));
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* createUserInviteEffectSaga(
  action: CreateUserInviteAction
): SagaIterator<void> {
  try {
    yield call(createUserInvite, action.payload);
    yield put(
      actions.updateStatusCreateUserInvite({
        fetchStatusCreateUserInvite: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(
        actions.updateErrorCreateUserInvite({
          errorCreateUserInvite: knownError,
        })
      );
    }

    yield put(
      actions.updateStatusCreateUserInvite({
        fetchStatusCreateUserInvite: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* deleteUserInviteEffectSaga(
  action: DeleteUserInviteAction
): SagaIterator<void> {
  try {
    yield call(deleteUserInvite, action.payload);
    yield call(getUserInvitesEffectSaga);
    yield put(
      actions.updateStatusDeleteUserInvite({
        fetchStatusDeleteUserInvite: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusDeleteUserInvite({
        fetchStatusDeleteUserInvite: FetchStatusEnum.Failure,
      })
    );
  }
}
