import { SquareQuotationGet } from '../../types';
import api from '../api';

export function getSquares() {
  return api.get(`/filter/quotation/square/available`);
}

export function getSquareQuotation(payload: SquareQuotationGet) {
  return api.get(`/quotation/square/${payload.squareId}`);
}

export default {
  getSquares,
  getSquareQuotation,
};
