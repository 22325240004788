import {
  BondPaymentStatusEnum,
  ContractPaymentStatusEnum,
  ContractTypeEnum,
  CreditPackageStatusEnum,
  DuplicateStatusEnum,
  GroupedRemittanceSegmentEnum,
  NotificationContextEnum,
  OptimalGrowthCurveStatus,
  OrderItemStatusEnum,
  OrderPaymentConditionEnum,
  PayableBondOperationTypeEnum,
  PayableBondPaymentStatusEnum,
  PaymentConditionEnum,
  PaymentMethodEnum,
  PaymentStatusEnum,
  ReceivableBondOperationTypeEnum,
  SegmentEnum,
  SprayingRecommendationEnum,
  UserInviteStatusEnum,
  ValidationCodeTypeEnum,
  WarrantyEnum,
  WeatherConditionEnum,
} from '@sinagro/shared';
import i18n from 'i18next';

export const SegmentEnumTranslated: Record<SegmentEnum | string, string> = {
  [SegmentEnum.Defensive]: i18n.t('enums.segmentEnum.defensive'),
  [SegmentEnum.Fertilizer]: i18n.t('enums.segmentEnum.fertilizer'),
  [SegmentEnum.Seed]: i18n.t('enums.segmentEnum.seed'),
  [SegmentEnum.Specialty]: i18n.t('enums.segmentEnum.specialty'),
  [SegmentEnum.AnimalNutrition]: i18n.t('enums.segmentEnum.animal_nutrition'),
  [SegmentEnum.Grains]: i18n.t('enums.segmentEnum.grains'),
  [SegmentEnum.Unknow]: i18n.t('enums.segmentEnum.unknow'),
};

export const OrderItemStatusEnumTranslated: Record<
  OrderItemStatusEnum | string,
  string
> = {
  [OrderItemStatusEnum.Finished]: i18n.t('enums.orderItemStatusEnum.finished'),
  [OrderItemStatusEnum.Open]: i18n.t('enums.orderItemStatusEnum.open'),
  [OrderItemStatusEnum.Partial]: i18n.t('enums.orderItemStatusEnum.partial'),
  [OrderItemStatusEnum.Canceled]: i18n.t('enums.orderItemStatusEnum.canceled'),
  [OrderItemStatusEnum.AwaitingApproval]: i18n.t(
    'enums.orderItemStatusEnum.awaiting_approval'
  ),
  [OrderItemStatusEnum.Unknow]: i18n.t('enums.orderItemStatusEnum.unknow'),
};

export const OrderPaymentConditionEnumTranslated: Record<
  OrderPaymentConditionEnum,
  string
> = {
  [OrderPaymentConditionEnum.Barter]: i18n.t(
    'enums.orderPaymentConditionEnum.barter'
  ),
  [OrderPaymentConditionEnum.Installment]: i18n.t(
    'enums.orderPaymentConditionEnum.installment'
  ),
  [OrderPaymentConditionEnum.Exchange]: i18n.t(
    'enums.orderPaymentConditionEnum.exchange'
  ),
  [OrderPaymentConditionEnum.InCash]: i18n.t(
    'enums.orderPaymentConditionEnum.inCash'
  ),
  [OrderPaymentConditionEnum.NoPayment]: i18n.t(
    'enums.orderPaymentConditionEnum.noPayment'
  ),
  [OrderPaymentConditionEnum.FixedDate]: i18n.t(
    'enums.orderPaymentConditionEnum.fixedDate'
  ),
  [OrderPaymentConditionEnum.Unknow]: i18n.t(
    'enums.orderPaymentConditionEnum.unknow'
  ),
};

export const ContractTypeEnumTranslated: Record<ContractTypeEnum, string> = {
  [ContractTypeEnum.Barter]: i18n.t('enums.contractTypeEnum.barter'),
  [ContractTypeEnum.Purchase]: i18n.t('enums.contractTypeEnum.purchase'),
  [ContractTypeEnum.ToBeSet]: i18n.t('enums.contractTypeEnum.toBeSet'),
};

export const PaymentMethodEnumTranslated: Record<PaymentMethodEnum, string> = {
  [PaymentMethodEnum.BankPaymentSlip]: i18n.t(
    'enums.paymentMethodEnum.bank_payment_slip'
  ),
  [PaymentMethodEnum.Other]: i18n.t('enums.paymentMethodEnum.other'),
  [PaymentMethodEnum.Transfer]: i18n.t('enums.paymentMethodEnum.transfer'),
};

export const BondPaymentStatusEnumTranslated: Record<
  BondPaymentStatusEnum,
  string
> = {
  [BondPaymentStatusEnum.Paid]: i18n.t('enums.bondPaymentStatusEnum.paid'),
  [BondPaymentStatusEnum.Expired]: i18n.t(
    'enums.bondPaymentStatusEnum.expired'
  ),
  [BondPaymentStatusEnum.Future]: i18n.t('enums.bondPaymentStatusEnum.future'),
};

export const ContractPaymentStatusEnumTranslated: Record<
  ContractPaymentStatusEnum,
  string
> = {
  [ContractPaymentStatusEnum.Paid]: i18n.t(
    'enums.receivableStatusSelectorFilterEnum.paid'
  ),
  [ContractPaymentStatusEnum.Partial]: i18n.t(
    'enums.receivableStatusSelectorFilterEnum.partial'
  ),
  [ContractPaymentStatusEnum.Open]: i18n.t(
    'enums.receivableStatusSelectorFilterEnum.open'
  ),
};

export const CreditPackageDocumentStatusEnumTranslated: Record<
  CreditPackageStatusEnum,
  string
> = {
  [CreditPackageStatusEnum.Pending]: i18n.t(
    'enums.creditPackageDocumentStatusEnum.pending'
  ),
  [CreditPackageStatusEnum.Approved]: i18n.t(
    'enums.creditPackageDocumentStatusEnum.approved'
  ),
  [CreditPackageStatusEnum.Rejected]: i18n.t(
    'enums.creditPackageDocumentStatusEnum.rejected'
  ),
  [CreditPackageStatusEnum.UnderReview]: i18n.t(
    'enums.creditPackageDocumentStatusEnum.underReview'
  ),
};

export const CreditPackageDocumentStatusPortalEnumTranslated: Record<
  CreditPackageStatusEnum,
  string
> = {
  [CreditPackageStatusEnum.Pending]: i18n.t(
    'enums.creditPackageDocumentStatusPortalEnum.pending'
  ),
  [CreditPackageStatusEnum.Approved]: i18n.t(
    'enums.creditPackageDocumentStatusPortalEnum.approved'
  ),
  [CreditPackageStatusEnum.Rejected]: i18n.t(
    'enums.creditPackageDocumentStatusPortalEnum.rejected'
  ),
  [CreditPackageStatusEnum.UnderReview]: i18n.t(
    'enums.creditPackageDocumentStatusPortalEnum.underReview'
  ),
};

export const CreditPackageDocumentStatusPortalMobileEnumTranslated: Record<
  CreditPackageStatusEnum,
  string
> = {
  [CreditPackageStatusEnum.Pending]: i18n.t(
    'enums.creditPackageDocumentStatusPortalMobileEnum.pending'
  ),
  [CreditPackageStatusEnum.Approved]: i18n.t(
    'enums.creditPackageDocumentStatusPortalMobileEnum.approved'
  ),
  [CreditPackageStatusEnum.Rejected]: i18n.t(
    'enums.creditPackageDocumentStatusPortalMobileEnum.rejected'
  ),
  [CreditPackageStatusEnum.UnderReview]: i18n.t(
    'enums.creditPackageDocumentStatusPortalMobileEnum.underReview'
  ),
};

export const PaymentStatusEnumTranslated: Record<PaymentStatusEnum, string> = {
  [PaymentStatusEnum.Conciliated]: i18n.t(
    'enums.openCreditsStatusSelectorFilterEnum.conciliated'
  ),
  [PaymentStatusEnum.Partial]: i18n.t(
    'enums.openCreditsStatusSelectorFilterEnum.partial'
  ),
  [PaymentStatusEnum.Open]: i18n.t(
    'enums.openCreditsStatusSelectorFilterEnum.open'
  ),
};

export const CreditWarrantyEnumTranslated: Record<WarrantyEnum, string> = {
  [WarrantyEnum.GrainFiduciaryAlienation]: i18n.t(
    'enums.creditWarrantyEnum.grainFiduciaryAlienation'
  ),
  [WarrantyEnum.PropertyFiduciaryAlienation]: i18n.t(
    'enums.creditWarrantyEnum.propertyFiduciaryAlienation'
  ),
  [WarrantyEnum.DebtAdmission]: i18n.t(
    'enums.creditWarrantyEnum.debtAdmission'
  ),
  [WarrantyEnum.CPR]: i18n.t('enums.creditWarrantyEnum.CPR'),
  [WarrantyEnum.Duplicate]: i18n.t('enums.creditWarrantyEnum.duplicate'),
  [WarrantyEnum.Mortgage]: i18n.t('enums.creditWarrantyEnum.mortgage'),
  [WarrantyEnum.PromissoryNote]: i18n.t(
    'enums.creditWarrantyEnum.promissoryNote'
  ),
  [WarrantyEnum.Exchange]: i18n.t('enums.creditWarrantyEnum.exchange'),
};

export const PayableBondOperationTypeEnumTranslated: Record<
  PayableBondOperationTypeEnum | string,
  string
> = {
  [PayableBondOperationTypeEnum.Advance]: i18n.t(
    'enums.payableBondOperationTypeEnum.advance'
  ),
  [PayableBondOperationTypeEnum.Payment]: i18n.t(
    'enums.payableBondOperationTypeEnum.payment'
  ),
  [PayableBondOperationTypeEnum.Purchase]: i18n.t(
    'enums.payableBondOperationTypeEnum.purchase'
  ),
  [PayableBondOperationTypeEnum.Residual]: i18n.t(
    'enums.payableBondOperationTypeEnum.residual'
  ),
  [PayableBondOperationTypeEnum.Unknown]: i18n.t(
    'enums.payableBondOperationTypeEnum.unknown'
  ),
};

export const PayableBondPaymentStatusEnumTranslated: Record<
  PayableBondPaymentStatusEnum | string,
  string
> = {
  [PayableBondPaymentStatusEnum.Future]: i18n.t(
    'enums.payableBondPaymentStatusEnum.future'
  ),
  [PayableBondPaymentStatusEnum.Expired]: i18n.t(
    'enums.payableBondPaymentStatusEnum.expired'
  ),
  [PayableBondPaymentStatusEnum.Paid]: i18n.t(
    'enums.payableBondPaymentStatusEnum.paid'
  ),
  [PayableBondPaymentStatusEnum.Unknown]: i18n.t(
    'enums.payableBondPaymentStatusEnum.unknown'
  ),
};

export const UserInviteStatusEnumTranslated: Record<
  UserInviteStatusEnum | string,
  string
> = {
  [UserInviteStatusEnum.Accepted]: i18n.t(
    'enums.userInviteStatusEnum.accepted'
  ),
  [UserInviteStatusEnum.Rejected]: i18n.t(
    'enums.userInviteStatusEnum.rejected'
  ),
  [UserInviteStatusEnum.Sent]: i18n.t('enums.userInviteStatusEnum.sent'),
};

export const ReceivableBondOperationTypeEnumTranslated: Record<
  ReceivableBondOperationTypeEnum | string,
  string
> = {
  [ReceivableBondOperationTypeEnum.Devolution]: i18n.t(
    'enums.receivableBondOperationTypeEnum.devolution'
  ),
  [ReceivableBondOperationTypeEnum.Receivable]: i18n.t(
    'enums.receivableBondOperationTypeEnum.receivable'
  ),
  [ReceivableBondOperationTypeEnum.Received]: i18n.t(
    'enums.receivableBondOperationTypeEnum.received'
  ),
};

export const ValidationCodeTypeEnumTranslated: Record<
  ValidationCodeTypeEnum,
  string
> = {
  [ValidationCodeTypeEnum.Email]: i18n.t('enums.validationCodeTypeEnum.email'),
  [ValidationCodeTypeEnum.Phone]: i18n.t('enums.validationCodeTypeEnum.phone'),
};

export const GroupedRemittanceSegmentEnumTranslated: Record<
  GroupedRemittanceSegmentEnum,
  string
> = {
  [GroupedRemittanceSegmentEnum.DefensivesAndSpecialties]: i18n.t(
    'enums.groupedRemittanceSegmentEnum.defensivesAndSpecialties'
  ),
  [GroupedRemittanceSegmentEnum.Fertilizers]: i18n.t(
    'enums.groupedRemittanceSegmentEnum.fertilizers'
  ),
  [GroupedRemittanceSegmentEnum.Seeds]: i18n.t(
    'enums.groupedRemittanceSegmentEnum.seeds'
  ),
  [GroupedRemittanceSegmentEnum.Others]: i18n.t(
    'enums.groupedRemittanceSegmentEnum.others'
  ),
};

export const WeatherConditionEnumTranslated: Record<
  WeatherConditionEnum,
  string
> = {
  [WeatherConditionEnum.Blizzard]: i18n.t(
    'enums.weatherConditionEnum.blizzard'
  ),
  [WeatherConditionEnum.Breezy]: i18n.t('enums.weatherConditionEnum.breezy'),
  [WeatherConditionEnum.Clear]: i18n.t('enums.weatherConditionEnum.clear'),
  [WeatherConditionEnum.Cloudy]: i18n.t('enums.weatherConditionEnum.cloudy'),
  [WeatherConditionEnum.DriftingSnow]: i18n.t(
    'enums.weatherConditionEnum.driftingSnow'
  ),
  [WeatherConditionEnum.Drizzle]: i18n.t('enums.weatherConditionEnum.drizzle'),
  [WeatherConditionEnum.Flurries]: i18n.t(
    'enums.weatherConditionEnum.flurries'
  ),
  [WeatherConditionEnum.Foggy]: i18n.t('enums.weatherConditionEnum.foggy'),
  [WeatherConditionEnum.FreezingDrizzle]: i18n.t(
    'enums.weatherConditionEnum.freezingDrizzle'
  ),
  [WeatherConditionEnum.FreezingRain]: i18n.t(
    'enums.weatherConditionEnum.freezingRain'
  ),
  [WeatherConditionEnum.Hail]: i18n.t('enums.weatherConditionEnum.hail'),
  [WeatherConditionEnum.Haze]: i18n.t('enums.weatherConditionEnum.haze'),
  [WeatherConditionEnum.HeavyRain]: i18n.t(
    'enums.weatherConditionEnum.heavyRain'
  ),
  [WeatherConditionEnum.HeavySnow]: i18n.t(
    'enums.weatherConditionEnum.heavySnow'
  ),
  [WeatherConditionEnum.Hot]: i18n.t('enums.weatherConditionEnum.hot'),
  [WeatherConditionEnum.Hurricane]: i18n.t(
    'enums.weatherConditionEnum.hurricane'
  ),
  [WeatherConditionEnum.IceCrystals]: i18n.t(
    'enums.weatherConditionEnum.iceCrystals'
  ),
  [WeatherConditionEnum.IsolatedThunderstorms]: i18n.t(
    'enums.weatherConditionEnum.isolatedThunderstorms'
  ),
  [WeatherConditionEnum.MixedRainAndHail]: i18n.t(
    'enums.weatherConditionEnum.mixedRainAndHail'
  ),
  [WeatherConditionEnum.MostlyClear]: i18n.t(
    'enums.weatherConditionEnum.mostlyClear'
  ),
  [WeatherConditionEnum.MostlyCloudy]: i18n.t(
    'enums.weatherConditionEnum.mostlyCloudy'
  ),
  [WeatherConditionEnum.MostlySunny]: i18n.t(
    'enums.weatherConditionEnum.mostlySunny'
  ),
  [WeatherConditionEnum.NotAvailable]: i18n.t(
    'enums.weatherConditionEnum.notAvailable'
  ),
  [WeatherConditionEnum.PartlyCloudy]: i18n.t(
    'enums.weatherConditionEnum.partlyCloudy'
  ),
  [WeatherConditionEnum.Rain]: i18n.t('enums.weatherConditionEnum.rain'),
  [WeatherConditionEnum.RainSleet]: i18n.t(
    'enums.weatherConditionEnum.rainSleet'
  ),
  [WeatherConditionEnum.RainSnow]: i18n.t(
    'enums.weatherConditionEnum.rainSnow'
  ),
  [WeatherConditionEnum.Sandstorm]: i18n.t(
    'enums.weatherConditionEnum.sandstorm'
  ),
  [WeatherConditionEnum.ScatteredShowers]: i18n.t(
    'enums.weatherConditionEnum.scatteredShowers'
  ),
  [WeatherConditionEnum.ScatteredSnowShowers]: i18n.t(
    'enums.weatherConditionEnum.scatteredSnowShowers'
  ),
  [WeatherConditionEnum.ScatteredThunderstorms]: i18n.t(
    'enums.weatherConditionEnum.scatteredThunderstorms'
  ),
  [WeatherConditionEnum.Showers]: i18n.t('enums.weatherConditionEnum.showers'),
  [WeatherConditionEnum.Sleet]: i18n.t('enums.weatherConditionEnum.sleet'),
  [WeatherConditionEnum.Smoke]: i18n.t('enums.weatherConditionEnum.smoke'),
  [WeatherConditionEnum.Snow]: i18n.t('enums.weatherConditionEnum.snow'),
  [WeatherConditionEnum.SnowShowers]: i18n.t(
    'enums.weatherConditionEnum.snowShowers'
  ),
  [WeatherConditionEnum.StrongStorms]: i18n.t(
    'enums.weatherConditionEnum.strongStorms'
  ),
  [WeatherConditionEnum.Sunny]: i18n.t('enums.weatherConditionEnum.sunny'),
  [WeatherConditionEnum.Thunderstorms]: i18n.t(
    'enums.weatherConditionEnum.thunderstorms'
  ),
  [WeatherConditionEnum.Tornado]: i18n.t('enums.weatherConditionEnum.tornado'),
  [WeatherConditionEnum.TropicalStorm]: i18n.t(
    'enums.weatherConditionEnum.tropicalStorm'
  ),
  [WeatherConditionEnum.Windy]: i18n.t('enums.weatherConditionEnum.windy'),
  [WeatherConditionEnum.WintryMix]: i18n.t(
    'enums.weatherConditionEnum.wintryMix'
  ),
};

export const SprayingRecommendationEnumTranslated: Record<
  SprayingRecommendationEnum,
  string
> = {
  [SprayingRecommendationEnum.Recommended]: i18n.t(
    'enums.sprayingRecommendationEnum.recommended'
  ),
  [SprayingRecommendationEnum.RecommendedAgainst]: i18n.t(
    'enums.sprayingRecommendationEnum.recommendedAgainst'
  ),
  [SprayingRecommendationEnum.NotRecommended]: i18n.t(
    'enums.sprayingRecommendationEnum.notRecommended'
  ),
};

export const OptimalGrowthCurveStatusEnumTranslated: Record<
  OptimalGrowthCurveStatus,
  string
> = {
  [OptimalGrowthCurveStatus.AboveOptimalBand]: i18n.t(
    'enums.optimalGrowthCurveStatusEnum.aboveOptimalBand'
  ),
  [OptimalGrowthCurveStatus.BelowOptimalBand]: i18n.t(
    'enums.optimalGrowthCurveStatusEnum.belowOptimalBand'
  ),
  [OptimalGrowthCurveStatus.BeyondOptimalBand]: i18n.t(
    'enums.optimalGrowthCurveStatusEnum.beyondOptimalBand'
  ),
  [OptimalGrowthCurveStatus.NewPlot]: i18n.t(
    'enums.optimalGrowthCurveStatusEnum.newPlot'
  ),
  [OptimalGrowthCurveStatus.NoBandForCrop]: i18n.t(
    'enums.optimalGrowthCurveStatusEnum.noBandForCrop'
  ),
  [OptimalGrowthCurveStatus.WaitingHarvestClosure]: i18n.t(
    'enums.optimalGrowthCurveStatusEnum.waitingHarvestClosure'
  ),
  [OptimalGrowthCurveStatus.WaitingNewHarvest]: i18n.t(
    'enums.optimalGrowthCurveStatusEnum.waitingNewHarvest'
  ),
  [OptimalGrowthCurveStatus.WithinOptimalBand]: i18n.t(
    'enums.optimalGrowthCurveStatusEnum.withinOptimalBand'
  ),
};

export const TradeContractTypeEnumTranslated: Record<ContractTypeEnum, string> =
  {
    [ContractTypeEnum.Barter]: i18n.t('enums.tradeContractTypeEnum.barter'),
    [ContractTypeEnum.Purchase]: i18n.t('enums.tradeContractTypeEnum.purchase'),
    [ContractTypeEnum.ToBeSet]: i18n.t('enums.tradeContractTypeEnum.toBeSet'),
  };

export const PaymentConditionEnumTranslated: Record<
  PaymentConditionEnum,
  string
> = {
  [PaymentConditionEnum.Barter]: i18n.t('enums.paymentConditionEnum.barter'),
  [PaymentConditionEnum.InCash]: i18n.t('enums.paymentConditionEnum.inCash'),
  [PaymentConditionEnum.InTerm]: i18n.t('enums.paymentConditionEnum.inTerm'),
  [PaymentConditionEnum.Other]: i18n.t('enums.paymentConditionEnum.other'),
  [PaymentConditionEnum.Partial]: i18n.t('enums.paymentConditionEnum.partial'),
};

export const NotificationContextEnumTranslated: Record<
  NotificationContextEnum | 'default',
  string
> = {
  [NotificationContextEnum.Contract]: i18n.t(
    'enums.notificationContextEnum.contract'
  ),
  [NotificationContextEnum.General]: i18n.t(
    'enums.notificationContextEnum.general'
  ),
  [NotificationContextEnum.Hub]: i18n.t('enums.notificationContextEnum.hub'),
  [NotificationContextEnum.HubObservation]: i18n.t(
    'enums.notificationContextEnum.hubObservation'
  ),
  [NotificationContextEnum.Order]: i18n.t(
    'enums.notificationContextEnum.order'
  ),
  [NotificationContextEnum.Payables]: i18n.t(
    'enums.notificationContextEnum.payables'
  ),
  [NotificationContextEnum.Tracking]: i18n.t(
    'enums.notificationContextEnum.tracking'
  ),
  [NotificationContextEnum.UserInvite]: i18n.t(
    'enums.notificationContextEnum.userInvite'
  ),
  [NotificationContextEnum.HubAlert]: i18n.t(
    'enums.notificationContextEnum.hubAlert'
  ),
  [NotificationContextEnum.HubFarmHealth]: i18n.t(
    'enums.notificationContextEnum.hubFarmHealth'
  ),
  default: i18n.t('enums.notificationContextEnum.default'),
};

export const DuplicateStatusEnumTranslated: Record<
  DuplicateStatusEnum | string,
  string
> = {
  [DuplicateStatusEnum.PendingSignature]: i18n.t(
    'enums.duplicateStatusEnum.pendingSignature'
  ),
  [DuplicateStatusEnum.AwaitingSignature]: i18n.t(
    'enums.duplicateStatusEnum.awaitingSignature'
  ),
  [DuplicateStatusEnum.Signed]: i18n.t('enums.duplicateStatusEnum.signed'),
  [DuplicateStatusEnum.ReviewRequested]: i18n.t(
    'enums.duplicateStatusEnum.reviewRequested'
  ),
  [DuplicateStatusEnum.Canceled]: i18n.t('enums.duplicateStatusEnum.canceled'),
};
