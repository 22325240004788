import { Backdrop, Fade, Modal as ModalMUI } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import React from 'react';

import { CloseButton, Container } from './styles';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  content: React.ReactElement;
  width?: string;
  hideCloseButton?: boolean;
};

const Modal = ({
  open,
  onClose,
  content,
  width = '500px',
  hideCloseButton,
}: ModalProps) => {
  return (
    <ModalMUI
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} timeout={500}>
        <Container style={{ width: width }}>
          {!hideCloseButton && (
            <CloseButton onClick={onClose}>
              <Cancel fontSize="small" />
            </CloseButton>
          )}
          {content}
        </Container>
      </Fade>
    </ModalMUI>
  );
};

export default Modal;
