import { Grid } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

const fadeAnimation = keyframes`
  0% {
    opacity:1;
  }
  17% {
    opacity:1;
  }
  25% {
    opacity:0;
  }
  92% {
    opacity:0;
  }

`;

export const WelcomeContainer = styled(Grid)`
  min-height: 100vh;
`;

export const ContentContainer = styled(Grid)`
  min-height: 100vh;
`;

export const ImagesBack = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Image = styled.img`
  position: absolute;
  left: 0;
  object-fit: cover;
  aspect-ratio: 3;

  :nth-of-type(1) {
    animation-name: ${fadeAnimation};
    animation-duration: 40s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 30s;
  }
  :nth-of-type(2) {
    animation-name: ${fadeAnimation};
    animation-duration: 40s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 20s;
  }
  :nth-of-type(3) {
    animation-name: ${fadeAnimation};
    animation-duration: 40s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 10s;
  }
  :nth-of-type(4) {
    animation-name: ${fadeAnimation};
    animation-duration: 40s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 0;
  }
`;
