import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
html, body, #root {
  min-height: 100%;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

body {
  background: ${({ theme }) => theme.colors.background};
  font-family: ${({ theme }) => theme.fontFamily};
}

/**
* Google Autocomplete List Style
*/

.pac-container {
  background: ${({ theme }) => theme.colors.background};
  border: 0;
  box-shadow: none;
  margin-top: 1px;
}

.pac-item{
  padding: 8px 12px;
  border: 0px;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fontFamily};
}

.pac-item:hover{
  background-color: ${({ theme }) => theme.colors.primaryLight}70;
}

.pac-item:last-child{
  margin-bottom: 16px;
}

.pac-item-query {
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fontFamily};
}

.pac-matched {
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fontFamily};
}

`;

export default GlobalStyle;
