import { useTranslation } from 'react-i18next';
import {
  atLeastOneLowercase,
  atLeastOneNumber,
  atLeastOneSymbol,
  atLeastOneUppercase,
  notAcceptSpaces,
} from 'src/utils/regexes';
import * as yup from 'yup';

export const useProfileSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    name: yup.string().required(t('form.required')),
  });
};

export const usePasswordSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    oldPassword: yup.string().required(t('form.required')),
    newPassword: yup
      .string()
      .required(t('form.required'))
      .notOneOf([yup.ref('oldPassword'), null], t('form.cantBeTheSamePassword'))
      .min(
        8,
        t('form.min', { field: t('profile.passwordForm.newPassword'), min: 8 })
      )
      .matches(atLeastOneLowercase, t('form.atLeastOneLowercase'))
      .matches(atLeastOneUppercase, t('form.atLeastOneUppercase'))
      .matches(atLeastOneNumber, t('form.atLeastOneNumber'))
      .matches(atLeastOneSymbol, t('form.atLeastOneSymbol'))
      .matches(notAcceptSpaces, t('form.notAcceptSpaces')),
    newPasswordConfirmation: yup
      .string()
      .required(t('form.required'))
      .oneOf([yup.ref('newPassword'), null], t('form.confirmPassword')),
  });
};
