import { analytics } from '@sinagro/frontend-shared/firebase';
import { AuthenticatedEnum } from '@sinagro/frontend-shared/sharedTypes';
import React, { createContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import GlobalLoader from '../components/modules/globalLoader';
import { actions, useDispatch, useSelector } from '../state/store';

export const AuthContext = createContext({});

export const AuthProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userAuthenticated = useSelector(
    (state) => state.shared.user.userAuthenticated
  );
  const userId = useSelector(
    (state) => state.shared.userProfile.userProfile.id
  );

  useEffect(() => {
    dispatch(actions.auth.onAuthStateChanged);
    dispatch(actions.auth.onIdTokenChanged);
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      analytics().setUserId(userId);

      dispatch(actions.shared.harvestReference.getHarvestReferences());
      dispatch(actions.shared.harvestReference.getFullHarvestReferences());
      dispatch(actions.shared.integrationConfig.getIntegrationConfig());
      dispatch(actions.shared.featureFlag.getFeatureFlag());
      dispatch(actions.shared.systemConfig.getSystemConfig());
      dispatch(actions.shared.creditPackage.getCreditPackage());
    }
  }, [userId, dispatch]);

  useEffect(() => {
    analytics().logEvent('page_view', {
      page_path: location.pathname,
    });
  }, [location.pathname]);

  return userAuthenticated !== AuthenticatedEnum.None ? (
    <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>
  ) : (
    <GlobalLoader />
  );
};

export default AuthProvider;
