import { Grid, Typography } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import { IUserInvite } from '@sinagro/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Button from '../../../../components/elements/materialUI/button';
import { actions, useDispatch, useSelector } from '../../../../state/store';
import { Description, Title } from './styles';

type Item = {
  userInvite: IUserInvite;
  onClose: () => void;
};

const DeleteInvite = ({ userInvite, onClose }: Item) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const fetchStatus = useSelector(
    (state) => state.shared.userInvite.fetchStatusUpdateUserInvite
  );
  const loading = fetchStatus === FetchStatusEnum.Fetching;

  const onDelete = () => {
    const sanitizedForm = {
      inviteId: userInvite.id,
    };
    dispatch(actions.shared.userInvite.deleteUserInvite(sanitizedForm));
    onClose();
  };

  return (
    <Grid
      container
      item
      justifyContent="center"
      style={{ padding: '0.75rem 1rem 0 1rem' }}
    >
      <Title>{t('userManagement.deleteInvite.title')}</Title>
      <Description>{t('userManagement.deleteInvite.description')}</Description>
      <Button
        variant="contained"
        color="primary"
        style={{
          width: '10rem',
          marginTop: '2rem',
          marginRight: '1rem',
        }}
        onClick={onClose}
      >
        <Typography variant="h6">
          {t('userManagement.deleteInvite.cancel')}
        </Typography>
      </Button>

      <Button
        variant="contained"
        loading={loading}
        color="primary"
        style={{
          width: '10rem',
          marginTop: '2rem',
          background: theme.colors.danger,
        }}
        onClick={onDelete}
      >
        <Typography variant="h6">
          {t('userManagement.deleteInvite.accept')}
        </Typography>
      </Button>
    </Grid>
  );
};

export default DeleteInvite;
