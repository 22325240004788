import type { RemittanceGet } from '../../../types/v2';
import { CustomURLSearchParams } from '../../../utils/customUrlSearchParams';
import api from '../../api';
import { trackingFiltersParams, trackingPageParams } from './queryParams';

const config = { timeout: 60000 };

export function getRemittance(payload: RemittanceGet) {
  let params = new CustomURLSearchParams({});

  params = trackingFiltersParams(params, payload.filters);
  params = trackingPageParams(params, payload);
  return api.get(`/v2/remittance?${params}`, config);
}
