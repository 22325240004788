import {
  Button as ButtonUI,
  ButtonProps as ButtonUIProps,
} from '@material-ui/core';
import React from 'react';
import { CSSProperties } from 'styled-components';

import Loader from '../loader';

type ButtonProps = ButtonUIProps & {
  loading?: boolean;
  style?: CSSProperties;
};

const Button = ({
  children,
  loading,
  disabled,
  style,
  ...props
}: ButtonProps) => {
  return (
    <ButtonUI
      {...props}
      disabled={loading || disabled}
      style={{
        textTransform: 'none',
        padding: '1rem',
        borderRadius: '8px',
        ...style,
      }}
    >
      {loading ? <Loader /> : children}
    </ButtonUI>
  );
};

export default Button;
