import { createSlice } from '@reduxjs/toolkit';

import {
  FinancialUpdateStatusAction,
  FinancialSlice,
  FinancialUpdateErrorAction,
  FetchStatusEnum,
  FinancialBankAccountsAction,
} from '../../types';

const initialState: FinancialSlice = {
  fetchStatus: FetchStatusEnum.None,
  fetchStatusBankAccounts: FetchStatusEnum.None,
  bankAccounts: [],
  error: null,
};

export const slice = createSlice({
  name: 'financial',
  initialState,
  reducers: {
    clear: () => initialState,
    updateStatus: (state, action: FinancialUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: FinancialUpdateErrorAction) => {
      state.error = action.payload.error;
    },
    getBankAccounts: (state) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    bankAccounts: (state, action: FinancialBankAccountsAction) => {
      state.bankAccounts = action.payload.bankAccounts;
    },
    updateBankAccountsStatus: (state, action: FinancialUpdateStatusAction) => {
      state.fetchStatusBankAccounts = action.payload.fetchStatus;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
