import { SvgIcon } from '@material-ui/core';
import React from 'react';

import { ReactComponent as Svg } from '../../../assets/logo.svg';

const Logo = () => {
  return (
    <SvgIcon
      component={Svg}
      viewBox="0 0 500 153"
      style={{
        width: 'clamp(130px, 20vw, 350px)',
        height: 'clamp(80px, 20vw, 100px)',
      }}
    />
  );
};

export default Logo;
