/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AuthenticatedEnum,
  SSOEventTypeEnum,
} from '@sinagro/frontend-shared/sharedTypes';
import React, { useCallback, useEffect } from 'react';
import { actions } from 'src/state';
import { useDispatch, useSelector } from 'src/state/store';

const targetOrigin = process.env.REACT_APP_BACKOFFICE_URL;
const BACKOFFICE_URL = process.env.REACT_APP_BACKOFFICE_URL;

type EventDataProps = {
  type?: SSOEventTypeEnum;
  authenticated?: boolean;
  token?: string;
};

const SSO = () => {
  let checkStarted = false;
  const dispatch = useDispatch();
  const userAuthenticated = useSelector(
    (state) => state.shared.user.userAuthenticated
  );
  const isAuthenticated = userAuthenticated === AuthenticatedEnum.Authenticated;

  const sendMessage = useCallback(() => {
    const data = {
      type: SSOEventTypeEnum.CheckAuth,
    };
    const iframe: any = document.getElementById('backoffice-iframe');

    iframe.contentWindow.postMessage(data, targetOrigin);
  }, []);

  const callSendMessage = useCallback(() => {
    const repeatWhileCheckingDoesNotStart = setInterval(() => {
      if (checkStarted) {
        clearInterval(repeatWhileCheckingDoesNotStart);
        return;
      }
      sendMessage();
    }, 3000);
  }, [checkStarted, sendMessage]);

  const updateCheckingAuthStatus = useCallback(
    (checkingAuthStatus: boolean) => {
      dispatch(actions.auth.updateCheckingAuthStatus({ checkingAuthStatus }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isAuthenticated) {
      updateCheckingAuthStatus(false);
      return;
    }
    callSendMessage();
  }, [callSendMessage, isAuthenticated, updateCheckingAuthStatus]);

  useEffect(() => {
    setTimeout(() => {
      updateCheckingAuthStatus(false);
    }, 15 * 1000);
  }, [updateCheckingAuthStatus]);

  window.addEventListener(
    'message',
    (event: any) => {
      const eventOrigin = event.origin;
      const isNotAnAllowedOrigin = BACKOFFICE_URL !== eventOrigin;
      const { type, authenticated, token } = event.data as EventDataProps;

      if (!type && isNotAnAllowedOrigin) return;

      if (type === SSOEventTypeEnum.CheckAuthStart) {
        checkStarted = true;
      }

      if (!authenticated && type === SSOEventTypeEnum.CheckAuthFinished) {
        updateCheckingAuthStatus(false);
      }

      if (
        token &&
        authenticated &&
        type === SSOEventTypeEnum.CheckAuthFinished
      ) {
        dispatch(actions.auth.singleSignOn({ token }));
        updateCheckingAuthStatus(false);
      }
    },
    false
  );

  return (
    <iframe
      // cache bust technique: https://stackoverflow.com/a/52507285
      src={`${BACKOFFICE_URL}?timestamp=${Date.now()}`}
      id="backoffice-iframe"
      width={0}
      height={0}
      style={{ display: 'none' }}
    />
  );
};

export default SSO;
