import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
} from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import * as portal from './';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: portal.reducer,
  middleware: [portal.routerMiddleware, sagaMiddleware],
});

export const history = portal.createReduxHistory(store);

sagaMiddleware.run(portal.sagas);

export const actions = portal.actions;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
