import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@material-ui/core';
import {
  FetchStatusEnum,
  SetNewPassword,
} from '@sinagro/frontend-shared/sharedTypes';
import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '../../components/elements/materialUI/button';
import { FormPasswordField } from '../../components/modules/form';
import { actions, useDispatch, useSelector } from '../../state/store';
import { usePasswordSchema } from './schema';
import { FieldContainer, SaveContainer } from './styles';

export type PasswordFormType = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

const FormPassword: React.FC = () => {
  const { t } = useTranslation();
  const schema = usePasswordSchema();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    control,
    clearErrors,
  } = useForm<PasswordFormType>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const profileError = useSelector(
    (state) => state.shared.changePassword.error
  );

  const fetchStatus = useSelector(
    (state) => state.shared.changePassword.fetchStatus
  );

  const newPassword = watch('newPassword');
  useEffect(() => {
    if (newPassword) {
      clearErrors('newPasswordConfirmation');
    }
  }, [newPassword, clearErrors]);

  useEffect(() => {
    if (fetchStatus === FetchStatusEnum.Success) {
      toast(t('profile.passwordForm.success'), { type: 'success' });
      reset({
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      });
    }
  }, [fetchStatus, t, reset]);

  useEffect(() => {
    return () => {
      dispatch(actions.shared.changePassword.clear());
    };
  }, [dispatch]);

  const onSubmit: SubmitHandler<PasswordFormType> = useCallback(
    (form: PasswordFormType) => {
      const sanitizedForm: SetNewPassword = {
        oldPassword: form.oldPassword,
        newPassword: form.newPassword,
        newPasswordConfirmation: form.newPasswordConfirmation,
      };
      dispatch(actions.shared.changePassword.changePassword(sanitizedForm));
    },
    [dispatch]
  );

  return (
    <Grid container item xs={12} spacing={2}>
      <FieldContainer item xs={12}>
        <FormPasswordField
          testID="inputOldPassword"
          name="oldPassword"
          label={t('profile.passwordForm.oldPassword')}
          control={control}
          defaultValue=""
          errorMessage={errors.oldPassword?.message}
        />
      </FieldContainer>
      <FieldContainer item xs={12}>
        <FormPasswordField
          testID="inputNewPassword"
          name="newPassword"
          label={t('profile.passwordForm.newPassword')}
          control={control}
          defaultValue=""
          errorMessage={errors.newPassword?.message}
          helper={
            !control.getFieldState('newPassword').isDirty
              ? t('form.passwordHelper')
              : ''
          }
          success={
            !control.getFieldState('newPassword').invalid &&
            control.getFieldState('newPassword').isDirty
              ? t('form.passwordHelper')
              : ''
          }
        />
      </FieldContainer>
      <FieldContainer item xs={12}>
        <FormPasswordField
          testID="inputNewPasswordConfirmation"
          name="newPasswordConfirmation"
          label={t('profile.passwordForm.confirmNewPassword')}
          control={control}
          defaultValue=""
          errorMessage={errors.newPasswordConfirmation?.message}
        />
      </FieldContainer>
      <FieldContainer item xs={12}>
        <Typography
          style={{ textAlign: 'start' }}
          variant="body2"
          color="error"
        >
          {profileError?.message}
        </Typography>
      </FieldContainer>
      <SaveContainer container item>
        <Button
          name="savePasswordButton"
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
          loading={fetchStatus === FetchStatusEnum.Fetching}
          style={{ width: '10rem', height: '3rem' }}
        >
          <Typography variant="h6">
            {t('profile.passwordForm.saveButton')}
          </Typography>
        </Button>
      </SaveContainer>
    </Grid>
  );
};

export default FormPassword;
