import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const FieldContainer = styled(Grid)`
  width: 100%;
  text-align: center;
`;

export const MainContainer = styled(Grid)<{ isMobile: boolean }>`
  padding: 2rem;
  border-radius: 3px;
  box-shadow: 1px 1px 10px 0px lightgrey;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.xxxl}px;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
`;

export const NoUsers = styled(Grid)`
  text-align: center;
  justify-content: center;
  margin: 1rem 0;
`;

export const TitleContainer = styled(Grid)``;

export const Header = styled(Grid)`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
