import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Logo from '../../components/elements/logo';
import Button from '../../components/elements/materialUI/button';
import WelcomeLayout from '../../components/layout/welcomeLayout';
import { FormPasswordField } from '../../components/modules/form';
import { useQuery } from '../../hooks/useQuery';
import { useDispatch, actions, useSelector } from '../../state/store';
import { usePasswordRecoverySchema } from './schema';
import {
  ButtonContainer,
  LogoContainer,
  TitleContainer,
  FieldContainer,
  ErrorLabel,
} from './styles';

export type PasswordRecoveryType = {
  newPassword: string;
  confirmNewPassword: string;
};

const PasswordRecovery: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const schema = usePasswordRecoverySchema();
  const query = useQuery();
  const token = query.get('token');

  const fetchStatus = useSelector(
    (state) => state.shared.forgotPassword.fetchStatusChangePassword
  );
  const error = useSelector(
    (state) => state.shared.forgotPassword.errorChangePassword
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<PasswordRecoveryType>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const navigateToLogin = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  const onSubmit: SubmitHandler<PasswordRecoveryType> = useCallback(
    (form: PasswordRecoveryType) => {
      if (token) {
        dispatch(
          actions.shared.forgotPassword.changePassword({
            newPassword: form.newPassword,
            token,
          })
        );
      }
    },
    [dispatch, token]
  );

  useEffect(() => {
    if (!token) {
      navigateToLogin();
    }

    if (fetchStatus === FetchStatusEnum.Success) {
      toast(t('passwordRecovery.success'), { type: 'success' });
      navigateToLogin();
    }
  }, [fetchStatus, navigateToLogin, t, token]);

  useEffect(() => {
    return () => {
      dispatch(actions.shared.forgotPassword.clear());
    };
  }, [dispatch]);

  return (
    <WelcomeLayout>
      <Grid
        container
        item
        xs={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid
          container
          item
          xs={12}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ flex: 1 }}
        >
          <LogoContainer item xs={12}>
            <Logo />
          </LogoContainer>
          <TitleContainer item xs={12}>
            <Typography variant="h1" color="textPrimary">
              {t('passwordRecovery.title')}
            </Typography>
          </TitleContainer>
          <FieldContainer item xs={12} lg={8}>
            <FormPasswordField
              testID="inputNewPassword"
              name="newPassword"
              label={t('passwordRecovery.newPassword')}
              control={control}
              defaultValue=""
              errorMessage={errors.newPassword?.message}
              helper={
                !control.getFieldState('newPassword').isDirty
                  ? t('form.passwordHelper')
                  : ''
              }
              success={
                !control.getFieldState('newPassword').invalid &&
                control.getFieldState('newPassword').isDirty
                  ? t('form.passwordHelper')
                  : ''
              }
            />
          </FieldContainer>
          <FieldContainer item xs={12} lg={8}>
            <FormPasswordField
              testID="inputConfirmNewPassword"
              name="confirmNewPassword"
              label={t('passwordRecovery.confirmNewPassword')}
              control={control}
              defaultValue=""
              errorMessage={errors.confirmNewPassword?.message}
            />
          </FieldContainer>
          <ButtonContainer item xs={12} lg={6}>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              fullWidth
              loading={fetchStatus === FetchStatusEnum.Fetching}
            >
              <Typography variant="h6">
                {t('passwordRecovery.submit')}
              </Typography>
            </Button>
          </ButtonContainer>
          <Grid item xs={12}>
            <ErrorLabel variant="body2" color="error">
              {error?.message}
            </ErrorLabel>
          </Grid>
        </Grid>
      </Grid>
    </WelcomeLayout>
  );
};

export default PasswordRecovery;
