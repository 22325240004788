import type { FiltersType } from '../../../types';
import type { InvoicesNumbersGet, NextBillsGet } from '../../../types/v2';
import { CustomURLSearchParams } from '../../../utils/customUrlSearchParams';

export const payablesFiltersParams = (
  params: CustomURLSearchParams,
  filters: FiltersType
) => {
  filters.harvestReferences.length > 0 &&
    params.set('harvestReference', filters.harvestReferences.join(','));

  filters.searchTerm.length > 0 && params.set('search', filters.searchTerm);

  filters.payableStatus && params.set('paymentStatus', filters.payableStatus);

  filters.paymentCondition &&
    params.set('paymentCondition', filters.paymentCondition);

  filters.dueDate.startDate &&
    params.set(
      'dueDate(min)',
      filters.dueDate.startDate.toDateString() + ' 00:00:00'
    );

  filters.dueDate.endDate &&
    params.set(
      'dueDate(max)',
      filters.dueDate.endDate.toDateString() + ' 23:59:59'
    );

  filters.documentDate.startDate &&
    params.set(
      'documentDate(min)',
      filters.documentDate.startDate.toDateString() + ' 00:00:00'
    );

  filters.documentDate.endDate &&
    params.set(
      'documentDate(max)',
      filters.documentDate.endDate.toDateString() + ' 23:59:59'
    );

  filters.compensationDate.startDate &&
    params.set(
      'compensationDate(min)',
      filters.compensationDate.startDate.toDateString() + ' 00:00:00'
    );

  filters.compensationDate.endDate &&
    params.set(
      'compensationDate(max)',
      filters.compensationDate.endDate.toDateString() + ' 23:59:59'
    );

  filters.operationPayableType &&
    params.set('operationType', filters.operationPayableType);

  filters.operationType && params.set('operationType', filters.operationType);

  filters.invoiceNumber && params.set('invoiceNumber', filters.invoiceNumber);

  filters.prus && params.set('prus', String(filters.prus));
  filters.clientId && params.set('clientId', filters.clientId);

  return params;
};

export const payablesPageParams = (
  params: CustomURLSearchParams,
  payload: NextBillsGet
) => {
  payload.limit && params.set('limit', payload.limit.toString());

  payload.page && params.set('page', payload.page.toString());

  return params;
};

export const invoicesNumbersParams = (
  params: CustomURLSearchParams,
  payload: InvoicesNumbersGet
) => {
  payload.limit && params.set('limit', payload.limit.toString());

  payload.invoiceNumber &&
    params.set('invoiceNumber', payload.invoiceNumber.toString());

  return params;
};
