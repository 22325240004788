import { Grid } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/elements/loader';

import { useSelector } from '../../state/store';
import { SubtitleCategory, Text } from './styles';

const ManagerInfo: React.FC = () => {
  const { t } = useTranslation();

  const managers = useSelector(
    (state) => state.shared.userProfile.userProfile.managers
  );

  const fetchStatus = useSelector(
    (state) => state.shared.userProfile.fetchStatus
  );

  if (fetchStatus === FetchStatusEnum.Fetching) {
    return (
      <Grid container style={{ paddingTop: '1rem', justifyContent: 'center' }}>
        <Loader />
      </Grid>
    );
  }

  return (
    <Grid container item style={{ paddingTop: '1rem' }}>
      {managers && managers.length > 0 && (
        <Grid>
          <SubtitleCategory color="primary">
            {t('profile.managerInfo.title')}
          </SubtitleCategory>
          {managers.map((manager) => (
            <Grid style={{ marginTop: '1.5rem' }} key={manager.id}>
              <Text color="secondary">{manager.name}</Text>
              <Text color="secondary">{manager.phoneNumber}</Text>
              <Text color="secondary">{manager.email}</Text>
              {manager.address && (
                <Text color="secondary">{manager.address.city}</Text>
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default ManagerInfo;
