import { Container, Grid, MenuItem, Typography } from '@material-ui/core';
import styled from 'styled-components';

type Props = {
  $isActive: boolean;
};

export const HeaderItemContainer = styled(MenuItem)<Props>`
  && {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    width: 100%;

    background-color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const HeaderItemDataContainer = styled(Container)`
  && {
    display: flex;
    padding: 0.75rem 0.3rem;
    align-items: center;
  }
`;

export const HeaderItemTextDataContainer = styled(Typography)<Props>`
  padding-left: '0.5rem';
  justify-content: center;
  margin-left: 0.5rem !important;
  text-decoration-color: ${({ theme }) => theme.colors.white};
  text-decoration-thickness: 0.063rem;
  text-decoration-line: 'none';
`;

export const HeaderSubItem = styled(Grid)`
  border-top: 0.5px solid lightgrey;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0.5rem 0.3rem 0 0.3rem;
  button {
    padding: 0px !important;
    min-width: auto;
    margin-bottom: 0.5rem;
  }
`;
