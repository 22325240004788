import React from 'react';

export type OverlayType = {
  component?: React.ReactNode;
  setComponent?: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

export const Overlay = React.createContext<OverlayType>({});

export const useOverlayContext = (): React.ContextType<
  React.Context<OverlayType>
> => {
  return React.useContext(Overlay);
};
