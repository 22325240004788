import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Header = styled(Grid)`
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    font-size: ${({ theme }) => theme.fontSize.big}px;
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`;

export const Title = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.xxl}px;
  }
`;

export const Container = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 1rem;
  gap: 1rem;
  padding-bottom: 1.5rem;
`;

export const Item = styled(Grid)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Text = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
  }
`;
