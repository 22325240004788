import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import { useSelector } from '../../../../../state/store';
import Avatar from '../../components/avatar';
import Footer from '../../components/footer';
import MenuItems from '../../components/menuItems';
import { MainContainer } from './styles';

type ContentMobileMenuProps = {
  headerHeight: number;
};

const ContentMobileMenu = ({ headerHeight }: ContentMobileMenuProps) => {
  const userProfile = useSelector(
    (state) => state.shared.userProfile.userProfile
  );

  return (
    <MainContainer
      container
      item
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      style={{ height: `calc(100vh - ${headerHeight}px )` }}
    >
      <Grid container item direction="column" alignItems="center">
        <Grid container item justifyContent="center">
          <Avatar name={userProfile.name} />
          <Typography color="textSecondary" variant="h4">
            {userProfile.name.split(' ')[0]}
          </Typography>
        </Grid>
        <Grid container item style={{ paddingTop: '1rem' }}>
          <MenuItems />
        </Grid>
      </Grid>
      <Grid container style={{ height: '10vh', marginBottom: '1rem' }}>
        <Footer />
      </Grid>
    </MainContainer>
  );
};

export default ContentMobileMenu;
