import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const SwitchContainer = styled(Grid)<{ marginBottom: number }>`
  && {
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
    flex-wrap: nowrap;
    align-items: center;
  }
`;

export const OptionContainer = styled(Grid)`
  flex-direction: column;
`;

export const OptionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSize.md}px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const OptionDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.fontSize.md}px;
`;
