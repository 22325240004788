import {
  DuplicatesDownloadInvoiceById,
  GetDuplicatePayload,
  GetDuplicatesPayload,
  UpdateDuplicatesStatusPayload,
} from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';
import api from '../api';

const duplicateQueryParams = (payload: GetDuplicatesPayload) => {
  const params = new CustomURLSearchParams({});
  const filters = payload.filters;

  filters.status &&
    filters.status.length > 0 &&
    params.set('status', filters.status.join(','));

  payload.limit && params.set('limit', payload.limit.toString());

  payload.page && params.set('page', payload.page.toString());

  return params;
};

export function getDuplicates(payload: GetDuplicatesPayload) {
  const params = duplicateQueryParams(payload);
  return api.get(`/duplicate?${params}`);
}

export function getDuplicate(payload: GetDuplicatePayload) {
  return api.get(`/duplicate/${payload.id}`);
}

export function updateDuplicatesStatus(payload: UpdateDuplicatesStatusPayload) {
  return api.patch(`/duplicate/batch/status`, payload);
}

export function downloadInvoiceById(payload: DuplicatesDownloadInvoiceById) {
  const url = `/duplicate/${payload.duplicateId}/download`;
  return api.get(url);
}

export default {
  getDuplicates,
  updateDuplicatesStatus,
  downloadInvoiceById,
};
