import { SagaIterator } from '@redux-saga/types';
import { call, takeLatest, put } from 'redux-saga/effects';

import { getFeatureFlag } from '../../services/featureFlag';
import { FetchStatusEnum } from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getFeatureFlag.type, getFeatureFlagEffectSaga),
];

export function* getFeatureFlagEffectSaga(): SagaIterator<void> {
  try {
    const featureFlag = yield call(getFeatureFlag);
    yield put(actions.featureFlag({ featureFlag }));
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
