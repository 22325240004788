import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const FieldContainer = styled(Grid)`
  width: 100%;
  text-align: center;
`;

export const SignUpContainer = styled(Grid)`
  width: 100%;
  padding: 3rem 0 0.5rem 0;
`;

export const MainContainer = styled(Grid)`
  min-height: 100vh;
  padding: 0 3rem;

  @media (max-width: 540px) {
    padding: 0 1rem;
  }
`;

export const LinkContainer = styled(Grid)`
  padding: 2rem 0 1rem 0;
`;
