import { InputProps, TextField } from '@material-ui/core';
import React from 'react';
import {
  Control,
  Controller,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import MaskedInput from 'react-input-mask';

import ErrorContainer from './errorContainer';

interface FormPhoneFieldProps<T> extends UseControllerProps<T> {
  control: Control<T>;
  label: string;
  errorMessage?: string;
  type?: string;
  inputProps?: InputProps;
  disabled?: boolean;
  placeholder?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  link?: {
    route: string;
    text: string;
  };
}

const FormPhoneField = <T extends FieldValues>(
  props: FormPhoneFieldProps<T>
): JSX.Element => {
  const {
    control,
    label,
    name,
    defaultValue,
    errorMessage,
    type,
    inputProps,
    disabled,
    placeholder,
    onBlur,
    link,
  } = props;

  return (
    <React.Fragment>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <MaskedInput
            value={field.value as string}
            onChange={field.onChange}
            onBlur={onBlur}
            mask={
              (field.value as string).length > 14
                ? '(99) 99999-9999'
                : '(99) 9999-99999'
            }
            maskPlaceholder=""
            disabled={disabled}
          >
            <TextField
              label={label}
              error={!!errorMessage}
              type={type}
              style={{ width: '100%' }}
              InputProps={inputProps}
              inputProps={{ 'data-testid': 'inputPhoneNumber' }}
              placeholder={placeholder}
            />
          </MaskedInput>
        )}
      />
      {errorMessage && (
        <ErrorContainer message={errorMessage} icon={true} link={link} />
      )}
    </React.Fragment>
  );
};

export default FormPhoneField;
