/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit';

import {
  CultivationsFetchAction,
  CultivationsUpdateStatusAction,
  FetchStatusEnum,
  OrdersDownloadInvoiceByIdAction,
  OrdersDownloadReportAction,
  OrdersExpandedAction,
  OrdersFetchAction,
  OrdersGetAction,
  OrdersGetSummaryAction,
  OrdersIdsFetchAction,
  OrdersIdsGetAction,
  OrdersIdsUpdateStatusAction,
  OrdersSlice,
  OrdersSummaryAction,
  OrdersUpdateErrorAction,
  OrdersUpdateStatusAction,
  OrdersUpdateStatusDownloadAction,
  ReportUrlFetchAction,
} from '../../types';

export const initialState: OrdersSlice = {
  summary: [],
  page: 0,
  total: 0,
  totalIds: 0,
  totalCultivations: 0,
  ordersAggregation: [],
  ordersIds: [],
  cultivations: [],
  reportUrl: '',
  fetchStatusReport: FetchStatusEnum.None,
  fetchStatusSummary: FetchStatusEnum.None,
  fetchStatusIds: FetchStatusEnum.None,
  fetchStatusCultivations: FetchStatusEnum.None,
  fetchStatus: FetchStatusEnum.None,
  fetchStatusDownload: FetchStatusEnum.None,
  error: null,
  expanded: false,
};

export const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    clear: () => initialState,
    getOrders: (state, action: OrdersGetAction) => {
      const { page, limit } = action.payload;
      state.page = page;
      state.limit = limit;
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    orders: (state, action: OrdersFetchAction) => {
      const { page, ordersAggregation, total } = action.payload;
      state.ordersAggregation =
        page === 0
          ? ordersAggregation
          : [...state.ordersAggregation, ...ordersAggregation];
      state.total = total;
    },
    getSummary: (state, _: OrdersGetSummaryAction) => {
      state.fetchStatusSummary = FetchStatusEnum.Fetching;
    },
    ordersIds: (state, action: OrdersIdsFetchAction) => {
      const { ordersIds, total } = action.payload;
      state.ordersIds = ordersIds;
      state.totalIds = total;
    },
    getOrdersIds: (state, _: OrdersIdsGetAction) => {
      state.fetchStatusIds = FetchStatusEnum.Fetching;
    },
    cultivations: (state, action: CultivationsFetchAction) => {
      const { cultivations, total } = action.payload;
      state.cultivations = cultivations;
      state.totalCultivations = total;
    },
    getCultivations: (state) => {
      state.fetchStatusCultivations = FetchStatusEnum.Fetching;
    },
    summary: (state, action: OrdersSummaryAction) => {
      state.summary = action.payload.summary;
    },
    expanded: (state, action: OrdersExpandedAction) => {
      state.expanded = action.payload.expanded;
    },
    updateStatus: (state, action: OrdersUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateStatusIds: (state, action: OrdersIdsUpdateStatusAction) => {
      state.fetchStatusIds = action.payload.fetchStatus;
    },
    updateStatusCultivations: (
      state,
      action: CultivationsUpdateStatusAction
    ) => {
      state.fetchStatusCultivations = action.payload.fetchStatus;
    },
    updateStatusSummary: (state, action: OrdersUpdateStatusAction) => {
      state.fetchStatusSummary = action.payload.fetchStatus;
    },
    updateError: (state, action: OrdersUpdateErrorAction) => {
      state.error = action.payload.error;
    },
    reportUrl: (state, action: ReportUrlFetchAction) => {
      const { url } = action.payload;
      state.reportUrl = url;
    },
    updateStatusReport: (state, action: OrdersUpdateStatusDownloadAction) => {
      state.fetchStatusReport = action.payload.fetchStatusDownload;
    },
    getUrlReport: (state, __: OrdersDownloadReportAction) => {
      state.fetchStatusReport = FetchStatusEnum.Fetching;
    },
    downloadReport: (_, __: OrdersDownloadReportAction) => {},
    downloadInvoiceById: (state, __: OrdersDownloadInvoiceByIdAction) => {
      state.fetchStatusDownload = FetchStatusEnum.Fetching;
    },
    updateStatusDownload: (state, action: OrdersUpdateStatusDownloadAction) => {
      state.fetchStatusDownload = action.payload.fetchStatusDownload;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
