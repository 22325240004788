import { Typography, Grid } from '@material-ui/core';
import { DocumentTypeEnum } from '@sinagro/shared';
import React, { useCallback } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import RadioButton from '../../components/elements/radioButton';
import {
  FormTextField,
  FormCheckbox,
  FormMaskedField,
  FormPasswordField,
  FormPhoneField,
} from '../../components/modules/form';
import { useSelector } from '../../state/store';
import { FormFields, SignUpFormType } from './';
import { FieldContainer } from './styles';
import TermsLabel from './termsLabel';

type FormProps = {
  control: Control<SignUpFormType>;
  errors: DeepMap<Partial<SignUpFormType>, FieldError>;
  onBlur: (field: FormFields) => void;
  documentType: DocumentTypeEnum;
  setDocumentType: (documentType: DocumentTypeEnum) => void;
};

const options: { value: string; label: string }[] = [
  {
    value: DocumentTypeEnum.CPF,
    label: 'CPF',
  },
  {
    value: DocumentTypeEnum.CNPJ,
    label: 'CNPJ',
  },
];

const Form: React.FC<FormProps> = ({
  control,
  errors,
  onBlur,
  documentType,
  setDocumentType,
}) => {
  const { t } = useTranslation();
  const signUpError = useSelector((state) => state.shared.signUp.error);

  const emailExists = useSelector((state) => state.shared.signUp.emailExistent);
  const phoneExists = useSelector((state) => state.shared.signUp.phoneExistent);
  const goToLoginLink = {
    text: t('signUpForm.alreadyRegistered.goToLogin'),
    route: '/',
  };

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<{
        value: unknown;
      }>
    ) => {
      const value = event.target.value as DocumentTypeEnum;
      setDocumentType(value);
    },
    [setDocumentType]
  );

  return (
    <Grid container item xs={12} spacing={2} style={{ marginTop: '1rem' }}>
      <RadioButton
        options={options}
        handleChange={handleChange}
        value={documentType.toString()}
        label={`${t('signUpForm.chooseDocument')}:`}
        box
      />

      <FieldContainer item xs={12}>
        <FormTextField
          testID="inputEmail"
          name="email"
          label={t('signUpForm.email')}
          control={control}
          defaultValue=""
          errorMessage={errors.email?.message}
          placeholder="nome@email.com"
          onBlur={() => onBlur('email')}
          link={emailExists ? goToLoginLink : undefined}
        />
      </FieldContainer>
      <FieldContainer item xs={12}>
        <FormPhoneField
          name="phoneNumber"
          label={t('signUpForm.phoneNumber')}
          control={control}
          defaultValue=""
          errorMessage={errors.phoneNumber?.message}
          placeholder="(00) 00000-0000"
          onBlur={() => onBlur('phoneNumber')}
          link={phoneExists ? goToLoginLink : undefined}
        />
      </FieldContainer>
      {documentType === DocumentTypeEnum.CNPJ ? (
        <FieldContainer item xs={12}>
          <FormMaskedField
            testID="inputCNPJ"
            name="document"
            label={t('signUpForm.cnpj')}
            control={control}
            defaultValue=""
            mask="99.999.999/9999-99"
            errorMessage={errors.document?.message}
            placeholder="00.000.000/0000-00"
            onBlur={() => onBlur('document')}
          />
        </FieldContainer>
      ) : (
        <FieldContainer item xs={12}>
          <FormMaskedField
            testID="inputCPF"
            name="document"
            label={t('signUpForm.cpf')}
            control={control}
            defaultValue=""
            mask="999.999.999-99"
            errorMessage={errors.document?.message}
            placeholder="000.000.000-00"
            onBlur={() => onBlur('document')}
          />
        </FieldContainer>
      )}
      <FieldContainer item xs={12}>
        <FormMaskedField
          testID="inputName"
          name="name"
          mask={''}
          label={
            documentType === DocumentTypeEnum.CNPJ
              ? t('signUpForm.companyName')
              : t('signUpForm.name')
          }
          control={control}
          defaultValue=""
          errorMessage={errors.name?.message}
        />
      </FieldContainer>
      <FieldContainer item xs={12}>
        <FormPasswordField
          testID="inputPassword"
          name="password"
          label={t('signUpForm.password')}
          control={control}
          defaultValue=""
          errorMessage={errors.password?.message}
          helper={
            !control.getFieldState('password').isDirty
              ? t('form.passwordHelper')
              : ''
          }
          success={
            !control.getFieldState('password').invalid &&
            control.getFieldState('password').isDirty
              ? t('form.passwordHelper')
              : ''
          }
        />
      </FieldContainer>
      <FieldContainer item xs={12}>
        <FormPasswordField
          testID="inputConfirmPassword"
          name="confirmPassword"
          label={t('signUpForm.confirmPassword')}
          control={control}
          defaultValue=""
          errorMessage={errors.confirmPassword?.message}
        />
      </FieldContainer>
      <FieldContainer item xs={12}>
        <FormCheckbox
          name="termsOfService"
          label={<TermsLabel />}
          control={control}
          defaultValue={false}
          errorMessage={errors.termsOfService?.message}
          testId="checkboxTermsOfService"
        />
      </FieldContainer>
      <FieldContainer item xs={12}>
        <Typography
          style={{ textAlign: 'start' }}
          variant="body2"
          color="error"
        >
          {signUpError?.message}
        </Typography>
      </FieldContainer>
    </Grid>
  );
};

export default Form;
