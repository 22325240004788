import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'src/state/store';

import { useIsMobile } from '../../hooks/responsiveLayout';
import FormPassword from './formPassword';
import { PasswordContainer, Subtitle, Title } from './styles';


const ChangePassword: React.FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate()
  const { t } = useTranslation();
  const padding = isMobile ? '2rem 0rem' : '2rem 0rem';
  const user = useSelector(state => state.shared.userProfile.userProfile)

  useEffect(() => {
    if(user.thirdPartyProvider) {
      navigate('/profile/user-profile')
    }
  })

  return (
    <Grid container style={{ padding }}>
      <Grid container style={{ justifyContent: 'space-between' }}>
        <PasswordContainer container item isMobile={isMobile}>
          <Title color="primary">{t('profile.titlePasswordChange')}</Title>
          <Subtitle color="secondary">
            {t('profile.subtitlePasswordChange')}
          </Subtitle>
          <FormPassword />
        </PasswordContainer>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
