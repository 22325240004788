import { Grid, Typography } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import React from 'react';

import { BaseGrid, Link } from './styles';

type Props = {
  Icon: SvgIconComponent;
  title: string;
  subtitle: string;
  onClick: () => void;
  url: string;
};

const ScheduleAppointmentCard = ({
  Icon,
  title,
  subtitle,
  url,
  onClick,
}: Props) => {
  return (
    <Link href={url} target="_blank" rel="noreferrer">
      <BaseGrid container alignItems="center" onClick={onClick}>
        <Grid item xs={2}>
          <Icon fontSize="small" />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
          <Typography variant="body1">{subtitle}</Typography>
        </Grid>
      </BaseGrid>
    </Link>
  );
};

export default ScheduleAppointmentCard;
