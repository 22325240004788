import styled from 'styled-components';

import { ReactComponent as SvgError } from '../../../assets/error.svg';

export const SvgConteiner = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 70%;
  margin-left: 15%;
`;

export const Svg = styled(SvgError)`
  width: 100%;
  height: 100%;
`;
