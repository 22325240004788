import { Grid, Typography } from '@material-ui/core';
import { DefaultThemeColorsType } from '@sinagro/frontend-shared/themes';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  gap: 0.5rem;
  padding-right: 1.5rem;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    width: 0px;
  }
`;

export const Badge = styled(Grid)<{ $color: DefaultThemeColorsType }>`
  display: flex;
  height: 22px;
  background-color: ${({ theme, $color }) => theme.colors[$color]};
  padding: 0 8px;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
`;

export const BadgeLabel = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.md}px;
    white-space: nowrap;
  }
`;
