import { useTranslation } from 'react-i18next';
import { emailRegex } from 'src/utils/regexes';
import * as yup from 'yup';

export const useInviteSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    email: yup
      .string()
      .required(t('form.required'))
      .matches(emailRegex, t('form.invalidFormat')),
  });
};
