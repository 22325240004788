import { Typography } from '@material-ui/core';
import { TabList as TabListMUI } from '@material-ui/lab';
import styled from 'styled-components';

export const Title = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSize.xxl}px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin: 1rem 0 1rem 0;
    padding-bottom: 2rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  }
`;

export const Description = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin-bottom: 2rem;
  }
`;

export const TabList = styled(TabListMUI)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  .MuiTabs-indicator {
    background: ${({ theme }) => theme.colors.quaternary};
  }

  .MuiTab-wrapper {
    text-transform: none;
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
  }
`;
