import { FetchStatusEnum } from '../../../../types';
import {
  RemittanceFetchAction,
  RemittanceGetAction,
  RemittanceUpdateStatusAction,
  TrackingSlice,
} from '../../../../types/v2';

export const reducersSeeds = {
  getRemittancesSeedsScheduled: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusSeedsScheduled = FetchStatusEnum.Fetching;
  },
  remittancesSeedsScheduled: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesSeedsScheduled =
      page === 0
        ? remittances
        : [...state.remittancesSeedsScheduled, ...remittances];
    state.totalSeedsScheduled = total;
  },
  updateStatusSeedsScheduled: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusSeedsScheduled = action.payload.fetchStatus;
  },
  getRemittancesSeedsHandling: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusSeedsHandling = FetchStatusEnum.Fetching;
  },
  remittancesSeedsHandling: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesSeedsHandling =
      page === 0
        ? remittances
        : [...state.remittancesSeedsHandling, ...remittances];
    state.totalSeedsHandling = total;
  },
  updateStatusSeedsHandling: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusSeedsHandling = action.payload.fetchStatus;
  },
  getRemittancesSeedsInTransit: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusSeedsInTransit = FetchStatusEnum.Fetching;
  },
  remittancesSeedsInTransit: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesSeedsInTransit =
      page === 0
        ? remittances
        : [...state.remittancesSeedsInTransit, ...remittances];
    state.totalSeedsInTransit = total;
  },
  updateStatusSeedsInTransit: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusSeedsInTransit = action.payload.fetchStatus;
  },
  getRemittancesSeedsDelivered: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusSeedsDelivered = FetchStatusEnum.Fetching;
  },
  remittancesSeedsDelivered: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesSeedsDelivered =
      page === 0
        ? remittances
        : [...state.remittancesSeedsDelivered, ...remittances];
    state.totalSeedsDelivered = total;
  },
  updateStatusSeedsDelivered: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusSeedsDelivered = action.payload.fetchStatus;
  },
};
