import { NotificationGet, NotificationMarkAsSeen } from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';
import api from '../api';
import { notificationPageParams } from './queryParams';

export function getNotifications(payload: NotificationGet) {
  let params = new CustomURLSearchParams({});
  params = notificationPageParams(params, payload);
  return api.get(`/notification?${params}`);
}

export function getNotSeenCount() {
  return api.get(`/notification/not-seen/count`);
}

export function markNotificationAsSeen(payload: NotificationMarkAsSeen) {
  return api.patch(`/notification/${payload.notificationId}/seen`, payload);
}

export function markAllNotificationsAsSeen() {
  return api.post(`/notification/clear-all`, {});
}

export default {
  getNotifications,
  getNotSeenCount,
  markNotificationAsSeen,
};
