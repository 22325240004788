import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import { ValidationCodeTypeEnum } from '@sinagro/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Loader from '../../components/elements/loader';
import Modal from '../../components/elements/modal';
import { actions, useDispatch, useSelector } from '../../state/store';
import { ValidationCodeTypeEnumTranslated } from '../../utils/enumsTranslate';
import EditField from './editField';
import EditFieldName from './editFieldName';
import Form, { ProfileFormType } from './form';
import { useProfileSchema } from './schema';
import { FieldContainer } from './styles';
import ValidationCode from './validationCode';

const FormProfile: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openEditFieldModal, setOpenEditFieldModal] = useState(false);
  const [validationCodeType, setValidationCodeType] =
    useState<ValidationCodeTypeEnum>();

  const userProfile = useSelector(
    (state) => state.shared.userProfile.userProfile
  );

  const fetchStatus = useSelector(
    (state) => state.shared.userProfile.fetchStatus
  );

  const fetchStatusUpdateUserProfile = useSelector(
    (state) => state.shared.userProfile.fetchStatusUpdateUserProfile
  );

  const fetchCheckStatus = useSelector(
    (state) => state.shared.validationCode.fetchCheckStatus
  );

  const profileError = useSelector(
    (state) => state.shared.userProfile.errorUpdateUserProfile
  );

  const loading =
    fetchStatus === FetchStatusEnum.Fetching ||
    fetchStatus === FetchStatusEnum.None ||
    fetchStatusUpdateUserProfile === FetchStatusEnum.Fetching;

  const schema = useProfileSchema();

  const {
    formState: { errors },
    control,
    reset,
  } = useForm<ProfileFormType>({
    resolver: yupResolver(schema),
  });

  const onValidate = (validationCodeType: ValidationCodeTypeEnum) => {
    setOpenModal(true);
    setValidationCodeType(validationCodeType);
    dispatch(
      actions.shared.validationCode.request({
        type: validationCodeType,
      })
    );
  };

  const onEdit = (validationCodeType: ValidationCodeTypeEnum | undefined) => {
    if(userProfile.thirdPartyProvider) {
      toast(t('profile.profileForm.thirdProviderUser'), { type: 'warning' });
      return
    }
    setValidationCodeType(validationCodeType);
    setOpenEditFieldModal(true);
  };

  const onClose = useCallback(() => {
    reset();
    setOpenModal(false);
    setOpenEditFieldModal(false);
    dispatch(actions.shared.validationCode.clear());
    dispatch(actions.shared.userProfile.clear());
    dispatch(actions.shared.userProfile.getUserProfile());
  }, [dispatch, reset]);

  useEffect(() => {
    if (fetchStatusUpdateUserProfile === FetchStatusEnum.Success) {
      toast(t('profile.profileForm.success'), { type: 'success' });
      onClose();
    }
  }, [fetchStatusUpdateUserProfile, t, onClose]);

  useEffect(() => {
    if (fetchCheckStatus === FetchStatusEnum.Success) {
      const typeTranslated = validationCodeType
        ? ValidationCodeTypeEnumTranslated[validationCodeType]
        : '';
      toast(t('profile.validationCode.success', { type: typeTranslated }), {
        type: 'success',
      });
      onClose();
    }
  }, [dispatch, fetchCheckStatus, onClose, t, validationCodeType]);

  useEffect(() => {
    dispatch(actions.shared.userProfile.getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.shared.userProfile.clear());
      dispatch(actions.shared.userProfile.getUserProfile());
    };
  }, [dispatch]);

  if (loading) {
    return (
      <Grid container item justifyContent="center">
        <Loader />
      </Grid>
    );
  }

  return (
    <Grid container item xs={12} spacing={2} style={{ paddingTop: '1rem' }}>
      <Form
        userProfile={userProfile}
        control={control}
        errors={errors}
        onValidate={onValidate}
        onEdit={onEdit}
      />
      <FieldContainer item xs={12}>
        <Typography
          style={{ textAlign: 'start' }}
          variant="body2"
          color="error"
        >
          {profileError?.message}
        </Typography>
      </FieldContainer>

      <Modal
        open={openEditFieldModal}
        onClose={onClose}
        content={<EditFieldName />}
      />
      {validationCodeType && (
        <>
          <Modal
            open={openModal}
            onClose={onClose}
            content={<ValidationCode validationCodeType={validationCodeType} />}
          />
          <Modal
            open={openEditFieldModal}
            onClose={onClose}
            content={<EditField validationCodeType={validationCodeType} />}
          />
        </>
      )}
    </Grid>
  );
};

export default FormProfile;
