import { SagaIterator } from '@redux-saga/types';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getWeatherForecast } from '../../services/weather';
import {
  FetchStatusEnum,
  WeatherForecastGeolocationGetAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getWeatherForecast.type, getWeatherForecastEffectSaga),
];

export function* getWeatherForecastEffectSaga(
  action: WeatherForecastGeolocationGetAction
): SagaIterator<void> {
  try {
    const weatherForecast = yield call(getWeatherForecast, action.payload);
    yield put(actions.weatherForecast({ weatherForecast }));
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
