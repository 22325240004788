import { Grid, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { ReactComponent as FarmSVG } from '../../../assets/icons/torq/farm.svg';
import { ReactComponent as RegionSVG } from '../../../assets/icons/torq/region.svg';

export const Container = styled(Grid)<{ $isMobile: boolean }>`
  margin-bottom: 3rem;
  align-items: center;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding: 0 1rem;
    `}
`;

export const FarmIcon = styled(FarmSVG)``;

export const RegionIcon = styled(RegionSVG)``;

export const Content = styled(Grid)`
  margin-left: 1rem;
`;

export const Title = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
`;

export const Text = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.md}px;
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-top: 4px;
  }
`;
