import { SagaIterator } from '@redux-saga/types';
import { IUserProfile } from '@sinagro/shared';
import { SharedError } from '@sinagro/shared/errors';
import { call, takeLatest, put } from 'redux-saga/effects';

import {
  createUserDeviceToken,
  createUserWebToken,
  deleteAccount,
  deleteUserDeviceToken,
  deleteUserWebToken,
  getUserProfile,
  updateUserEmailOrPhone,
  updateUserProfile,
} from '../../services/user';
import {
  DeleteAccountAction,
  FetchStatusEnum,
  UpdateUserDeviceTokenAction,
  UpdateUserEmailOrPhoneAction,
  UpdateUserProfileAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getUserProfile.type, getUserProfileEffectSaga),
  takeLatest(actions.updateUserProfile.type, updateUserProfileEffectSaga),
  takeLatest(
    actions.createUserDeviceToken.type,
    createUserDeviceTokenEffectSaga
  ),
  takeLatest(
    actions.deleteUserDeviceToken.type,
    deleteUserDeviceTokenEffectSaga
  ),
  takeLatest(actions.createUserWebToken.type, createUserWebTokenEffectSaga),
  takeLatest(actions.deleteUserWebToken.type, deleteUserWebTokenEffectSaga),
  takeLatest(
    actions.updateUserEmailOrPhone.type,
    updateUserEmailOrPhoneEffectSaga
  ),
  takeLatest(actions.deleteAccount.type, deleteAccountEffectSaga),
];

export type getUserProfileResponse = IUserProfile;

export function* getUserProfileEffectSaga(): SagaIterator<void> {
  try {
    const userProfile: getUserProfileResponse = yield call(getUserProfile);
    yield put(actions.userProfile({ userProfile }));
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* updateUserProfileEffectSaga(
  action: UpdateUserProfileAction
): SagaIterator<void> {
  try {
    yield call(updateUserProfile, action.payload);
    yield put(
      actions.updateStatusUpdateUserProfile({
        fetchStatusUpdateUserProfile: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(
        actions.updateErrorUpdateUserProfile({
          errorUpdateUserProfile: knownError,
        })
      );
    }

    yield put(
      actions.updateStatusUpdateUserProfile({
        fetchStatusUpdateUserProfile: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* createUserDeviceTokenEffectSaga(
  action: UpdateUserDeviceTokenAction
): SagaIterator<void> {
  try {
    yield call(createUserDeviceToken, action.payload);
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
  }
}

export function* deleteUserDeviceTokenEffectSaga(
  action: UpdateUserDeviceTokenAction
): SagaIterator<void> {
  try {
    yield call(deleteUserDeviceToken, action.payload);
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
  }
}

export function* createUserWebTokenEffectSaga(
  action: UpdateUserDeviceTokenAction
): SagaIterator<void> {
  try {
    yield call(createUserWebToken, action.payload);
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
  }
}

export function* deleteUserWebTokenEffectSaga(
  action: UpdateUserDeviceTokenAction
): SagaIterator<void> {
  try {
    yield call(deleteUserWebToken, action.payload);
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
  }
}

export function* updateUserEmailOrPhoneEffectSaga(
  action: UpdateUserEmailOrPhoneAction
): SagaIterator<void> {
  try {
    yield call(updateUserEmailOrPhone, action.payload);
    yield put(
      actions.updateStatusUpdateUserEmailOrPhone({
        fetchStatusUpdateUserEmailOrPhone: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(
        actions.updateErrorUpdateUserEmailOrPhone({
          errorUpdateUserEmailOrPhone: knownError,
        })
      );
    }

    yield put(
      actions.updateStatusUpdateUserEmailOrPhone({
        fetchStatusUpdateUserEmailOrPhone: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* deleteAccountEffectSaga(
  action: DeleteAccountAction
): SagaIterator<void> {
  try {
    yield call(deleteAccount, action.payload);
    yield put(
      actions.updateStatusDeleteAccount({
        fetchStatusDeleteAccount: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(
        actions.updateErrorDeleteAccount({
          errorDeleteAccount: knownError,
        })
      );
    }

    yield put(
      actions.updateStatusDeleteAccount({
        fetchStatusDeleteAccount: FetchStatusEnum.Failure,
      })
    );
  }
}
