import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Validation from '../pages/validation';
import ValidationCode from '../pages/validationCode';
import ValidationCodeSuccess from '../pages/validationCodeSuccess';
import AuthRoute from './authRoute';

const ValidationRoutes = () => {
  return (
    <Routes>
      <Route
        path="/validation"
        element={
          <AuthRoute type="validation">
            <Validation />
          </AuthRoute>
        }
      />
      <Route
        key="validation-code"
        path="/validation-code"
        element={
          <AuthRoute type="validation">
            <ValidationCode />
          </AuthRoute>
        }
      />
      <Route
        path="/validation-code-success"
        element={
          <AuthRoute type="validation">
            <ValidationCodeSuccess />
          </AuthRoute>
        }
      />
    </Routes>
  );
};

export default ValidationRoutes;
