import { createSlice } from '@reduxjs/toolkit';
import { UserRoleEnum, UserStatusEnum } from '@sinagro/shared';
import {
  AuthenticatedEnum,
  FetchStatusEnum,
  FirebasePersistenceEnum,
  UpdateUserNotificationConfigAction,
  UpdateUserNotificationConfigUpdateErrorAction,
  UpdateUserNotificationConfigUpdateStatusAction,
  userAuthenticatedAction,
  UserPersistenceAction,
  UserSlice,
  UserUpdateAction,
} from '../../types';

const initialState: UserSlice = {
  claims: {
    email: '',
    id: '',
    phoneNumber: '',
    role: UserRoleEnum.Producer,
    status: UserStatusEnum.Inactive,
    mainRelatedClientId: '',
  },
  persistence: FirebasePersistenceEnum.Session,
  userFirebase: {
    displayName: '',
    email: '',
    phoneNumber: '',
    photoURL: '',
  },
  userAuthenticated: AuthenticatedEnum.None,
  fetchStatusUpdateUserNotificationConfig: FetchStatusEnum.None,
  errorUpdateUserNotificationConfig: null,
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clear: () => initialState,
    update: (state, action: UserUpdateAction) => {
      state.claims = action.payload.claims;
      state.userFirebase = action.payload.userFirebase;
    },
    persistence: (state, action: UserPersistenceAction) => {
      state.persistence = action.payload.persistence;
    },
    userAuthenticated: (state, action: userAuthenticatedAction) => {
      state.userAuthenticated = action.payload.userAuthenticated;
    },
    updateUserNotificationConfig: (
      state,
      _: UpdateUserNotificationConfigAction
    ) => {
      state.fetchStatusUpdateUserNotificationConfig = FetchStatusEnum.Fetching;
    },
    updateStatusUpdateUserNotificationConfig: (
      state,
      action: UpdateUserNotificationConfigUpdateStatusAction
    ) => {
      state.fetchStatusUpdateUserNotificationConfig =
        action.payload.fetchStatusUpdateUserNotificationConfig;
    },
    updateErrorUpdateUserNotificationConfig: (
      state,
      action: UpdateUserNotificationConfigUpdateErrorAction
    ) => {
      state.errorUpdateUserNotificationConfig =
        action.payload.errorUpdateUserNotificationConfig;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
