import { createSlice } from '@reduxjs/toolkit';
import { SharedError } from '@sinagro/shared/errors';

import { ErrorSlice, AddErrorAction } from '../../types';

const initialState: ErrorSlice = {
  errors: [],
};

export const slice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    clear: () => initialState,
    add: (state, action: AddErrorAction) => {
      state.errors = [...state.errors, action.payload.error];
    },
    remove: (state, action: AddErrorAction) => {
      state.errors = state.errors.filter((error: SharedError) => {
        error.errorRef !== action.payload.error.errorRef;
      });
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
