import { SagaIterator } from '@redux-saga/types';
import { call, takeLatest, put } from 'redux-saga/effects';

import { getSystemConfig } from '../../services/systemConfig';
import { FetchStatusEnum } from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getSystemConfig.type, getSystemConfigEffectSaga),
];

export function* getSystemConfigEffectSaga(): SagaIterator<void> {
  try {
    const systemConfig = yield call(getSystemConfig);
    yield put(actions.systemConfig({ systemConfig }));
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatus({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
