import { Grid } from '@material-ui/core';
import React from 'react';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import BreadCrumbs from '../breadCrumbs';
import ProfileDropdown from './profileDropdown';
import { HeaderContainer } from './styles';

const DesktopHeader = () => {
  const isMobile = useIsMobile();
  const padding = isMobile ? '2rem 1rem' : '2rem 4rem';

  return (
    <>
      {!isMobile && (
        <HeaderContainer container>
          <ProfileDropdown />
        </HeaderContainer>
      )}

      <Grid container style={{ padding }}>
        <Grid container item style={{ padding: '1rem 0 0rem 0' }}>
          <BreadCrumbs />
        </Grid>
      </Grid>
    </>
  );
};

export default DesktopHeader;
