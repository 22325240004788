import React from 'react';
import { MdMenu, MdMenuOpen } from 'react-icons/md';
import { Link } from 'react-router-dom';

import IconWhite from '../../../../../assets/icon_white.png';
import LogoWhite from '../../../../../assets/logo_white.png';
import Button from '../../../../../components/elements/materialUI/button';
import { HeaderContainer } from './styles';

type Props = {
  openMenu: boolean;
  onClick: () => void;
};

const Header = ({ openMenu, onClick }: Props) => {
  return (
    <HeaderContainer
      container
      direction="column"
      alignItems="center"
      $openMenu={openMenu}
    >
      <Button onClick={onClick}>
        {openMenu ? <MdMenuOpen color="white" /> : <MdMenu color="white" />}
      </Button>

      <Link to={'/dashboard'}>
        {openMenu ? (
          <img
            src={LogoWhite}
            width={150}
            style={{ transition: 'ease-in-out 300ms' }}
          />
        ) : (
          <img src={IconWhite} width={40} />
        )}
      </Link>
    </HeaderContainer>
  );
};

export default Header;
