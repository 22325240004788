import {
  DailyWeatherForecastDTO,
  DiseaseForecastDTO,
  SprayingForecastDTO,
  HourlyWeatherForecastDTO,
  IRegion,
  FarmDetailsDTO,
  IAlert,
  PlotImageRemoteSensingDTO,
  GrowthCurveChartDTO,
} from '@sinagro/shared';
import React from 'react';
import { LocationProps } from 'src/components/modules/googleAutoComplete';

export type SprayingRecommendationType = {
  isRecommended: boolean;
  message: string;
};

export type DailyWeatherForecast = DailyWeatherForecastDTO & {
  hourly: HourlyWeatherForecastDTO[];
  sprayingRecommendation: SprayingRecommendationType;
};

export type SprayingForecast = {
  date: string;
  hourly: SprayingForecastDTO[];
};

export type TorqType = {
  regionId: string;
  onChangeRegionId?: (regionId: string) => void;
  region: IRegion | null;
  onChangeRegion?: (region: IRegion) => void;
  regions: IRegion[] | undefined;
  selectedDay: DailyWeatherForecast | null;
  onChangeSelectedDay?: (newDay: DailyWeatherForecast) => void;
  dailyWeatherForecast: DailyWeatherForecast[];
  diseaseForecast: DiseaseForecastDTO[];
  sprayingForecast: SprayingForecast[];
  farmDetails: FarmDetailsDTO | null;
  activeAlerts: number;
  alerts: IAlert[];
  remoteSensing: PlotImageRemoteSensingDTO | null;
  growthCurveChart: GrowthCurveChartDTO | null;
  showPlotSections: boolean;
  onChangeLocation?: (location: LocationProps) => void;
};

export const Torq = React.createContext<TorqType>({
  regionId: '',
  region: null,
  regions: [],
  selectedDay: null,
  dailyWeatherForecast: [],
  diseaseForecast: [],
  sprayingForecast: [],
  farmDetails: null,
  activeAlerts: 0,
  alerts: [],
  remoteSensing: null,
  growthCurveChart: null,
  showPlotSections: false,
});

export const useTorqContext = (): React.ContextType<
  React.Context<TorqType>
> => {
  return React.useContext(Torq);
};
