import { createSlice } from '@reduxjs/toolkit';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';

import {
  RecoveryPasswordAction,
  ForgotPasswordSlice,
  ForgotPasswordUpdateStatusAction,
  ForgotPasswordUpdateErrorAction,
  ChangePasswordAction,
  ForgotChangePasswordUpdateStatusAction,
  ForgotChangePasswordUpdateErrorAction,
} from '../../types';

const initialState: ForgotPasswordSlice = {
  fetchStatus: FetchStatusEnum.None,
  fetchStatusChangePassword: FetchStatusEnum.None,
  error: null,
  errorChangePassword: null,
};

export const slice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    clear: () => initialState,
    recovery: (state, _: RecoveryPasswordAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    changePassword: (state, _: ChangePasswordAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    updateStatus: (state, action: ForgotPasswordUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: ForgotPasswordUpdateErrorAction) => {
      state.error = action.payload.error;
    },
    updateStatusChangePassword: (
      state,
      action: ForgotChangePasswordUpdateStatusAction
    ) => {
      state.fetchStatusChangePassword =
        action.payload.fetchStatusChangePassword;
    },
    updateErrorChangePassword: (
      state,
      action: ForgotChangePasswordUpdateErrorAction
    ) => {
      state.errorChangePassword = action.payload.errorChangePassword;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
