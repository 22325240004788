import { DefaultThemeColorsType } from '@sinagro/frontend-shared/themes';
import { TrendEnum } from '@sinagro/shared';
import React, { ReactElement } from 'react';

import { TrendUpIcon, TrendDownIcon } from './styles';

export const trendIcon: Record<TrendEnum, ReactElement> = {
  [TrendEnum.Up]: <TrendUpIcon />,
  [TrendEnum.Down]: <TrendDownIcon />,
  [TrendEnum.Same]: <></>,
};

export const trendColor: Record<TrendEnum, DefaultThemeColorsType | undefined> =
  {
    [TrendEnum.Up]: 'quaternary',
    [TrendEnum.Down]: 'danger',
    [TrendEnum.Same]: undefined,
  };
