import React, { useState } from 'react';

import { Overlay } from '../contexts/overlay';

type OverlayProviderProps = {
  children: React.ReactNode;
};

export const OverlayProvider = ({
  children,
}: OverlayProviderProps): JSX.Element => {
  const [component, setComponent] = useState<React.ReactNode>(null);

  return (
    <Overlay.Provider value={{ component, setComponent }}>
      {children}
    </Overlay.Provider>
  );
};
