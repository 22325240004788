import { Grid } from '@material-ui/core';
import React, { createRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SprayingForecast } from 'src/contexts/torq';
import {
  formattedCurrentHour,
  formatDateHour,
  formatDateAndMonth,
  isToday,
} from 'src/utils/format';

import Item from './item';
import {
  Caption,
  CaptionSidebar,
  CaptionText,
  Details,
  DetailsWrapper,
  Hour,
  HourContent,
} from './styles';

type SprayingHourlyDetailsProps = {
  sprayingForecast: SprayingForecast[];
};

const SprayingHourlyDetails: React.FC<SprayingHourlyDetailsProps> = ({
  sprayingForecast,
}) => {
  const { t } = useTranslation();
  const ref = createRef<HTMLDivElement>();
  const currentHour = formattedCurrentHour();

  const formatDate = (date: string) => {
    const formattedDate = formatDateAndMonth(date, true);
    const dateIsToday = isToday(date, true);
    return dateIsToday ? t('torq.details.today') : formattedDate;
  };

  const updateScroll = useCallback(
    (currentHourIndex: number) => {
      ref.current?.scrollTo({
        left: 52 * currentHourIndex,
      });
    },
    [ref]
  );

  useEffect(() => {
    const currentHourIndex =
      sprayingForecast.length > 0
        ? sprayingForecast[0].hourly.findIndex(
            (item) => formatDateHour(item.date, true) === currentHour
          )
        : 0;
    updateScroll(currentHourIndex);
  }, [currentHour, sprayingForecast, updateScroll]);

  useEffect(() => {
    if (ref.current === null) return;

    ref.current.addEventListener('mouseover', () => {
      if (ref.current === null) return;

      ref.current.addEventListener('wheel', function (e: WheelEvent) {
        if (ref.current === null) return;

        if (e.deltaY > 0) ref.current.scrollLeft += 1;
        else ref.current.scrollLeft -= 1;
        e.preventDefault();
      });
    });
  });

  return (
    <Grid container direction="row">
      <CaptionSidebar item xs={3} sm={2} lg={1}>
        <HourContent>
          <Hour>{t('torq.details.hour')}</Hour>
        </HourContent>

        {sprayingForecast.map((spraying) => (
          <Caption key={spraying.date}>
            <CaptionText $color="text" $bold={isToday(spraying.date, true)}>
              {formatDate(spraying.date)}
            </CaptionText>
          </Caption>
        ))}
      </CaptionSidebar>

      <Details item xs={9} sm={10} lg={11} ref={ref}>
        {sprayingForecast.map((spraying, index) => (
          <DetailsWrapper key={spraying.date}>
            {spraying.hourly.map((item) => (
              <Item item={item} showHeader={index === 0} key={item.date} />
            ))}
          </DetailsWrapper>
        ))}
      </Details>
    </Grid>
  );
};

export default SprayingHourlyDetails;
