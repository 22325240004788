import {
  cpfValidation,
  cnpjValidation,
} from '@sinagro/frontend-shared/validations';
import { DocumentTypeEnum } from '@sinagro/shared';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useDispatch, actions, useSelector } from '../../state/store';
import {
  cpfRegex,
  phoneNumberRegex,
  emailRegex,
  cnpjRegex,
  atLeastOneUppercase,
  atLeastOneLowercase,
  atLeastOneNumber,
  atLeastOneSymbol,
  notAcceptSpaces,
} from '../../utils/regexes';

export const useSignUpSchema = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const emailExists = useSelector((state) => state.shared.signUp.emailExistent);
  const phoneExists = useSelector((state) => state.shared.signUp.phoneExistent);
  const documentExists = useSelector(
    (state) => state.shared.signUp.documentExistent
  );

  return yup.object().shape({
    email: yup
      .string()
      .required(t('form.required'))
      .matches(emailRegex, t('form.invalidFormat'))
      .test(
        'checkEmailExistence',
        t('signUpForm.alreadyRegistered.email'),
        (value) => {
          if (value) {
            if (!emailExists)
              dispatch(
                actions.shared.signUp.fetchExistence({
                  field: 'email',
                  value,
                })
              );
          }
          return !emailExists;
        }
      ),
    name: yup
      .string()
      .required(t('form.required'))
      .max(
        200,
        t('form.max', {
          field: t('signUpForm.name'),
          max: 200,
        })
      ),
    phoneNumber: yup
      .string()
      .required(t('form.required'))
      .matches(phoneNumberRegex, t('form.invalidFormat'))
      .test(
        'checkPhonelExistence',
        t('signUpForm.alreadyRegistered.phoneNumber'),
        (value) => {
          if (value) {
            if (!phoneExists)
              dispatch(
                actions.shared.signUp.fetchExistence({
                  field: 'phone',
                  value,
                })
              );
          }
          return !phoneExists;
        }
      ),
    documentType: yup.string().required(t('form.required')),
    document: yup
      .string()
      .required(t('form.required'))
      .when('documentType', {
        is: DocumentTypeEnum.CNPJ,
        then: yup
          .string()
          .matches(cnpjRegex, t('form.invalidFormat'))
          .test({
            name: 'cnpj',
            message: t('form.invalidField', { field: 'CNPJ' }),
            test: (value) => cnpjValidation(value),
          }),
        otherwise: yup
          .string()
          .matches(cpfRegex, t('form.invalidFormat'))
          .test({
            name: 'cpf',
            message: t('form.invalidField', { field: 'CPF' }),
            test: (value) => cpfValidation(value),
          }),
      })
      .test(
        'checkDocumentExistence',
        t('signUpForm.alreadyRegistered.document'),
        (value) => {
          if (value) {
            if (!documentExists)
              dispatch(
                actions.shared.signUp.fetchExistence({
                  field: 'document',
                  value,
                })
              );
          }
          return !documentExists;
        }
      ),
    password: yup
      .string()
      .required(t('form.required'))
      .min(8, t('form.min', { field: t('signUpForm.password'), min: 8 }))
      .matches(atLeastOneLowercase, t('form.atLeastOneLowercase'))
      .matches(atLeastOneUppercase, t('form.atLeastOneUppercase'))
      .matches(atLeastOneNumber, t('form.atLeastOneNumber'))
      .matches(atLeastOneSymbol, t('form.atLeastOneSymbol'))
      .matches(notAcceptSpaces, t('form.notAcceptSpaces')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], t('form.confirmPassword'))
      .min(1, t('form.required')),
    termsOfService: yup.bool().required().oneOf([true], t('form.required')),
  });
};
