import { WeatherConditionEnum } from '@sinagro/shared';
import React, { ReactElement } from 'react';

import { WeatherConditionEnumTranslated } from '../../../utils/enumsTranslate';
import {
  BlizzardIcon,
  BreezyIcon,
  ClearIcon,
  CloudyIcon,
  DriftingSnowIcon,
  DrizzleIcon,
  FlurriesIcon,
  FoggyIcon,
  FreezingDrizzleIcon,
  FreezingRainIcon,
  HailIcon,
  HazeIcon,
  HeavyRainIcon,
  HeavySnowIcon,
  HotIcon,
  HurricaneIcon,
  IceCrystalsIcon,
  IsolatedThunderstormsIcon,
  MixedRainAndHailIcon,
  MostlyClearIcon,
  MostlyCloudyIcon,
  MostlySunnyIcon,
  PartlyCloudyIcon,
  RainIcon,
  RainSleetIcon,
  RainSnowIcon,
  SandstormIcon,
  ScatteredShowersIcon,
  ScatteredSnowShowersIcon,
  ScatteredThunderstormsIcon,
  ShowersIcon,
  SleetIcon,
  SmokeIcon,
  SnowIcon,
  SnowShowersIcon,
  StrongStormsIcon,
  SunnyIcon,
  ThunderstormsIcon,
  TornadoIcon,
  TropicalStormIcon,
  WindyIcon,
  WintryMixIcon,
} from './styles';

type WeatherConditionProps = {
  type: WeatherConditionEnum;
  label: string;
  Icon: ReactElement;
};

export const weatherCondition: Record<string, WeatherConditionProps> = {
  [WeatherConditionEnum.Blizzard]: {
    type: WeatherConditionEnum.Blizzard,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Blizzard],
    Icon: <BlizzardIcon />,
  },
  [WeatherConditionEnum.Breezy]: {
    type: WeatherConditionEnum.Breezy,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Breezy],
    Icon: <BreezyIcon />,
  },
  [WeatherConditionEnum.Clear]: {
    type: WeatherConditionEnum.Clear,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Clear],
    Icon: <ClearIcon />,
  },
  [WeatherConditionEnum.Cloudy]: {
    type: WeatherConditionEnum.Cloudy,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Cloudy],
    Icon: <CloudyIcon />,
  },
  [WeatherConditionEnum.DriftingSnow]: {
    type: WeatherConditionEnum.DriftingSnow,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.DriftingSnow],
    Icon: <DriftingSnowIcon />,
  },
  [WeatherConditionEnum.Drizzle]: {
    type: WeatherConditionEnum.Drizzle,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Drizzle],
    Icon: <DrizzleIcon />,
  },
  [WeatherConditionEnum.Flurries]: {
    type: WeatherConditionEnum.Flurries,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Flurries],
    Icon: <FlurriesIcon />,
  },
  [WeatherConditionEnum.Foggy]: {
    type: WeatherConditionEnum.Foggy,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Foggy],
    Icon: <FoggyIcon />,
  },
  [WeatherConditionEnum.FreezingDrizzle]: {
    type: WeatherConditionEnum.FreezingDrizzle,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.FreezingDrizzle],
    Icon: <FreezingDrizzleIcon />,
  },
  [WeatherConditionEnum.FreezingRain]: {
    type: WeatherConditionEnum.FreezingRain,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.FreezingRain],
    Icon: <FreezingRainIcon />,
  },
  [WeatherConditionEnum.Hail]: {
    type: WeatherConditionEnum.Hail,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Hail],
    Icon: <HailIcon />,
  },
  [WeatherConditionEnum.Haze]: {
    type: WeatherConditionEnum.Haze,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Haze],
    Icon: <HazeIcon />,
  },
  [WeatherConditionEnum.HeavyRain]: {
    type: WeatherConditionEnum.HeavyRain,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.HeavyRain],
    Icon: <HeavyRainIcon />,
  },
  [WeatherConditionEnum.HeavySnow]: {
    type: WeatherConditionEnum.HeavySnow,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.HeavySnow],
    Icon: <HeavySnowIcon />,
  },
  [WeatherConditionEnum.Hot]: {
    type: WeatherConditionEnum.Hot,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Hot],
    Icon: <HotIcon />,
  },
  [WeatherConditionEnum.Hurricane]: {
    type: WeatherConditionEnum.Hurricane,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Hurricane],
    Icon: <HurricaneIcon />,
  },
  [WeatherConditionEnum.IceCrystals]: {
    type: WeatherConditionEnum.IceCrystals,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.IceCrystals],
    Icon: <IceCrystalsIcon />,
  },
  [WeatherConditionEnum.IsolatedThunderstorms]: {
    type: WeatherConditionEnum.IsolatedThunderstorms,
    label:
      WeatherConditionEnumTranslated[
        WeatherConditionEnum.IsolatedThunderstorms
      ],
    Icon: <IsolatedThunderstormsIcon />,
  },
  [WeatherConditionEnum.MixedRainAndHail]: {
    type: WeatherConditionEnum.MixedRainAndHail,
    label:
      WeatherConditionEnumTranslated[WeatherConditionEnum.MixedRainAndHail],
    Icon: <MixedRainAndHailIcon />,
  },
  [WeatherConditionEnum.MostlyClear]: {
    type: WeatherConditionEnum.MostlyClear,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.MostlyClear],
    Icon: <MostlyClearIcon />,
  },
  [WeatherConditionEnum.MostlyCloudy]: {
    type: WeatherConditionEnum.MostlyCloudy,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.MostlyCloudy],
    Icon: <MostlyCloudyIcon />,
  },
  [WeatherConditionEnum.MostlySunny]: {
    type: WeatherConditionEnum.MostlySunny,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.MostlySunny],
    Icon: <MostlySunnyIcon />,
  },
  [WeatherConditionEnum.NotAvailable]: {
    type: WeatherConditionEnum.NotAvailable,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.NotAvailable],
    Icon: <></>,
  },
  [WeatherConditionEnum.PartlyCloudy]: {
    type: WeatherConditionEnum.PartlyCloudy,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.PartlyCloudy],
    Icon: <PartlyCloudyIcon />,
  },
  [WeatherConditionEnum.Rain]: {
    type: WeatherConditionEnum.Rain,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Rain],
    Icon: <RainIcon />,
  },
  [WeatherConditionEnum.RainSleet]: {
    type: WeatherConditionEnum.RainSleet,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.RainSleet],
    Icon: <RainSleetIcon />,
  },
  [WeatherConditionEnum.RainSnow]: {
    type: WeatherConditionEnum.RainSnow,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.RainSnow],
    Icon: <RainSnowIcon />,
  },
  [WeatherConditionEnum.Sandstorm]: {
    type: WeatherConditionEnum.Sandstorm,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Sandstorm],
    Icon: <SandstormIcon />,
  },
  [WeatherConditionEnum.ScatteredShowers]: {
    type: WeatherConditionEnum.ScatteredShowers,
    label:
      WeatherConditionEnumTranslated[WeatherConditionEnum.ScatteredShowers],
    Icon: <ScatteredShowersIcon />,
  },
  [WeatherConditionEnum.ScatteredSnowShowers]: {
    type: WeatherConditionEnum.ScatteredSnowShowers,
    label:
      WeatherConditionEnumTranslated[WeatherConditionEnum.ScatteredSnowShowers],
    Icon: <ScatteredSnowShowersIcon />,
  },
  [WeatherConditionEnum.ScatteredThunderstorms]: {
    type: WeatherConditionEnum.ScatteredThunderstorms,
    label:
      WeatherConditionEnumTranslated[
        WeatherConditionEnum.ScatteredThunderstorms
      ],
    Icon: <ScatteredThunderstormsIcon />,
  },
  [WeatherConditionEnum.Showers]: {
    type: WeatherConditionEnum.Showers,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Showers],
    Icon: <ShowersIcon />,
  },
  [WeatherConditionEnum.Sleet]: {
    type: WeatherConditionEnum.Sleet,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Sleet],
    Icon: <SleetIcon />,
  },
  [WeatherConditionEnum.Smoke]: {
    type: WeatherConditionEnum.Smoke,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Smoke],
    Icon: <SmokeIcon />,
  },
  [WeatherConditionEnum.Snow]: {
    type: WeatherConditionEnum.Snow,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Snow],
    Icon: <SnowIcon />,
  },
  [WeatherConditionEnum.SnowShowers]: {
    type: WeatherConditionEnum.SnowShowers,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.SnowShowers],
    Icon: <SnowShowersIcon />,
  },
  [WeatherConditionEnum.StrongStorms]: {
    type: WeatherConditionEnum.StrongStorms,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.StrongStorms],
    Icon: <StrongStormsIcon />,
  },
  [WeatherConditionEnum.Sunny]: {
    type: WeatherConditionEnum.Sunny,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Sunny],
    Icon: <SunnyIcon />,
  },
  [WeatherConditionEnum.Thunderstorms]: {
    type: WeatherConditionEnum.Thunderstorms,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Thunderstorms],
    Icon: <ThunderstormsIcon />,
  },
  [WeatherConditionEnum.Tornado]: {
    type: WeatherConditionEnum.Tornado,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Tornado],
    Icon: <TornadoIcon />,
  },
  [WeatherConditionEnum.TropicalStorm]: {
    type: WeatherConditionEnum.TropicalStorm,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.TropicalStorm],
    Icon: <TropicalStormIcon />,
  },
  [WeatherConditionEnum.Windy]: {
    type: WeatherConditionEnum.Windy,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.Windy],
    Icon: <WindyIcon />,
  },
  [WeatherConditionEnum.WintryMix]: {
    type: WeatherConditionEnum.WintryMix,
    label: WeatherConditionEnumTranslated[WeatherConditionEnum.WintryMix],
    Icon: <WintryMixIcon />,
  },
};
