import { faUser } from '@fortawesome/free-solid-svg-icons';
import { ClickAwayListener, Grid, Typography } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import React, { useEffect, useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import Loader from 'src/components/elements/loader';

import { actions, useDispatch, useSelector } from '../../../state/store';
import HeaderItems from './components/headerItems';
import { MainContainer, OptionsContainer, ProfileIcon } from './styles';

const ProfileDropdown = () => {
  const dispatch = useDispatch();

  const [showOptions, setShowOptions] = useState(false);

  const handleShow = () => {
    showOptions ? setShowOptions(false) : setShowOptions(true);
  };

  const userProfile = useSelector(
    (state) => state.shared.userProfile.userProfile
  );

  const fetchStatus = useSelector(
    (state) => state.shared.userProfile.fetchStatus
  );

  const fetchStatusUpdateUserProfile = useSelector(
    (state) => state.shared.userProfile.fetchStatusUpdateUserProfile
  );

  const loading =
    fetchStatus === FetchStatusEnum.Fetching ||
    fetchStatus === FetchStatusEnum.None ||
    fetchStatusUpdateUserProfile === FetchStatusEnum.Fetching;

  useEffect(() => {
    dispatch(actions.shared.userProfile.getUserProfile());
  }, [dispatch, fetchStatusUpdateUserProfile]);

  if (loading) {
    return (
      <MainContainer
        container
        item
        justifyContent="flex-end"
        alignItems="center"
        onClick={handleShow}
        data-testid="menuDropdown"
      >
        <Loader />
      </MainContainer>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setShowOptions(false)}>
      <MainContainer
        container
        item
        justifyContent="space-between"
        alignItems="flex-start"
        onClick={handleShow}
        data-testid="menuDropdown"
      >
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ProfileIcon icon={faUser} />
          <Grid style={{ width: '55%' }}>
            <Typography color="inherit" variant="h3">
              {userProfile.name}
            </Typography>
          </Grid>

          <MdMoreVert size="24" />
          {showOptions && (
            <OptionsContainer container item>
              <HeaderItems isThirdPartyUser={!!userProfile.thirdPartyProvider}/>
            </OptionsContainer>
          )}
        </Grid>
      </MainContainer>
    </ClickAwayListener>
  );
};

export default ProfileDropdown;
