import i18n from 'i18next';
import React from 'react';

import Browser from './browser';
import Mobile from './mobile';

export type TabProps = {
  title: string;
  route: string;
  component: JSX.Element;
};

export const tabList: TabProps[] = [
  {
    title: i18n.t('notifications.settings.browser'),
    route: 'profile/notifications-preferences/browser',
    component: <Browser />,
  },
  {
    title: i18n.t('notifications.settings.app'),
    route: 'profile/notifications-preferences/mobile',
    component: <Mobile />,
  },
];
