import type {
  TradeDownloadCargoStatement,
  TradeDownloadReport,
  TradeGetContracts,
} from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';
import api from '../api';

const contractQueryParams = (payload: TradeGetContracts) => {
  const params = new CustomURLSearchParams({});
  const filters = payload.filters;

  filters.contract.length > 0 &&
    params.set('contract', `%${filters.contract}%`);

  filters.harvestReferences.length > 0 &&
    params.set('harvestReference', filters.harvestReferences.join(','));

  filters.deliveryDate.startDate &&
    params.set(
      'plannedDeliveryDate(min)',
      filters.deliveryDate.startDate.toDateString() + ' 00:00:00'
    );

  filters.deliveryDate.endDate &&
    params.set(
      'plannedDeliveryDate(max)',
      filters.deliveryDate.endDate.toDateString() + ' 23:59:59'
    );

  filters.paymentDate.startDate &&
    params.set(
      'paymentDate(min)',
      filters.paymentDate.startDate.toDateString() + ' 00:00:00'
    );

  filters.paymentDate.endDate &&
    params.set(
      'paymentDate(max)',
      filters.paymentDate.endDate.toDateString() + ' 23:59:59'
    );

  filters.contractDeliveryStatus &&
    params.set('deliveryStatus', filters.contractDeliveryStatus);

  filters.productName.length > 0 &&
    params.set('productName', `%${filters.productName}%`);

  filters.propertyName.length > 0 &&
    params.set('propertyName', `%${filters.propertyName}%`);

  filters.searchTerm.length > 0 && params.set('search', filters.searchTerm);

  payload.limit && params.set('limit', payload.limit.toString());

  payload.page && params.set('page', payload.page.toString());

  return params;
};

export function getContracts(payload: TradeGetContracts) {
  const params = contractQueryParams(payload);
  return api.get(`/contract?${params}`);
}

export function downloadCargoStatement(payload: TradeDownloadCargoStatement) {
  const url = `/contract/${payload.contractId}/cargo/${payload.cargoStatementId}/download`;
  return api.get(url);
}

export function downloadReport(payload: TradeDownloadReport) {
  const url = `/contract/${payload.contractId}/download`;
  return api.get(url);
}

export default { downloadCargoStatement, downloadReport };
