import { Grid } from '@material-ui/core';
import React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import { Arrow, ArrowButton } from './styles';

type ListArrowProps = {
  type: 'right' | 'left';
  onClick: () => void;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
};

const ListArrow = ({
  type,
  onClick,
  style,
  containerStyle,
}: ListArrowProps) => {
  return (
    <Grid item sm={1} style={containerStyle}>
      <Arrow style={style}>
        <ArrowButton onClick={onClick}>
          {type === 'right' ? (
            <MdKeyboardArrowRight />
          ) : (
            <MdKeyboardArrowLeft />
          )}
        </ArrowButton>
      </Arrow>
    </Grid>
  );
};

export default ListArrow;
