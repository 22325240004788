import type {
  ChangePassword,
  DeleteAccount,
  ExistenceValues,
  RecoveryPassword,
  SetNewPassword,
  SignUpPost,
  UpdateUserDeviceToken,
  UpdateUserEmailOrPhone,
  UpdateUserNotificationConfig,
  UpdateUserProfile,
  UpdateUserWebToken,
} from '../../types';
import api from '../api';

export function createtUser(payload: SignUpPost): Promise<SignUpPost> {
  return api.post('/user', payload);
}

export function recoveryPassword(
  payload: RecoveryPassword
): Promise<RecoveryPassword> {
  return api.post('/user/password/recovery', payload);
}

export function changePassword(
  payload: ChangePassword
): Promise<ChangePassword> {
  return api.post('/user/password/change/by-token', payload);
}

export function setNewPassword(
  payload: SetNewPassword
): Promise<SetNewPassword> {
  return api.post('/user/password/change', payload);
}

export function getUserProfile() {
  return api.get(`/user/me`);
}

export function getAccountExistence(type: ExistenceValues, data: string) {
  return api.get(`/account/available/${type}/${data}`);
}

export function updateUserProfile(
  payload: UpdateUserProfile
): Promise<UpdateUserProfile> {
  return api.patch(`/user/me`, payload);
}

export function updateUserEmailOrPhone(
  payload: UpdateUserEmailOrPhone
): Promise<Pick<UpdateUserEmailOrPhone, 'data'>> {
  return api.patch(`/user/me/${payload.type}/request`, { data: payload.data });
}

export function createUserDeviceToken(
  payload: UpdateUserDeviceToken
): Promise<UpdateUserDeviceToken> {
  return api.put('/user/device-token', payload);
}

export function deleteUserDeviceToken(payload: UpdateUserDeviceToken) {
  return api.delete(`/user/device-token/${payload.token}`);
}

export function updateUserNotificationConfig(
  payload: UpdateUserNotificationConfig
): Promise<UpdateUserNotificationConfig> {
  return api.put(`/user/notification-config`, payload);
}

export function createUserWebToken(
  payload: UpdateUserWebToken
): Promise<UpdateUserWebToken> {
  return api.put('/user/web-token', payload);
}

export function deleteUserWebToken(payload: UpdateUserWebToken) {
  return api.delete(`/user/web-token/${payload.token}`);
}

export function deleteAccount(payload: DeleteAccount): Promise<DeleteAccount> {
  return api.post('/user/delete', payload);
}

export default {
  createtUser,
  recoveryPassword,
  changePassword,
  setNewPassword,
  getUserProfile,
  createUserDeviceToken,
  deleteUserDeviceToken,
  deleteAccount,
};
