import { Fab } from '@material-ui/core';
import { WhatsApp } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsLogger } from 'src/utils/analytics';
import { useTheme } from 'styled-components';

import { Container, FloatingMessage, Text } from './styles';

const ContactFloatingButton = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (window.location.pathname === '/dashboard') {
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 5000);
    }
  }, []);

  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  return (
    <Container>
      {showMessage && (
        <FloatingMessage>
          <Text color="primary">{t('commons.needHelp')}</Text>
          <Text color="secondary">{t('commons.talkToUs')}</Text>

          <div />
        </FloatingMessage>
      )}

      <Fab
        href={`https://api.whatsapp.com/send?1=pt_BR&phone=${whatsappNumber}`}
        rel="noopener noreferrer"
        target="_blank"
        size="small"
        style={{
          background: theme.colors.green,
        }}
        onClick={() => analyticsLogger.whatsapp('general', { floating: true })}
      >
        <WhatsApp color="secondary" />
      </Fab>
    </Container>
  );
};

export default ContactFloatingButton;
