import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { useMenuContext } from '../../../../../contexts/menu';
import { MenuItemProps } from './items';
import { Container, Content, Label } from './styles';

type Props = MenuItemProps & {
  openMenu: boolean;
};

const SubMenuItem = ({ id, label, Icon, url, openMenu, color }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { setShowMenu } = useMenuContext();

  const handleNavigation = useCallback(() => {
    if (setShowMenu) setShowMenu(false);
    navigate(url);
  }, [navigate, url, setShowMenu]);

  const isActive = pathname === url ? true : false;

  return (
    <Container
      onClick={handleNavigation}
      $isActive={isActive}
      $color={color}
      $openMenu={openMenu}
      data-testid={`menu-${id}`}
    >
      <Content $openMenu={openMenu}>
        {Icon && <Icon style={{ height: 24, width: 24 }} />}

        {openMenu && (
          <Label variant="body1" $isActive={isActive}>
            {t(`${label}`)}
          </Label>
        )}
      </Content>
    </Container>
  );
};

export default SubMenuItem;
