import { defaultTheme as theme } from '@sinagro/frontend-shared/themes';
import { DiseaseRiskLevelEnum } from '@sinagro/shared';

export const riskColor: Record<DiseaseRiskLevelEnum, string> = {
  veryLow: theme.colors.torqDisease.veryLow,
  low: theme.colors.torqDisease.low,
  medium: theme.colors.torqDisease.medium,
  high: theme.colors.torqDisease.high,
  veryHigh: theme.colors.torqDisease.veryHigh,
};
