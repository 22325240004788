import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  padding: 1rem;
`;

export const Title = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.xxxl}px;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    margin-bottom: 2rem;
  }
`;

export const Text = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.lg}px;
  }
`;

export const Footer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
`;
