import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineBugReport } from 'react-icons/md';
import Title from 'src/components/modules/torq/title';
import { useReportContext } from 'src/contexts/report';
import { useIsMobile } from 'src/hooks/responsiveLayout';
import { formattedDisease } from 'src/pages/torq/utils/utils';

import Card from './card';
import { Container, Subtitle, SubtitleContainer } from './styles';

const DiseaseForecast = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { diseaseForecast, plot } = useReportContext();
  const plotName = plot?.name;

  if (diseaseForecast.length === 0) {
    return <></>;
  }

  return (
    <Container container $isMobile={isMobile}>
      <Title title={t('report.diseaseForecast')} plotName={plotName} />
      {diseaseForecast.map((disease) => {
        return (
          <>
            <SubtitleContainer container key={disease.name}>
              <MdOutlineBugReport />
              <Subtitle>{disease.name}</Subtitle>
            </SubtitleContainer>
            <Grid container spacing={2}>
              {formattedDisease(disease)
                .splice(0, 6)
                .map((item) => {
                  return <Card item={item} key={item.date} />;
                })}
            </Grid>
          </>
        );
      })}
    </Container>
  );
};

export default DiseaseForecast;
