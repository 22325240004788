import { IRegion, RegionType } from '@sinagro/shared';

export const regionName = (region: IRegion) => {
  if (region.type === RegionType.Store) {
    const formattedAddress = [];
    const address = region.address;
    if (address?.city) formattedAddress.push(address.city);
    if (address?.state) formattedAddress.push(address.state);

    return formattedAddress.join(' - ');
  }

  return region.name;
};

export const styleGoogleAutoComplete = {
  fontSize: 14,
  padding: 0,
  border: 0,
  outline: 0,
  background: 'transparent',
};
