import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSize.xxxl}px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};

    margin: 1.75rem 0 2rem 0;
  }
`;

export const Description = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    max-width: 360px;

    margin-bottom: 2rem;
  }
`;

export const Success = styled(Grid)`
  height: 72px;
  align-items: center;
  justify-content: center;

  svg {
    animation-duration: 500ms;
    animation-name: successAnimation;
    font-size: 44px;
  }

  @keyframes successAnimation {
    from {
      font-size: 0;
    }

    75% {
      font-size: 72px;
    }

    to {
      font-size: 44px;
    }
  }
`;
