import { OptimalGrowthCurveStatus } from '@sinagro/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { growthColor } from '../../../utils/growth';
import { Container, Title, Description, GrowthItem } from './styles';

const Caption: React.FC = () => {
  const { t } = useTranslation();

  const optimals = Object.values(OptimalGrowthCurveStatus);

  return (
    <Container>
      <Title>{t('torq.farm.caption.title')}</Title>
      <Description>
        {optimals.map((optimal) => (
          <>
            <GrowthItem $color={growthColor[optimal]} key={optimal}>
              {t(`torq.farm.caption.status.${optimal}`)}
            </GrowthItem>
            {t(`torq.farm.caption.description.${optimal}`)}
          </>
        ))}
      </Description>
    </Container>
  );
};

export default Caption;
