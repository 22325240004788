import { createSlice } from '@reduxjs/toolkit';
import { DuplicateStatusEnum } from '@sinagro/shared';

import {
  FetchStatusEnum,
  DuplicateSlice,
  DuplicateUpdateErrorAction,
  PersistDuplicatesAction,
  ToogleDuplicateAction,
  ToggleSelectAllAction,
  UpdateKeyAction,
  SendToSignatureAction,
  GetDuplicatesAction,
  SendToRevisionAction,
  SendOneDuplicateToRevisionAction,
  SelectDuplicatesAction,
  DuplicatesDownloadInvoiceByIdAction,
} from '../../types';

export const initialState: DuplicateSlice = {
  duplicates: [],
  selectedIds: {},
  totalPending: 0,
  fetchStatus: FetchStatusEnum.None,
  sendToSignatureStatus: FetchStatusEnum.None,
  sendToRevisionStatus: FetchStatusEnum.None,
  fetchStatusDownload: FetchStatusEnum.None,
  lastInvoiceDownloadedByDuplicateId: {},
  idsWithErrors: [],
  page: 0,
  limit: 25,
  totalItems: 0,
  selectAll: false,
  error: null,
};

export const slice = createSlice({
  name: 'duplicate',
  initialState,
  reducers: {
    clear: () => initialState,
    clearSelectedItems: (state) => {
      state.selectedIds = {};
      state.selectAll = false;
    },
    clearRevision: (state) => {
      state.refuseNotes = undefined;
      state.refuseReason = undefined;
    },
    getDuplicates: (state, action: GetDuplicatesAction) => {
      const { page, limit } = action.payload;
      state.page = page;
      state.limit = limit;
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    persistDuplicates: (state, action: PersistDuplicatesAction) => {
      const { page, duplicates, total, totalPending, resetItems } =
        action.payload;
      state.totalPending =
        page === 0 || resetItems
          ? totalPending
          : state.totalPending + totalPending;
      state.duplicates =
        page === 0 || resetItems
          ? duplicates
          : [...state.duplicates, ...duplicates];
      state.totalItems = total;
    },
    toogleDuplicate: (state, action: ToogleDuplicateAction) => {
      const { id, value } = action.payload;

      const clone = state.selectedIds;

      if (!value) {
        delete clone[id];
        state.selectedIds = clone;
      } else {
        state.selectedIds = { ...state.selectedIds, [id]: true };
      }
    },
    toogleMultipleDuplicate: (state, action: SelectDuplicatesAction) => {
      const { ids } = action.payload;

      state.selectedIds = ids.reduce<Record<string, true>>((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
    },
    updateStatus: (state, action) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    toggleSelectAll: (state, action: ToggleSelectAllAction) => {
      const clone = action.payload.isAllSelected;
      const nextState = !clone;

      if (!nextState) {
        state.selectedIds = {};
      } else {
        state.selectedIds = state.duplicates
          .filter(
            (item) => item.status === DuplicateStatusEnum.PendingSignature
          )
          .reduce<Record<string, true>>((acc, item) => {
            acc[item.id] = true;
            return acc;
          }, {});
      }
    },
    sendToSignature: (state, _: SendToSignatureAction) => {
      state.sendToSignatureStatus = FetchStatusEnum.Fetching;
    },
    sendToRevision: (state, _: SendToRevisionAction) => {
      state.sendToRevisionStatus = FetchStatusEnum.Fetching;
    },
    sendOneDuplicateToSignature: (
      state,
      _: SendOneDuplicateToRevisionAction
    ) => {
      state.sendToSignatureStatus = FetchStatusEnum.Fetching;
    },
    sendOneDuplicateToRevision: (
      state,
      _: SendOneDuplicateToRevisionAction
    ) => {
      state.sendToRevisionStatus = FetchStatusEnum.Fetching;
    },
    updateError: (state, action: DuplicateUpdateErrorAction) => {
      state.error = action.payload.error;
    },
    updateKey: (state, action: UpdateKeyAction) => {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    },
    downloadInvoiceById: (state, __: DuplicatesDownloadInvoiceByIdAction) => {
      state.fetchStatusDownload = FetchStatusEnum.Fetching;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
