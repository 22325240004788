import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Link, Typography } from '@material-ui/core';
import { MdEdit, MdDelete } from 'react-icons/md';
import styled from 'styled-components';

export const FieldContainer = styled(Grid)`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const VerifyIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.green};
`;

export const ProfileContainer = styled(Grid)<{ isMobile: boolean }>`
  height: 25rem;
  padding: 2rem;
  border-radius: 3px;
  box-shadow: 1px 1px 10px 0px lightgrey;
  background: ${({ theme }) => theme.colors.white};
  width: ${({ isMobile }) => (isMobile ? '100%' : '48%')} !important;
  margin-bottom: ${({ isMobile }) => (isMobile ? '2rem' : '0')} !important;
`;

export const ClientInfosContainer = styled(Grid)<{ isMobile: boolean }>`
  height: 25rem;
  padding: 2rem;
  border-radius: 3px;
  box-shadow: 1px 1px 10px 0px lightgrey;
  background: ${({ theme }) => theme.colors.white};
  width: ${({ isMobile }) => (isMobile ? '100%' : '48%')} !important;
  margin-bottom: ${({ isMobile }) => (isMobile ? '2rem' : '0')} !important;
  overflow-y: scroll;
`;

export const Title = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.xxxl}px;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
`;

export const SubtitleCategory = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.xl}px;
  }
`;

export const Subtitle = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    margin: 1rem 0;
  }
`;

export const Text = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    margin-bottom: 1rem;
  }
`;

export const SaveContainer = styled(Grid)`
  text-align: center;
  justify-content: flex-end;
`;

export const CheckLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.colors.orange};
    font-size: ${({ theme }) => theme.fontSize.md}px;
    text-decoration: underline;
  }

  :hover {
    cursor: pointer;
  }
`;

export const EditIcon = styled(MdEdit)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSize.xl}px !important;
  margin-left: 1rem;

  :hover {
    cursor: pointer;
  }
`;

export const Delete = styled(Link)<{ component: 'button' }>`
  font-size: ${({ theme }) => theme.fontSize.lg}px;
  font-family: ${({ theme }) => theme.fontFamily};

  display: flex;
  align-items: center;
`;

export const DeleteIcon = styled(MdDelete)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSize.xl}px !important;
  margin-right: 0.5rem;
`;
