import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Summary = styled(Grid)`
  display: flex;
  width: 252px;
  align-items: center;
  justify-content: space-between;
`;

export const DateContent = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p:first-child {
    font-size: ${({ theme }) => theme.fontSize.xxxl}px;
  }

  p:not(:first-child) {
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-top: 8px;
  }
`;

export const TemperatureContent = styled(Grid)`
  margin-top: 1.5rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const WeatherConditionContent = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  max-width: 120px;
  text-align: right;

  p {
    color: ${({ theme }) => theme.colors.textSecondary} !important;
  }
`;

export const TextTemperature = styled(Typography)<{
  $isActive: boolean;
  $isMobile: boolean;
}>`
  && {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.text : theme.colors.textSecondary};
    font-size: ${({ theme, $isMobile }) =>
      $isMobile ? theme.fontSize.xxl : theme.fontSize.xxxl}px;
  }
`;

export const Text = styled(Typography)<{ $isMobile: boolean }>`
  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme, $isMobile }) =>
      $isMobile ? theme.fontSize.md : theme.fontSize.lg}px;
  }
`;

export const PrecipitationContent = styled(Grid)`
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 1rem;

  p {
    color: ${({ theme }) => theme.colors.torqBlue} !important;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 80%;
  background: ${({ theme }) => theme.colors.white};
`;
