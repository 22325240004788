import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(231, 234, 246, 0) 8.4%,
      #e7eaf6 23.44%
    ),
    #f9faff;
  min-height: 100vh;
`;
