export const replaceMask = (value: string) => value.replace(/\W/g, '');
export const replacePhoneMask = (value: string) =>
  value.replace(/[()-\s]/g, '');

export const cpfValidation = (value?: string) => {
  if (!value) return false;
  const cpf = replaceMask(value);

  let Soma: number;
  let Resto: number;
  Soma = 0;
  if (cpf == '00000000000') return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(cpf.substring(9, 10))) return false;

  Soma = 0;
  for (let j = 1; j <= 10; j++)
    Soma = Soma + parseInt(cpf.substring(j - 1, j)) * (12 - j);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const cnpjValidation = (value?: string) => {
  if (!value) return false;

  const cnpj = value.replace(/\W/g, '');

  if (cnpj.length !== 14) return false;

  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;

  let length = cnpj.length - 2;
  let numbers = cnpj.substring(0, length);
  const digit = cnpj.substring(length);
  let soma = 0;
  let pos = length - 7;
  for (let i = length; i >= 1; i--) {
    soma += Number.parseInt(numbers.charAt(length - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  let result = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (result !== Number(digit.charAt(0))) return false;

  length += 1;
  numbers = cnpj.substring(0, length);
  soma = 0;
  pos = length - 7;
  for (let i = length; i >= 1; i--) {
    soma += Number.parseInt(numbers.charAt(length - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  result = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (result !== Number(digit.charAt(1))) return false;

  return true;
};
