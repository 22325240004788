import type { FiltersType } from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';

export const creditFiltersParams = (
  params: CustomURLSearchParams,
  filters: FiltersType
) => {
  filters.harvestReferences.length > 0 &&
    params.set('harvestReference', filters.harvestReferences.join(','));

  return params;
};
