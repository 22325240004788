import React from 'react';

export type NotificationType = {
  createUserWebToken?: () => Promise<void>;
  deleteUserWebToken?: () => Promise<void>;
};

export const Notification = React.createContext<NotificationType>({});

export const useNotificationContext = (): React.ContextType<
  React.Context<NotificationType>
> => {
  return React.useContext(Notification);
};
