import i18n from 'i18next';
import React from 'react';

import ChangePassword from '../changePassword';
import Settings from '../notifications/settings';
import UserManagement from '../userManagement';
import UserProfile from '../userProfile';

export type TabProps = {
  id: ProfileTabListIdsEnum;
  title: string;
  route: string;
  component: JSX.Element;
};

export enum ProfileTabListIdsEnum {
  userProfile = 'user-profile',
  userManagement = 'user-management',
  changePassword = 'change-password',
  notificationPreferences = 'notification-preferences',
}

export const tabList: TabProps[] = [
  {
    id: ProfileTabListIdsEnum.userProfile,
    title: i18n.t('profile.tab.userProfile'),
    route: '/profile/user-profile',
    component: <UserProfile />,
  },
  {
    id: ProfileTabListIdsEnum.userManagement,
    title: i18n.t('profile.tab.userManagement'),
    route: '/profile/user-management',
    component: <UserManagement />,
  },
  {
    id: ProfileTabListIdsEnum.changePassword,
    title: i18n.t('profile.tab.changePassword'),
    route: '/profile/change-password',
    component: <ChangePassword />,
  },
  {
    id: ProfileTabListIdsEnum.notificationPreferences,
    title: i18n.t('profile.tab.notificationPreferences'),
    route: '/profile/notification-preferences',
    component: <Settings />,
  },
];
