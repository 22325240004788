import { Grid, Link } from '@material-ui/core';
import styled from 'styled-components';

export const TitleContainer = styled(Grid)`
  padding: 2rem;
  text-align: center;
`;

export const SubtitleContainer = styled(Grid)`
  padding: 3rem 3rem;
  text-align: center;
`;

export const MainContainer = styled(Grid)`
  min-height: 100vh;
`;

export const SubmitContainer = styled(Grid)`
  width: 70%;
  padding: 1rem 0 1.5rem 0;
  display: flex;
  justify-content: center;
`;

export const PrivacyPolicy = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.md};
  padding-right: 1rem;
  @media (max-width: 400px) {
    width: 100%;
    text-align: center;
    padding-right: 0;
    padding-bottom: 0.5rem;
  }
`;

export const TermsOfUse = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.md};
  @media (max-width: 400px) {
    width: 100%;
    text-align: center;
  }
`;
