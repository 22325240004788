import type { FiltersType, OpenCreditsGet } from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';

export const openCreditsFiltersParams = (
  params: CustomURLSearchParams,
  filters: FiltersType
) => {
  filters.harvestReferences.length > 0 &&
    params.set('harvestReference', filters.harvestReferences.join(','));

  filters.searchTerm.length > 0 && params.set('search', filters.searchTerm);

  filters.paymentDate.startDate &&
    params.set(
      'paymentDate(min)',
      filters.paymentDate.startDate.toDateString() + ' 00:00:00'
    );

  filters.paymentDate.endDate &&
    params.set(
      'paymentDate(max)',
      filters.paymentDate.endDate.toDateString() + ' 23:59:59'
    );

  filters.paymentMethod && params.set('paymentMethod', filters.paymentMethod);

  filters.paymentStatus && params.set('paymentStatus', filters.paymentStatus);

  filters.propertyName.length > 0 &&
    params.set('propertyName', `%${filters.propertyName}%`);

  filters.invoice.length > 0 && params.set('invoiceId', `%${filters.invoice}%`);

  return params;
};

export const openCreditsPageParams = (
  params: CustomURLSearchParams,
  payload: OpenCreditsGet
) => {
  payload.limit && params.set('limit', payload.limit.toString());

  payload.page && params.set('page', payload.page.toString());

  return params;
};
