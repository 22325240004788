export const getInputProps = (
  parentElementIsPopper?: boolean,
  top?: number
) => {
  return parentElementIsPopper
    ? {
        MenuProps: {
          disablePortal: true,
          anchorEl: this,
          style: {
            position: 'absolute',
            minHeight: 300,
            top: top ?? -570,
            left: -12,
          },
        },
      }
    : {
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          disableScrollLock: true,
          style: {
            maxHeight: 276,
            position: 'absolute',
            marginTop: -8,
          },
        },
      };
};
