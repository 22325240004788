import translations from '@sinagro/frontend-shared/i18n';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  pluralSeparator: '_',
  resources: {
    pt_br: {
      translation: {
        ...translations,
      },
    },
  },
  lng: 'pt_br',
  fallbackLng: 'pt_br',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
