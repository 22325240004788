import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import React from 'react';
import GlobalLoader from 'src/components/modules/globalLoader';
import { useSelector } from 'src/state/store';

import { useIsMobile } from '../../../hooks/responsiveLayout';
import Backgrounds from './backgrounds';
import { ContentContainer, WelcomeContainer } from './styles';

const WelcomeLayout: React.FC = ({ children }) => {
  const isMobile = useIsMobile();
  const fetchStatus = useSelector((state) => state.auth.fetchStatus);
  const checkingAuthStatus = useSelector(
    (state) => state.auth.checkingAuthStatus
  );
  const isLoading = fetchStatus === FetchStatusEnum.Fetching;

  if (isLoading || checkingAuthStatus) {
    return <GlobalLoader />;
  }

  return (
    <WelcomeContainer container>
      {!isMobile && <Backgrounds />}

      <ContentContainer
        item
        style={{ padding: isMobile ? '0 3rem' : '0 4rem', flex: 1 }}
      >
        {children}
      </ContentContainer>
    </WelcomeContainer>
  );
};

export default WelcomeLayout;
