import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdChevronRight } from 'react-icons/md';
import {
  items,
  menuItemsFeatureFlag,
} from 'src/components/modules/menu/components/menuItems/items';
import { useSelector } from 'src/state/store';
import { useTheme } from 'styled-components';

import { Container, Content, Text } from './styles';

const TorqCard = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const featureFlag = useSelector(
    (state) => state.shared.featureFlag.featureFlag
  );
  const regions =
    useSelector((state) => state.shared.userProfile.userProfile.regions) || [];
  const isClientTorq = !!regions.length;

  const menuItems = menuItemsFeatureFlag(items(isClientTorq), featureFlag);
  const torqItem = menuItems.find((item) => item.label === 'menu.torq') || null;

  if (!torqItem) {
    return <></>;
  }

  const { url, Icon, label } = torqItem;

  return (
    <Container to={url} data-testid={`card-torq`}>
      <Content>
        {Icon && (
          <Icon
            fill={theme.colors.quaternary}
            style={{ height: 48, width: 48 }}
          />
        )}
        <Grid>
          <Text>{t(`${label}`)}</Text>
          <Text $small>{t('torq.subtitle')}</Text>
        </Grid>
      </Content>
      <MdChevronRight color={theme.colors.white} size={24} />
    </Container>
  );
};

export default TorqCard;
