import React from 'react';

import { Container, Tag, Text } from './styles';

type PlotTagProps = {
  plotName: string;
};

const PlotTag = ({ plotName }: PlotTagProps) => {
  return (
    <Container>
      <Tag>
        <Text>{plotName}</Text>
      </Tag>
      <Text style={{ fontSize: 20 }}>*</Text>
    </Container>
  );
};

export default PlotTag;
