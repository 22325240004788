import type {
  FinancialPayableGetPayables,
  FinancialPayableGetPayablesPaged,
  FinancialPayableGetSummary,
} from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';
import api from '../api';
import { payablesFiltersParams, payablesPageParams } from './queryParams';

export function getSummary(payload: FinancialPayableGetSummary) {
  let params = new CustomURLSearchParams({});
  params = payablesFiltersParams(params, payload.filters);

  return api.get(`/financial/payable/aggregated/summary?${params}`);
}

export function getPayables(payload: FinancialPayableGetPayables) {
  let params = new CustomURLSearchParams({});
  params = payablesFiltersParams(params, payload.filters);

  return api.get(`/financial/payable/aggregated?${params}`);
}

export function getPayablesPaged(payload: FinancialPayableGetPayablesPaged) {
  let params = new CustomURLSearchParams({});

  params = payablesFiltersParams(params, payload.filters);
  params = payablesPageParams(params, payload);
  return api.get(`/financial/payable?${params}`);
}
