import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../hooks/responsiveLayout';
import { Subtitle, Title } from './styles';

type Props = {
  title?: string;
  subtitle?: string;
  marginBottom?: string;
};

const PortalTitle = ({ title, subtitle, marginBottom = '2rem' }: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <div style={{ marginBottom }}>
      {title ? (
        <Title color="primary" $isMobile={isMobile}>
          {title}
        </Title>
      ) : (
        <Title color="primary" $isMobile={isMobile}>
          {t('commons.portalTitle')}
        </Title>
      )}

      {subtitle && <Subtitle color="primary">{subtitle}</Subtitle>}
    </div>
  );
};

export default PortalTitle;
