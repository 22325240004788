import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  && {
    .MuiFormControl-root {
      width: 100% !important;
    }

    .MuiInputBase-root {
      width: 100% !important;
      height: 36px;
      padding-left: 0.5rem;
      border-radius: 0.5rem;
    }

    .MuiSelect-select:focus {
      background-color: transparent;
    }
  }
`;
