import { Grid } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { Title, Description, Success } from './styles';

export type InviteFormType = {
  email: string;
};

const InviteUserSuccess = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid
      container
      item
      justifyContent="center"
      style={{ padding: '0.75rem 1rem 0 1rem' }}
    >
      <Success container item justifyContent="center">
        <CheckCircle style={{ color: theme.colors.quaternary }} />
      </Success>
      <Title>{t('userManagement.inviteUserSuccess.title')}</Title>
      <Description>
        {t('userManagement.inviteUserSuccess.description.part1')}
      </Description>
      <Description>
        {t('userManagement.inviteUserSuccess.description.part2')}
      </Description>
    </Grid>
  );
};

export default InviteUserSuccess;
