import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
`;

export const Tag = styled(Grid)`
  border: 1px solid ${({ theme }) => theme.colors.textSecondary};
  border-radius: 16px;
  padding: 0.25rem 1rem;
`;

export const Text = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: ${({ theme }) => theme.fontSize.md}px;
  }
`;
