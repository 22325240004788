import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { ProfileTabListIdsEnum } from 'src/pages/profile/tabs';
import { useTheme } from 'styled-components';

import {
  HeaderItemContainer,
  HeaderItemDataContainer,
  HeaderItemTextDataContainer,
} from './styles';

export type HeaderItemProps = {
  Icon: IconType;
  label: string;
  url: string;
  id: ProfileTabListIdsEnum
};

const HeaderItem = ({ label, url, Icon }: HeaderItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const handleNavigation = useCallback(() => {
    navigate(url);
  }, [navigate, url]);

  const isActive = pathname === url ? true : false;

  return (
    <HeaderItemContainer onClick={handleNavigation} $isActive={isActive}>
      <HeaderItemDataContainer>
        <Icon
          color={isActive ? theme.colors.black : theme.colors.secondary}
          style={{ height: 20, width: 20 }}
        />
        <HeaderItemTextDataContainer
          color={isActive ? 'inherit' : 'textPrimary'}
          variant="h6"
          $isActive={isActive}
        >
          {t(`${label}`)}
        </HeaderItemTextDataContainer>
      </HeaderItemDataContainer>
    </HeaderItemContainer>
  );
};

export default HeaderItem;
