import { createSlice } from '@reduxjs/toolkit';
import { analyticsLogger } from 'src/utils/analytics';

import { ColumnsSlice, GetColumnsAction, SetColumnsAction } from './types';

const initialState: ColumnsSlice = {
  columns: null,
};

export const slice = createSlice({
  name: 'columns',
  initialState,
  reducers: {
    clear: () => initialState,
    getColumns: (state, action: GetColumnsAction) => {
      const storage = localStorage.getItem(action.payload.tableKey);
      const columns = storage ? JSON.parse(storage) : null;
      state.columns = { ...action.payload.columns, ...columns };
    },
    setColumns: (state, action: SetColumnsAction) => {
      const columns = action.payload.columns;
      localStorage.setItem(action.payload.tableKey, JSON.stringify(columns));
      state.columns = action.payload.columns;
      analyticsLogger.changeOrderColumns(action.payload.columns);
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
