import { Grid } from '@material-ui/core';
import { Menu, Clear } from '@material-ui/icons';
import styled from 'styled-components';

export const MenuContentContainer = styled.div<{
  showMenu: boolean;
  headerHeight: number;
}>`
  position: absolute;
  top: ${({ headerHeight }) => headerHeight}px;
  opacity: 0.98;
  right: 0;
  bottom: 0;
  transition: 0.25s transform ease-in-out;
  width: 100%;
  -webkit-transform: ${({ showMenu }) =>
    showMenu ? 'translateX(0%)' : ' translateX(100%)'};
  transform: ${({ showMenu }) =>
    showMenu ? 'translateX(0%)' : ' translateX(100%)'};
`;

export const MainContainer = styled(Grid)<{ $headerHeight: number }>`
  position: fixed;
  height: ${({ $headerHeight }) => $headerHeight}px;
  padding: 0 0 0 1rem;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const MenuIcon = styled(Menu)`
  color: ${({ theme }) => theme.colors.primary};
  transition: transform 150ms ease;
`;

export const ClearIcon = styled(Clear)`
  color: ${({ theme }) => theme.colors.primary};
  transition: transform 150ms ease;
`;
