import { createSlice } from '@reduxjs/toolkit';

import {
  DownloadInvoicesAction,
  DownloadReceiptsAction,
  FetchStatusEnum,
  GroupedRemittanceFetchAction,
  GroupedRemittanceGetAction,
  GroupedRemittanceUpdateErrorAction,
  GroupedRemittanceUpdateStatusAction,
  RemittanceHistoryFetchAction,
  RemittanceHistoryGetAction,
  RemittanceHistoryUpdateStatusAction,
  TrackingSlice,
} from '../../types';

export const initialState: TrackingSlice = {
  groupedRemittance: [],
  remittanceHistory: [],
  remittanceHistoryTotal: 0,
  fetchStatus: FetchStatusEnum.None,
  fetchStatusHistory: FetchStatusEnum.None,
  fetchStatusDownloadReceipts: FetchStatusEnum.None,
  fetchStatusDownloadInvoices: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    clear: () => initialState,
    getGroupedRemittance: (state, _: GroupedRemittanceGetAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    groupedRemittance: (state, action: GroupedRemittanceFetchAction) => {
      const { groupedRemittance } = action.payload;
      state.groupedRemittance = groupedRemittance;
    },
    getRemittanceHistory: (state, _: RemittanceHistoryGetAction) => {
      state.fetchStatusHistory = FetchStatusEnum.Fetching;
    },
    remmitanceHistory: (state, action: RemittanceHistoryFetchAction) => {
      const { remittanceHistory, total, page } = action.payload;
      state.remittanceHistory =
        page === 0
          ? remittanceHistory
          : [...state.remittanceHistory, ...remittanceHistory];
      state.remittanceHistoryTotal = total;
    },
    downloadReceipts: (state, _: DownloadReceiptsAction) => {
      state.fetchStatusDownloadReceipts = FetchStatusEnum.Fetching;
    },
    downloadInvoices: (state, _: DownloadInvoicesAction) => {
      state.fetchStatusDownloadInvoices = FetchStatusEnum.Fetching;
    },
    updateStatus: (state, action: GroupedRemittanceUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateStatusHistory: (
      state,
      action: RemittanceHistoryUpdateStatusAction
    ) => {
      state.fetchStatusHistory = action.payload.fetchStatus;
    },
    updateStatusDownloadReceipts: (
      state,
      action: GroupedRemittanceUpdateStatusAction
    ) => {
      state.fetchStatusDownloadReceipts = action.payload.fetchStatus;
    },
    updateStatusDownloadInvoices: (
      state,
      action: GroupedRemittanceUpdateStatusAction
    ) => {
      state.fetchStatusDownloadInvoices = action.payload.fetchStatus;
    },
    updateError: (state, action: GroupedRemittanceUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
