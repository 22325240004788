import { SagaIterator } from '@redux-saga/types';
import * as Sentry from '@sentry/browser';
import firebase from '@sinagro/frontend-shared/firebase';
import { Api } from '@sinagro/frontend-shared/services';
import { TokenClaims } from '@sinagro/shared';
import { eventChannel } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { actions } from '../store';

export function onIdTokenChangedChannel() {
  return eventChannel((emit) => {
    const unsubscribe = firebase.auth().onIdTokenChanged((user) => {
      emit({ user });
    });
    return unsubscribe;
  });
}

type GetIdTokenResultResponse = {
  tokenClaims: TokenClaims;
  token: string;
};

export function* getIdTokenResult(
  user: firebase.User
): SagaIterator<GetIdTokenResultResponse> {
  const { claims, token }: firebase.auth.IdTokenResult = yield call([
    user,
    'getIdTokenResult',
  ]);
  const tokenClaims = claims as TokenClaims;

  return { tokenClaims, token };
}

export function* syncUser(user?: firebase.User) {
  if (user) {
    const { token, tokenClaims }: GetIdTokenResultResponse = yield call(
      getIdTokenResult,
      user
    );
    Api.setToken(token);

    const userFirebase = {
      email: user.email ?? '',
      displayName: user.displayName ?? '',
      phoneNumber: user.phoneNumber ?? '',
      photoURL: user.photoURL ?? '',
    };

    yield put(
      actions.shared.user.update({ claims: tokenClaims, userFirebase })
    );

    Sentry.setUser({ id: tokenClaims.id });
  }
}
