import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Button from '../../../components/elements/materialUI/button';
import { useIsMobile } from '../../../hooks/responsiveLayout';
import DeleteAccountForm from './form';
import {
  Container,
  ButtonContainer,
  DeleteAccountIcon,
  Text,
  Title,
  DeleteIcon,
} from './styles';

type Props = {
  onClose: () => void;
};

const DeleteAccount: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useIsMobile();

  const width = isMobile ? '100%' : 'auto';

  const [confirmDeletion, setConfirmDeletion] = useState(false);

  if (confirmDeletion) {
    return <DeleteAccountForm onClose={onClose} />;
  }

  return (
    <Container>
      <DeleteAccountIcon name="delete-forever" />
      <Title color="primary">{t('profile.deleteAccount.title')}</Title>
      <Text color="secondary">
        {t('profile.deleteAccount.description.part1')}
      </Text>
      <Text color="secondary">
        {t('profile.deleteAccount.description.part2')}
      </Text>
      <ButtonContainer container>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          style={{ width }}
        >
          <Typography variant="h6">
            {t('profile.deleteAccount.cancel')}
          </Typography>
        </Button>
        <Button
          variant="outlined"
          style={{
            color: theme.colors.danger,
            borderColor: theme.colors.danger,
            height: '3rem',
            width,
          }}
          onClick={() => setConfirmDeletion(true)}
        >
          <DeleteIcon name="delete-forever" $color="danger" />
          <Typography variant="h6">
            {t('profile.deleteAccount.confirm')}
          </Typography>
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default DeleteAccount;
