import i18n from 'i18next';
import React, { ReactElement } from 'react';

import {
  CornIcon,
  CottonIcon,
  CropIcon,
  GrowthCurveIcon,
  HarvestEstimateIcon,
  NDVIIcon,
  SoyIcon,
} from './styles';

export enum RegionPropertiesEnum {
  HarvestEstimate = 'harvestEstimate',
  GrowthCurve = 'growthCurve',
  NDVI = 'ndvi',
  Crop = 'crop',
}

type RegionPropertiesProps = {
  label: string;
  Icon: ReactElement;
};

export const regionProperties: Record<
  RegionPropertiesEnum,
  RegionPropertiesProps
> = {
  [RegionPropertiesEnum.Crop]: {
    label: i18n.t('torq.plot.summary.crop'),
    Icon: <CropIcon />,
  },
  [RegionPropertiesEnum.HarvestEstimate]: {
    label: i18n.t('torq.plot.summary.harvestEstimate'),
    Icon: <HarvestEstimateIcon />,
  },
  [RegionPropertiesEnum.GrowthCurve]: {
    label: i18n.t('torq.plot.summary.growthCurve'),
    Icon: <GrowthCurveIcon />,
  },
  [RegionPropertiesEnum.NDVI]: {
    label: i18n.t('torq.plot.summary.ndvi'),
    Icon: <NDVIIcon />,
  },
};

export const cropIcon: Record<string, ReactElement> = {
  algodão: <CottonIcon />,
  milho: <CornIcon />,
  soja: <SoyIcon />,
  outro: <CropIcon />,
};
