import { DefaultThemeTorqGrowthCurveColorsType } from '@sinagro/frontend-shared/themes';
import { OptimalGrowthCurveStatus } from '@sinagro/shared';

export const growthColor: Record<
  OptimalGrowthCurveStatus,
  DefaultThemeTorqGrowthCurveColorsType
> = {
  [OptimalGrowthCurveStatus.AboveOptimalBand]: 'above',
  [OptimalGrowthCurveStatus.BelowOptimalBand]: 'below',
  [OptimalGrowthCurveStatus.BeyondOptimalBand]: 'beyond',
  [OptimalGrowthCurveStatus.NewPlot]: 'newPlot',
  [OptimalGrowthCurveStatus.NoBandForCrop]: 'noBandForCrop',
  [OptimalGrowthCurveStatus.WaitingHarvestClosure]: 'waitingHarvestClosure',
  [OptimalGrowthCurveStatus.WaitingNewHarvest]: 'waitingNewHarvest',
  [OptimalGrowthCurveStatus.WithinOptimalBand]: 'within',
};
