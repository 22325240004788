import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NewsPanel from 'src/components/modules/newsPanel';
import ScheduleAppointment from 'src/components/modules/scheduleAppointment';
import ServicesCards from 'src/components/modules/servicesCards';

import PortalTitle from '../../components/modules/portalTitle';
import { useIsMobile } from '../../hooks/responsiveLayout';

const Services: React.FC = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const padding = isMobile ? '2rem 1rem' : '2rem 4rem';
  const gridDirection = isMobile ? 'column-reverse' : 'row';

  return (
    <Grid
      container
      style={{ padding }}
      direction={gridDirection}
      justifyContent="space-between"
      spacing={3}
    >
      <Grid item lg={7} xs={12}>
        {!isMobile && <PortalTitle title={t('commons.portalTitleHome')} />}
        <ServicesCards />
        {isMobile && (
          <>
            <ScheduleAppointment />
            <NewsPanel />
          </>
        )}
      </Grid>
      <Grid item lg={5} xs={12}>
        {!isMobile && (
          <>
            <ScheduleAppointment />
            <NewsPanel />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Services;
