import React from 'react';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import PlotTag from './plotTag';
import { Container, Text } from './styles';

type TitleProps = {
  title: string;
  plotName?: string;
};

const Title = ({ title, plotName }: TitleProps) => {
  const isMobile = useIsMobile();

  return (
    <Container $isMobile={isMobile}>
      <Text>{title}</Text>
      {plotName && <PlotTag plotName={plotName} />}
    </Container>
  );
};

export default Title;
