import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DayWeather from 'src/components/modules/torq/dayWeather';
import ListArrow from 'src/components/modules/torq/listArrow';
import Title from 'src/components/modules/torq/title';
import { useReportContext } from 'src/contexts/report';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { DailyContainer, DayWeatherContainer, List } from './styles';
import { Container } from './styles';

const Weather = () => {
  const { t } = useTranslation();
  const { weather, plot } = useReportContext();
  const isMobile = useIsMobile();

  const [index, setIndex] = useState(0);
  const ref = createRef<HTMLDivElement>();
  const plotName = plot?.name;

  const showArrowLeft = index > 0 && !isMobile;
  const showArrowRight = index < 4 && !isMobile;
  const arrowsActive = showArrowLeft && showArrowRight;
  const listGridSize = isMobile ? 12 : arrowsActive ? 10 : 11;

  const updateIndex = (type: 'prev' | 'next') => {
    if (type === 'next' && index < 4) {
      setIndex(index + 1);
    }

    if (type == 'prev' && index > 0) {
      setIndex(index - 1);
    }
  };

  const updateScroll = useCallback(() => {
    ref.current?.scrollTo({
      left: 296 * index,
    });
  }, [index, ref]);

  useEffect(() => {
    updateScroll();
  }, [index, updateScroll]);

  if (weather.length === 0) {
    return <></>;
  }

  return (
    <Container container $isMobile={isMobile}>
      <Title title={t('report.weather')} plotName={plotName} />

      <DailyContainer container>
        {showArrowLeft && (
          <ListArrow
            type="left"
            onClick={() => updateIndex('prev')}
            style={{
              marginRight: '1rem',
              borderRadius: 8,
            }}
            containerStyle={{
              marginBottom: '1rem',
            }}
          />
        )}

        <List item ref={ref} sm={listGridSize}>
          {weather.map((day) => (
            <DayWeatherContainer key={day.date}>
              <DayWeather day={day} type="report" />
            </DayWeatherContainer>
          ))}
        </List>

        {showArrowRight && (
          <ListArrow
            type="right"
            onClick={() => updateIndex('next')}
            style={{
              marginLeft: '1rem',
              borderRadius: 8,
            }}
            containerStyle={{
              marginBottom: '1rem',
            }}
          />
        )}
      </DailyContainer>
    </Container>
  );
};

export default Weather;
