import { Grid, Link, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  width: 100%;
  flex: 1;
`;

export const LoaderContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export const EmptyMessage = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    color: ${({ theme }) => theme.colors.textSecondary};
    text-align: center;
    padding: 1rem;
    margin-top: 2rem;
  }
`;

export const LinkToNotificationConfig = styled(Link)`
  text-decoration: revert !important;
`;
