import {
  Typography,
  Checkbox,
  Box,
  Grid,
  FormControlLabel,
} from '@material-ui/core';
import React, { InputHTMLAttributes } from 'react';
import {
  Controller,
  Control,
  UseControllerProps,
  FieldValues,
} from 'react-hook-form';

import ErrorContainer from './errorContainer';

interface FormCheckboxProps<T> extends UseControllerProps<T> {
  control: Control<T>;
  errorMessage?: string;
  label?: React.ReactNode | string;
  testId?: string;
}

const FormCheckbox = <T extends FieldValues>(
  props: FormCheckboxProps<T>
): JSX.Element => {
  const { control, errorMessage, defaultValue, name, label } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value as boolean}
                  color="primary"
                  size="small"
                  inputProps={
                    {
                      'data-testid': props.testId,
                    } as InputHTMLAttributes<HTMLInputElement>
                  }
                />
              }
              label={
                <Typography
                  variant="body2"
                  color="secondary"
                  style={{ paddingLeft: '0.3rem' }}
                >
                  {label}
                </Typography>
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Box pl={0.2} pt={0.4}>
          {errorMessage && (
            <ErrorContainer message={errorMessage} icon={true} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormCheckbox;
