import { Grid, Typography } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';

import Loader from '../../components/elements/loader';
import Button from '../../components/elements/materialUI/button';
import Modal from '../../components/elements/modal';
import { useIsMobile } from '../../hooks/responsiveLayout';
import { actions, useDispatch, useSelector } from '../../state/store';
import Item from './inviteItem';
import InviteUser from './inviteUser';
import InviteUserSuccess from './inviteUserSuccess';
import {
  Header,
  MainContainer,
  NoUsers,
  Title,
  TitleContainer,
} from './styles';

const UserManagement: React.FC = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fetchStatus = useSelector(
    (state) => state.shared.userInvite.fetchStatus
  );
  const fetchStatusCreateUserInvite = useSelector(
    (state) => state.shared.userInvite.fetchStatusCreateUserInvite
  );
  const padding = isMobile ? '2rem 0rem' : '2rem 0rem';

  const [open, setOpen] = useState(false);

  const userInvites = useSelector(
    (state) => state.shared.userInvite.userInvites
  );

  const createInviteSuccess =
    fetchStatusCreateUserInvite === FetchStatusEnum.Success;

  const loading =
    fetchStatus === FetchStatusEnum.Fetching ||
    fetchStatus === FetchStatusEnum.None;

  const notIsEmpty = userInvites.length > 0;

  const handleClose = useCallback(() => {
    setOpen(false);
    if (createInviteSuccess) {
      dispatch(actions.shared.userInvite.clear());
      dispatch(actions.shared.userInvite.getUserInvite());
    }
  }, [createInviteSuccess, dispatch]);

  useEffect(() => {
    dispatch(actions.shared.userInvite.getUserInvite());
  }, [dispatch]);

  if (loading) {
    return (
      <Grid container item justifyContent="center">
        <Loader />
      </Grid>
    );
  }

  return (
    <Grid container style={{ padding }}>
      <MainContainer container item isMobile={isMobile}>
        <Header>
          <TitleContainer>
            <Title color="primary">{t('userManagement.title')}</Title>
          </TitleContainer>
          {notIsEmpty && (
            <Button
              name="savePasswordButton"
              variant="contained"
              color="primary"
              style={{ width: '10rem', height: '3rem' }}
              onClick={() => setOpen(true)}
            >
              <Typography variant="h4">
                <BiPlus /> {t('userManagement.newInvite')}
              </Typography>
            </Button>
          )}
        </Header>

        {fetchStatus === FetchStatusEnum.Success && notIsEmpty ? (
          userInvites.map((invite, index) => (
            <Item key={`order-card-${index}`} userInvite={invite} />
          ))
        ) : (
          <NoUsers container color="secondary">
            <Typography color="secondary" variant="h3">
              {t('userManagement.noUsers')}
            </Typography>

            <Grid
              container
              item
              style={{ justifyContent: 'center', marginBottom: '2rem' }}
            >
              <Typography color="secondary" variant="h3">
                {t('userManagement.sendNewInvite')}
              </Typography>
            </Grid>

            <Button
              name="savePasswordButton"
              variant="contained"
              color="primary"
              style={{ width: '10rem', height: '3rem' }}
              onClick={() => setOpen(true)}
            >
              <Typography variant="h4">
                <BiPlus /> {t('userManagement.newInvite')}
              </Typography>
            </Button>
          </NoUsers>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          content={createInviteSuccess ? <InviteUserSuccess /> : <InviteUser />}
        />
      </MainContainer>
    </Grid>
  );
};

export default UserManagement;
