import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(Link)`
  width: 100%;
  position: relative;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primaryLight};
  border-radius: 8px;
  text-decoration: none;

  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  animation-duration: 700ms;
  animation-name: bornAnimation;

  @keyframes bornAnimation {
    from {
      width: 70px;
    }

    to {
      width: 100%;
    }
  }
`;

export const Title = styled(Typography)`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize.md}px !important;
  color: ${({ theme }) => theme.colors.primary};

  margin-top: 8px !important;
`;

export const Badge = styled.div`
  width: 12px;
  height: 12px;
  background: ${({ theme }) => theme.colors.red};
  border-radius: 6px;
  position: absolute;
  top: -4px;
  right: -4px;
`;
