import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Link, Typography } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import { replaceMask } from '@sinagro/frontend-shared/validations';
import { RecoverMethodEnum } from '@sinagro/shared';
import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Logo from '../../components/elements/logo';
import Button from '../../components/elements/materialUI/button';
import WelcomeLayout from '../../components/layout/welcomeLayout';
import { FormPhoneField, FormTextField } from '../../components/modules/form';
import { actions, useDispatch, useSelector } from '../../state/store';
import {
  useForgotPasswordSchemaEmail,
  useForgotPasswordSchemaPhoneNumber,
} from './schema';
import {
  ButtonContainer,
  ErrorLabel,
  FieldContainer,
  LinkContainer,
  LogoContainer,
  SubTitleContainer,
} from './styles';

export type ForgotPasswordFormType = {
  email: string;
  phoneNumber: string;
};

type LocationType = {
  recoverMethod?: RecoverMethodEnum;
};

const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationType;
  const recoverMethod = state?.recoverMethod ?? RecoverMethodEnum.Email;
  const schemaEmail = useForgotPasswordSchemaEmail();
  const schemaPhoneNumber = useForgotPasswordSchemaPhoneNumber();

  const fetchStatus = useSelector(
    (state) => state.shared.forgotPassword.fetchStatus
  );
  const error = useSelector((state) => state.shared.forgotPassword.error);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ForgotPasswordFormType>({
    resolver: yupResolver(
      recoverMethod === RecoverMethodEnum.Email
        ? schemaEmail
        : schemaPhoneNumber
    ),
  });

  const onSubmit: SubmitHandler<ForgotPasswordFormType> = useCallback(
    (form: ForgotPasswordFormType) => {
      dispatch(
        actions.shared.forgotPassword.recovery({
          emailOrPhone:
            recoverMethod === RecoverMethodEnum.Email
              ? form.email
              : `55${replaceMask(form.phoneNumber)}`,
        })
      );
    },
    [dispatch, recoverMethod]
  );

  const handleReturnToLogin = useCallback(() => {
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    if (fetchStatus === FetchStatusEnum.Success) {
      toast(t('forgotPassword.success'), { type: 'success' });
    }
  }, [dispatch, fetchStatus, t]);

  useEffect(() => {
    return () => {
      dispatch(actions.shared.forgotPassword.clear());
    };
  }, [dispatch]);

  return (
    <WelcomeLayout>
      <Grid
        container
        item
        xs={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid
          container
          item
          xs={12}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ flex: 1 }}
        >
          <LogoContainer item xs={12}>
            <Logo />
          </LogoContainer>
          {recoverMethod === RecoverMethodEnum.Email ? (
            <>
              <SubTitleContainer item xs={12}>
                <Typography variant="h6" color="textPrimary">
                  {t('forgotPassword.subtitleEmail')}
                </Typography>
              </SubTitleContainer>
              <FieldContainer item xs={12} lg={6}>
                <FormTextField
                  testID="inputEmail"
                  name="email"
                  label={t('forgotPassword.email')}
                  control={control}
                  defaultValue=""
                  errorMessage={errors.email?.message}
                />
              </FieldContainer>
            </>
          ) : (
            <>
              <SubTitleContainer item xs={12}>
                <Typography variant="h6" color="textPrimary">
                  {t('forgotPassword.subtitleWhatsapp')}
                </Typography>
              </SubTitleContainer>
              <FieldContainer item xs={12} lg={6}>
                <FormPhoneField
                  name="phoneNumber"
                  label={t('forgotPassword.whatsapp')}
                  control={control}
                  defaultValue=""
                  errorMessage={errors.phoneNumber?.message}
                />
              </FieldContainer>
            </>
          )}

          <ButtonContainer item xs={12} lg={6}>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              loading={fetchStatus === FetchStatusEnum.Fetching}
              fullWidth
            >
              <Typography variant="h6">{t('forgotPassword.submit')}</Typography>
            </Button>
          </ButtonContainer>
          <Grid item xs={12}>
            <ErrorLabel variant="body2" color="error">
              {error?.message}
            </ErrorLabel>
          </Grid>
          <LinkContainer item xs={12}>
            <Link
              component="button"
              onClick={handleReturnToLogin}
              variant="body2"
              underline="always"
              style={{ fontSize: 14 }}
            >
              <>{t('forgotPassword.returnToLogin')}</>
            </Link>
          </LinkContainer>
        </Grid>
      </Grid>
    </WelcomeLayout>
  );
};

export default ForgotPasswordForm;
