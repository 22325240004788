import { TextField, InputProps } from '@material-ui/core';
import { replacePhoneMask } from '@sinagro/frontend-shared/validations';
import React, { useCallback, useRef } from 'react';
import {
  Controller,
  Control,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';

import ErrorContainer from './errorContainer';

interface FormTextFieldProps<T> extends UseControllerProps<T> {
  control: Control<T>;
  label: string;
  errorMessage?: string;
  type?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  inputProps?: InputProps;
  handleKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const getMaskedPhone = (phone: string) => {
  const valueMaskedDDD = phone
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2');
  return valueMaskedDDD.length >= 14
    ? valueMaskedDDD.replace(/(\d{5})(\d)/, '$1-$2')
    : valueMaskedDDD.replace(/(\d{4})(\d)/, '$1-$2');
};

const FormTextField = <T extends FieldValues>(
  props: FormTextFieldProps<T>
): JSX.Element => {
  const {
    control,
    label,
    name,
    defaultValue,
    errorMessage,
    type,
    inputProps,
    handleKeyPress,
  } = props;

  const isPhone = useRef(false);

  const handleOnChange = useCallback(
    (
      callbackHookForm: (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => void,
      e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      const value = e.target.value.trim();
      e.target.value = value;

      const lastCharacter = value.slice(-1);

      const onlyNumbers =
        !!lastCharacter.match(/[0-9]/) &&
        replacePhoneMask(value).match(/^[0-9]*$/g);

      const lastCharacterIsHyphen = !!lastCharacter.match(/[-]/);

      if (onlyNumbers) {
        e.target.value = getMaskedPhone(value);
        isPhone.current = true;
      } else {
        if (isPhone.current) {
          e.target.value = lastCharacterIsHyphen
            ? replacePhoneMask(e.target.value) + lastCharacter
            : replacePhoneMask(e.target.value);
          isPhone.current = false;
        }
      }
      callbackHookForm(e);
    },
    []
  );

  return (
    <React.Fragment>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, ...rest } }) => (
          <TextField
            {...rest}
            label={label}
            error={!!errorMessage}
            onKeyPress={handleKeyPress}
            type={type}
            onChange={(e) => handleOnChange(onChange, e)}
            style={{ width: '100%' }}
            InputProps={inputProps}
            inputProps={{ 'data-testid': 'inputEmailOrPhone' }}
          />
        )}
      />
      {errorMessage && <ErrorContainer message={errorMessage} icon={true} />}
    </React.Fragment>
  );
};

export default FormTextField;
