import { createSlice } from '@reduxjs/toolkit';
import {
  FetchStatusEnum,
  DoLoginAction,
  AuthUpdateStatusAction,
  AuthUpdateErrorAction,
  AuthSlice,
  SingleSignOnAction,
  CheckingAuthStatusAction,
} from '@sinagro/frontend-shared/sharedTypes';

export const sagaActions = {
  onAuthStateChanged: { type: '@sinagro/auth/onAuthStateChanged' },
  onIdTokenChanged: { type: '@sinagro/auth/onIdTokenChanged' },
};

const initialState: AuthSlice = {
  fetchStatus: FetchStatusEnum.None,
  checkingAuthStatus: true,
  error: null,
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clear: () => initialState,
    doLogin: (state, _: DoLoginAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    singleSignOn: (state, _: SingleSignOnAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    updateCheckingAuthStatus: (state, action: CheckingAuthStatusAction) => {
      state.checkingAuthStatus = action.payload.checkingAuthStatus;
    },
    updateStatus: (state, action: AuthUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: AuthUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = { ...slice.actions, ...sagaActions };

export const reducer = slice.reducer;
