import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Grid,
} from '@material-ui/core';
import React from 'react';

import { RadioButtonOption } from '../../../types';
import { FormControlLabel, FormControlLabelBox } from './styles';

type RadioButtonProps = {
  options: RadioButtonOption[];
  handleChange: (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => void;
  value: string;
  label?: string;
  box?: boolean;
  dataTestId?: string;
};

const RadioButton = ({
  options,
  handleChange,
  value,
  label,
  box = false,
  dataTestId,
}: RadioButtonProps) => {
  const marginBottom = box ? '1rem' : 0;
  const margin = box ? '1rem 1rem 0.5rem 0.5rem' : '0 1rem 0 0';

  return (
    <FormControl>
      <Grid container item style={{ alignItems: 'center', marginBottom }}>
        {label && (
          <FormLabel id="radio-buttons-group-label" style={{ margin }}>
            {label}
          </FormLabel>
        )}
        <RadioGroup
          row
          aria-labelledby="radio-buttons-group-label"
          name="radio-buttons-group"
          value={value}
          onChange={handleChange}
          color="primary"
          style={{ marginLeft: '1rem' }}
          data-testid={dataTestId}
        >
          {box
            ? options.map((option, index) => (
                <>
                  <FormControlLabelBox
                    key={`radio-item-${index}`}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    isSelected={option.value === value}
                    date-testid={`option-${option.value}`}
                  />
                </>
              ))
            : options.map((option, index) => (
                <FormControlLabel
                  key={`radio-item-${index}`}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  date-testid={`option-${option.value}`}
                />
              ))}
        </RadioGroup>
      </Grid>
    </FormControl>
  );
};

export default RadioButton;
