import { SharedError } from '.'

export const notificationNotFoundError: SharedError = {
  errorRef: 'notification/not-found',
  message: 'Notificação não encontrada',
}

export const clientAndUserNotFoundError: SharedError = {
  errorRef: 'notifications/client-and-user/not-found',
  message: 'Usuário e Cliente não encontrados',
}

export default [notificationNotFoundError]
