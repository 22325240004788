import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'src/components/elements/modal';

import { useIsMobile } from '../../hooks/responsiveLayout';
import DeleteAccount from './deleteAccount';
import FormProfile from './formProfile';
import IdentificationNumbers from './identificationNumbers';
import ManagerInfo from './managerInfo';
import {
  ProfileContainer,
  ClientInfosContainer,
  Title,
  Delete,
  DeleteIcon,
} from './styles';

const UserProfile: React.FC = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const padding = isMobile ? '2rem 0rem' : '2rem 0rem';

  const [openModal, setOpenModal] = useState(false);


  return (
    <Grid container style={{ padding }}>
      <Grid container style={{ justifyContent: 'space-between' }}>
        
          <ProfileContainer container item isMobile={isMobile}>
          <Title color="primary">{t('profile.titleProfile')}</Title>
          <FormProfile />
        </ProfileContainer>

        <ClientInfosContainer container item isMobile={isMobile}>
          <Title color="primary">{t('profile.titleClientInfos')}</Title>
          <IdentificationNumbers />
          <ManagerInfo />
        </ClientInfosContainer>
      </Grid>

      <Grid style={{ marginTop: '2rem' }}>
        <Delete
          component="button"
          underline="always"
          onClick={() => setOpenModal(true)}
        >
          <DeleteIcon /> {t('profile.deleteAccount.delete')}
        </Delete>
      </Grid>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        content={<DeleteAccount onClose={() => setOpenModal(false)} />}
      />
    </Grid>
  );
};

export default UserProfile;
