import { createSlice } from '@reduxjs/toolkit';

import {
  FetchStatusEnum,
  CampaignsFetchAction,
  CampaignsSlice,
  CampaignsUpdateStatusAction,
  CampaignsUpdateErrorAction,
} from '../../types';

export const initialState: CampaignsSlice = {
  campaigns: [],
  fetchStatus: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    clear: () => initialState,
    getCampaigns: (state) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    campaigns: (state, action: CampaignsFetchAction) => {
      state.campaigns = action.payload.campaigns;
    },
    updateStatus: (state, action: CampaignsUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: CampaignsUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
