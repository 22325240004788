import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { InputAdornment } from '@material-ui/core';
import {
  DocumentTypeEnum,
  IUserProfile,
  ValidationCodeTypeEnum,
} from '@sinagro/shared';
import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatPhone } from 'src/utils/format';

import {
  FormMaskedField,
  FormPhoneField,
  FormTextField,
} from '../../components/modules/form';
import { CheckLink, EditIcon, FieldContainer, VerifyIcon } from './styles';

export type ProfileFormType = Pick<
  IUserProfile,
  'email' | 'phoneNumber' | 'document' | 'name'
>;

type FormProps = {
  userProfile: IUserProfile;
  control: Control<ProfileFormType>;
  errors: DeepMap<Partial<ProfileFormType>, FieldError>;
  onValidate: (type: ValidationCodeTypeEnum) => void;
  onEdit: (type?: ValidationCodeTypeEnum) => void;
};

const Form: React.FC<FormProps> = ({
  userProfile,
  control,
  errors,
  onValidate,
  onEdit,
}) => {
  const { t } = useTranslation();

  const documentType = userProfile?.documentType;

  const inputPropsEmailComponent = (
    <InputAdornment position="start">
      {userProfile.isEmailValidated ? (
        <VerifyIcon edgeMode="end" icon={faCheckCircle} />
      ) : (
        <CheckLink onClick={() => onValidate(ValidationCodeTypeEnum.Email)}>
          {t('profile.profileForm.check')}
        </CheckLink>
      )}
      {!userProfile.thirdPartyProvider && (
        <EditIcon onClick={() => onEdit(ValidationCodeTypeEnum.Email)} />
      )}
    </InputAdornment>
  );

  const inputPropsPhoneComponent = (
    <InputAdornment position="start">
      {userProfile.isPhoneValidated ? (
        <VerifyIcon edgeMode="end" icon={faCheckCircle} />
      ) : (
        <CheckLink onClick={() => onValidate(ValidationCodeTypeEnum.Phone)}>
          {t('profile.profileForm.check')}
        </CheckLink>
      )}
      <EditIcon onClick={() => onEdit(ValidationCodeTypeEnum.Phone)} />
    </InputAdornment>
  );

  const inputPropsNameComponent = (
    <InputAdornment position="start">
      {!userProfile.thirdPartyProvider && <EditIcon onClick={() => onEdit()} />}
    </InputAdornment>
  );

  return (
    <>
      <FieldContainer item xs={12}>
        <FormTextField
          testID="inputEmail"
          name="email"
          label={t('profile.profileForm.email')}
          control={control}
          defaultValue={userProfile.email}
          errorMessage={errors.email?.message}
          inputProps={{
            endAdornment: inputPropsEmailComponent,
          }}
          disabled
        />
      </FieldContainer>
      {!userProfile.thirdPartyProvider && (
        <FieldContainer item xs={12}>
          <FormPhoneField
            name="phoneNumber"
            label={t('profile.profileForm.phoneNumber')}
            control={control}
            defaultValue={
              userProfile.phoneNumber
                ? `${formatPhone(userProfile.phoneNumber)}`
                : ''
            }
            errorMessage={errors.phoneNumber?.message}
            inputProps={{
              endAdornment: inputPropsPhoneComponent,
            }}
            disabled
          />
        </FieldContainer>
      )}
      {!userProfile.thirdPartyProvider &&
        (documentType === DocumentTypeEnum.CNPJ ? (
          <FieldContainer item xs={12}>
            <FormMaskedField
              testID="inputCNPJ"
              name="document"
              label={t('profile.profileForm.cnpj')}
              control={control}
              defaultValue={userProfile.document}
              mask="99.999.999/9999-99"
              errorMessage={errors.document?.message}
              disabled
            />
          </FieldContainer>
        ) : (
          <FieldContainer item xs={12}>
            <FormMaskedField
              testID="inputCPF"
              name="document"
              label={t('profile.profileForm.cpf')}
              control={control}
              defaultValue={userProfile.document}
              mask="999.999.999-99"
              errorMessage={errors.document?.message}
              disabled
            />
          </FieldContainer>
        ))}
      <FieldContainer item xs={12}>
        <FormMaskedField
          testID="inputName"
          name="name"
          mask={Array.from({ length: 200 }, () => /^[\p{L}\s]$/u)}
          label={t('profile.profileForm.name')}
          control={control}
          defaultValue={userProfile.name}
          errorMessage={errors.name?.message}
          inputProps={{
            endAdornment: inputPropsNameComponent,
          }}
          disabled
        />
      </FieldContainer>
    </>
  );
};

export default Form;
