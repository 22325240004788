import { OptimalGrowthCurveStatus } from '@sinagro/shared';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Select from '../../../../../components/elements/select';
import { getInputProps } from '../utils';
import { Container } from './styles';

type GrowthCurveStatusSelectProps = {
  value: string;
  setValue: (newValue: string) => void;
  parentElementIsPopper?: boolean;
};

const GrowthCurveStatusSelect: React.FC<GrowthCurveStatusSelectProps> = ({
  value,
  setValue,
  parentElementIsPopper,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const options: { value: string; label: string }[] = Object.values(
    OptimalGrowthCurveStatus
  ).map((item) => ({
    value: item,
    label: t(`torq.farm.caption.status.${item}`),
  }));
  const inputProps = getInputProps(parentElementIsPopper);

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<{
        value: unknown;
      }>
    ) => {
      setValue(event.target.value as string);
    },
    [setValue]
  );

  return (
    <Container>
      <Select
        label={t('torq.plot.filter.growthCurveStatus')}
        options={[...options, { value: '', label: 'Todos' }]}
        handleChange={handleChange}
        value={value?.toString() ?? ''}
        MenuProps={{ style: { marginTop: 36 } }}
        labelStyle={{ marginTop: '-0.9rem', paddingLeft: '0.6rem' }}
        border={`1px solid ${theme.colors.border}`}
        inputProps={inputProps}
      />
    </Container>
  );
};

export default GrowthCurveStatusSelect;
