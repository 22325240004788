import React from 'react';

import { SuccessTypography, SuccessWrapper, SuccessIcon } from './styles';

interface Props {
  message?: string;
  icon?: boolean;
}

const SuccessContainer = ({ message, icon = true }: Props): JSX.Element => {
  return (
    <SuccessWrapper>
      {icon && <SuccessIcon />}
      <SuccessTypography variant="body2">{message}</SuccessTypography>
    </SuccessWrapper>
  );
};

export default SuccessContainer;
