import { PayloadAction } from '@reduxjs/toolkit';
import { ConfigDefault, ConfigWhatsApp, TokenClaims } from '@sinagro/shared';
import { SharedError } from '@sinagro/shared/errors';

import { FetchStatusEnum, FirebasePersistenceEnum } from '.';

export type UserFirebase = {
  email: string;
  displayName: string;
  phoneNumber: string;
  photoURL: string;
};

export enum AuthenticatedEnum {
  None = 'none',
  Authenticated = 'authenticated',
  NotAuthenticated = 'notAuthenticated',
}

export enum SSOEventTypeEnum {
  CheckAuth = 'check-auth',
  CheckAuthStart = 'check-auth-start',
  CheckAuthFinished = 'check-auth-finished',
}

export type UserSlice = {
  claims: TokenClaims;
  persistence: FirebasePersistenceEnum;
  userFirebase: UserFirebase;
  userAuthenticated: AuthenticatedEnum;
  fetchStatusUpdateUserNotificationConfig: FetchStatusEnum;
  errorUpdateUserNotificationConfig: SharedError | null;
};

export type UserUpdate = {
  claims: TokenClaims;
  userFirebase: UserFirebase;
};

export type UserUpdateAction = PayloadAction<UserUpdate>;

export type UserPersistence = {
  persistence: FirebasePersistenceEnum;
};

export type UserPersistenceAction = PayloadAction<UserPersistence>;

export type userAuthenticated = {
  userAuthenticated: AuthenticatedEnum;
};

export type userAuthenticatedAction = PayloadAction<userAuthenticated>;

export type UpdateUserNotificationConfig = {
  web: ConfigDefault;
  mobile: ConfigDefault;
  whatsapp: ConfigWhatsApp;
};

export type UpdateUserNotificationConfigAction =
  PayloadAction<UpdateUserNotificationConfig>;

export type UpdateUserNotificationConfigUpdateStatus = {
  fetchStatusUpdateUserNotificationConfig: FetchStatusEnum;
};

export type UpdateUserNotificationConfigUpdateStatusAction =
  PayloadAction<UpdateUserNotificationConfigUpdateStatus>;

export type UpdateUserNotificationConfigUpdateError = {
  errorUpdateUserNotificationConfig: SharedError;
};

export type UpdateUserNotificationConfigUpdateErrorAction =
  PayloadAction<UpdateUserNotificationConfigUpdateError>;
