import type {
  FinancialOpenCreditsGetOpenCredits,
  FinancialOpenCreditsGetOpenCreditsPaged,
  FinancialOpenCreditsGetSummary,
} from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';
import api from '../api';
import { openCreditsFiltersParams, openCreditsPageParams } from './queryParams';

export function getSummary(payload: FinancialOpenCreditsGetSummary) {
  let params = new CustomURLSearchParams({});
  params = openCreditsFiltersParams(params, payload.filters);

  return api.get(`/financial/open-credits/aggregated/summary?${params}`);
}

export function getOpenCredits(payload: FinancialOpenCreditsGetOpenCredits) {
  let params = new CustomURLSearchParams({});
  params = openCreditsFiltersParams(params, payload.filters);

  return api.get(`/financial/open-credits/aggregated?${params}`);
}

export function getOpenCreditsPaged(
  payload: FinancialOpenCreditsGetOpenCreditsPaged
) {
  let params = new CustomURLSearchParams({});

  params = openCreditsFiltersParams(params, payload.filters);
  params = openCreditsPageParams(params, payload);
  return api.get(`/financial/open-credits?${params}`);
}
