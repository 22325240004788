import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'src/state/store';

import { useMenuContext } from '../../../../../contexts/menu';
import { MenuItemProps, menuItemsFeatureFlag } from './items';
import {
  Container,
  Content,
  ExpandedButton,
  Label,
  SubMenuList,
  Wrapper,
} from './styles';
import SubMenuItem from './subMenuItem';

type Props = MenuItemProps & {
  openMenu: boolean;
};

const MenuItem = ({
  id,
  label,
  Icon,
  url,
  openMenu,
  color,
  subMenu,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { setShowMenu } = useMenuContext();
  const [expandSubMenuList, setExpandSubMenuList] = useState(true);
  const isParentMenuItem = subMenu && subMenu.length > 0;

  const featureFlag = useSelector(
    (state) => state.shared.featureFlag.featureFlag
  );

  const subMenuItems = subMenu
    ? menuItemsFeatureFlag(subMenu, featureFlag)
    : [];

  const handleExpandSubMenuList = (event: React.MouseEvent) => {
    event.stopPropagation();
    setExpandSubMenuList((state) => !state);
  };

  const handleNavigation = useCallback(() => {
    if (setShowMenu) setShowMenu(false);
    navigate(url);
  }, [navigate, url, setShowMenu]);

  const isActive = pathname === url ? true : false;

  return (
    <Wrapper>
      <Container
        onClick={handleNavigation}
        $isActive={isActive}
        $color={color}
        $openMenu={openMenu}
        data-testid={`menu-${id}`}
      >
        <Content $openMenu={openMenu}>
          {Icon && <Icon style={{ height: 24, width: 24 }} />}

          {openMenu && (
            <Label variant="body1" $isActive={isActive}>
              {t(`${label}`)}
            </Label>
          )}
        </Content>
        {isParentMenuItem && openMenu && (
          <ExpandedButton onClick={handleExpandSubMenuList}>
            {expandSubMenuList ? <MdExpandLess /> : <MdExpandMore />}
          </ExpandedButton>
        )}
      </Container>
      {isParentMenuItem && expandSubMenuList && (
        <SubMenuList $openMenu={openMenu}>
          {subMenuItems.map((item) => (
            <SubMenuItem {...item} openMenu={openMenu} key={item.id} />
          ))}
        </SubMenuList>
      )}
    </Wrapper>
  );
};

export default MenuItem;
