import { SagaIterator } from '@redux-saga/types';
import {
  GroupedRemittanceSegmentEnum,
  RemittanceStatusEnum,
} from '@sinagro/shared';
import { SharedError, unknownError } from '@sinagro/shared/errors';
import { call, put } from 'redux-saga/effects';

import { getRemittance } from '../../../../services/tracking/v2';
import { FetchStatusEnum } from '../../../../types';
import { RemittanceGetAction, RemittanceResponse } from '../../../../types/v2';
import { handleError } from '../../../errors/sagas';
import { actions } from '../slice';

export function* getRemittancesFertilizersScheduledEffectSaga(
  action: RemittanceGetAction
): SagaIterator<void> {
  try {
    const filters = {
      ...action.payload.filters,
      segment: GroupedRemittanceSegmentEnum.Fertilizers,
      remittanceStatus: RemittanceStatusEnum.Scheduled,
    };

    const payload = { ...action.payload, filters };
    const { results: remittances, total }: RemittanceResponse = yield call(
      getRemittance,
      payload
    );

    yield put(
      actions.remittancesFertilizersScheduled({
        remittances,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusFertilizersScheduled({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError | undefined = yield call(handleError, {
      error,
    });

    yield put(
      actions.updateError({
        error: knownError || unknownError,
      })
    );

    yield put(
      actions.updateStatusFertilizersScheduled({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getRemittancesFertilizersHandlingEffectSaga(
  action: RemittanceGetAction
): SagaIterator<void> {
  try {
    const filters = {
      ...action.payload.filters,
      segment: GroupedRemittanceSegmentEnum.Fertilizers,
      remittanceStatus: RemittanceStatusEnum.Handling,
    };

    const payload = { ...action.payload, filters };
    const { results: remittances, total }: RemittanceResponse = yield call(
      getRemittance,
      payload
    );

    yield put(
      actions.remittancesFertilizersHandling({
        remittances,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusFertilizersHandling({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(
        actions.updateError({
          error: knownError,
        })
      );
    }

    yield put(
      actions.updateStatusFertilizersHandling({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getRemittancesFertilizersInTransitEffectSaga(
  action: RemittanceGetAction
): SagaIterator<void> {
  try {
    const filters = {
      ...action.payload.filters,
      segment: GroupedRemittanceSegmentEnum.Fertilizers,
      remittanceStatus: RemittanceStatusEnum.InTransit,
    };

    const payload = { ...action.payload, filters };
    const { results: remittances, total }: RemittanceResponse = yield call(
      getRemittance,
      payload
    );

    yield put(
      actions.remittancesFertilizersInTransit({
        remittances,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusFertilizersInTransit({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(
        actions.updateError({
          error: knownError,
        })
      );
    }

    yield put(
      actions.updateStatusFertilizersInTransit({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getRemittancesFertilizersDeliveredEffectSaga(
  action: RemittanceGetAction
): SagaIterator<void> {
  try {
    const filters = {
      ...action.payload.filters,
      segment: GroupedRemittanceSegmentEnum.Fertilizers,
      remittanceStatus: RemittanceStatusEnum.Delivered,
    };

    const payload = { ...action.payload, filters };
    const { results: remittances, total }: RemittanceResponse = yield call(
      getRemittance,
      payload
    );

    yield put(
      actions.remittancesFertilizersDelivered({
        remittances,
        total,
        page: action.payload.page,
      })
    );
    yield put(
      actions.updateStatusFertilizersDelivered({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(
        actions.updateError({
          error: knownError,
        })
      );
    }

    yield put(
      actions.updateStatusFertilizersDelivered({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
