import { SharedError } from '.'

export const notImplementedYetError: SharedError = {
  errorRef: 'default/not-implemented-yet',
  message: 'Funcionalidade ainda não implementada',
}
export const sendMailError: SharedError = {
  errorRef: 'default/send-mail',
  message: 'Não foi possível enviar o e-mail. Tente novamente mais tarde',
}
export const sendWhatsappError: SharedError = {
  errorRef: 'default/send-whatsapp',
  message:
    'Não foi possível enviar a mensagem via whatsapp. Tente novamente mais tarde',
}
export const forbiddenError: SharedError = {
  errorRef: 'default/forbidden',
  message: 'Você não tem acesso a essa funcionalidade.',
}
export const badRequestMissingBodyError: SharedError = {
  errorRef: 'default/badRequest',
  message: 'Body é obrigatório na requisição.',
}
export const badRequestMissingRouteParamsError: SharedError = {
  errorRef: 'default/badRequest',
  message: 'Route params é obrigatório na requisição.',
}
export const unauthorizedError: SharedError = {
  errorRef: 'default/unauthorized',
  message: 'Você não tem autorização para acessar esse recurso.',
}

export const fileUploadError: SharedError = {
  errorRef: 'default/file-upload-error',
  message:
    'Algo inesperado aconteceu ao fazer o upload, tente novamente mais tarde',
}

export const unknownError: SharedError = {
  errorRef: 'default/unknown-error',
  message: 'Algo inesperado aconteceu, tente novamente mais tarde',
}

export default [
  badRequestMissingRouteParamsError,
  notImplementedYetError,
  sendMailError,
  unknownError,
  sendWhatsappError,
  fileUploadError,
  badRequestMissingBodyError,
]
