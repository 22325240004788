import { SagaIterator } from '@redux-saga/types';
import { ICreditSegmentDetail, ICreditSummary } from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getSegmentDetail, getSummary } from '../../services/credit';
import {
  CreditGetSegmentDetailAction,
  CreditGetSummaryAction,
  FetchStatusEnum,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getSummary.type, getSummaryEffectSaga),
  takeLatest(actions.getSegmentDetail.type, getSegmentDetailEffectSaga),
];

export function* getSummaryEffectSaga(
  action: CreditGetSummaryAction
): SagaIterator<void> {
  try {
    const summary: ICreditSummary[] = yield call(getSummary, action.payload);
    yield put(actions.summary({ summary }));
    yield put(
      actions.updateStatusSummary({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusSummary({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}

export function* getSegmentDetailEffectSaga(
  action: CreditGetSegmentDetailAction
): SagaIterator<void> {
  try {
    const segmentDetail: ICreditSegmentDetail[] = yield call(
      getSegmentDetail,
      action.payload
    );
    yield put(actions.segmentDetail({ segmentDetail }));
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
