import { createSlice } from '@reduxjs/toolkit';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';

import {
  SignUpPostAction,
  SignUpSlice,
  SignUpUpdateStatusAction,
  SignUpUpdateErrorAction,
  UpdateExistenceAction,
  FetchExistenceAction,
} from '../../types';

const initialState: SignUpSlice = {
  fetchStatus: FetchStatusEnum.None,
  error: null,
  fetchingDocument: FetchStatusEnum.None,
  documentExistent: false,
  fetchingEmail: FetchStatusEnum.None,
  emailExistent: false,
  fetchingPhone: FetchStatusEnum.None,
  phoneExistent: false,
};

export const slice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    clear: () => initialState,
    post: (state, _: SignUpPostAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    postMobile: (state, _: SignUpPostAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    updateStatus: (state, action: SignUpUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: SignUpUpdateErrorAction) => {
      state.error = action.payload.error;
    },
    fetchExistence: (state, action: FetchExistenceAction) => {
      if (action.payload.field === 'email') {
        state.fetchingEmail = FetchStatusEnum.Fetching;
      } else if (action.payload.field === 'phone') {
        state.fetchingPhone = FetchStatusEnum.Fetching;
      } else if (action.payload.field === 'document') {
        state.fetchingDocument = FetchStatusEnum.Fetching;
      }
    },
    updateExistence: (state, action: UpdateExistenceAction) => {
      if (action.payload.field === 'email') {
        state.emailExistent = action.payload.exists;
        state.fetchingEmail = FetchStatusEnum.Success;
      } else if (action.payload.field === 'phone') {
        state.phoneExistent = action.payload.exists;
        state.fetchingPhone = FetchStatusEnum.Success;
      } else if (action.payload.field === 'document') {
        state.documentExistent = action.payload.exists;
        state.fetchingDocument = FetchStatusEnum.Success;
      }
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
