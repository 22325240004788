import { IRegion, RegionType } from '@sinagro/shared';
import React from 'react';
import { regionName } from 'src/components/modules/torq/region/utils';
import { useReportContext } from 'src/contexts/report';
import { useIsMobile } from 'src/hooks/responsiveLayout';
import { formatDateHourMinute, formatDateLong } from 'src/utils/format';

import {
  Container,
  Content,
  FarmIcon,
  RegionIcon,
  Text,
  Title,
} from './styles';

const Region = () => {
  const isMobile = useIsMobile();
  const { region, reportGeneratedAt, client } = useReportContext();
  const isFarm = region?.type === RegionType.Farm;
  const title = region ? regionName(region as IRegion) : '';
  const clientName = client?.name;

  if (region === null) {
    return <></>;
  }

  return (
    <Container container $isMobile={isMobile}>
      {isFarm ? <FarmIcon /> : <RegionIcon />}
      <Content>
        <Title>{title}</Title>
        {isFarm && <Text>{clientName}</Text>}
        {reportGeneratedAt && (
          <>
            <Text>{formatDateLong(reportGeneratedAt)}</Text>
            <Text>{formatDateHourMinute(reportGeneratedAt)}</Text>
          </>
        )}
      </Content>
    </Container>
  );
};

export default Region;
