import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonContainer = styled(Grid)`
  width: 100%;
  padding-top: 2rem;
`;

export const LogoContainer = styled(Grid)`
  padding: 1rem 0 2rem 0;
`;

export const TitleContainer = styled(Grid)`
  padding-top: 2rem;
  text-align: center;
`;

export const SubTitleContainer = styled(Grid)`
  padding: 1rem 0 2rem 0;
  text-align: center;
`;

export const FieldContainer = styled(Grid)`
  width: 100%;
  text-align: center;
`;

export const ErrorLabel = styled(Typography)`
  padding: 1rem 0;
  text-align: center;
`;

export const LinkContainer = styled(Grid)`
  padding: 1rem;
`;

export const TitleLabel = styled(Typography)`
  && {
    font-size: clamp(18px, 3vw, 22px);
  }
`;
