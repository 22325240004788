import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Grid, Link } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/elements/loader';
import { formatDateTime } from 'src/utils/format';

import { actions, useDispatch, useSelector } from '../../../state/store';
import { Header, MainContainer, Title, Article, Subtext, Icon } from './styles';

const NewsPanel: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.shared.news.getNews());
  }, [dispatch]);

  const news = useSelector((state) => state.shared.news.news);
  const fetchStatus = useSelector((state) => state.shared.news.fetchStatus);

  return (
    <>
      <MainContainer>
        <Header>
          <Title>{t('commons.news.title')}</Title>
        </Header>
        {fetchStatus === FetchStatusEnum.Fetching && <Loader size={25} />}

        {news?.map((item, index) => (
          <>
            <Link href={item.url} rel="noopener noreferrer" target="_blank">
              <Article key={index}>
                {item.title} <Icon icon={faExternalLinkAlt} />
              </Article>
            </Link>
            <Subtext>{formatDateTime(item.date, true)}</Subtext>
          </>
        ))}
        <Grid container style={{ margin: '1rem 0 0 0' }}>
          <Subtext>{t('commons.news.disclaimer')}</Subtext>
        </Grid>
        {news.length === 0 && fetchStatus === FetchStatusEnum.Success && (
          <Article>{t('commons.news.empty')}</Article>
        )}
      </MainContainer>
    </>
  );
};

export default NewsPanel;
