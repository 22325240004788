import { SagaIterator } from '@redux-saga/types';
import { ISquareQuotation, SquareDTO } from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getSquareQuotation, getSquares } from '../../services/quotation';
import { FetchStatusEnum, SquareQuotationGetAction } from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getSquares.type, getSquaresEffectSaga),
  takeLatest(actions.getSquareQuotation.type, getSquareQuotationEffectSaga),
];

export type getSquaresResponse = SquareDTO[];

export function* getSquaresEffectSaga(): SagaIterator<void> {
  try {
    const squares: getSquaresResponse = yield call(getSquares);
    yield put(actions.squares({ squares }));
    yield put(
      actions.updateStatusSquare({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusSquare({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}

export type SquareQuotationResponse = ISquareQuotation[];

export function* getSquareQuotationEffectSaga(
  action: SquareQuotationGetAction
): SagaIterator<void> {
  try {
    const quotation: SquareQuotationResponse = yield call(
      getSquareQuotation,
      action.payload
    );

    yield put(actions.squareQuotation({ quotation }));
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
