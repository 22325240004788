import { Grid, Typography } from '@material-ui/core';
import { DefaultThemeColorsType } from '@sinagro/frontend-shared/themes';
import styled from 'styled-components';

export const CaptionSidebar = styled(Grid)`
  align-items: center;
  box-shadow: 4px 0px 4px rgba(160, 176, 232, 0.15);
  margin-top: 8px !important;
  border-top: 1px solid ${({ theme }) => theme.colors.primaryLight};
`;

export const Caption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 76px;
`;

export const CaptionText = styled(Typography)<{
  $color?: DefaultThemeColorsType;
  $bold?: boolean;
}>`
  && {
    color: ${({ theme, $color }) =>
      $color ? theme.colors[$color] : theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.md}px;
    font-weight: ${({ $bold }) => ($bold ? 600 : 400)};
  }
`;

export const HourContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
`;

export const Hour = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
  }
`;

export const Details = styled(Grid)`
  display: flex;
  width: 1728px;
  overflow-x: scroll;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 15px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.primaryLight};
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    width: 6px;
    background-color: ${({ theme }) => theme.colors.borderSecondary};
    border-radius: 8px;
  }
`;

export const DetailsWrapper = styled(Grid)`
  display: flex;
  width: 100%;

  .hourColumn:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.background};
  }
`;
