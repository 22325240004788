import type { FiltersType, PayableGet } from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';

export const payablesFiltersParams = (
  params: CustomURLSearchParams,
  filters: FiltersType
) => {
  filters.harvestReferences.length > 0 &&
    params.set('harvestReference', filters.harvestReferences.join(','));

  filters.searchTerm.length > 0 && params.set('search', filters.searchTerm);

  filters.order.length > 0 && params.set('orderId', `%${filters.order}%`);

  filters.invoice.length > 0 && params.set('invoiceId', `%${filters.invoice}%`);

  filters.dueDate.startDate &&
    params.set(
      'dueDate(min)',
      filters.dueDate.startDate.toDateString() + ' 00:00:00'
    );

  filters.dueDate.endDate &&
    params.set(
      'dueDate(max)',
      filters.dueDate.endDate.toDateString() + ' 23:59:59'
    );

  filters.segment.length > 0 &&
    params.set('segment', filters.segment.join(','));

  filters.propertyName.length > 0 &&
    params.set('propertyName', `%${filters.propertyName}%`);

  filters.paymentCondition &&
    params.set('paymentCondition', filters.paymentCondition);

  filters.currency && params.set('currency', filters.currency);

  filters.contract.length > 0 &&
    params.set('contract', `%${filters.contract}%`);

  filters.payableStatus && params.set('paymentStatus', filters.payableStatus);

  return params;
};

export const payablesPageParams = (
  params: CustomURLSearchParams,
  payload: PayableGet
) => {
  payload.limit && params.set('limit', payload.limit.toString());

  payload.page && params.set('page', payload.page.toString());

  return params;
};
