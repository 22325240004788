/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit';

import {
  CreditPackageGetAction,
  CreditPackageSlice,
  CreditUpdateErrorAction,
  CreditUpdateStatusAction,
  FetchStatusEnum,
  UploadCreditPackageDocumentFileAction,
} from '../../types';

export const initialState: CreditPackageSlice = {
  creditPackage: [],
  fetchStatus: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'creditPackage',
  initialState,
  reducers: {
    clear: () => initialState,
    getCreditPackage: (state) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    creditPackage: (state, action: CreditPackageGetAction) => {
      state.creditPackage = action.payload.creditPackage;
    },
    updateCreditPackageDocumentFile: (
      state,
      _: UploadCreditPackageDocumentFileAction
    ) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    updateStatus: (state, action: CreditUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: CreditUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
