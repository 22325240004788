import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const FieldContainer = styled(Grid)`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const PasswordContainer = styled(Grid)<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '2rem 1.5rem' : '3rem 6rem')};
  border-radius: 3px;
  box-shadow: 1px 1px 10px 0px lightgrey;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
`;

export const Title = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.xxxl}px;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
`;

export const Subtitle = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    margin: 1rem 0;
    width: 100%;
  }
`;

export const Text = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    margin-bottom: 1rem;
  }
`;

export const SaveContainer = styled(Grid)`
  text-align: center;
  justify-content: flex-end;
`;
