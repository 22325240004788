import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { emailRegex, phoneNumberRegex } from '../../utils/regexes';

export const useForgotPasswordSchemaEmail = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    email: yup
      .string()
      .required(t('form.required'))
      .matches(emailRegex, t('form.invalidFormat')),
  });
};

export const useForgotPasswordSchemaPhoneNumber = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    phoneNumber: yup
      .string()
      .required(t('form.required'))
      .matches(phoneNumberRegex, t('form.invalidFormat')),
  });
};
