import type { FiltersType, RemittanceHistoryGet } from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';

export const trackingFiltersParams = (
  params: CustomURLSearchParams,
  filters: FiltersType
) => {
  filters.order.length > 0 && params.set('orderId', filters.order);

  return params;
};

export const trackingPageParams = (
  params: CustomURLSearchParams,
  payload: RemittanceHistoryGet
) => {
  payload.limit && params.set('limit', payload.limit.toString());

  payload.page && params.set('page', payload.page.toString());

  return params;
};
