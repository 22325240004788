import { Grid, Tab } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'src/hooks/useQuery';
import { useSelector } from 'src/state/store';

import { Description, TabList } from './styles';
import { tabList } from './tabs';

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('');
  const query = useQuery();
  const tabQuery = query.get('tab');

  const fetchStatus = useSelector(
    (state) => state.shared.userProfile.fetchStatus
  );

  const handleChangeTab = (event: React.ChangeEvent<unknown>, tab: string) => {
    setCurrentTab(tab);
  };

  const checkIfHasTab = useCallback(() => {
    if (tabQuery && tabQuery === 'whatsapp') {
      setCurrentTab(tabList[2].route);
    }
    window.history.replaceState(null, '', window.location.pathname);
  }, [tabQuery]);

  useEffect(() => {
    setCurrentTab(tabList[0].route);
  }, []);

  useEffect(() => {
    if (fetchStatus === FetchStatusEnum.Success) {
      checkIfHasTab();
    }
  }, [checkIfHasTab, fetchStatus]);

  return (
    <Grid
      container
      justifyContent="center"
      style={{
        padding: '1rem',
        flexDirection: 'column',
        backgroundColor: '#FFF',
      }}
    >
      <Description>{t('notifications.settings.subtitle')}</Description>

      <Grid
        container
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TabContext value={currentTab}>
          <Grid container>
            <TabList onChange={handleChangeTab}>
              {tabList.map((item) => (
                <Tab label={item.title} value={item.route} key={item.route} />
              ))}
            </TabList>
          </Grid>
          {tabList.map((item) => (
            <TabPanel
              value={item.route}
              key={item.route}
              style={{ padding: 0 }}
            >
              {item.component}
            </TabPanel>
          ))}
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default Settings;
