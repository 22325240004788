import { RegionType } from '@sinagro/shared';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useReportContext } from 'src/contexts/report';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { Container, Link } from './styles';

const Disclaimer = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { region } = useReportContext();

  const handleLogin = useCallback(() => {
    navigate('/');
  }, [navigate]);

  if (region === null || region?.type !== RegionType.Farm) {
    return <></>;
  }

  return (
    <Container $isMobile={isMobile}>
      <Link onClick={handleLogin} underline="always">
        {t('report.disclaimer')}
      </Link>
    </Container>
  );
};

export default Disclaimer;
