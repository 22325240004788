import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography, Link } from '@material-ui/core';
import { analytics } from '@sinagro/frontend-shared/firebase';
import { auth } from '@sinagro/frontend-shared/firebase';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import { FirebasePersistenceEnum } from '@sinagro/frontend-shared/src/types';
import { replaceMask } from '@sinagro/frontend-shared/validations';
import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import Logo from '../../components/elements/logo';
import Button from '../../components/elements/materialUI/button';
import WelcomeLayout from '../../components/layout/welcomeLayout';
import { useDispatch, actions, useSelector } from '../../state/store';
import { analyticsLogger } from '../../utils/analytics';
import { phoneNumberRegex } from '../../utils/regexes';
import { privacyPolicyURL, termsOfUseURL } from '../../utils/urls';
import Content from './content';
import { useLoginSchema } from './schema';
import {
  MainContainer,
  TitleContainer,
  SubmitContainer,
  PrivacyPolicy,
  ErrorLabel,
  TermsOfUse,
} from './styles';

export type LoginType = {
  emailOrPhone: string;
  password: string;
  stayConnected: boolean;
};

const Login: React.FC = () => {
  const { t } = useTranslation();
  const loginSchema = useLoginSchema();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const fetchStatus = useSelector((state) => state.auth.fetchStatus);
  const loginError = useSelector((state) => state.auth.error);
  const claims = useSelector((state) => state.shared.user.claims);

  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<LoginType>({
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });

  const watchStayConnected = watch('stayConnected', false);

  const onSubmit: SubmitHandler<LoginType> = useCallback(
    (form: LoginType) => {
      const isPhone = form.emailOrPhone.match(phoneNumberRegex);

      const sanitizedForm = {
        emailOrPhone: isPhone
          ? `55${replaceMask(form.emailOrPhone)}`
          : form.emailOrPhone,
        password: form.password,
      };

      dispatch(actions.auth.doLogin(sanitizedForm));
    },
    [dispatch]
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const { code, key, charCode } = event;
      const enterKey = 'Enter';
      const enterCharCode = 13;

      if (code === enterKey || key === enterKey || charCode === enterCharCode) {
        handleSubmit(onSubmit)();
      }
    },
    [handleSubmit, onSubmit]
  );

  const handleSignUp = useCallback(() => {
    navigate('/sign-up');
  }, [navigate]);

  useEffect(() => {
    if (fetchStatus === FetchStatusEnum.Success) {
      const persistence = watchStayConnected
        ? FirebasePersistenceEnum.Local
        : FirebasePersistenceEnum.Session;
      dispatch(actions.shared.user.persistence({ persistence }));
      auth().setPersistence(persistence);
    }
  }, [dispatch, fetchStatus, watchStayConnected]);

  useEffect(() => {
    if (fetchStatus === FetchStatusEnum.Success && claims.id) {
      analytics().logEvent('login', {
        platform: 'web',
        user_id: claims.id,
        method: 'custom',
      });

      dispatch(
        actions.auth.updateStatus({ fetchStatus: FetchStatusEnum.None })
      );
    }
  }, [claims, dispatch, fetchStatus]);

  useEffect(() => {
    return () => {
      dispatch(actions.auth.clear());
    };
  }, [dispatch]);

  return (
    <WelcomeLayout>
      <MainContainer
        container
        item
        xs={12}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={12}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ flex: 1 }}
        >
          <Grid item xs={12}>
            <Logo />
          </Grid>
          <TitleContainer item xs={12}>
            <Typography variant="h1" color="textPrimary">
              {t('commons.title')}
            </Typography>
          </TitleContainer>

          <Content
            control={control}
            errors={errors}
            handleKeyPress={handleKeyPress}
          />

          <SubmitContainer item xs={12}>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              fullWidth
              loading={
                fetchStatus === FetchStatusEnum.Fetching ||
                fetchStatus === FetchStatusEnum.Success
              }
              data-testid="buttonLogin"
            >
              <Typography variant="h6">{t('login.submit')}</Typography>
            </Button>
            <Grid item xs={12}>
              <ErrorLabel variant="body2" color="error">
                {loginError?.message}
              </ErrorLabel>
            </Grid>
          </SubmitContainer>
          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">
              {t('login.noAccount')}&nbsp;
              <Link
                component="button"
                onClick={handleSignUp}
                underline="always"
                style={{
                  fontSize: theme.fontSize.lg,
                  fontFamily: theme.fontFamily,
                }}
              >
                <>{t('login.signUp')}</>
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: '1rem 0 1.5rem 0' }}>
            <Typography variant="h6" color="textPrimary">
              {t('login.needHelp')}
              <Link
                href={`https://api.whatsapp.com/send?1=pt_BR&phone=${whatsappNumber}`}
                underline="always"
                variant="body2"
                target="_blank"
                style={{
                  fontSize: 16,
                  paddingLeft: '0.25rem',
                  lineHeight: 1.7,
                }}
                rel="noopener"
                onClick={() => analyticsLogger.whatsapp('login')}
              >
                <>{t('login.talkToUs')}</>
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{ padding: '0.5rem 0 1.5rem 0' }}
        >
          <Typography variant="h6" color="textPrimary">
            <Link
              href={process.env.REACT_APP_BACKOFFICE_URL}
              underline="always"
              variant="body2"
              target="_blank"
              style={{
                fontSize: 16,
                paddingLeft: '0.25rem',
                lineHeight: 1.7,
              }}
              rel="noopener"
            >
              <>{t('login.backofficeAccess')}</>
            </Link>
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{ padding: '0.5rem 0 1.5rem 0' }}
        >
          <PrivacyPolicy
            href={privacyPolicyURL}
            underline="always"
            target="_blank"
          >
            <>{t('login.privacyPolicy')}</>
          </PrivacyPolicy>
          <TermsOfUse href={termsOfUseURL} underline="always" target="_blank">
            <>{t('login.termsOfUse')}</>
          </TermsOfUse>
        </Grid>
      </MainContainer>
    </WelcomeLayout>
  );
};

export default Login;
