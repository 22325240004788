import { Alert } from '@mui/material';
import React from 'react';
import { useTheme } from 'styled-components';

type AlertProps = {
  alertContent?: string;
};

const AlertPanel = ({ alertContent }: AlertProps) => {
  const theme = useTheme();
  return (
    <Alert severity="info">
      <div style={{ fontFamily: theme.fontFamily }}>{alertContent}</div>
    </Alert>
  );
};

export default AlertPanel;
