import { createSlice } from '@reduxjs/toolkit';

import {
  FetchStatusEnum,
  NotificationFetchedAction,
  NotificationGetAction,
  NotificationMarkAllAsSeenAction,
  NotificationMarkAsSeenAction,
  NotificationNotSeenCountAction,
  NotificationSlice,
  NotificationUpdateErrorAction,
  NotificationUpdateReadAsSeenAction,
  NotificationUpdateStatusAction,
  NotificationUpdateStatusNotSeenCountAction,
} from '../../types';

const initialState: NotificationSlice = {
  notifications: [],
  notSeenCount: 0,
  firstNotRead: -1,
  fetchStatus: FetchStatusEnum.None,
  fetchStatusNotSeenCount: FetchStatusEnum.None,
  error: null,
  notificationsTotal: 0,
  page: 0,
  limit: 0,
};

export const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    clear: () => initialState,
    getNotifications: (state, action: NotificationGetAction) => {
      const { page, limit } = action.payload;
      state.page = page;
      state.limit = limit;
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    notifications: (state, action: NotificationFetchedAction) => {
      const { notifications, notificationsTotal, page } = action.payload;
      state.notifications =
        page === 0 ? notifications : [...state.notifications, ...notifications];
      state.notificationsTotal = notificationsTotal;
    },
    getFirstRead: (state) => {
      state.firstNotRead = state.notifications.findIndex((item) => item.read);
    },
    getNotSeenCount: (state) => {
      state.fetchStatusNotSeenCount = FetchStatusEnum.Fetching;
    },
    notSeenCount: (state, action: NotificationNotSeenCountAction) => {
      state.notSeenCount = action.payload.notSeenCount;
    },
    markNotificationAsSeen: (state, _: NotificationMarkAsSeenAction) => {},
    markAllNotificationsAsSeen: (state, _: NotificationMarkAllAsSeenAction) => {
      state.notifications.forEach((notification) => {
        notification.read = true;
      });
    },
    updateStatus: (state, action: NotificationUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateNotificationReadAsSeen: (
      state,
      action: NotificationUpdateReadAsSeenAction
    ) => {
      const indexRead = state.notifications.findIndex(
        (notification) => notification.id === action.payload.notificationId
      );
      state.notifications[indexRead].read = true;
    },
    updateStatusNotSeenCount: (
      state,
      action: NotificationUpdateStatusNotSeenCountAction
    ) => {
      state.fetchStatusNotSeenCount = action.payload.fetchStatusNotSeenCount;
    },
    updateError: (state, action: NotificationUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
