import { TypographyProps } from '@material-ui/core';
import {
  DefaultThemeColorsType,
  DefaultThemeFontSizeType,
  DefaultThemeFinancialOperationColorsType,
  DefaultThemeCreditPackageStatusColorsType,
} from '@sinagro/frontend-shared/themes';
import React from 'react';
import { IconType } from 'react-icons';

import {
  BadgeContainerFinancial,
  BadgeContainer,
  BadgeContainerTable,
  BadgeLabel,
  BadgeContainerCreditPackage,
} from './styles';

type BadgeProps = {
  Icon?: IconType;
  onClickIcon?: () => void;
  iconPosition?: 'start' | 'end';
  color?: DefaultThemeColorsType;
  borderColor?: DefaultThemeColorsType;
  financialOperationColor?: DefaultThemeFinancialOperationColorsType;
  creditPackageColor?: DefaultThemeCreditPackageStatusColorsType;
  fontSize?: DefaultThemeFontSizeType;
  labelColor: TypographyProps['color'];
  label: string;
  type?: 'default' | 'table' | 'financial' | 'creditPackage';
};

const Badge = ({
  Icon,
  onClickIcon,
  iconPosition = 'start',
  color = 'primary',
  labelColor,
  label,
  borderColor,
  financialOperationColor = 'payment',
  creditPackageColor = 'pending',
  fontSize = 'md',
  type = 'default',
}: BadgeProps) => {
  const Badge = {
    default: (
      <BadgeContainer item color={color} borderColor={borderColor}>
        {Icon && iconPosition === 'start' && (
          <Icon
            style={{ cursor: onClickIcon ? 'pointer' : 'default' }}
            onClick={onClickIcon}
          />
        )}
        <BadgeLabel
          fontSize={fontSize}
          style={{ lineHeight: '24px', padding: '3px' }}
          color={labelColor}
        >
          {label}
        </BadgeLabel>
        {Icon && iconPosition === 'end' && (
          <Icon
            style={{ cursor: onClickIcon ? 'pointer' : 'default' }}
            onClick={onClickIcon}
          />
        )}
      </BadgeContainer>
    ),
    table: (
      <BadgeContainerTable item color={color}>
        {Icon && iconPosition === 'start' && (
          <Icon
            style={{ cursor: onClickIcon ? 'pointer' : 'default' }}
            onClick={onClickIcon}
          />
        )}
        <BadgeLabel
          fontSize={fontSize}
          style={{ lineHeight: '16px' }}
          color={labelColor}
        >
          {label}
        </BadgeLabel>
        {Icon && iconPosition === 'end' && (
          <Icon
            style={{ cursor: onClickIcon ? 'pointer' : 'default' }}
            onClick={onClickIcon}
          />
        )}
      </BadgeContainerTable>
    ),
    financial: (
      <BadgeContainerFinancial item color={financialOperationColor}>
        {Icon && iconPosition === 'start' && (
          <Icon
            style={{ cursor: onClickIcon ? 'pointer' : 'default' }}
            onClick={onClickIcon}
          />
        )}
        <BadgeLabel
          fontSize={fontSize}
          style={{ lineHeight: '16px' }}
          color={labelColor}
        >
          {label}
        </BadgeLabel>
        {Icon && iconPosition === 'end' && (
          <Icon
            style={{ cursor: onClickIcon ? 'pointer' : 'default' }}
            onClick={onClickIcon}
          />
        )}
      </BadgeContainerFinancial>
    ),
    creditPackage: (
      <BadgeContainerCreditPackage item color={creditPackageColor}>
        {Icon && iconPosition === 'start' && (
          <Icon
            style={{ cursor: onClickIcon ? 'pointer' : 'default' }}
            onClick={onClickIcon}
          />
        )}
        <BadgeLabel
          fontSize={fontSize}
          style={{ lineHeight: '24px', padding: '3px' }}
          color={labelColor}
        >
          {label}
        </BadgeLabel>
        {Icon && iconPosition === 'end' && (
          <Icon
            style={{ cursor: onClickIcon ? 'pointer' : 'default' }}
            onClick={onClickIcon}
          />
        )}
      </BadgeContainerCreditPackage>
    ),
  };

  return Badge[type];
};

export default Badge;
