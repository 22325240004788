import React from 'react';

export type MenuType = {
  showMenu: boolean;
  setShowMenu?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Menu = React.createContext<MenuType>({
  showMenu: false,
});

export const useMenuContext = (): React.ContextType<
  React.Context<MenuType>
> => {
  return React.useContext(Menu);
};
