import { FetchStatusEnum } from '../../../../types';
import {
  RemittanceFetchAction,
  RemittanceGetAction,
  RemittanceUpdateStatusAction,
  TrackingSlice,
} from '../../../../types/v2';

export const reducersDefensivesAndSpecialties = {
  getRemittancesDefensivesAndSpecialtiesScheduled: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusDefensivesAndSpecialtiesScheduled =
      FetchStatusEnum.Fetching;
  },
  remittancesDefensivesAndSpecialtiesScheduled: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesDefensivesAndSpecialtiesScheduled =
      page === 0
        ? remittances
        : [
            ...state.remittancesDefensivesAndSpecialtiesScheduled,
            ...remittances,
          ];
    state.totalDefensivesAndSpecialtiesScheduled = total;
  },
  updateStatusDefensivesAndSpecialtiesScheduled: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusDefensivesAndSpecialtiesScheduled =
      action.payload.fetchStatus;
  },
  getRemittancesDefensivesAndSpecialtiesHandling: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusDefensivesAndSpecialtiesHandling =
      FetchStatusEnum.Fetching;
  },
  remittancesDefensivesAndSpecialtiesHandling: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesDefensivesAndSpecialtiesHandling =
      page === 0
        ? remittances
        : [
            ...state.remittancesDefensivesAndSpecialtiesHandling,
            ...remittances,
          ];
    state.totalDefensivesAndSpecialtiesHandling = total;
  },
  updateStatusDefensivesAndSpecialtiesHandling: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusDefensivesAndSpecialtiesHandling =
      action.payload.fetchStatus;
  },
  getRemittancesDefensivesAndSpecialtiesInTransit: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusDefensivesAndSpecialtiesInTransit =
      FetchStatusEnum.Fetching;
  },
  remittancesDefensivesAndSpecialtiesInTransit: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesDefensivesAndSpecialtiesInTransit =
      page === 0
        ? remittances
        : [
            ...state.remittancesDefensivesAndSpecialtiesInTransit,
            ...remittances,
          ];
    state.totalDefensivesAndSpecialtiesInTransit = total;
  },
  updateStatusDefensivesAndSpecialtiesInTransit: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusDefensivesAndSpecialtiesInTransit =
      action.payload.fetchStatus;
  },
  getRemittancesDefensivesAndSpecialtiesDelivered: (
    state: TrackingSlice,
    _: RemittanceGetAction
  ) => {
    state.fetchStatusDefensivesAndSpecialtiesDelivered =
      FetchStatusEnum.Fetching;
  },
  remittancesDefensivesAndSpecialtiesDelivered: (
    state: TrackingSlice,
    action: RemittanceFetchAction
  ) => {
    const { remittances, total, page } = action.payload;
    state.remittancesDefensivesAndSpecialtiesDelivered =
      page === 0
        ? remittances
        : [
            ...state.remittancesDefensivesAndSpecialtiesDelivered,
            ...remittances,
          ];
    state.totalDefensivesAndSpecialtiesDelivered = total;
  },
  updateStatusDefensivesAndSpecialtiesDelivered: (
    state: TrackingSlice,
    action: RemittanceUpdateStatusAction
  ) => {
    state.fetchStatusDefensivesAndSpecialtiesDelivered =
      action.payload.fetchStatus;
  },
};
