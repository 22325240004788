import React from 'react';
import { useTranslation } from 'react-i18next';
import RecommendedSprayingCaption from 'src/components/modules/torq/recommendedSprayingCaption';
import SprayingHourlyDetails from 'src/components/modules/torq/sprayingHourlyDetails';
import Title from 'src/components/modules/torq/title';
import { useReportContext } from 'src/contexts/report';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { Caption, Container, Content } from './styles';

const SprayingForecast = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { sprayingForecast, plot } = useReportContext();
  const plotName = plot?.name;

  if (sprayingForecast.length === 0) {
    return <></>;
  }

  return (
    <Container container $isMobile={isMobile}>
      <Title title={t('report.sprayingForecast')} plotName={plotName} />
      <Content $isMobile={isMobile}>
        <Caption>
          <RecommendedSprayingCaption />
        </Caption>
        <SprayingHourlyDetails sprayingForecast={sprayingForecast} />
      </Content>
    </Container>
  );
};

export default SprayingForecast;
