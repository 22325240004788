import { useTranslation } from 'react-i18next';
import {
  atLeastOneLowercase,
  atLeastOneNumber,
  atLeastOneSymbol,
  atLeastOneUppercase,
  notAcceptSpaces,
} from 'src/utils/regexes';
import * as yup from 'yup';

export const usePasswordRecoverySchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    newPassword: yup
      .string()
      .required(t('form.required'))
      .min(
        8,
        t('form.min', {
          field: t('passwordRecovery.newPassword'),
          min: 8,
        })
      )
      .matches(atLeastOneLowercase, t('form.atLeastOneLowercase'))
      .matches(atLeastOneUppercase, t('form.atLeastOneUppercase'))
      .matches(atLeastOneNumber, t('form.atLeastOneNumber'))
      .matches(atLeastOneSymbol, t('form.atLeastOneSymbol'))
      .matches(notAcceptSpaces, t('form.notAcceptSpaces')),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], t('form.confirmPassword'))
      .min(1, t('form.required')),
  });
};
