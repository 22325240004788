import { SharedError } from '.'

export const sapCdsOrderError: SharedError = {
  errorRef: 'sap:cds:order/error',
  message: 'Erro ao buscar pedidos na CDS',
}
export const sapCdsBillingError: SharedError = {
  errorRef: 'sap:cds:billing/error',
  message: 'Erro ao buscar faturamentos na CDS',
}
export const sapCdsBpError: SharedError = {
  errorRef: 'sap:cds:bp/error',
  message: 'Erro ao buscar parceiros na CDS',
}
export const sapCdsTradeContractError: SharedError = {
  errorRef: 'sap:cds:trade-contract/error',
  message: 'Erro ao buscar contratos de trade na CDS',
}
export const sapCdsBaseError: SharedError = {
  errorRef: 'sap:cds/error',
  message: 'Erro ao buscar dados na CDS',
}

export default [
  sapCdsOrderError,
  sapCdsBillingError,
  sapCdsBpError,
  sapCdsTradeContractError,
  sapCdsBaseError,
]
