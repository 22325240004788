import { Typography, Link } from '@material-ui/core';
import { IAlert } from '@sinagro/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/elements/materialUI/button';

import { Container, Description, Title } from './styles';

type DetailsProps = Pick<IAlert, 'title' | 'description'>;

const Details = ({ title, description }: DetailsProps) => {
  const { t } = useTranslation();

  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>

      <Link
        href={`https://api.whatsapp.com/send?1=pt_BR&phone=${whatsappNumber}`}
        underline="none"
        variant="body2"
        target="_blank"
        rel="noopener"
      >
        <Button
          variant="outlined"
          color="secondary"
          style={{
            padding: '0.5rem 1rem',
            width: '100%',
          }}
        >
          <Typography
            variant="h6"
            color="primary"
            style={{
              fontWeight: 600,
            }}
          >
            {t('torq.alert.talkToUs')}
          </Typography>
        </Button>
      </Link>
    </Container>
  );
};

export default Details;
