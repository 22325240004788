import React from 'react';

import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
} from './styles';

type AccordionProps = {
  summary: React.ReactNode;
  details: React.ReactNode;
  expanded: boolean;
  popper?: boolean;
  onChange?: (event: React.ChangeEvent<unknown>, expanded: boolean) => void;
  style?: React.CSSProperties;
};

const Accordion = ({
  summary,
  details,
  expanded,
  popper = false,
  onChange,
  style,
}: AccordionProps) => {
  return (
    <>
      {popper && summary}
      <AccordionStyled expanded={expanded} onChange={onChange} style={style}>
        <AccordionSummaryStyled>{!popper && summary}</AccordionSummaryStyled>
        <AccordionDetailsStyled>{details}</AccordionDetailsStyled>
      </AccordionStyled>
    </>
  );
};

export default Accordion;
