import { SharedError } from '.'

export const orderNotFoundError: SharedError = {
  errorRef: 'order/not-found',
  message: 'Pedido não encontrado',
}
export const orderInvoiceNotFoundError: SharedError = {
  errorRef: 'order:invoice/not-found',
  message: 'Nota não encontrada',
}
export const orderInvoiceFileNotFoundError: SharedError = {
  errorRef: 'order:invoice:file/not-found',
  message: 'Arquivo da nota fiscal não encontrada',
}

export default [
  orderNotFoundError,
  orderInvoiceNotFoundError,
  orderInvoiceFileNotFoundError,
]
