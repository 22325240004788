import { createSlice } from '@reduxjs/toolkit';

import {
  FetchStatusEnum,
  ReportGetAction,
  ReportFetchAction,
  ReportSlice,
  ReportUpdateStatusAction,
  ReportUpdateErrorAction,
  AuthenticatedEnum,
  ReportUpdateUserAuthenticatedAction,
  ReportShareLinkGetAction,
  ReportShareLinkFetchAction,
} from '../../types';

export const initialState: ReportSlice = {
  report: null,
  shareLink: null,
  reportUserAuthenticated: AuthenticatedEnum.None,
  fetchStatus: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    clear: () => initialState,
    getReport: (state, _: ReportGetAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    report: (state, action: ReportFetchAction) => {
      state.report = action.payload.report;
    },
    getShareLink: (state, _: ReportShareLinkGetAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    shareLink: (state, action: ReportShareLinkFetchAction) => {
      state.shareLink = action.payload.shareLink;
    },
    reportUserAuthenticated: (
      state,
      action: ReportUpdateUserAuthenticatedAction
    ) => {
      state.reportUserAuthenticated = action.payload.reportUserAuthenticated;
    },
    updateStatus: (state, action: ReportUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: ReportUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
