import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/elements/loader';
import ReportLayout from 'src/components/layout/reportLayout';
import { useReportContext } from 'src/contexts/report';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import Alerts from './alerts';
import Disclaimer from './disclaimer';
import DiseaseForecast from './diseaseForecast';
import FarmSummary from './farmSummary';
import Plots from './plots';
import Region from './region';
import SprayingForecast from './sprayingForecast';
import {
  Container,
  EmptyMessage,
  LinkToNotificationConfig,
  LoaderContainer,
} from './styles';
import Weather from './weather';

const ReportContent: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { isLoading, isEmpty } = useReportContext();

  const padding = isMobile ? '1rem 0' : '1rem 2rem';

  if (isLoading) {
    return (
      <ReportLayout>
        <Container>
          <LoaderContainer>
            <Loader size={24} />
          </LoaderContainer>
        </Container>
      </ReportLayout>
    );
  }

  if (isEmpty) {
    return (
      <ReportLayout>
        <Container>
          <EmptyMessage>{t('report.empty')}</EmptyMessage>
        </Container>
      </ReportLayout>
    );
  }

  return (
    <ReportLayout>
      <Container style={{ padding }}>
        <Grid container alignItems="center">
          <Grid container item xs={12} sm={6} md={4} lg={3}>
            <Region />
          </Grid>
          <Grid container item xs={12} sm={6} md={8} lg={9}>
            <FarmSummary />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid container item xs={12} sm={6} md={4} lg={3}>
            <Alerts />
          </Grid>
          <Grid container item xs={12} sm={6} md={8} lg={9}>
            <Weather />
            <SprayingForecast />
            <DiseaseForecast />
            <Plots />
            <Disclaimer />
            <LinkToNotificationConfig
              href={`${process.env.REACT_APP_PORTAL_BASE_URL}/profile/notification-preferences`}
              target="_blank"
            >
              {t('report.notificationConfigConsent')}
            </LinkToNotificationConfig>
          </Grid>
        </Grid>
      </Container>
    </ReportLayout>
  );
};

export default ReportContent;
