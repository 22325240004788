import { Grid, Link as MUILink } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)<{ $isMobile: boolean }>`
  width: 100%;
  margin-bottom: 2rem;
  padding: ${({ $isMobile }) => ($isMobile ? '0 1rem' : 0)};
`;

export const Link = styled(MUILink)`
  && {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.fontSize.lg}px;
    color: ${({ theme }) => theme.colors.blue};
  }
  cursor: pointer;
`;
