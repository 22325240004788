import { SagaIterator } from '@redux-saga/types';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getWeatherForecast,
  getDiseaseForecast,
  getSprayingForecast,
  getFarmDetails,
  getCountActiveAlerts,
  getActiveAlerts,
  getRemoteSensingImages,
  getGrowthCurveChart,
} from '../../services/torq';
import {
  FetchStatusEnum,
  WeatherForecastGetAction,
  DiseaseForecastGetAction,
  SprayingForecastGetAction,
  FarmDetailsGetAction,
  CountActiveAlertsGetAction,
  ActiveAlertsGetAction,
  RemoteSensingImagesGetAction,
  GrowthCurveChartGetAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getWeatherForecast.type, getWeatherForecastEffectSaga),
  takeLatest(actions.getDiseaseForecast.type, getDiseaseForecastEffectSaga),
  takeLatest(actions.getSprayingForecast.type, getSprayingForecastEffectSaga),
  takeLatest(actions.getFarmDetails.type, getFarmDetailsEffectSaga),
  takeLatest(actions.getCountActiveAlerts.type, getCountActiveAlertsEffectSaga),
  takeLatest(actions.getActiveAlerts.type, getActiveAlertsEffectSaga),
  takeLatest(
    actions.getRemoteSensingImages.type,
    getRemoteSensingImagesEffectSaga
  ),
  takeLatest(actions.getGrowthCurveChart.type, getGrowthCurveChartEffectSaga),
];

export function* getWeatherForecastEffectSaga(
  action: WeatherForecastGetAction
): SagaIterator<void> {
  try {
    const weatherForecast = yield call(getWeatherForecast, action.payload);
    yield put(actions.weatherForecast({ weatherForecast }));
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export function* getDiseaseForecastEffectSaga(
  action: DiseaseForecastGetAction
): SagaIterator<void> {
  try {
    const diseaseForecast = yield call(getDiseaseForecast, action.payload);
    yield put(actions.diseaseForecast({ diseaseForecast }));
    yield put(
      actions.updateStatusDisease({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusDisease({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}

export function* getSprayingForecastEffectSaga(
  action: SprayingForecastGetAction
): SagaIterator<void> {
  try {
    const sprayingForecast = yield call(getSprayingForecast, action.payload);
    yield put(actions.sprayingForecast({ sprayingForecast }));
    yield put(
      actions.updateStatusSpraying({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusSpraying({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}

export function* getFarmDetailsEffectSaga(
  action: FarmDetailsGetAction
): SagaIterator<void> {
  try {
    const farmDetails = yield call(getFarmDetails, action.payload);
    yield put(actions.farmDetails({ farmDetails }));
    yield put(
      actions.updateStatusFarmDetails({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusFarmDetails({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}

export function* getCountActiveAlertsEffectSaga(
  action: CountActiveAlertsGetAction
): SagaIterator<void> {
  try {
    const countActiveAlerts = yield call(getCountActiveAlerts, action.payload);
    yield put(actions.countActiveAlerts(countActiveAlerts));
    yield put(
      actions.updateStatusCountActiveAlerts({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusCountActiveAlerts({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getActiveAlertsEffectSaga(
  action: ActiveAlertsGetAction
): SagaIterator<void> {
  try {
    const alerts = yield call(getActiveAlerts, action.payload);
    yield put(actions.activeAlerts({ alerts }));
    yield put(
      actions.updateStatusActiveAlerts({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusActiveAlerts({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getRemoteSensingImagesEffectSaga(
  action: RemoteSensingImagesGetAction
): SagaIterator<void> {
  try {
    const remoteSensingImages = yield call(
      getRemoteSensingImages,
      action.payload
    );
    yield put(actions.remoteSensingImages({ remoteSensingImages }));
    yield put(
      actions.updateStatusRemoteSensingImages({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusRemoteSensingImages({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}

export function* getGrowthCurveChartEffectSaga(
  action: GrowthCurveChartGetAction
): SagaIterator<void> {
  try {
    const growthCurveChart = yield call(getGrowthCurveChart, action.payload);
    yield put(actions.growthCurveChart({ growthCurveChart }));
    yield put(
      actions.updateStatusGrowthCurveChart({
        fetchStatus: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusGrowthCurveChart({
        fetchStatus: FetchStatusEnum.Failure,
      })
    );
  }
}
