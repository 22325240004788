import { SagaIterator } from '@redux-saga/types';
import { IHarvestReference } from '@sinagro/shared';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getFullHarvestReferences,
  getHarvestReferences,
} from '../../services/harvestReferences';
import { FetchStatusEnum } from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getHarvestReferences.type, getHarvestReferencesEffectSaga),
  takeLatest(
    actions.getFullHarvestReferences.type,
    getFullHarvestReferencesEffectSaga
  ),
];

export type getHarvestReferencesResponse = string[];
export type getFullHarvestReferencesResponse = IHarvestReference[];

export function* getHarvestReferencesEffectSaga(): SagaIterator<void> {
  try {
    const harvestReferences: getHarvestReferencesResponse = yield call(
      getHarvestReferences
    );
    yield put(actions.harvestReferences({ harvestReferences }));
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export function* getFullHarvestReferencesEffectSaga(): SagaIterator<void> {
  try {
    const fullHarvestReferences: getFullHarvestReferencesResponse = yield call(
      getFullHarvestReferences
    );
    yield put(actions.fullHarvestReferences({ fullHarvestReferences }));
    yield put(
      actions.updateStatusFull({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusFull({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}
