export const phoneNumberRegex = /(\(\d{2}\)\s)(\d{4,5}-\d{4})/g;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
export const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/g;
export const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/g;
export const atLeastOneLowercase = /(.*[a-z].*)/;
export const atLeastOneUppercase = /(.*[A-Z].*)/;
export const atLeastOneNumber = /(.*\d.*)/;
export const atLeastOneSymbol = /(.*\W.*)/;
export const notAcceptSpaces = /^[^ ]+$/;
