import { Link } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { privacyPolicyURL, termsOfUseURL } from '../../utils/urls';

const TermsLabel = () => {
  const { t } = useTranslation();

  return (
    <>
      {t('signUpForm.policyAndTermsOfService.part1')}
      <Link href={privacyPolicyURL} underline="always" target="_blank">
        <>{t('signUpForm.policyAndTermsOfService.part2')}</>
      </Link>
      <>{t('signUpForm.policyAndTermsOfService.part3')}</>
      <Link href={termsOfUseURL} underline="always" target="_blank">
        <>{t('signUpForm.policyAndTermsOfService.part4')}</>
      </Link>
    </>
  );
};

export default TermsLabel;
