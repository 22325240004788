import { Grid } from '@material-ui/core';
import React from 'react';

import bg2 from '../../../assets/backgrounds/2.png';
import bg3 from '../../../assets/backgrounds/3.png';
import bg4 from '../../../assets/backgrounds/4.png';
import { Image, ImagesBack } from './styles';

const Backgrounds: React.FC = () => {
  const backgrounds = [
    {
      image: bg2,
      alt: 'Produtor rural e consultor da Sinova se cumprimentando na fazenda - Portal do Cliente',
    },
    {
      image: bg3,
      alt: 'Plantação de soja - Portal do Cliente Sinova',
    },
    {
      image: bg4,
      alt: 'Plantação de algodão - Portal Sinova',
    },
  ];

  const shuffledBacks = backgrounds.sort(() => Math.random() - 0.5);

  return (
    <Grid item sm={6}>
      <ImagesBack>
        {shuffledBacks.map((item, index) => (
          <Image
            width="100%"
            height="100%"
            src={item.image}
            alt={item.alt}
            key={index}
          />
        ))}
      </ImagesBack>
    </Grid>
  );
};

export default React.memo(Backgrounds);
