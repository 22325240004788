import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { getDuplicate } from '../../services/duplicate';
import {
  FetchStatusEnum,
  GetDuplicateResponse,
  UpdateDuplicatesStatusPayload,
} from '../../types';
import {
  handleSuccessBoolean,
  HandleSuccessBooleanReturn,
} from '../errors/sagas';
import { actions } from './slice';

export function* handleOneDuplicateResponse(
  response: { success: boolean }[],
  duplicateId: string,
  statusProperty: 'sendToRevisionStatus' | 'sendToSignatureStatus'
): SagaIterator<void> {
  const { indexesWithError, indexesWithSuccess }: HandleSuccessBooleanReturn =
    yield call(handleSuccessBoolean, response);

  if (indexesWithError.length === 0) {
    const duplicate: GetDuplicateResponse = yield call(getDuplicate, {
      id: duplicateId,
    });

    yield put(
      actions.updateKey({
        key: 'duplicateUpdated',
        value: duplicate,
      })
    );

    yield put(
      actions.updateKey({
        key: statusProperty,
        value: FetchStatusEnum.Success,
      })
    );
    return;
  }

  if (indexesWithSuccess.length === 0) {
    yield put(
      actions.updateKey({
        key: statusProperty,
        value: FetchStatusEnum.Failure,
      })
    );
    return;
  }
}

export function* handleMultipleDuplicateResponse(
  response: { success: boolean }[],
  payload: UpdateDuplicatesStatusPayload,
  statusProperty: 'sendToRevisionStatus' | 'sendToSignatureStatus'
): SagaIterator<void> {
  const { indexesWithError, indexesWithSuccess }: HandleSuccessBooleanReturn =
    yield call(handleSuccessBoolean, response);

  if (indexesWithError.length === 0) {
    yield put(
      actions.updateKey({
        key: statusProperty,
        value: FetchStatusEnum.Success,
      })
    );
    return;
  }

  yield put(
    actions.updateKey({
      key: statusProperty,
      value: FetchStatusEnum.Failure,
    })
  );

  if (indexesWithSuccess.length === 0) return;

  yield put(
    actions.updateKey({
      key: 'idsWithErrors',
      value: indexesWithError.map((index) => payload[index].duplicateId),
    })
  );
}
