import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)<{ $isMobile: boolean }>`
  width: 100%;
  margin-bottom: 3rem;
  padding: ${({ $isMobile }) => ($isMobile ? '0 1rem' : 0)};
`;

export const Title = styled(Typography)`
  && {
    font-size: ${({ theme }) => theme.fontSize.xxl}px;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 1rem;
  }
`;

export const Content = styled(Grid)<{ $hideScroll: boolean }>`
  overflow-x: hidden;
  overflow-y: ${({ $hideScroll }) => ($hideScroll ? 'hidden' : 'auto')};
  max-height: 380px;
  padding-right: 1rem;
  padding-bottom: 1rem;

  ::-webkit-scrollbar {
    width: 8px;
    height: 16px;
    background-color: ${({ theme }) => theme.colors.primary}10;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:vertical {
    width: 6px;
    background-color: ${({ theme }) => theme.colors.primary}20;
    border-radius: 8px;
  }
`;

export const Card = styled(Grid)`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 8px 8px rgba(32, 40, 109, 0.1);
  border-radius: 8px;
  padding: 1rem;
`;
