export class CustomURLSearchParams {
  _values = new Map();
  constructor(init?: Record<string, string>) {
    if (!init) return;
    Object.entries(init).forEach(([key, value]) => {
      this._values.set(key, value);
    });
  }

  set(param: string, value: string | string[]) {
    this._values.set(param, value);
  }
  toString() {
    let result = '';
    this._values.forEach((value, key) => {
      if (Array.isArray(value)) {
        value.forEach((v) => (result += `${key}=${v}&`));
        return;
      }
      result += `${key}=${value}&`;
    });
    return encodeURI(result.slice(0, result.length - 1));
  }
}
