import {
  AuthenticatedEnum,
  FetchStatusEnum,
} from '@sinagro/frontend-shared/sharedTypes';
import { UserStatusEnum } from '@sinagro/shared';
import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/layout/dashboardLayout';

import { useSelector } from '../state/store';

type AuthRouteProps = {
  children: ReactElement;
  type: 'private' | 'validation' | 'public';
};

const AuthRoute = ({ children, type }: AuthRouteProps) => {
  const userAuthenticated = useSelector(
    (state) => state.shared.user.userAuthenticated
  );
  const claims = useSelector((state) => state.shared.user.claims);

  const userNotValidated = claims.status === UserStatusEnum.NotValidated;

  const fetchValidationStatus = useSelector(
    (state) => state.shared.validationCode.fetchStatus
  );

  const validationCodeSuccess =
    fetchValidationStatus === FetchStatusEnum.Success;

  const fetchAuth = useSelector((state) => state.auth.fetchStatus);

  const userAuthenticatedAndValidated =
    !userNotValidated &&
    !validationCodeSuccess &&
    fetchAuth === FetchStatusEnum.None;

  if (userAuthenticated === AuthenticatedEnum.Authenticated) {
    if (type !== 'validation' && userNotValidated) {
      return <Navigate to="/validation" replace />;
    }
    if (type !== 'private' && userAuthenticatedAndValidated) {
      return <Navigate to="/dashboard" replace />;
    }
  } else if (
    type !== 'public' &&
    userAuthenticated === AuthenticatedEnum.NotAuthenticated
  ) {
    return <Navigate to="/" replace />;
  }

  if (
    userAuthenticated === AuthenticatedEnum.Authenticated &&
    !userNotValidated &&
    !validationCodeSuccess
  ) {
    return <DashboardLayout>{children}</DashboardLayout>;
  }
  return children;
};

export default AuthRoute;
