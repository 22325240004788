import { Grid, Typography } from '@material-ui/core';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';
import { ValidationCodeTypeEnum } from '@sinagro/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/elements/loader';

import Button from '../../../components/elements/materialUI/button';
import CodeInput from '../../../components/modules/codeInput';
import { actions, useSelector, useDispatch } from '../../../state/store';
import { ValidationCodeTypeEnumTranslated } from '../../../utils/enumsTranslate';
import { Container, Footer, Text, Title } from './styles';

type Props = {
  validationCodeType: ValidationCodeTypeEnum;
  emailOrPhone?: string;
  requestByEditField?: boolean;
};

const ValidationCode = ({
  validationCodeType,
  emailOrPhone,
  requestByEditField = false,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const typeTranslated = ValidationCodeTypeEnumTranslated[validationCodeType];
  const titleTranslated =
    validationCodeType === ValidationCodeTypeEnum.Email
      ? t('profile.validationCode.title', { type: typeTranslated })
      : t('profile.validationCode.title', { type: 'Whastapp' });

  const [code, setCode] = useState<string[]>([]);
  const [countdown, setCountdown] = useState(120);
  const [resend, setResend] = useState(false);

  const fetchStatus = useSelector(
    (state) => state.shared.validationCode.fetchStatus
  );
  const fetchCheckStatus = useSelector(
    (state) => state.shared.validationCode.fetchCheckStatus
  );
  const error = useSelector((state) => state.shared.validationCode.error);

  useEffect(() => {
    if (fetchCheckStatus === FetchStatusEnum.Failure) {
      document.getElementById(`code-5`)?.focus();
    }
  }, [fetchCheckStatus]);

  const isLoading =
    fetchStatus === FetchStatusEnum.Fetching ||
    fetchCheckStatus === FetchStatusEnum.Fetching;

  const handleSubmit = useCallback(() => {
    dispatch(
      actions.shared.validationCode.profileDataCheck({
        code: code.join(''),
      })
    );
  }, [code, dispatch]);

  const handleSendAgain = useCallback(() => {
    setCode([]);
    setCountdown(120);
    setResend(false);
    dispatch(actions.shared.validationCode.clear());

    if (requestByEditField && emailOrPhone) {
      dispatch(
        actions.shared.userProfile.updateUserEmailOrPhone({
          data: emailOrPhone,
          type: validationCodeType,
        })
      );
      return;
    }

    dispatch(
      actions.shared.validationCode.request({
        type: validationCodeType,
      })
    );
  }, [dispatch, emailOrPhone, requestByEditField, validationCodeType]);

  useEffect(() => {
    countdown > 0
      ? setTimeout(() => setCountdown(countdown - 1), 1000)
      : setResend(true);
  }, [countdown]);

  useEffect(() => {
    if (code.join('').trim().length === 6) {
      handleSubmit();
    }
  }, [code, handleSubmit]);

  if (isLoading) {
    return (
      <Grid container style={{ marginTop: '1rem', justifyContent: 'center' }}>
        <Loader />
      </Grid>
    );
  }

  return (
    <Container>
      <Title color="primary">{titleTranslated}</Title>

      <Text color="secondary">
        {requestByEditField
          ? t('profile.validationCode.textEdit', { type: typeTranslated })
          : t('profile.validationCode.text', { type: typeTranslated })}
      </Text>

      <CodeInput code={code} setCode={setCode} />

      <Footer>
        <Text color="secondary" style={{ marginRight: '2rem' }}>
          {countdown} {t('profile.validationCode.seconds')}
        </Text>
        <Button
          variant="contained"
          color="primary"
          style={{ width: 96 }}
          disabled={!resend}
          onClick={handleSendAgain}
        >
          <Typography variant="h6">
            {t('profile.validationCode.sendAgain')}
          </Typography>
        </Button>
      </Footer>
      {error && (
        <Text
          variant="body2"
          color="error"
          style={{ marginTop: '1rem', textAlign: 'center' }}
        >
          {error.message}
        </Text>
      )}
    </Container>
  );
};

export default ValidationCode;
