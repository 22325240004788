import React from 'react';
import ReportProvider from 'src/providers/report';

import ReportContent from './report';

const Report: React.FC = () => {
  return (
    <ReportProvider>
      <ReportContent />
    </ReportProvider>
  );
};

export default Report;
