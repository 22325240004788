import { Grid } from '@material-ui/core';
import { DiseaseForecastDaily } from '@sinagro/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { riskColor } from 'src/pages/torq/utils/disease';
import { formatItemDate } from 'src/pages/torq/utils/utils';

import { Container, Date, Risk } from './styles';

type CardProps = {
  item: DiseaseForecastDaily;
};

const Card = ({ item }: CardProps) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={4} md={2} zeroMinWidth>
      <Container>
        <Date>{formatItemDate(item.date)}</Date>
        <Risk style={{ color: riskColor[item.riskLevel] }}>
          {t(`torq.disease.risk.${item.riskLevel}`)}
        </Risk>
      </Container>
    </Grid>
  );
};

export default Card;
