import { WeatherForecastGeolocationGet } from '../../types';
import { CustomURLSearchParams } from '../../utils/customUrlSearchParams';
import api from '../api';
import { weatherForecastParams } from './queryParams';

export function getWeatherForecast(payload: WeatherForecastGeolocationGet) {
  let params = new CustomURLSearchParams({});

  params = weatherForecastParams(
    params,
    payload.lat,
    payload.long,
    payload.date
  );

  return api.get(`/weather/forecast/geolocation?${params}`);
}
