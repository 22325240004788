import { createSlice } from '@reduxjs/toolkit';
import { FetchStatusEnum } from '@sinagro/frontend-shared/sharedTypes';

import {
  RequestCodeAction,
  CheckCodeAction,
  ValidationCodeSlice,
  ValidationCodeUpdateErrorAction,
  ValidationCodeUpdateStatusAction,
  ValidationCodeUpdateCheckStatusAction,
} from '../../types';

const initialState: ValidationCodeSlice = {
  fetchStatus: FetchStatusEnum.None,
  fetchCheckStatus: FetchStatusEnum.None,
  fetchValidationCodeStatus: FetchStatusEnum.None,
  error: null,
};

export const slice = createSlice({
  name: 'validationCode',
  initialState,
  reducers: {
    clear: () => initialState,
    request: (state, _: RequestCodeAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    check: (state, _: CheckCodeAction) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    profileDataCheck: (state, _: CheckCodeAction) => {
      state.fetchCheckStatus = FetchStatusEnum.Fetching;
    },
    updateStatus: (state, action: ValidationCodeUpdateStatusAction) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateCheckStatus: (
      state,
      action: ValidationCodeUpdateCheckStatusAction
    ) => {
      state.fetchCheckStatus = action.payload.fetchCheckStatus;
    },
    updateValidationCodeStatus: (
      state,
      action: ValidationCodeUpdateStatusAction
    ) => {
      state.fetchValidationCodeStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: ValidationCodeUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
