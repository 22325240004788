import { Grid, Typography, Link } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { analyticsLogger } from 'src/utils/analytics';

import Logo from '../../components/elements/logo';
import Button from '../../components/elements/materialUI/button';
import WelcomeLayout from '../../components/layout/welcomeLayout';
import { privacyPolicyURL, termsOfUseURL } from '../../utils/urls';
import {
  MainContainer,
  TitleContainer,
  SubtitleContainer,
  SubmitContainer,
  PrivacyPolicy,
  TermsOfUse,
} from './styles';

const Invite: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  const handleAlreadyRegister = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  const handleSignUp = useCallback(() => {
    navigate('/sign-up', { replace: true });
  }, [navigate]);

  return (
    <WelcomeLayout>
      <MainContainer
        container
        item
        xs={12}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          xs={12}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ flex: 1 }}
        >
          <Grid item xs={12}>
            <Logo />
          </Grid>
          <TitleContainer item xs={12}>
            <Typography variant="h1" color="textPrimary">
              {t('commons.title')}
            </Typography>
          </TitleContainer>

          <SubtitleContainer item xs={12}>
            <Typography variant="h3" color="textPrimary">
              {t('inviteSignUp.subtitle')}
            </Typography>
          </SubtitleContainer>

          <SubmitContainer item xs={12}>
            <Button
              onClick={handleSignUp}
              variant="contained"
              color="primary"
              fullWidth
            >
              <Typography variant="h6">{t('inviteSignUp.button')}</Typography>
            </Button>
          </SubmitContainer>

          <SubtitleContainer item xs={12}>
            <Typography variant="h6" color="textPrimary">
              {t('inviteSignUp.alreadyRegistered')}
            </Typography>
          </SubtitleContainer>

          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ fontSize: 16 }}
            >
              {t('signUp.alreadyRegister')}
              <Link
                href="#"
                underline="always"
                component="button"
                variant="body2"
                onClick={handleAlreadyRegister}
                style={{
                  fontSize: 16,
                  paddingLeft: '0.25rem',
                  lineHeight: 1.7,
                }}
              >
                <>{t('signUp.doLogin')}</>
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ padding: '1rem 0 1.5rem 0' }}>
            <Typography variant="h6" color="textPrimary">
              {t('login.needHelp')}
              <Link
                href={`https://api.whatsapp.com/send?1=pt_BR&phone=${whatsappNumber}`}
                underline="always"
                variant="body2"
                target="_blank"
                style={{
                  fontSize: 16,
                  paddingLeft: '0.25rem',
                  lineHeight: 1.7,
                }}
                rel="noopener"
                onClick={() => analyticsLogger.whatsapp('login')}
              >
                <>{t('login.talkToUs')}</>
              </Link>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{ padding: '0.5rem 0 1.5rem 0' }}
        >
          <PrivacyPolicy
            href={privacyPolicyURL}
            underline="always"
            target="_blank"
          >
            <>{t('login.privacyPolicy')}</>
          </PrivacyPolicy>
          <TermsOfUse href={termsOfUseURL} underline="always" target="_blank">
            <>{t('login.termsOfUse')}</>
          </TermsOfUse>
        </Grid>
      </MainContainer>
    </WelcomeLayout>
  );
};

export default Invite;
