import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import styled from 'styled-components';

export const AccordionStyled = styled(Accordion)`
  && {
    width: 100%;
    margin: 0;

    :before {
      height: 0 !important;
    }
  }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  && {
    margin: 0;
    padding: 0;
    min-height: auto !important;

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
  && {
    padding: 0;
    margin: 0;
  }
`;
