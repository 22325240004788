import { SagaIterator } from '@redux-saga/types';
import {
  IFinancialPayableAggregation,
  IFinancialPayableAggregationItem,
  IFinancialPayableSummary,
} from '@sinagro/shared';
import { call, takeLatest, put } from 'redux-saga/effects';

import {
  getPayables,
  getPayablesPaged,
  getSummary,
} from '../../services/financialPayable';
import {
  FetchStatusEnum,
  FinancialPayableGetPayablesAction,
  FinancialPayableGetSummaryAction,
  PayableGetAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.getSummary.type, getSummaryEffectSaga),
  takeLatest(actions.getPayables.type, getPayablesEffectSaga),
  takeLatest(actions.getPayablesPaged.type, getPayablesPagedEffectSaga),
];

export function* getSummaryEffectSaga(
  action: FinancialPayableGetSummaryAction
): SagaIterator<void> {
  try {
    const summary: IFinancialPayableSummary = yield call(
      getSummary,
      action.payload
    );
    yield put(actions.summary({ summary }));
    yield put(
      actions.updateStatusSummary({ fetchStatus: FetchStatusEnum.Success })
    );
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(
      actions.updateStatusSummary({ fetchStatus: FetchStatusEnum.Failure })
    );
  }
}

export function* getPayablesEffectSaga(
  action: FinancialPayableGetPayablesAction
): SagaIterator<void> {
  try {
    const payables: IFinancialPayableAggregation[] = yield call(
      getPayables,
      action.payload
    );
    yield put(actions.payables({ payables }));
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export type getPayableResponse = {
  total: number;
  results: IFinancialPayableAggregationItem[];
};

export function* getPayablesPagedEffectSaga(
  action: PayableGetAction
): SagaIterator<void> {
  try {
    const { results: payablesPaged, total }: getPayableResponse = yield call(
      getPayablesPaged,
      action.payload
    );
    yield put(
      actions.payablesPaged({ payablesPaged, total, page: action.payload.page })
    );
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
  } catch (error) {
    yield call(handleError, {
      error,
      showToast: true,
    });
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}
