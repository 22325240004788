import { ReceivablesGet, ReceivablesSummaryGet } from '../../../types/v2';
import { CustomURLSearchParams } from '../../../utils/customUrlSearchParams';
import api from '../../api';
import { receivablesFiltersParams, receivablesPageParams } from './queryParams';

export function getReceivables(payload: ReceivablesGet) {
  let params = new CustomURLSearchParams({});

  params = receivablesFiltersParams(params, payload.filters);
  params = receivablesPageParams(params, payload);
  return api.get(`/financial/receivable/v2?${params}`);
}

export function getSummary(payload: ReceivablesSummaryGet) {
  let params = new CustomURLSearchParams({});

  params = receivablesFiltersParams(params, payload.filters);
  return api.get(`/financial/receivable/v2/summary?${params}`);
}
