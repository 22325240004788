import {
  ThemeProvider as MaterialUIProvider,
  createTheme,
} from '@material-ui/core';
import { defaultTheme } from '@sinagro/frontend-shared/themes';
import React from 'react';
import { ThemeProvider as StyledComponentsProvider } from 'styled-components';

import { materialUITheme } from '../utils/theme';

const ThemeProviderWrapper: React.FC = ({ children }) => {
  const materialUIThemeCustomized = createTheme(materialUITheme);

  return (
    <React.Fragment>
      <MaterialUIProvider theme={materialUIThemeCustomized}>
        <StyledComponentsProvider theme={defaultTheme}>
          {children}
        </StyledComponentsProvider>
      </MaterialUIProvider>
    </React.Fragment>
  );
};

export default ThemeProviderWrapper;
