import { SharedError } from '.'

export const notFoundDuplicateError: SharedError = {
  errorRef: 'duplicate/not-found',
  message: 'Duplicata não encontrada',
}

export const clientNotFoundDuplicateError: SharedError = {
  errorRef: 'duplicate/client-not-found',
  message: 'ID de cliente não encontrado',
}

export default [notFoundDuplicateError, clientNotFoundDuplicateError]
