import {
  DailyWeatherForecastDTO,
  DiseaseForecastDTO,
  FarmDetailsDTO,
  IAlert,
  IClient,
  IRegion,
} from '@sinagro/shared';
import React from 'react';

import { SprayingForecast } from './torq';

export type ReportType = {
  alerts: IAlert[];
  weather: DailyWeatherForecastDTO[];
  sprayingForecast: SprayingForecast[];
  diseaseForecast: DiseaseForecastDTO[];
  region: IRegion | FarmDetailsDTO | null;
  plot: IRegion | null;
  client: IClient | null;
  reportGeneratedAt: Date | null;
  isLoading: boolean;
  isEmpty: boolean;
};

export const Report = React.createContext<ReportType>({
  alerts: [],
  weather: [],
  sprayingForecast: [],
  diseaseForecast: [],
  region: null,
  plot: null,
  client: null,
  reportGeneratedAt: null,
  isLoading: true,
  isEmpty: false,
});

export const useReportContext = (): React.ContextType<
  React.Context<ReportType>
> => {
  return React.useContext(Report);
};
