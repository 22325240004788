import {
  AuthenticatedEnum,
  FetchStatusEnum,
} from '@sinagro/frontend-shared/sharedTypes';
import {
  DailyWeatherForecastDTO,
  DiseaseForecastDTO,
  FarmReportData,
  IRegion,
  RegionType,
  ReportDTO,
} from '@sinagro/shared';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SprayingForecast } from 'src/contexts/torq';
import { useQuery } from 'src/hooks/useQuery';
import { actions, useDispatch, useSelector } from 'src/state/store';

import { Report } from '../contexts/report';
import {
  getFilteredDailyWeather,
  getFormattedSprayingForecast,
} from './torq/format';

export const ReportProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get('token');

  const [weather, setWeather] = useState<DailyWeatherForecastDTO[]>([]);
  const [sprayingForecast, setSprayingForecast] = useState<SprayingForecast[]>(
    []
  );
  const [diseaseForecast, setDiseaseForecast] = useState<DiseaseForecastDTO[]>(
    []
  );
  const [plot, setPlot] = useState<IRegion | null>(null);

  const report = useSelector((state) => state.shared.report.report);
  const fetchStatus = useSelector((state) => state.shared.report.fetchStatus);
  const userAuthenticated = useSelector(
    (state) => state.shared.report.reportUserAuthenticated
  );

  const alerts = report?.data.alerts || [];
  const region = report?.data.region || null;
  const client = report?.client || null;
  const reportGeneratedAt = report?.generatedAt || null;

  const userNotAuthenticated =
    userAuthenticated === AuthenticatedEnum.NotAuthenticated;
  const isLoading = fetchStatus === FetchStatusEnum.Fetching;
  const isEmpty = !isLoading && report === null;

  useEffect(() => {
    if (!token || userNotAuthenticated) {
      if (userNotAuthenticated) {
        toast(t('report.invalidToken'), { type: 'error' });
      }
      return navigate('/', { replace: true });
    }

    dispatch(actions.shared.report.getReport({ token }));
  }, [navigate, token, dispatch, userNotAuthenticated, t]);

  useEffect(() => {
    const weatherForecast = report?.data.weatherForecast || [];
    const filteredDailyWeather = getFilteredDailyWeather(weatherForecast);
    setWeather(filteredDailyWeather);

    const spraying = report?.data.sprayingForecast || [];
    const formattedSpraying = getFormattedSprayingForecast(spraying);
    setSprayingForecast(formattedSpraying);

    const disease = report?.data.diseaseForecast || [];
    setDiseaseForecast(disease);
  }, [report]);

  useEffect(() => {
    if (report && region?.type === RegionType.Farm) {
      setPlot((report as ReportDTO<FarmReportData>)?.data.plot);
    }
  }, [region, report]);

  useEffect(() => {
    return () => {
      dispatch(actions.shared.report.clear());
    };
  }, [dispatch]);

  return (
    <Report.Provider
      value={{
        alerts,
        weather,
        sprayingForecast,
        diseaseForecast,
        region,
        plot,
        client,
        reportGeneratedAt,
        isLoading,
        isEmpty,
      }}
    >
      {children}
    </Report.Provider>
  );
};

export default ReportProvider;
