import { SagaIterator } from '@redux-saga/types';
import { SharedError } from '@sinagro/shared/errors';
import { call, put, takeLatest } from 'redux-saga/effects';
import { updateUserNotificationConfig } from '../../services/user';
import {
  FetchStatusEnum,
  UpdateUserNotificationConfigAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(
    actions.updateUserNotificationConfig.type,
    updateUserNotificationConfigEffectSaga
  ),
];

export function* updateUserNotificationConfigEffectSaga(
  action: UpdateUserNotificationConfigAction
): SagaIterator<void> {
  try {
    yield call(updateUserNotificationConfig, action.payload);
    yield put(
      actions.updateStatusUpdateUserNotificationConfig({
        fetchStatusUpdateUserNotificationConfig: FetchStatusEnum.Success,
      })
    );
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(
        actions.updateErrorUpdateUserNotificationConfig({
          errorUpdateUserNotificationConfig: knownError,
        })
      );
    }

    yield put(
      actions.updateStatusUpdateUserNotificationConfig({
        fetchStatusUpdateUserNotificationConfig: FetchStatusEnum.Failure,
      })
    );
  }
}
