import React from 'react';

import { useIsMobile } from '../../../hooks/responsiveLayout';
import DesktopMenu from './desktop';
import MobileMenu from './mobile';

type Props = {
  openMenu: boolean;
  onClick: () => void;
};

const Menu = ({ openMenu, onClick }: Props) => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <MobileMenu />
  ) : (
    <DesktopMenu onClick={onClick} openMenu={openMenu} isMobile={isMobile} />
  );
};

export default Menu;
