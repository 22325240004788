import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const MainContainer = styled(Grid)<{ $openMenu: boolean }>`
  position: fixed;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.primary};
  padding-bottom: 1rem;
  height: 100vh;
  gap: 1rem;
  box-shadow: 3px -1px 2px #989898;

  && {
    width: ${({ $openMenu }) => ($openMenu ? 215 : 80)}px;
  }

  transition: width 300ms;
`;
