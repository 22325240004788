/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit';

import {
  FetchStatusEnum,
  IntegrationConfigAction,
  IntegrationConfigSlice,
  IntegrationConfigUpdateErrorAction,
  IntegrationConfigUpdateStatusAction,
} from '../../types';

export const initialState: IntegrationConfigSlice = {
  fetchStatus: FetchStatusEnum.None,
  integrations: [],
  error: null,
};

export const slice = createSlice({
  name: 'integrationConfig',
  initialState,
  reducers: {
    clear: () => initialState,
    getIntegrationConfig: (state) => {
      state.fetchStatus = FetchStatusEnum.Fetching;
    },
    integrationConfig: (state, action: IntegrationConfigAction) => {
      state.integrations = action.payload.integrations;
    },
    updateStatusIntegrationConfig: (
      state,
      action: IntegrationConfigUpdateStatusAction
    ) => {
      state.fetchStatus = action.payload.fetchStatus;
    },
    updateError: (state, action: IntegrationConfigUpdateErrorAction) => {
      state.error = action.payload.error;
    },
  },
});

export const actions = slice.actions;

export const reducer = slice.reducer;
