import { Grid, Typography } from '@material-ui/core';
import { DefaultThemeColorsType } from '@sinagro/frontend-shared/themes';
import styled, { css } from 'styled-components';

export const ItemContainer = styled(Grid)``;

export const Container = styled(Grid)<{ $isActive: boolean }>`
  width: 100%;
  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.primaryLight} !important;
    `}
`;

export const Tab = styled.div<{ $isActive: boolean }>`
  width: 100%;
  height: 8px;
  background: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.primaryLight : theme.colors.white};
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
`;

export const Header = styled(Grid)`
  display: flex;
  flex: 1;
  min-width: 52px;
  height: 48px;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.primaryLight};
`;

export const Hour = styled(Typography)<{ $isActive: boolean }>`
  && {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSize.md}px;
    font-weight: ${({ $isActive }) => ($isActive ? 700 : 400)};
  }
`;

export const Content = styled(Grid)`
  display: flex;
  flex: 1;
  min-width: 52px;
  flex-direction: column;
  height: 76px;
  align-items: center;
  justify-content: flex-start;
  padding-top: 16;
`;

export const Bar = styled(Grid)`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: flex-end;
`;

export const Text = styled(Typography)<{ $color: DefaultThemeColorsType }>`
  && {
    color: ${({ theme, $color }) => theme.colors[$color]};
    font-size: ${({ theme }) => theme.fontSize.sm}px;
  }
`;

export const Spraying = styled(Grid)<{ $color: DefaultThemeColorsType }>`
  display: flex;
  width: 100%;
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, $color }) => theme.colors[$color]};
  align-items: center;
  justify-content: center;
`;

export const Risk = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.md}px;
  }
`;
