import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectProps as SelectPropsUI,
} from '@material-ui/core';
import React from 'react';
import { CSSProperties } from 'styled-components';

import { SelectOption } from '../../../types';
import { SelectStyled } from './styles';

type SelectProps = SelectPropsUI & {
  options: SelectOption[];
  handleChange: (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => void;
  value?: string;
  label?: string;
  persistLabelInSelect?: boolean;
  labelStyle?: CSSProperties;
  border?: string;
  style?: CSSProperties;
};

const Select = ({
  options,
  handleChange,
  value,
  labelStyle,
  label,
  border,
  style,
  persistLabelInSelect,
  ...rest
}: SelectProps) => {
  return (
    <FormControl fullWidth={rest.fullWidth}>
      {label && (
        <InputLabel
          id="select-label-element"
          style={{
            zIndex: 1,
            marginTop: '-0.8rem',
            paddingLeft: 12,
            pointerEvents: 'none',
            ...labelStyle,
          }}
        >
          {label}
        </InputLabel>
      )}
      <SelectStyled
        labelId="select-label-element"
        variant="outlined"
        label={persistLabelInSelect ? label : undefined}
        value={value}
        style={{ width: 150, ...style }}
        border={border}
        inputProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            disableScrollLock: true,
            style: {
              maxHeight: 276,
              position: 'absolute',
              marginTop: -8,
            },
          },
        }}
        onChange={handleChange}
        {...rest}
      >
        {options.map((option, index) => (
          <MenuItem
            key={`select-item-${index}`}
            value={option.value}
            data-testid={`option-${option.value}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  );
};

export default Select;
