import { DefaultThemeColorsType } from '@sinagro/frontend-shared/themes';
import {
  SprayingForecastRisk,
  SprayingRecommendationEnum,
} from '@sinagro/shared';
import i18n from 'i18next';
import { SprayingRecommendationEnumTranslated } from 'src/utils/enumsTranslate';

type SprayingRecommendationProps = {
  color: DefaultThemeColorsType;
  label: string;
};

export const sprayingRecommendation: Record<
  string,
  SprayingRecommendationProps
> = {
  [SprayingRecommendationEnum.NotRecommended]: {
    color: 'orange',
    label:
      SprayingRecommendationEnumTranslated[
        SprayingRecommendationEnum.NotRecommended
      ],
  },
  [SprayingRecommendationEnum.Recommended]: {
    color: 'quaternary',
    label:
      SprayingRecommendationEnumTranslated[
        SprayingRecommendationEnum.Recommended
      ],
  },
  [SprayingRecommendationEnum.RecommendedAgainst]: {
    color: 'danger',
    label:
      SprayingRecommendationEnumTranslated[
        SprayingRecommendationEnum.RecommendedAgainst
      ],
  },
  ['default']: {
    color: 'primaryLight',
    label: '',
  },
};

export const riskSprayingCaption: SprayingForecastRisk[] = [
  {
    code: 'S',
    description: i18n.t('torq.spraying.riskCaption.s'),
  },
  {
    code: 'RS',
    description: i18n.t('torq.spraying.riskCaption.rs'),
  },
  {
    code: 'H',
    description: i18n.t('torq.spraying.riskCaption.h'),
  },
  {
    code: 'RH',
    description: i18n.t('torq.spraying.riskCaption.rh'),
  },
  {
    code: 'L',
    description: i18n.t('torq.spraying.riskCaption.l'),
  },
  {
    code: 'RL',
    description: i18n.t('torq.spraying.riskCaption.rl'),
  },
  {
    code: 'E',
    description: i18n.t('torq.spraying.riskCaption.e'),
  },
  {
    code: 'RE',
    description: i18n.t('torq.spraying.riskCaption.re'),
  },
  {
    code: 'R',
    description: i18n.t('torq.spraying.riskCaption.r'),
  },
  {
    code: 'RR',
    description: i18n.t('torq.spraying.riskCaption.rr'),
  },
  {
    code: 'W',
    description: i18n.t('torq.spraying.riskCaption.w'),
  },
  {
    code: 'RW',
    description: i18n.t('torq.spraying.riskCaption.rw'),
  },
  {
    code: 'RM',
    description: i18n.t('torq.spraying.riskCaption.rm'),
  },
];
