import { SagaIterator } from '@redux-saga/types';
import { SharedError } from '@sinagro/shared/errors';
import { replace } from 'redux-first-history';
import { call, takeLatest, put } from 'redux-saga/effects';

import { changePassword, recoveryPassword } from '../../services/user';
import {
  ChangePasswordAction,
  FetchStatusEnum,
  RecoveryPasswordAction,
} from '../../types';
import { handleError } from '../errors/sagas';
import { actions } from './slice';

export default [
  takeLatest(actions.recovery.type, recoveryPasswordEffectSaga),
  takeLatest(actions.changePassword.type, changePasswordEffectSaga),
];

export function* recoveryPasswordEffectSaga(
  action: RecoveryPasswordAction
): SagaIterator<void> {
  try {
    yield call(recoveryPassword, action.payload);
    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Success }));
    yield put(replace('/'));
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(actions.updateError({ error: knownError }));
    }

    yield put(actions.updateStatus({ fetchStatus: FetchStatusEnum.Failure }));
  }
}

export function* changePasswordEffectSaga(
  action: ChangePasswordAction
): SagaIterator<void> {
  try {
    yield call(changePassword, action.payload);
    yield put(
      actions.updateStatusChangePassword({
        fetchStatusChangePassword: FetchStatusEnum.Success,
      })
    );
    yield put(replace('/'));
  } catch (error) {
    const knownError: SharedError = yield call(handleError, {
      error,
    });

    if (knownError) {
      yield put(
        actions.updateErrorChangePassword({ errorChangePassword: knownError })
      );
    }

    yield put(
      actions.updateStatusChangePassword({
        fetchStatusChangePassword: FetchStatusEnum.Failure,
      })
    );
  }
}
