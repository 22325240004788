import { MenuList, Typography } from '@material-ui/core';
import { auth } from '@sinagro/frontend-shared/firebase';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdSettings } from 'react-icons/io';
import { MdGroup, MdLogout, MdPerson, MdVpnKey } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useNotificationContext } from 'src/contexts/notification';
import { ProfileTabListIdsEnum } from 'src/pages/profile/tabs';

import Button from '../../../../../components/elements/materialUI/button';
import { actions, useDispatch } from '../../../../../state/store';
import HeaderItem, { HeaderItemProps } from './headerItems';
import { HeaderSubItem } from './styles';

type Item = HeaderItemProps[];

export const items: Item = [
  {
    id: ProfileTabListIdsEnum.userProfile,
    label: 'header.myProfile',
    url: '/profile/user-profile',
    Icon: MdPerson,
  },
  {
    id: ProfileTabListIdsEnum.userManagement,
    label: 'header.userManagement',
    url: '/profile/user-management',
    Icon: MdGroup,
  },
  {
    id: ProfileTabListIdsEnum.changePassword,
    label: 'header.changePassword',
    url: '/profile/change-password',
    Icon: MdVpnKey,
  },
  {
    id: ProfileTabListIdsEnum.changePassword,
    label: 'header.notificationPreferences',
    url: '/profile/notification-preferences',
    Icon: IoMdSettings,
  },
];

interface Props {
  isThirdPartyUser: boolean;
}

const MenuItems = ({ isThirdPartyUser }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deleteUserWebToken } = useNotificationContext();

  const handleLogOut = useCallback(() => {
    deleteUserWebToken && deleteUserWebToken();
    auth().signOut();
    dispatch(actions.shared.trade.clear());
    dispatch(actions.shared.user.clear());
    dispatch(actions.shared.userProfile.clear());
    dispatch(actions.shared.orders.clear());
    navigate('/', { replace: true });
  }, [deleteUserWebToken, dispatch, navigate]);

  const checkIfUserCanChangePassword = useCallback(
    (itemId: ProfileTabListIdsEnum) => {
      if (!isThirdPartyUser) return true;
      if (itemId !== ProfileTabListIdsEnum.changePassword) return true;
      return false;
    },
    [isThirdPartyUser]
  );

  return (
    <MenuList style={{ padding: '0', width: '100%' }}>
      {items.map((item, index) =>
        checkIfUserCanChangePassword(item.id) ? (
          <HeaderItem key={`header-item-${index}`} {...item} />
        ) : (
          <></>
        )
      )}

      <HeaderSubItem
        item
        container
        xs={12}
        direction="row"
        onClick={handleLogOut}
        justifyContent={'flex-start'}
        data-testid="logout"
      >
        <Button>
          <MdLogout
            color="primary"
            style={{ height: 20, width: 20, marginRight: 8 }}
          />
          <Typography color="textPrimary" variant="body1">
            {t('commons.logOut')}
          </Typography>
        </Button>
      </HeaderSubItem>
    </MenuList>
  );
};

export default MenuItems;
