import { Typography, Grid, Link } from '@material-ui/core';
import { WhatsApp, EmailOutlined } from '@material-ui/icons';
import { auth } from '@sinagro/frontend-shared/firebase';
import {
  FirebasePersistenceEnum,
  AuthenticatedEnum,
} from '@sinagro/frontend-shared/src/types';
import { ValidationCodeTypeEnum } from '@sinagro/shared';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Logo from '../../components/elements/logo';
import Button from '../../components/elements/materialUI/button';
import WelcomeLayout from '../../components/layout/welcomeLayout';
import { useDispatch, actions, useSelector } from '../../state/store';
import {
  ButtonContainer,
  LogoContainer,
  TitleContainer,
  TitleLabel,
} from './styles';

const Validation: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchStatus = useSelector(
    (state) => state.shared.validationCode.fetchStatus
  );

  const userPersistence = useSelector((state) => state.shared.user.persistence);

  const handleNavigation = useCallback(
    (validationCode: ValidationCodeTypeEnum) => {
      dispatch(
        actions.shared.validationCode.request({
          type: validationCode,
        })
      );
      dispatch(
        actions.shared.user.userAuthenticated({
          userAuthenticated: AuthenticatedEnum.Authenticated,
        })
      );
      navigate('/validation-code', {
        state: { validationCode: validationCode },
      });
    },
    [dispatch, navigate]
  );

  const handleLogOut = useCallback(() => {
    auth().signOut();
    navigate('/', { replace: true });
  }, [navigate]);

  useEffect(() => {
    if (userPersistence !== FirebasePersistenceEnum.Local) {
      auth().setPersistence(
        process.env.NODE_ENV === 'test'
          ? FirebasePersistenceEnum.None
          : FirebasePersistenceEnum.Session
      );
    }
  }, [dispatch, fetchStatus, userPersistence]);

  return (
    <WelcomeLayout>
      <Grid
        container
        item
        xs={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid
          container
          item
          xs={12}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ flex: 1 }}
        >
          <LogoContainer item xs={12}>
            <Logo />
          </LogoContainer>
          <TitleContainer item xs={12}>
            <TitleLabel variant="h1" color="textPrimary">
              {t('validation.title')}
            </TitleLabel>
          </TitleContainer>
          <ButtonContainer item xs={12} lg={8}>
            <Button
              onClick={() => handleNavigation(ValidationCodeTypeEnum.Email)}
              variant="contained"
              color="primary"
              fullWidth
              data-testid="buttonEmailValidation"
            >
              <EmailOutlined style={{ marginRight: 5 }} />
              <Typography variant="h6">{t('validation.email')}</Typography>
            </Button>
          </ButtonContainer>
          <ButtonContainer item xs={12} lg={8}>
            <Button
              onClick={() => handleNavigation(ValidationCodeTypeEnum.Phone)}
              variant="contained"
              color="primary"
              fullWidth
            >
              <WhatsApp style={{ marginRight: 5 }} />
              <Typography variant="h6">{t('validation.phone')}</Typography>
            </Button>
          </ButtonContainer>
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end">
          <Link
            component="button"
            onClick={handleLogOut}
            underline="always"
            style={{ fontSize: 18, paddingBottom: '1rem' }}
          >
            <>{t('commons.logOut')}</>
          </Link>
        </Grid>
      </Grid>
    </WelcomeLayout>
  );
};

export default Validation;
