import { SprayingRecommendationEnum } from '@sinagro/shared';
import React from 'react';

import { sprayingRecommendation } from '../../../../pages/torq/utils/sprayingRecommendation';
import { SprayingIcon } from '../../../../pages/torq/utils/styles';
import { Badge, BadgeLabel, Container } from './styles';

const RecommendedSprayingCaption: React.FC = () => {
  const sprayingRecommendations = Object.values(SprayingRecommendationEnum);

  return (
    <Container>
      {sprayingRecommendations.map((item) => (
        <Badge $color={sprayingRecommendation[item].color} key={item}>
          <SprayingIcon size={18} fill="white" />
          <BadgeLabel>{sprayingRecommendation[item].label}</BadgeLabel>
        </Badge>
      ))}
    </Container>
  );
};

export default RecommendedSprayingCaption;
