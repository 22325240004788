import { InvoiceUrlDTO, RemittanceReceipt } from '@sinagro/shared';

export function xlsxBufferToURL(data: string) {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
  });

  return URL.createObjectURL(blob);
}

export function download(url: string, fileName: string) {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.click();
}

export function downloadInvoice(
  fileName: string,
  pdfUrl?: string,
  xmlUrl?: string
) {
  if (pdfUrl) {
    download(pdfUrl, fileName);
  }

  if (xmlUrl) {
    download(xmlUrl, fileName);
  }
}

export function downloadRemittanceReceipts(
  remittanceReceipts: RemittanceReceipt[]
) {
  remittanceReceipts.map((receipt) =>
    receipt.files.map((file, index) =>
      download(file.url, `${receipt.remittanceId}_${index}`)
    )
  );
}

export function downloadRemittanceInvoices(
  remittanceId: string,
  remittanceInvoice: InvoiceUrlDTO[]
) {
  remittanceInvoice.map((file, index) =>
    downloadInvoice(`${remittanceId}_${index}`, file.pdfUrl, file.xmlUrl)
  );
}
