import { Grid } from '@material-ui/core';
import { FarmDetailsDTO, RegionType } from '@sinagro/shared';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Plot from 'src/components/modules/torq/plot';
import PlotCaption from 'src/components/modules/torq/plotCaption';
import { useReportContext } from 'src/contexts/report';
import { useIsMobile } from 'src/hooks/responsiveLayout';

import { Card, Container, Content, Title } from './styles';

const Plots = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { region } = useReportContext();

  if (region === null || region.type !== RegionType.Farm) {
    return <></>;
  }

  const plots = (region as FarmDetailsDTO).children;
  const hideScroll = plots.length < 7 && !isMobile;

  return (
    <Container container $isMobile={isMobile}>
      <Title>{t('report.plots')}</Title>
      <PlotCaption />
      <Content $hideScroll={hideScroll}>
        <Grid container spacing={2}>
          {plots.map((item) => (
            <Grid item xs={12} md={6} lg={4} zeroMinWidth key={item.id}>
              <Card>
                <Plot item={item} />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Content>
    </Container>
  );
};

export default Plots;
