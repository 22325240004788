import { PlotDetails } from '@sinagro/shared';
import React from 'react';
import { growthColor } from 'src/pages/torq/utils/growth';
import {
  CropIcon,
  GrowthCurveIcon,
  HarvestEstimateIcon,
  NDVIIcon,
} from 'src/pages/torq/utils/styles';
import { trendColor, trendIcon } from 'src/pages/torq/utils/trend';
import { OptimalGrowthCurveStatusEnumTranslated } from 'src/utils/enumsTranslate';
import { formatMeasurementUnit } from 'src/utils/format';

import {
  PlotContent,
  PlotCrop,
  PlotCropContainer,
  PlotHeader,
  PlotItem,
  PlotText,
  PlotTitle,
} from './styles';

type PlotProps = {
  item: PlotDetails;
};

const Plot = ({ item }: PlotProps) => {
  return (
    <>
      <PlotHeader>
        <PlotTitle>{item.name}</PlotTitle>
        <PlotCropContainer>
          <CropIcon />
          <PlotCrop>{item.crop}</PlotCrop>
        </PlotCropContainer>
      </PlotHeader>

      <PlotContent>
        <PlotItem>
          <HarvestEstimateIcon />
          <PlotText>
            {formatMeasurementUnit(
              item.harvestEstimate.value,
              item.harvestEstimate.measurementUnit,
              1
            )}
          </PlotText>
        </PlotItem>

        <PlotItem $growthColor={growthColor[item.growthCurve]}>
          <GrowthCurveIcon fill={growthColor[item.growthCurve]} />
          <PlotText>
            {OptimalGrowthCurveStatusEnumTranslated[item.growthCurve]}
          </PlotText>
        </PlotItem>

        <PlotItem $color={trendColor[item.ndvi.current.trend]}>
          <NDVIIcon />
          <PlotText>
            {formatMeasurementUnit(item.ndvi.current.value, null, 2)}
            {trendIcon[item.ndvi.current.trend]}
          </PlotText>
        </PlotItem>
      </PlotContent>
    </>
  );
};

export default Plot;
